/* eslint-disable import/no-unused-modules */
import {
  PublicClientApplication,
  EventType,
  LogLevel,
} from '@azure/msal-browser'
import { miniSerializeError } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import streamManager, { TAG as STREAM_MANAGER_TAG } from './stream-manager'

const SECONDS_IN_MINUTE = 60

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${window.__ENV.REACT_APP_AAD_TENANT_ID}`,
    clientId: window.__ENV.REACT_APP_AAD_CLIENT_ID,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  // https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#cacheoptions
  cache: {
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
    // secureCookies
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  // scopes: ["User.Read"]
}

/****************************
 * Initialize
 ****************************/

// TODO: shouldn't have to export this, it shouldn't be used directly
export const msal = new PublicClientApplication(msalConfig)

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msal.getAllAccounts()
if (accounts.length > 0) {
  msal.setActiveAccount(accounts[0])
}

// auto set account when logged in
msal.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    msal.setActiveAccount(event.payload.account)
  }
})

// The response includes dates, which aren't serializable, and
// thus shouldn't be put in the Redux store. Change the dates to
// timestamps (in seconds).
const datesToTimeStamps = (response) => ({
  ...response,
  expiresOn: Math.floor(response.expiresOn.getTime() / 1000),
  extExpiresOn: Math.floor(response.extExpiresOn.getTime() / 1000),
})

/****************************
 * Define RTKQ API
 ****************************/

const msalApi = createApi({
  reducerPath: 'msal',
  baseQuery: fetchBaseQuery,
  endpoints: (build) => {
    const tokenQuery = (loginRequest) =>
      build.query({
        queryFn: async (_, { dispatch }) => {
          try {
            let account = msal.getActiveAccount()

            if (!account) {
              // Not logged in yet, this can happen at first page load (active
              // account not yet set).
              account = await new Promise((resolve) => {
                msal.addEventCallback((event) => {
                  if (
                    event.eventType === EventType.LOGIN_SUCCESS &&
                    event.payload.account
                  ) {
                    msal.setActiveAccount(event.payload.account)
                    resolve(msal.getActiveAccount())
                  }
                })
              })
            }

            const response = await msal.acquireTokenSilent({
              ...loginRequest,
              account,
            })

            dispatch(
              streamManager.util.invalidateTags([
                STREAM_MANAGER_TAG.UNAUTHORIZED_ERROR,
              ]),
            )

            return { data: datesToTimeStamps(response) }
          } catch (error) {
            console.error(error)
            return { error: { data: miniSerializeError(error) } }
          }
        },
        onCacheEntryAdded: async (
          _,
          { dispatch, cacheDataLoaded, cacheEntryRemoved, updateCachedData },
        ) => {
          await cacheDataLoaded

          // periodically get token from cache, or from server
          // prevent longer periods of inactivity (only watching a stream) from causing logout
          const periodicGetMsalTokenIntervalId = setInterval(
            async function periodicGetMsalToken() {
              try {
                let account = msal.getActiveAccount()

                if (!account) {
                  // Not logged in yet, this can happen at first page load (active
                  // account not yet set).
                  account = await new Promise((resolve) => {
                    msal.addEventCallback((event) => {
                      if (
                        event.eventType === EventType.LOGIN_SUCCESS &&
                        event.payload.account
                      ) {
                        msal.setActiveAccount(event.payload.account)
                        resolve(msal.getActiveAccount())
                      }
                    })
                  })
                }

                const response = await msal.acquireTokenSilent({
                  ...loginRequest,
                  account,
                })

                updateCachedData(() => datesToTimeStamps(response))
                dispatch(
                  streamManager.util.invalidateTags([
                    STREAM_MANAGER_TAG.UNAUTHORIZED_ERROR,
                  ]),
                )
              } catch (error) {
                console.error(error)
                return { error: { data: miniSerializeError(error) } }
              }
            },
            5 * SECONDS_IN_MINUTE * 1000,
          )

          await cacheEntryRemoved
          clearInterval(periodicGetMsalTokenIntervalId)
        },
      })

    return {
      appToken: tokenQuery({
        scopes: [`${window.__ENV.REACT_APP_AAD_CLIENT_ID}/.default`],
      }),
      userToken: tokenQuery({
        scopes: ['https://graph.microsoft.com/.default'],
      }),
      // TODO: create logout mutation
    }
  },
})

export default msalApi

export const { useAppTokenQuery, useUserTokenQuery } = msalApi

export const useLoginQuery = () => {
  useAppTokenQuery()
  useUserTokenQuery()
}

export const selectUserAuth = msalApi.endpoints.userToken.select()
export const selectAppAuth = msalApi.endpoints.appToken.select()
export const selectAdmin = (state) => {
  const roles = selectAppAuth(state).data?.idTokenClaims?.roles
  return roles?.includes(window.__ENV.REACT_APP_ADMINISTRATOR_ROLE)
}
export const useUserAuth = () => useSelector(selectUserAuth)
export const useAppAuth = () => useSelector(selectAppAuth)
export const useIsAdmin = () => useSelector(selectAdmin)

export const withAdmin = (Component) => {
  const WrappedComponent = (props) => {
    const isAdmin = useIsAdmin()
    return isAdmin && <Component {...props} />
  }
  WrappedComponent.displayName = `withAdmin(${Component.displayName})`
  return WrappedComponent
}

export const MsalAuthentication = ({ children }) => {
  useLoginQuery()
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    msal.handleRedirectPromise().then((res) => setIsAuthenticated(Boolean(res)))
  }, [])

  useEffect(() => {
    if (isAuthenticated === false) {
      msal.loginRedirect()
    }
  }, [isAuthenticated])

  return isAuthenticated ? children : null
}
