const boxToDropShadow = boxShadow => boxShadow
    // Split the shadows
    // https://regex101.com/r/VlMY7Y/1
    .split(/((?:-?\d+(?:px)?\s){4}(?:rgb|hsl)a?\((?:\d(?:\.\d+)?,?){4}\),?)/)
    .filter(Boolean) // remove empty strings
    .map(str => str.replace(/,$/, '')) // remove trailing commas
    // Remove the 4th value, it’s valid for box-shadow but not for drop-shadow
    // https://regex101.com/r/VeK9vP/1
    .map(str => str.replace(/((?:-?\d+(?:px)?\s){3})(-?\d+(?:px)\s?)(.*)/, '$1$3'))
    .map(shadow => `drop-shadow(${shadow})`)
    .join(' ')

export default boxToDropShadow
