import React, { useEffect, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'inline-block',
    paddingTop: theme.spacing(1),
  },
  dropZone: {
    position: 'relative',
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.text.secondary,
    borderStyle: 'dashed',
    backgroundColor:
      theme.palette.type === 'light'
        ? 'rgba(0, 0, 0, 0.09)'
        : 'rgba(255, 255, 255, 0.09)',
    color: theme.palette.text.secondary,
    outline: 'none',
    transition: [
      `border ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
      `backgroundColor ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
    ].join(', '),
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.13)'
          : 'rgba(255, 255, 255, 0.13)',
    },
    '&$active': {
      borderColor: theme.palette.primary.main,
    },
    '&$accepted': {
      borderColor: theme.palette.success.main,
    },
    '&$rejected': {
      borderColor: theme.palette.error.main,
    },
    '&$error': {
      borderColor: theme.palette.error.main,
    },
  },
  active: {},
  accepted: {},
  rejected: {},
  error: {},
  text: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: '#fafafa',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    '& p:first-child': {
      marginTop: 0,
    },
    '& p:last-child': {
      marginBottom: 0,
    },
  },
}))

const FileUpload = ({
  label,
  onChange,
  name,
  error,
  helperText,
  value: _,
  'data-tour': dataTour,
  ...props
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    multiple: false,
    accept: '.csv,text/csv',
    onDrop: (acceptedFiles) => {
      onChange({ target: { name, value: acceptedFiles?.[0] } })
    },
  })

  const file = acceptedFiles?.[0]
  const fileUrl = useMemo(() => file && URL.createObjectURL(file), [file])

  const classes = useStyles()

  useEffect(
    () => () => {
      URL.revokeObjectURL(fileUrl)
    },
    [fileUrl],
  )

  return (
    <>
      <FormLabel className={classes.label}>{label}</FormLabel>
      <div
        data-tour={dataTour}
        className={classes.dropZone}
        {...getRootProps({
          className: clsx(classes.dropZone, {
            [classes.active]: isDragActive,
            [classes.accepted]: isDragAccept,
            [classes.rejected]: isDragReject,
            [classes.error]: error,
          }),
        })}
      >
        <input {...props} {...getInputProps()} />
        <div className={classes.text}>
          {isDragActive ? (
            <p>Drop the video here ...</p>
          ) : file ? (
            <>
              <p>
                <em>{file.name}</em>
              </p>
              <p>
                To change your selection, drag 'n' drop a file, or click to
                select a file
              </p>
            </>
          ) : (
            <p>Drag 'n' drop a file, or click to select a file</p>
          )}
        </div>
      </div>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  )
}

export default FileUpload
