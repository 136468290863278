import React, { useCallback, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { dateToString, msToTime, generateDvrTimelineMarks } from './utils'

import { MODE } from './state'

const useStyles = makeStyles((theme) => ({
  separator: {
    display: 'block',
    width: 2,
    height: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    currentTime: 'relative',
    bottom: -13,
  },
  separatorLabel: {
    currentTime: 'absolute',
    top: theme.spacing(-2),
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: 11,
  },
  timelineItem: {
    paddingRight: theme.spacing(10),
    '&:last-child': {
      paddingRight: 0,
    },
  },
  timeline: {
    flex: '1 0 100%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  // Slider class name without makeStyles override is .MuiSlider-root.
  root: {
    padding: 0,
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}))

const TimelineValueTooltip = ({ children, open, value, valueLabelDisplay }) => {
  const { arrow, tooltip } = useStyles()
  // Get formatted title here since we have to set it to null before hiding the tooltip.
  // If we don't do this, the title appears below the normal tooltip location after
  // hiding the tooltip with open = false.
  let title = value

  // valueLabelDisplay prop == 'off' indicates tooltip should be hidden
  if (valueLabelDisplay === 'off') {
    open = false
    title = null
  }
  return (
    <Tooltip
      arrow
      classes={{ arrow, tooltip }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={title || ''}
    >
      {children}
    </Tooltip>
  )
}

TimelineValueTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  valueLabelDisplay: PropTypes.string.isRequired,
}

const DVRTimeline = ({
  onSeekStart,
  onSeekEnd,
  timeline,
  currentTime,
  mode,
  ...rest
}) => {
  const prevSeekActive = useRef(false)
  // track whether slider is busy seeking (used to control visibility of slider timestamp)
  const [seekActive, setSeekActive] = useState(false)
  const [time, setTime] = useState(currentTime)

  const marks = generateDvrTimelineMarks(timeline).map(({ value }) => ({
    value,
  }))

  const classes = useStyles()

  const handleChange = useCallback((event, time) => {
    setTime(time)
    // Ensures slider timestamp remains visible.
    setSeekActive(true)
  }, [])

  // Set seekActive to false
  const handleChangeCommitted = useCallback((event, time) => {
    setTime(time)
    // Slider timestamp tooltip invisible when seekActive is false.
    setSeekActive(false)
  }, [])

  const valueLabelFormat = useCallback(
    (value) => {
      return mode === MODE.VOD
        ? msToTime(new Date(+value))
        : dateToString(new Date(+value))
    },
    [mode],
  )

  useEffect(() => {
    if (!seekActive) {
      setTime(currentTime)
    }
  }, [currentTime, seekActive])

  useEffect(() => {
    if (seekActive && !prevSeekActive.current) {
      onSeekStart?.(time)
      prevSeekActive.current = seekActive
    } else if (!seekActive && prevSeekActive.current) {
      onSeekEnd?.(time)
      prevSeekActive.current = seekActive
    }
  }, [seekActive, time, onSeekStart, onSeekEnd])

  return (
    <div data-tour="player-timeline" className={classes.timeline}>
      {/*TODO: [adt] Removed class spec on this diff since it had empty style definition.*/}
      <Slider
        defaultValue={timeline[timeline.length - 1].end}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        value={time}
        className={classes.root}
        min={timeline[0].start}
        max={timeline[timeline.length - 1].end}
        marks={marks}
        ValueLabelComponent={TimelineValueTooltip}
        valueLabelDisplay={seekActive ? 'on' : 'off'}
        valueLabelFormat={valueLabelFormat}
        {...rest}
      />
    </div>
  )
}

DVRTimeline.propTypes = {
  currentTime: PropTypes.number,
  timeline: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  ),
  onSeekStart: PropTypes.func,
  onSeekEnd: PropTypes.func,
  mode: PropTypes.oneOf(Object.values(MODE)),
}

export default DVRTimeline
