import React from 'react'
import moment from 'moment'

import Paper from '@material-ui/core/Paper'

import {
  useUsersQuery,
  useDisclaimerLogQuery,
  useDownloadCsv,
} from '../../services/stream-manager'
import { withAdmin } from '../../services/msal'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import EnhancedTable from '../table/EnhancedTable'

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    id: 'accepted',
    Header: 'Accepted',
    accessor: 'disclaimer_acknowledged',
    Cell: ({ value }) => (value ? 'Yes' : 'No'),
  },
  {
    id: 'date',
    Header: 'Date',
    accessor: 'disclaimer_acknowledged',
    Cell: ({ value }) => (value ? moment.utc(value).format('D MMMM YYYY') : ''),
  },
  {
    id: 'time',
    Header: 'Time',
    accessor: 'disclaimer_acknowledged',
    Cell: ({ value }) =>
      value ? moment.utc(value).format('HH:mm') + ' UTC' : '',
  },
]

const Logs = () => (
  <Paper>
    <EnhancedTable
      label="Disclaimer Acceptance Log"
      columns={columns}
      itemsQuery={usePaginatedFilteredQuery(
        useUsersQuery
      )(
        { params: { order: '-disclaimer_acknowledged' } }
      )}
      downloadCsv={useDownloadCsv(usePaginatedFilteredQuery(
        useDisclaimerLogQuery,
        { urlKey: 'disclaimer' },
      ))()}
      search
    />
  </Paper>
)

export const LogsPage = withAdmin(Logs)
