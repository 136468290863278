import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PublicIcon from '@material-ui/icons/Public'

import { useDivisionsQuery } from '../../services/stream-manager'
import useFilterParams from '../../hooks/useFilterParams'

import DivisionListItem from './DivisionListItem'

import useSurfaceStyles from './styles'


const DivisionList = ({ onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const divisions = useDivisionsQuery()

  const routeMatch = useRouteMatch('/streams')
  const [{ division, site, section }] = useFilterParams()

  const selected = routeMatch && !section && !site && !division

  return (
    <List>
      <ListItem
        className={surfaceClasses.listItem}
        classes={{ selected: surfaceClasses.listItemSelected }}
        data-tour="sidebar-streams"
        selected={selected}
        button
        component={Link}
        to="/streams"
      >
        <ListItemIcon>
          <PublicIcon className={surfaceClasses.drawerIcons} />
        </ListItemIcon>
        <ListItemText primary="ALL" onClick={onSelect} />
      </ListItem>
      {divisions.data?.map((division) => (
        <DivisionListItem key={division.id} division={division} onSelect={onSelect} />
      ))}
    </List>
  )
}

DivisionList.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default DivisionList
