import React, { useEffect, useMemo } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import MomentUtils from '@date-io/moment'
import _merge from 'lodash/merge'

import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createTheme,
  alpha,
  lighten,
  darken,
} from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import history from './history'

import { useLoginQuery, MsalAuthentication } from './services/msal'
import { useMeQuery, useRegisterMeMutation } from './services/stream-manager'

import Disclaimer from './features/dialogs/Disclaimer'
import Surface from './features/surface/Surface'
import HomePage from './features/home/HomePage'
import StreamsPage from './features/streams/StreamsPage'
import StreamPage from './features/streams/StreamPage'
import MapPage from './features/map/MapPage'
import SettingsPage from './features/settings/SettingsPage'
import AnalyticsPage from './features/analytics/AnalyticsPage'
import DashboardPage from './features/dashboard/DashboardPage'
import DigitalSignageDisplayPage from './features/digital-signage/DisplayPage'
import DigitalSignageManagementPage from './features/digital-signage/ManagementPage'
import DigitalSignagePreviewPage from './features/digital-signage/PreviewPage'
import Orchestrator from './features/player/Orchestrator'
import UpdateApp from './features/update-app/UpdateApp'

import usePrefs from './hooks/usePrefs'

import defaultTheme from './defaultTheme'

import 'fontsource-roboto'

const updateFavicon = (favicon) => {
  const icon = document.querySelector('link[rel*="icon"]')
  if (icon.tagName === 'LINK') {
    icon.href = `/theme/${favicon}`

    const imageExt = favicon.match(/\.[a-z]+/i)
    let mimeType = ''
    if (imageExt !== null) {
      switch (imageExt[0].toLowerCase()) {
        case '.png':
          mimeType = 'image/png'
          break
        case '.jpg':
        case '.jpeg':
          mimeType = 'image/jpeg'
          break
        case '.ico':
          mimeType = 'image/x-icon'
          break
        default:
          mimeType = ''
      }
    }
    icon.type = mimeType !== '' ? mimeType : ''
  }
}


const getIdleTimeout = (meQuery) => {
  if (meQuery.data?.service_account) {
    return null;
  }

  return (Number(window.__ENV.REACT_APP_PLAYER_IDLE_MS ?? null) || 5 * 60 * 1000)
}

const AuthRoutes = ({ waitingServiceWorker }) => {
  // Trigger login
  useLoginQuery()

  // Register the user if necessary
  const meQuery = useMeQuery()
  const [registerMe] = useRegisterMeMutation()
  useEffect(() => {
    if (meQuery.isError) {
      registerMe()
    }
  }, [meQuery.isError, registerMe])

  return (
    <>
      <Disclaimer />
      <UpdateApp waitingServiceWorker={waitingServiceWorker} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MsalAuthentication>
          <Orchestrator
            maxSimultaneousStreams={
              Number(window.__ENV.REACT_APP_PLAYER_MAX_SIMULTANEOUS ?? null) ||
              3
            }
            idle={
              getIdleTimeout(meQuery)
            }
          >
            <Surface>
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/map" component={MapPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/streams" component={StreamsPage} />
                <Route
                  path="/digital-signage/manage"
                  component={DigitalSignageManagementPage}
                />
                <Route path="/stream/:id/:slug?" component={StreamPage} />
                <Route path="/analytics" component={AnalyticsPage} />
                <Route path="/settings" component={SettingsPage} />
                {/*<Route path="/support" component={SupportPage}/>*/}
                <Redirect to="/" />
              </Switch>
            </Surface>
          </Orchestrator>
        </MsalAuthentication>
      </MuiPickersUtilsProvider>
    </>
  )
}

const App = ({ waitingServiceWorker }) => {
  // Set up the theme
  const [prefs] = usePrefs()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = useMemo(() => {
    const colorScheme =
      window.__ENV.REACT_APP_DARKMODE === 'true'
        ? prefs.colorScheme ?? (prefersDarkMode ? 'dark' : 'light')
        : 'light'

    const theme = createTheme(
      _merge(
        {},
        defaultTheme,
        window.theme ?? {},
        colorScheme === 'dark' ? window.themeDark ?? {} : {},
        {
          palette: {
            type: colorScheme,
            ...(colorScheme === 'dark' ? {
              text: {
                primary: '#fff',
                secondary: 'rgba(255, 255, 255, 0.7)',
                disabled: 'rgba(255, 255, 255, 0.5)',
              },
              action: {
                active: '#fff',
                hover: 'rgba(255, 255, 255, 0.08)',
                selected: 'rgba(255, 255, 255, 0.16)',
                disabled: 'rgba(255, 255, 255, 0.3)',
                disabledBackground: 'rgba(255, 255, 255, 0.12)',
              },
              background: {
                default: '#303030',
                paper: '#424242',
              },
              divider: 'rgba(255, 255, 255, 0.12)',
            } : undefined)
          },
        },
      ),
    )

    const scrollbarColor = alpha(
      theme.palette.type === 'dark'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.5,
    )

    return _merge({}, theme, {
      overrides: {
        MuiCssBaseline: {
          '@global': {
            ':root': {
              scrollbarColor: `${scrollbarColor} transparent`,
            },
            /* Works on Chrome, Edge, and Safari */
            '*::-webkit-scrollbar': {
              width: theme.spacing(1),
              height: theme.spacing(1),
            },
            '*::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: scrollbarColor,
              borderRadius: '20px',
              '&:hover': {
                backgroundColor:
                  colorScheme === 'light'
                    ? lighten(scrollbarColor, 0.5)
                    : darken(scrollbarColor, 0.5),
              },
            },
          },
        },
      },
    })
  }, [prefersDarkMode, prefs.colorScheme])

  useEffect(() => {
    if (window.theme?.data?.assets) {
      updateFavicon(window.theme.assets.favicon)
      document.title = window.theme.assets.titleText
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Switch>
          <Route
            path="/digital-signage/preview"
            component={DigitalSignagePreviewPage}
          />
          <Route
            path="/digital-signage/display"
            component={DigitalSignageDisplayPage}
          />
          <Route
            render={() => (
              <AuthRoutes waitingServiceWorker={waitingServiceWorker} />
            )}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

export default App
