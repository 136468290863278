import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntersection } from 'react-use'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'

import { useStreamThumbnailQuery } from '../../services/stream-manager'
import boxToDropShadow from '../../styles/boxToDropShadow'
import boxToTextShadow from '../../styles/boxToTextShadow'
import { StreamProperties } from '../../proptypes/proptypes'
import noImage from '../../assets/no-image.png'

const POLLING_INTERVAL = 60 * 1000 // ms

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    cursor: 'pointer',
  },
  skeleton: {
    width: '100%',
    paddingTop: '56.25%',
  },
  playing: {
    position: 'absolute',
    textTransform: 'uppercase',
    fontWeight: '600',
    color: theme.palette.primary.contrastText,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textShadow: boxToTextShadow(theme.shadows[1]),
  },
  playIconButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  playIcon: {
    color: theme.palette.primary.contrastText,
    fontSize: '60px',
    filter: boxToDropShadow(theme.shadows[1]),
  },
  thumbnail: {
    width: '100%',
    height: 'auto',
    aspectRatio: ({ width, height }) => `${width} / ${height}`,
    display: 'block',
  },
  unavailableImageWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  unavailableImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.2,
  },
}))

export const VideoThumb = ({ className, stream, playing, onClick }) => {
  const [{ width, height }, setDimensions] = useState({})
  const classes = useStyles({
    width,
    height,
  })

  const intersectionRef = useRef()
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
  })

  const streamThumbnailQuery = useStreamThumbnailQuery(stream, {
    skip: stream.is_vod || intersection?.intersectionRatio === 0,
    pollingInterval: POLLING_INTERVAL,
  })

  return (
    <div
      ref={intersectionRef}
      className={clsx(classes.wrapper, className)}
      onClick={onClick}
    >
      {streamThumbnailQuery.isLoading ? (
        <Skeleton
          key="not_allowThumbnailToLoad"
          animation="wave"
          className={classes.skeleton}
          variant="rect"
        />
      ) : (stream.is_vod && stream.thumb_url) || streamThumbnailQuery.data ? (
        <>
          {playing ? (
            <Typography className={classes.playing} variant="h6" component="p">
              Playing…
            </Typography>
          ) : (
            <IconButton className={classes.playIconButton} onClick={onClick}>
              <PlayIcon className={classes.playIcon} />
            </IconButton>
          )}
          <img
            className={classes.thumbnail}
            src={stream.is_vod ? stream.thumb_url : streamThumbnailQuery.data}
            alt={stream.description}
            width={width}
            height={height}
            onLoadedMetadata={(e) =>
              setDimensions({
                width: e.target.videoWidth,
                height: e.target.videoHeight,
              })
            }
          />
        </>
      ) : (
        <div key="img" className={classes.unavailableImageWrapper}>
          <img
            alt="Unavailable"
            className={classes.unavailableImage}
            src={noImage}
          />
        </div>
      )}
    </div>
  )
}

VideoThumb.propTypes = {
  className: PropTypes.string,
  stream: StreamProperties,
  onError: PropTypes.func,
  onCanPlay: PropTypes.func,
  onClick: PropTypes.func,
}
