import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'

import { useSitesQuery } from '../../services/stream-manager'

import SiteListItem from './SiteListItem'


const SiteList = ({ division, onSelect }) => {

  // get list of sites that belong to division identified by divisionId
  const sitesQuery = useSitesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.filter((site) => site.division_id === division.id),
      ...rest,
    }),
  })

  return (
    <List>
      {sitesQuery.data?.map((site) => (
        <SiteListItem
          key={site.id}
          site={site}
          division={division}
          onSelect={onSelect}
        />
      ))}
    </List>
  )
}

SiteList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  division: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default SiteList
