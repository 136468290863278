import React, { useMemo } from 'react'
import { FastField } from 'formik'
import * as yup from 'yup'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { withAdmin } from '../../services/msal'
import {
  useDivisionsQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
  useSitesQuery,
  useCreateSiteMutation,
  useUpdateSiteMutation,
  useDeleteSiteMutation,
  useSectionsQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
} from '../../services/stream-manager'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import EnhancedTable from '../table/EnhancedTable'

import Access from './AccessPage'

const mapValuesToArg = ({ group, id, ...body }) => ({
  group,
  id,
  body,
})

const DivisionFormFields = () => (
  <>
    <FastField name="name">
      {({ field, meta }) => (
        <TextField
          {...field}
          autoFocus
          label="Name"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
    <FastField name="description">
      {({ field, meta }) => (
        <TextField
          {...field}
          label="Description"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
  </>
)

const SiteFormFields = () => {
  const divisionsQuery = useDivisionsQuery()

  return (
    <>
      <FastField name="name">
        {({ field, meta }) => (
          <TextField
            {...field}
            autoFocus
            label="Name"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="description">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Description"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="division_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Division"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {divisionsQuery.data?.map((division) => (
              <MenuItem key={division.id} value={division.id}>
                {`${division.name}: ${division.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
      <FastField name="lat">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Latitude"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="long">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Longitude"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
    </>
  )
}

const SectionFormFields = () => {
  const sitesQuery = useSitesQuery()

  return (
    <>
      <FastField name="name">
        {({ field, meta }) => (
          <TextField
            {...field}
            autoFocus
            label="Name"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="description">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Description"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="site_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Site"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {sitesQuery.data?.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {`${site.name}: ${site.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
    </>
  )
}

const SiteTable = ({ columns, ...props }) => {
  const sites = useSitesQuery()
  const divisions = useDivisionsQuery()

  const mapColumns = useMemo(
    () => ({
      division_id: divisions.data,
      site_id: sites.data,
    }),
    [divisions.data, sites.data],
  )

  return (
    <EnhancedTable
      search
      columns={columns}
      actions={({ row }) => <Access entity={row.original} />}
      mapColumns={mapColumns}
      withGroups
      {...props}
    />
  )
}

const SiteManagement = () => (
  <>
    <Paper>
      <SiteTable
        label="Divisions"
        urlKey="divisions"
        FormFields={DivisionFormFields}
        createValidationSchema={() =>
          yup.object({
            name: yup
              .string()
              .min(4, 'The division name should have at least 4 characters')
              .max(80, 'The division name should have maximum 80 characters')
              .required('The division name is required'),
            description: yup
              .string()
              .required('The division description is required'),
          })
        }
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
        ]}
        itemsQuery={usePaginatedFilteredQuery(
          useDivisionsQuery,
          { urlKey: 'divisions' },
        )()}
        createMutation={useMapValuesToArg(
          useCreateDivisionMutation,
          mapValuesToArg,
        )}
        updateMutation={useMapValuesToArg(
          useUpdateDivisionMutation,
          mapValuesToArg,
        )}
        deleteMutation={useDeleteDivisionMutation()}
      />
    </Paper>
    <br />
    <Paper>
      <SiteTable
        label="Sites"
        urlKey="sites"
        FormFields={SiteFormFields}
        createValidationSchema={() =>
          yup.object({
            name: yup
              .string()
              .min(4, 'The site name should have at least 4 characters')
              .max(80, 'The site name should have maximum 80 characters')
              .required('The site name is required'),
            description: yup
              .string()
              .required('The site description is required'),
            division_id: yup.string().required('Division is required'),
          })
        }
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Division',
            accessor: 'division_id',
          },
          {
            Header: 'Latitude',
            accessor: 'lat',
          },
          {
            Header: 'Longitude',
            accessor: 'long',
          },
        ]}
        itemsQuery={usePaginatedFilteredQuery(
          useSitesQuery,
          { urlKey: 'sites' },
        )()}
        createMutation={useMapValuesToArg(
          useCreateSiteMutation,
          mapValuesToArg,
        )}
        updateMutation={useMapValuesToArg(
          useUpdateSiteMutation,
          mapValuesToArg,
        )}
        deleteMutation={useDeleteSiteMutation()}
      />
    </Paper>
    <br />
    <Paper>
      <SiteTable
        label="Sections"
        urlKey="sections"
        FormFields={SectionFormFields}
        createValidationSchema={() =>
          yup.object({
            name: yup
              .string()
              .min(4, 'The section name should have at least 4 characters')
              .max(80, 'The section name should have maximum 80 characters')
              .required('The section name is required'),
            description: yup
              .string()
              .required('The section description is required'),
            site_id: yup.string().required('Site is required'),
          })
        }
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Site',
            accessor: 'site_id',
          },
        ]}
        itemsQuery={usePaginatedFilteredQuery(
          useSectionsQuery,
          { urlKey: 'sections' },
        )()}
        createMutation={useMapValuesToArg(
          useCreateSectionMutation,
          mapValuesToArg,
        )}
        updateMutation={useMapValuesToArg(
          useUpdateSectionMutation,
          mapValuesToArg,
        )}
        deleteMutation={useDeleteSectionMutation()}
      />
    </Paper>
  </>
)

export const SiteManagementPage = withAdmin(SiteManagement)
