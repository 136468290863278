import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const { origin } = new URL(window.location.href)

const useStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      margin: 0,
    },
    '#root': {
      height: '100%',
    },
  },
  iframe: {
    display: 'block',
  },
})

const PreviewPage = () => {
  const classes = useStyles()

  return (
    <iframe
      className={classes.iframe}
      frameBorder="0"
      scrolling="no"
      width="100%"
      height="100%"
      title="Digital Signage Display Preview"
      src={`${origin}/digital-signage/display${window.location.search}`}
    />
  )
}

export default PreviewPage
