import React from 'react'
import { FastField } from 'formik'
import slug from 'slug'
import * as yup from 'yup'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

import { withAdmin } from '../../services/msal'
import {
  useTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
} from '../../services/stream-manager'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import EnhancedTable from '../table/EnhancedTable'

export const TagFormFields = () => (
  <>
    <FastField name="tag">
      {({ field: { value, ...field }, meta }) => (
        <TextField
          {...field}
          value={slug(value || '', { trim: false })}
          autoFocus
          label="Name"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
    <FastField name="description">
      {({ field, meta }) => (
        <TextField
          {...field}
          label="Description"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
  </>
)

const mapValuesToArg = ({ name, ...body }) => ({
  body: {
    name,
    ...body,
  },
})

export const createTagValidationSchema = () =>
  yup.object({
    tag: yup
      .string()
      .min(4, 'The tag name should have at least 4 characters')
      .max(80, 'The tag name should have maximum 80 characters')
      .required('Tag name is required'),
    description: yup
      .string()
      .min(4, 'The tag description should have at least 4 characters')
      .max(80, 'The tag description should have maximum 80 characters')
      .required('Tag description is required'),
  })

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
]

const TagManagement = () => {
  const tagsQuery = usePaginatedFilteredQuery(useTagsQuery)()

  return (
    <Paper>
      <EnhancedTable
        label="Tags"
        columns={columns}
        FormFields={TagFormFields}
        createValidationSchema={createTagValidationSchema}
        itemsQuery={tagsQuery}
        createMutation={useMapValuesToArg(useCreateTagMutation, mapValuesToArg)}
        deleteMutation={useDeleteTagMutation()}
        search
      />
    </Paper>
  )
}

export const TagManagementPage = withAdmin(TagManagement)
