import React, {
  createContext,
  memo,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useReducer,
} from 'react'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import useTimeout from '../../hooks/useTimeout'

import AlertDialog from '../dialogs/AlertDialog'

import { ACTIONS, reducer, initialState } from './state'
import { playVideoSafely } from './utils'

const presenceEvents = Object.keys(window)
  .filter((key) => /^on(pointer|keyboard)/.test(key))
  .map((key) => key.slice(2))

const set = (id, value) => (obj) => ({ ...obj, [id]: value })
const remove = (id) => (obj) => {
  const { [id]: _, ..._obj } = obj
  return _obj
}

const MemoizedChildren = memo(({ children }) => children)

const OrchestratorContext = createContext()

/**
 * The orchestrator manages several players. The orchestrator provides a
 * register function through the React context. The players look for the
 * context, and if they find it, they register with the orchestrator.
 * Specifically, they register their initial state reducer and its dispatch
 * function. The orchestrator then provides back—again using the react context—a
 * reducer function that the player will actually use to manage its state. This
 * reducer function can be dynamically changed without clobbering its state,
 * which is great. One of the things the orchestrator does is wrap the reducer
 * functions to keep a reference to each player's state, so that it has an
 * overall overview of their states. Based on that, it may decide to allow or
 * not allow the players to take certain actions. It can also trigger side
 * effects in response to players actions (or attempts at action), and since the
 * players also register their dispatch functions, the orchestrator can also
 * fire actions for the players.
 *
 * This means that we're be able to:
 *
 * - limit what actions can be taken by specific players (we don't have a use
     case for this atm)
 * - limit how many can be playing at a single time
 * - keep them in sync by periodically seeking each player programmatically to a
     "leader" video (TODO)
 *
 * The players don't have to have an orchestrator somewhere in their component
 * tree ancestry, if there isn't one, they act independently according to their
 * default reducers.
 */
const Orchestrator = ({
  maxSimultaneousStreams,
  idle: idleDelay,
  children,
}) => {
  const [players, setPlayers] = useState({})
  const [reducers, setReducers] = useState({})
  const [states, setStates] = useState({})
  const [alert, setAlert] = useState(null)
  const [idle, setIdle] = useState(false)

  const [resetIdleTimeout, clearIdleTimeout] = useTimeout(
    () => setIdle(true),
    idleDelay,
  )

  const playingPlayers = Object.entries(states)
    .filter(([, { paused }]) => !paused)
    .map(([id]) => id)

  const playingRefs = playingPlayers
    .map((id) => players[id]?.ref)
    .filter(Boolean)

  useEffect(() => {
    if (!idle && playingPlayers.length) {
      presenceEvents.forEach((event) => {
        window.addEventListener(event, resetIdleTimeout)
      })
    }
    return () => {
      presenceEvents.forEach((event) => {
        window.removeEventListener(event, resetIdleTimeout)
      })
    }
  }, [idle, resetIdleTimeout, playingPlayers.length])

  useEffect(() => {
    if (!playingPlayers.length) {
      clearIdleTimeout()
    } else {
      resetIdleTimeout()
    }
  }, [resetIdleTimeout, clearIdleTimeout, playingPlayers.length])

  const duration = useMemo(() => moment.duration(idleDelay).humanize(), [
    idleDelay,
  ])

  useEffect(() => {
    if (idle) {
      playingRefs.forEach((ref) => ref.current?.pause())
    } else {
      playingRefs.forEach((ref) => ref.current && playVideoSafely(ref.current))
    }
  }, [playingRefs, states, idle])

  const handleConfirmPresence = useCallback(
    (e) => {
      e.stopPropagation()
      setIdle(false)
      resetIdleTimeout()
    },
    [resetIdleTimeout],
  )

  useEffect(() => {
    Object.values(players).forEach(({ dispatch }) => {
      dispatch({ type: 'PLAYING_PLAYERS', payload: playingPlayers.length })
    })
  }, [players, playingPlayers.length])

  const enhanceReducer = useCallback(
    (id, reducer) => (...args) => {
      const state = ((state, { type, payload } = {}) => {
        switch (type) {
          case 'PLAYING_PLAYERS':
            return {
              ...state,
              playingPlayers: payload,
            }
          case ACTIONS.PLAY:
            if (state.playingPlayers >= maxSimultaneousStreams) {
              setTimeout(
                () =>
                  setAlert(
                    `You cannot play more than ${maxSimultaneousStreams} streams at a time`,
                  ),
                0,
              )
              return state
            }
            return reducer(...args)

          default:
            return reducer(...args)
        }
      })(...args)

      return state
    },
    [maxSimultaneousStreams],
  )

  const register = useCallback((id, { reducer, dispatch, ref }) => {
    setPlayers(set(id, { reducer, dispatch, ref }))
    return () => {
      setPlayers(remove(id))
      setStates(remove(id))
      setReducers(remove(id))
    }
  }, [])

  useEffect(() => {
    setReducers(
      Object.fromEntries(
        Object.entries(players).map(([id, { reducer }]) => [
          id,
          enhanceReducer(id, reducer),
        ]),
      ),
    )
  }, [enhanceReducer, players])

  const setState = useCallback((id, state) => setStates(set(id, state)), [])

  const contextValue = useMemo(
    () => ({
      register,
      reducers,
      setState,
    }),
    [register, reducers, setState],
  )

  return (
    <>
      <AlertDialog
        open={Boolean(alert)}
        alert={alert}
        severity="error"
        onClose={() => setAlert(null)}
      />
      <Dialog
        open={idle}
        onClose={handleConfirmPresence}
        aria-labelledby="idle-dialog-title"
        aria-describedby="idle-dialog-description"
      >
        <DialogTitle id="idle-dialog-title">
          Are you still watching?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="idle-dialog-description">
            You've been idle for more than {duration}. We've paused the stream
            playback for now, to conserve bandwidth. Are you still watching?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmPresence}
            color="primary"
            variant="contained"
          >
            I'm still watching
          </Button>
        </DialogActions>
      </Dialog>
      <OrchestratorContext.Provider value={contextValue}>
        <MemoizedChildren>{children}</MemoizedChildren>
      </OrchestratorContext.Provider>
    </>
  )
}

export default Orchestrator

export const useOrchestratedReducer = ({ id, ref }) => {
  const { register, reducers, setState } = useContext(OrchestratorContext) ?? {}

  const myReducer = useMemo(() => reducers?.[id] ?? reducer, [reducers, id])

  const [state, dispatch] = useReducer(myReducer, initialState)

  useEffect(() => {
    setState?.(id, state)
  }, [id, state, setState])

  useEffect(() => {
    return register?.(id, { reducer, dispatch, ref })
  }, [dispatch, register, id, ref])

  return useMemo(() => [state, dispatch], [state, dispatch])
}
