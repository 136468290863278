import React from 'react'

import { alpha, makeStyles } from '@material-ui/core/styles'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core'

import {
  useMeQuery,
  useAcknowledgeDisclaimerMutation,
} from '../../services/stream-manager'

const DISCLAIMER = window.__ENV.REACT_APP_DISCLAIMER ?? null

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    zIndex: '2000 !important',
  },
  disclaimerPaper: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  disclaimerTitle: {
    textAlign: 'center',
  },
  disclaimerText: {
    color: alpha(theme.palette.primary.contrastText, 0.9),
  },
  disclaimerActions: {
    justifyContent: 'center',
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonDisabled: {
    backgroundColor: `${theme.palette.grey[300]} !important`,
  },
}))

const Disclaimer = () => {
  const classes = useStyles()

  const meQuery = useMeQuery()
  const [acknowledgeDisclaimer, acknowledgeDisclaimerStatus] = useAcknowledgeDisclaimerMutation()
  const disclaimerOpen = meQuery.isSuccess && meQuery.data.disclaimer_acknowledged == null
  const acknowledgementLoading = acknowledgeDisclaimerStatus.isLoading || meQuery.isFetching

  return DISCLAIMER && (
    <Dialog
      className={classes.disclaimer}
      classes={{ paper: classes.disclaimerPaper }}
      open={disclaimerOpen}
      aria-labelledby="disclaimer-dialog-title"
      aria-describedby="disclaimer-dialog-description"
    >
      <DialogTitle id="disclaimer-dialog-title" className={classes.disclaimerTitle}>Disclaimer</DialogTitle>
      <DialogContent>
        <DialogContentText id="disclaimer-dialog-description" className={classes.disclaimerText}>
          {DISCLAIMER}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.disclaimerActions}>
        <div className={classes.buttonWrapper}>
          <Button
            classes={{ disabled: classes.buttonDisabled }}
            onClick={acknowledgeDisclaimer}
            variant="contained"
            autoFocus
            disabled={acknowledgementLoading}
          >
            I agree
          </Button>
          {acknowledgementLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default Disclaimer
