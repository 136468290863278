/* eslint-disable import/no-unused-modules */
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import getUrls from 'get-urls'
import moment from 'moment'
import _get from 'lodash/fp/get'
import _reverse from 'lodash/fp/reverse'

import { base64ToUuid } from '../utils/uuid'

import msalApi, { selectUserAuth } from './msal'

const SECONDS_IN_MINUTE = 60
const TEAMS_CHAT_POLL_INTERVAL = 30 * 1000 // milliseconds

const baseQueryConfig = {
  baseUrl: 'https://graph.microsoft.com/v1.0',
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = selectUserAuth(getState()).data ?? {}
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
}

// Wrap `fetchBaseQuery` to:
// * retry requests with a sensible backoff policy [1]
// * fake a `401 Unauthorized` response if we don't have a token in the store,
//   we know that that'll be the response anyway,
// * trigger a re-auth if a real response comes back as `401 Unauthorized`
//   (presumably due to a stale token).
//
// [1]: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const myFetchBaseQuery = (config) => {
  const baseQuery = fetchBaseQuery(config)

  return retry(async (args, api, extraOptions) => {
    // Fake a 401 if we don't have a token yet, no need to actually try make the
    // request, this will set the unauthorized tag on the query, so it will be
    // automatically re-tried after auth.
    if (!selectUserAuth(api.getState()).data?.accessToken)
      return { error: { status: 401 } }

    const response = await baseQuery(args, api, extraOptions)

    // bail out of re-tries immediately if 4xx, because we know successive
    // re-retries would be redundant, since the problem is on our side
    if (response.error?.status >= 400 && response.error?.status < 500) {
      retry.fail(response.error)
    }

    // If there's a real 401 it's probably an expired token
    if (response.error?.status === 401) {
      api.dispatch(
        msalApi.endpoints.login.initiate(undefined, {
          subscribe: false,
          // This will still only result in 1 active request even if multiple API
          // calls return 401 and we fire `initiate` a bunch of times
          forceRefetch: true,
        }),
      )
    }

    if (response.error?.status === 403) {
      const msg = `403 ${response.error.statusText}`
      console.error(new Error(msg))
    }

    return response
  })
}

const baseQuery = myFetchBaseQuery(baseQueryConfig)

const microsoft = createApi({
  reducerPath: 'microsoft',

  baseQuery,

  refetchOnMountOrArgChange: 5 * SECONDS_IN_MINUTE,
  refetchOnReconnect: true,

  endpoints: (build) => ({
    // https://docs.microsoft.com/en-us/graph/api/calendar-list-events
    calendarEvents: build.query({
      query: () =>
        `/users/${window.__ENV.REACT_APP_EVENT_CALENDAR
        }/calendar/calendarView?$orderby=start/dateTime%20asc&startDateTime=${moment()
          .subtract(2, 'hours')
          .toISOString()}&endDateTime=${moment()
            .add(1, 'years')
            .toISOString()}`,
      transformResponse: (response) =>
        Array.isArray(response.value)
          ? response.value.map((event) => {
            const { originalStreamLinks, streamLinks, streamIds } = [...getUrls(event.body.content)]
              .filter((url) => Boolean(new URL(url).pathname.match(/^\/stream/)))
              .reduce((acc, link) => {
                const { pathname, searchParams, hash } = new URL(link)

                searchParams.set('time', moment.utc(event.start.dateTime).local().format())

                const timedLink = `${pathname}?${decodeURIComponent(searchParams.toString())}${hash}`

                const id = base64ToUuid(pathname.match(/^\/stream\/([^/]+)/)[1])

                acc.originalStreamLinks.push(link)
                acc.streamLinks.push(timedLink)
                acc.streamIds.push(id)
                return acc
              }, {
                originalStreamLinks: [],
                streamLinks: [],
                streamIds: [],
              })

            return {
              ...event,
              originalStreamLinks,
              streamLinks,
              streamIds
            }
          })
          : response.value,
    }),
    // https://docs.microsoft.com/en-us/graph/api/chatmessage-delta
    // https://docs.microsoft.com/en-us/graph/api/chatmessage-list-replies
    teamsChannelMessages: (() => {
      const fetchMessages = async (url, api, extraOptions, baseQuery) => {
        const messagesQuery = await baseQuery({ url }, api, extraOptions)

        if (messagesQuery.error) return messagesQuery

        const messageIds = messagesQuery.data.value.map(_get('id'))

        const replyQueries = await Promise.all(
          messageIds.map((id) =>
            baseQuery(
              {
                url: `/teams/${window.__ENV.REACT_APP_TEAMS_TEAM_ID}/channels/${window.__ENV.REACT_APP_TEAMS_CHANNEL_ID}/messages/${id}/replies`,
              },
              api,
              extraOptions,
            ),
          ),
        )

        if (replyQueries.some(_get('error'))) {
          return { error: { data: "Couldn't get replies" } }
        }

        return {
          data: {
            ...messagesQuery.data,
            value: messagesQuery.data.value?.map((message, i) => ({
              ...message,
              replies: _reverse(replyQueries[i].data.value ?? []),
            })),
          },
        }
      }

      return build.query({
        queryFn: (_, ...args) =>
          fetchMessages(
            `/teams/${window.__ENV.REACT_APP_TEAMS_TEAM_ID}/channels/${window.__ENV.REACT_APP_TEAMS_CHANNEL_ID}/messages/delta`,
            ...args,
          ),
        // https://docs.microsoft.com/en-us/graph/throttling#microsoft-teams-service-limits
        onCacheEntryAdded: async (
          _,
          {
            dispatch,
            getState,
            extra,
            cacheEntryRemoved,
            cacheDataLoaded,
            updateCachedData,
          },
        ) => {
          const {
            data: {
              '@odata.nextLink': nextLink,
              '@odata.deltaLink': deltaLink,
            },
          } = await cacheDataLoaded

          let timer
            ; (function fetchMoreMessages({ nextLink, deltaLink }) {
              timer = setTimeout(
                async () => {
                  const {
                    data: {
                      '@odata.nextLink': nLink,
                      '@odata.deltaLink': dLink,
                      value,
                    } = {},
                  } = await fetchMessages(
                    nextLink ?? deltaLink,
                    {
                      dispatch,
                      getState,
                      extra,
                    },
                    undefined,
                    baseQuery,
                  )

                  if (value?.length > 0) {
                    updateCachedData((data) => {
                      // When a message gets replies, or is edited, it comes through again on the delta, so we need to de-dupe
                      const newIds = value.map(_get('id'))

                      data.value = data.value.filter(
                        ({ id }) => !newIds.includes(id),
                      )

                      data.value.push(...value)
                    })
                  }

                  if (nLink || dLink) {
                    fetchMoreMessages({ nextLink: nLink, deltaLink: dLink })
                  }
                },
                nextLink ? 0 : TEAMS_CHAT_POLL_INTERVAL,
              )
            })({ nextLink, deltaLink })

          await cacheEntryRemoved

          clearTimeout(timer)
        },
      })
    })(),
    teamsChannel: build.query({
      query: () =>
        `/teams/${window.__ENV.REACT_APP_TEAMS_TEAM_ID}/channels/${window.__ENV.REACT_APP_TEAMS_CHANNEL_ID}`,
    }),
  }),
})

export default microsoft

// Export the hooks for easy access
export const {
  useCalendarEventsQuery,
  useTeamsChannelQuery,
  // useTeamsChannelMessagesQuery,
} = microsoft

export const useTeamsChannelMessagesQuery = (...args) => {
  const { data, ...rest } = microsoft.useTeamsChannelMessagesQuery(...args)
  return {
    data: _get('value', data),
    ...rest,
  }
}
