import React from 'react'
import _merge from 'lodash/fp/merge'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'


const useStyles = makeStyles({
  fieldset: {
    border: 0,
    padding: 0,
    marging: 0,
  },
})

const disabledFormElementProps = [
  'MuiButton',
  'MuiButtonBase',
  'MuiIconButton',
  'MuiButtonGroup',
  'MuiCheckBox',
  'MuiFormControl',
  'MuiFormControlLabel',
  'MuiFormLabel',
  'MuiFab',
  'MuiTextField',
  'MuiRadio',
  'MuiSlider',
  'MuiSwitch',
  'MuiFilledInput',
  'MuiFormHelperText',
  'MuiInput',
  'MuiInputBase',
  'MuiInputLabel',
  'MuiOutlinedInput',
  'MuiListItem',
].reduce((acc, component) => {
  acc[component] = { disabled: true }
  return acc
}, {})

const disableFormElementsOnSubmit = disabled => theme => {
  if (!disabled) return theme
  return _merge(theme, { props: disabledFormElementProps })
}

const DisableFormFields = ({ disabled, children }) => {
  const classes = useStyles()

  return (
    <fieldset className={classes.fieldset} disabled={disabled}>
      <ThemeProvider theme={disableFormElementsOnSubmit(disabled)}>
        {children}
      </ThemeProvider>
    </fieldset>
  )
}

export default DisableFormFields
