import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { lighten, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/GetApp'
import CloseIcon from '@material-ui/icons/Close'

import ConfirmDialog from '../dialogs/ConfirmDialog'
import { AddEditButton } from '../dialogs/AddEditDialog'

import GlobalFilter from './GlobalFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  search: {
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
    whiteSpace: 'nowrap',
  },
  spacer: {
    width: 36,
    flexShrink: 0,
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}))

const useDownloadButtonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

const TableToolbar = ({
  FormFields,
  createValidationSchema,
  columns,
  label,
  selectedItems,
  deleteHandler,
  bulkActions,
  downloadCsv,
  createMutation,
  withGroups,
  onClose,
  initialValues: _initialValues,
  search: searchable,
  urlKey,
}) => {
  const classes = useStyles()
  const downloadButtonClasses = useDownloadButtonStyles()

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false)

  const onCloseConfirmDelete = useCallback(
    (value) => {
      setOpenConfirmDeleteDialog(false)
      if ('yes' === value) {
        deleteHandler?.()
      }
    },
    [setOpenConfirmDeleteDialog, deleteHandler],
  )

  const initialValues = useMemo(
    () => ({
      ...columns.reduce((acc, column) => {
        acc[column.accessor] = ''
        return acc
      }, {}),
      ...(withGroups ? { __groups__: [] } : undefined),
      ..._initialValues,
    }),
    [withGroups, columns, _initialValues],
  )

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selectedItems.length > 0,
      })}
    >
      {selectedItems.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {selectedItems.length} selected
        </Typography>
      ) : (
        <>
          {createMutation[0] != null && FormFields ? (
            <AddEditButton
              data-tour="enhancedtable-addedit"
              label={label}
              createValidationSchema={createValidationSchema}
              createMutation={createMutation}
              initialValues={initialValues}
            >
              <FormFields />
            </AddEditButton>
          ) : deleteHandler || bulkActions ? (
            <div className={classes.spacer} />
          ) : null}
          <Typography className={classes.title} variant="h6" id="tableTitle">
            {label}
          </Typography>
        </>
      )}

      {bulkActions &&
        selectedItems.length > 0 &&
        bulkActions({ items: selectedItems })}

      {selectedItems.length > 0 && deleteHandler ? (
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={() => setOpenConfirmDeleteDialog(true)}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {searchable && (
            <GlobalFilter
              className={classes.search}
              urlKey={urlKey}
              {...(typeof searchable === 'object' ? searchable : undefined)}
            />
          )}
          {downloadCsv && (
            <Tooltip title="Download CSV">
              <IconButton
                classes={downloadButtonClasses}
                aria-label="download CSV"
                onClick={downloadCsv}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
          {onClose && (
            <Tooltip title="Close">
              <IconButton
                className={classes.closeButton}
                aria-label="Close"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
      <ConfirmDialog
        open={openConfirmDeleteDialog}
        title="Delete item(s)?"
        text="Delete item(s)?"
        onClose={onCloseConfirmDelete}
      />
    </Toolbar>
  )
}

TableToolbar.propTypes = {
  addDialog: PropTypes.element,
  bulkActions: PropTypes.func,
  label: PropTypes.node.isRequired,
  selectedItems: PropTypes.array.isRequired,
  deleteHandler: PropTypes.func,
  search: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
  ]),
  urlKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

export default TableToolbar
