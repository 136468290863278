/** @jsxImportSource @welldone-software/why-did-you-render */
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import history from './history'
import store from './store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// for IE
if (!Date.now) {
  Date.now = function () {
    return new Date().getTime()
  }
}

// https://github.com/welldone-software/why-did-you-render
if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
  })
}

const render = (props) => {
  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <App {...props} />
      </Provider>
    </StrictMode>,
    document.getElementById('root'),
  )
}

render()

const onSuccess = (registration) => {
  // On route changes, check whether a new app has been deployed, as
  // indicated by a new service worker
  let currentPathname = history.location.pathname
  history.listen((location) => {
    if (currentPathname !== location.pathname) {
      currentPathname = location.pathname
      registration.update()
    }
  })
}

// When a new service worker has been installed, re-render the app to let the
// user know they can update, which will set the new service worker to active
const onUpdate = (registration) => {
  render({ waitingServiceWorker: registration.waiting })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({ onSuccess, onUpdate })

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration) {
      onSuccess(registration)
    }
  })

  // When a new service worker has become active, reload the page
  let refreshing = false
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (!refreshing) {
      window.location.reload()
      refreshing = true
    }
  })
}
