import React, { useState } from 'react'
import { useInterval } from 'react-use'

import { makeStyles } from '@material-ui/core/styles'

import useURLParams from '../../hooks/useURLParams'

import { base64ToUuid } from '../../utils/uuid'

import Player from '../player/Player'

const useStyles = makeStyles({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
      margin: 0,
    },
    '#root': {
      height: '100%',
    },
  },
  player: {
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
  },
})

const DisplayPage = () => {
  const classes = useStyles()

  const [{ cycleTime, streamIds: _streamIds, accessToken }] = useURLParams()

  const streamIds = [].concat(_streamIds).map(base64ToUuid)

  const [streamIndex, setStreamIndex] = useState(0)

  useInterval(() => {
    setStreamIndex((streamIndex + 1) % streamIds.length)
  }, cycleTime * 1000)

  return (
    <Player
      className={classes.player}
      digitalSignage
      play
      id={streamIds?.[streamIndex]}
      accessToken={accessToken}
    />
  )
}

export default DisplayPage
