import * as PropTypes from 'prop-types';

export const StreamProperties = PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    uri: PropTypes.string,
    media_server_id: PropTypes.string,
    section_id: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
});
