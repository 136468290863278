import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MuiCircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import StatusIcon from '@material-ui/icons/Equalizer'

import {
  useServerQuery,
  useServerStatusQuery
} from '../../services/stream-manager'


const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24

const formatDuration = seconds => {
    if (seconds <= 0) return `${seconds}`

    let d = 0
    let h = 0
    let m = 0
    let s = 0 // eslint-disable-line no-unused-vars
    // remove days
    if (SECONDS_IN_DAY <= seconds) {
        d = Math.floor(seconds / SECONDS_IN_DAY)
        seconds = seconds % SECONDS_IN_DAY
    }
    // remove hours
    if (SECONDS_IN_HOUR <= seconds) {
        h = Math.floor(seconds / SECONDS_IN_HOUR)
        seconds = seconds % SECONDS_IN_HOUR
    }
    // remove minutes
    if (SECONDS_IN_MINUTE <= seconds) {
        m = Math.floor(seconds / SECONDS_IN_MINUTE)
        seconds = seconds % SECONDS_IN_MINUTE
    }
    // s = Math.ceil(seconds) // whatever is left

    if (1 <= d) {
        // longer than one day
        return `${d}d ${h}h`
    }

    return `${h}h ${m}min`
}

const formatStorageSize = bytes => {
    const fractionDigits = 1
    if (bytes < 1000) {
        return `${bytes.toFixed(fractionDigits)} bytes`
    }

    const kb = bytes / 1000
    if (kb < 1000) {
        return `${kb.toFixed(fractionDigits)} KB`
    }

    const mb = kb / 1000
    if (mb < 1000) {
        return `${mb.toFixed(fractionDigits)} MB`
    }

    const gb = mb / 1000
    return `${gb.toFixed(fractionDigits)} GB`
}

const useStyles = makeStyles(theme => ({
  noStatus: {
    margin: theme.spacing(2),
  },
}))

const CircularProgress = props => <MuiCircularProgress {...props} size="1rem" />

const ServerStatus = ({ server, open, setOpen, label }) => {
  const classes = useStyles()

  const serverStatusQuery = useServerStatusQuery(server?.id, { skip: !server?.id || !open })

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={false}
      >
        <DialogTitle>
          Media Server <em>{server?.name} ({ server?.description })</em> status
        </DialogTitle>
        {!serverStatusQuery.isLoading && serverStatusQuery.data == null ? (
          <Typography component="p" className={classes.noStatus}>
            No status: relays streams from other media servers
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Property</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Capacity Used</TableCell>
                  <TableCell padding={serverStatusQuery.isLoading ? 'none' : 'normal'} >
                    {!serverStatusQuery.isLoading ? (
                      <Typography>
                        {formatDuration(serverStatusQuery.data.used_capacity)} / {formatDuration(serverStatusQuery.data.capacity)}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Free Space</TableCell>
                  <TableCell padding={serverStatusQuery.isLoading ? 'none' : 'normal'} >
                    {!serverStatusQuery.isLoading ? (
                      <Typography>
                        {formatStorageSize(serverStatusQuery.data.free_space)} / {formatStorageSize(serverStatusQuery.data.total_space)}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Bandwidth</TableCell>
                  <TableCell padding={serverStatusQuery.isLoading ? 'none' : 'normal'} >
                    {!serverStatusQuery.isLoading ? (
                      <Typography>
                        {formatStorageSize(serverStatusQuery.data.bandwidth).replace('B', 'b')}/s
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Number of Streams</TableCell>
                  <TableCell padding={serverStatusQuery.isLoading ? 'none' : 'normal'} >
                    {!serverStatusQuery.isLoading ? (
                      <Typography>
                        {serverStatusQuery.data.total_streams}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Recommended Storage per Stream</TableCell>
                  <TableCell padding={serverStatusQuery.isLoading ? 'none' : 'normal'} >
                    {!serverStatusQuery.isLoading ? (
                      <Typography>
                        {serverStatusQuery.data.recommended_storage != null
                          ? `${Math.round(serverStatusQuery.data.recommended_storage / 10.0) * 10} MB`
                          : ''}
                      </Typography>
                    ) : (
                      <CircularProgress />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Dialog>
      <Tooltip title={`${label} status`}>
        <IconButton
          aria-label={`${label} status`}
          aria-haspopup="true"
          onClick={() => setOpen(true)}
          size="small"
        >
          <StatusIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}

const StreamServerStatus = ({ stream, open, ...props }) => {
  const serverQuery = useServerQuery(stream.media_server_id, { skip: !open })

  return (
    <ServerStatus
      server={serverQuery.data}
      open={open}
      label={`${stream.name}'s server`}
      {...props}
    />
  )
}

const Status = ({ entity, type }) => {
  const [open, setOpen] = useState(false)

  return type === 'stream' ? (
    <StreamServerStatus
      stream={entity}
      open={open}
      setOpen={setOpen}
    />
  ) : (
    <ServerStatus
      server={entity}
      open={open}
      setOpen={setOpen}
      label={entity.name}
    />
  )
}

export default Status

Status.propTypes = {
  entity: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'stream',
    'server',
  ]),
}
