import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import _get from 'lodash/fp/get'

import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { useIsAdmin } from '../../services/msal'
import {
  useStreamsQuery,
  useUploadVideoMutation,
  useHasGroup,
} from '../../services/stream-manager'

import usePaginationParams from '../../hooks/usePaginationParams'
import useFilterParams from '../../hooks/useFilterParams'
import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import formatAPIParams from '../../utils/formatAPIParams'

import PageTitle from '../../components/PageTitle'

import Container from '../layout/Container'
import AlertDialog from '../dialogs/AlertDialog'
import AddEditDialog from '../dialogs/AddEditDialog'

import { MostViewedStreams } from '../analytics/MostViewedStreams'

import GlobalFilter from '../table/GlobalFilter'
import { GroupsSelect, groupsValidationSchema } from '../table/EnhancedTable'

import {
  VideoUploadFormFields,
  createUploadValidationSchema,
  mapValuesToArg,
  UploadProgressDialog,
  initialValues,
} from '../crud/VideoManagementPage'

import StreamsGrid from './StreamsGrid'
import StreamPopup from './StreamPopup'

const STREAMS_PER_PAGE = 12

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  vodHeader: {
    display: 'flex',
    fontWeight: 'bold',
    '& > *': {
      flex: 1,
      textAlign: 'center',
      marginRight: theme.spacing(2),
    },
    '& > :first-child': {
      textAlign: 'left',
    },
    '& > :last-child': {
      textAlign: 'right',
      marginRight: 0,
    },
  },
  vodTotal: {
    color: theme.palette.success.main,
  },
  vodFresh: {
    color: theme.palette.success.main,
  },
  vodStale: {
    color: theme.palette.warning.main,
  },
  vodExpiring: {
    color: theme.palette.error.main,
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  uploadButton: {
    marginTop: -theme.spacing(1),
    marginBottom: -theme.spacing(1),
  },
  pagination: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}))

const StreamsPagination = ({ count }) => {
  const classes = useStyles()

  const [{ page, limit }, setPaginationParams] = usePaginationParams(
    { limit: STREAMS_PER_PAGE },
  )

  const pages = useMemo(() => Math.ceil(count / limit), [count, limit])

  return (
    <div className={classes.pagination}>
      <Pagination
        showFirstButton
        showLastButton
        shape="rounded"
        color="primary"
        count={pages}
        page={page}
        onChange={(_, page) => setPaginationParams({ page })}
      />
    </div>
  )
}

const FormFields = (props) => (
  <>
    <VideoUploadFormFields {...props} />
    <GroupsSelect />
  </>
)

const StreamsPage = () => {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const hasVODGroup = useHasGroup(window.__ENV.REACT_APP_VIDEO_PROCESSOR_STREAM_GROUP)

  const today = useMemo(() => moment(), [])
  const expired = useMemo(() => today.clone().subtract(window.__ENV.REACT_APP_VOD_EXPIRY_WINDOW, 'seconds'), [today])
  const expiring = useMemo(() => expired.clone().add(1, 'day'), [expired])
  const stale = useMemo(() => expired.clone().add(1, 'week'), [expired])
  const yesterday = useMemo(() => moment().seconds(0).subtract(1, 'day'), [])

  const [{ division, site, section, type }] = useFilterParams()

  const [selectedStreamId, setSelectedStreamId] = useState(null)

  const streamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { limit: STREAMS_PER_PAGE },
  )()

  const vodStreamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { limit: 0 }, // We’re only interested in the `count`
  )(
    { params: formatAPIParams({ type: 'video' }) },
    { skip: type === 'stream' },
  )

  // We have at least one VOD video for the current parameters, or we’re
  // specifically interested in videos.
  const isVODMode = useMemo(
    () =>
      type === 'stream'
        ? false
        : type === 'video' || vodStreamsQuery.data?.count > 0,
    [type, vodStreamsQuery.data],
  )

  const freshVodStreamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { limit: 0 }, // We’re only interested in the `count`
  )(
    {
      params: formatAPIParams({
        type: 'video',
        from: yesterday,
      }),
    },
    { skip: !isVODMode },
  )

  const staleVodStreamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { limit: 0 }, // We’re only interested in the `count`
  )(
    {
      params: formatAPIParams({
        type: 'video',
        from: expiring,
        until: stale,
      }),
    },
    { skip: !isVODMode },
  )

  const expiringVodStreamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { limit: 0 }, // We’re only interested in the `count`
  )(
    {
      params: formatAPIParams({
        type: 'video',
        until: expiring,
      }),
    },
    { skip: !isVODMode },
  )

  // handle stream and date in url
  const hash = useLocation().hash
  // remove leading '#' and treat hash like a query param encoding
  const fakeQueryStringForParsing = hash.slice(1, hash.length)
  const hashParams = new URLSearchParams(fakeQueryStringForParsing)
  const targetStreamId = hashParams.get('id')
  const targetDateStr = hashParams.get('d')
  const [initDate, setInitDate] = useState() // Date that is passed to player

  useEffect(() => {
    if (!streamsQuery.data?.length) return
    if (!targetStreamId?.length) return

    const streamToSelect = streamsQuery.data?.find(
      ({ id }) => id === targetStreamId,
    )

    if (!streamToSelect) return

    setSelectedStreamId(streamToSelect)
    setInitDate(targetDateStr ? new Date(targetDateStr) : undefined)
    // clear values in hash. should not cause page refresh
    window.location.hash = ''
  }, [targetStreamId, targetDateStr, streamsQuery.data])

  const [uploadVideoOpen, setUploadVideoOpen] = useState(false)

  const [uploadVideoTrigger, uploadVideoResult] = useMapValuesToArg(
    useUploadVideoMutation,
    mapValuesToArg,
  )

  const [loadingProgressOpen, setLoadingProgressOpen] = useState(false)
  useEffect(() => {
    if (uploadVideoResult.data?.status != null) {
      setLoadingProgressOpen(true)
    }
  }, [uploadVideoResult.data?.status])

  const createUploadVideoSchema = useCallback(
    (props) =>
      createUploadValidationSchema(props).concat(groupsValidationSchema),
    [],
  )

  return (
    <>
      <AlertDialog
        key={streamsQuery.startedTimeStamp}
        open={streamsQuery.isError}
        status={streamsQuery.error?.status}
        alert={streamsQuery.error?.data}
      />
      <StreamPopup
        id={selectedStreamId}
        open={Boolean(selectedStreamId)}
        initDate={initDate}
        onClose={() => {
          setSelectedStreamId(null)
          setInitDate(undefined)
        }}
      />
      {isVODMode && (
        <>
          <UploadProgressDialog
            open={loadingProgressOpen}
            setOpen={setLoadingProgressOpen}
            uploadVideoResult={uploadVideoResult}
          />
          <PageTitle className={classes.vodHeader}>
            <div className={classes.vodTotal}>
              {vodStreamsQuery.data?.count != null &&
                `${vodStreamsQuery.data?.count} Available Footage`}
            </div>
            <div className={classes.vodFresh}>
              {freshVodStreamsQuery.data?.count != null &&
                `${freshVodStreamsQuery.data?.count} Uploaded in last 24hrs`}
            </div>
            <div className={classes.vodStale}>
              {staleVodStreamsQuery.data?.count != null &&
                `${staleVodStreamsQuery.data?.count} expire within a week`}
            </div>
            <div className={classes.vodExpiring}>
              {expiringVodStreamsQuery.data?.count != null &&
                `${expiringVodStreamsQuery.data?.count} expire within a day`}
            </div>
          </PageTitle>
        </>
      )}
      <PageTitle className={classes.title}>
        <Typography variant="h6" component="h1">
          {!division && !site && !section
            ? 'All Streams'
            : [division, site, section]
              .filter(Boolean) // Filter out falsy values
              .map(_get('name'))
              .join(' / ')}
        </Typography>
        {isVODMode && (isAdmin || hasVODGroup) && (
          <>
            <AddEditDialog
              open={uploadVideoOpen}
              setOpen={setUploadVideoOpen}
              label="Video"
              initialValues={initialValues}
              createMutation={[uploadVideoTrigger, uploadVideoResult]}
              createValidationSchema={createUploadVideoSchema}
            >
              <FormFields />
            </AddEditDialog>
            <Button
              className={classes.uploadButton}
              variant="contained"
              color="primary"
              onClick={() => setUploadVideoOpen(true)}
            >
              Upload Video
            </Button>
          </>
        )}
      </PageTitle>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs />
          <Grid item>
            <GlobalFilter
              tags
              groups
              location
              type
              dateTime
            />
          </Grid>
          <Grid item>
            <MostViewedStreams />
          </Grid>
        </Grid>
        {!streamsQuery.isLoading && (
          <StreamsPagination count={streamsQuery.data?.count} />
        )}
        <StreamsGrid
          streamsQuery={streamsQuery}
          onStreamSelect={setSelectedStreamId}
          group
        />
        {!streamsQuery.isLoading && (
          <StreamsPagination count={streamsQuery.data?.count} />
        )}
      </Container>
    </>
  )
}

export default StreamsPage
