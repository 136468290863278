import _get from 'lodash/fp/get'


/*
 * Convert params from useFilterQueryParams and usePaginationParams to the
 * format the API expects.
 */
const formatAPIParams = ({
  page,
  limit,
  type,
  group,
  section,
  site,
  division,
  tags,
  from,
  until,
  ...rest
}) => ({
  ...(page != null && limit != null ? {
    offset: (page - 1) * limit || undefined,
  } : undefined),
  ...(limit != null ? {
    limit,
  } : undefined),
  ...(tags != null ? {
    tags: tags?.map(_get('tag')).join('|'),
  } : undefined),
  ...(section != null
    ? {
      entity_type: 'section',
      entity_id: section.id,
    }
    : site != null
      ? {
        entity_type: 'site',
        entity_id: site.id,
      }
      : division != null
        ? {
          entity_type: 'division',
          entity_id: division.id,
        }
        : undefined),
  ...(type != null
    ? {
      stream_type: type === 'video' ? 'vod' : type,
    }
    : undefined),
  ...(group != null ? {
    groups: group.id,
  } : undefined),
  ...(from != null ? {
    start: from.unix(),
  } : undefined),
  ...(until != null ? {
    stop: until.unix(),
  } : undefined),
  ...rest
})

export default formatAPIParams
