const defaultTheme = {
  props: {
    MuiTextField: {
      variant: 'filled',
    },
    MuiFormControl: {
      variant: 'filled',
    },
  },
}

export default defaultTheme
