import { useMemo } from 'react'

import formatAPIParams from '../utils/formatAPIParams'

import { normalizeArg } from '../services/stream-manager'

import usePaginationParams from './usePaginationParams'
import useFilterParams from './useFilterParams'

/*
 * A helper hook that wraps an entity query to apply filter and pagination query
 * parameters,
 *
 * Takes an optional `urlKey` (useful in case there's a need for multiple
 * URL parameterized queries on a single page, or if you don't want the state to
 * be stored in the URL; see `useURLParams`).
 */
const usePaginatedFilteredQuery = (
  useQuery,
  {
    urlKey,
    limit,
    filter = true,
    pagination = true,
    ...defaults
  } = {},
) => function useWrappedQuery(
  args = {},
  { skip, ...options } = {},
) {
  const [paginationParams] = usePaginationParams({ urlKey, limit })
  const [{ isLoading, ...filterParams }] = useFilterParams({ urlKey, ...defaults })

  const queryArg = useMemo(() => {
    const params = {
      ...formatAPIParams({
        ...(pagination ? paginationParams : undefined),
        ...(filter ? filterParams : undefined),
      }),
      ...args.params,
    }

    if (Array.isArray(args)) {
      return args.map((arg) => ({
        ...normalizeArg(arg),
        params,
      }))
    }

    return {
      ...normalizeArg(args),
      params,
    }
  }, [
    args,
    paginationParams,
    filterParams,
  ])

  return useQuery(queryArg, { skip: skip || isLoading, ...options })
}

export default usePaginatedFilteredQuery
