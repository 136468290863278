import { useCallback } from 'react'
import _identity from 'lodash/fp/identity'

const useMapValuesToArg = (useMutation, mapFn = _identity) => {
  const [_trigger, result] = useMutation()
  const trigger = useCallback(
    async (values, ...args) =>
      _trigger(
        Array.isArray(values)
          ? await Promise.all(values.map(mapFn))
          : await mapFn(values),
        ...args,
      ),
    [_trigger, mapFn],
  )
  return [trigger, result]
}

export default useMapValuesToArg
