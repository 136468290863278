import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';

import useWidth from '../../hooks/useWidth';

const useStyles = makeStyles(theme => ({
    container: ({ isTabletOrMobileDevice }) =>
        !isTabletOrMobileDevice ? {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(4),
        } : {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
}));

const Container = ({ children, className, ...props }) => {
    const width = useWidth();
    const isTabletOrMobileDevice = useMemo(() => width.match(/(xs|sm|md)/), [width]);
    const classes = useStyles({ isTabletOrMobileDevice });

    return (
        <MuiContainer
            maxWidth="lg"
            className={clsx(classes.container, className)}
            {...props}
        >
            {children}
        </MuiContainer>
    );
};

Container.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Container;
