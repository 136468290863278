import React, { forwardRef, useState, useEffect, useCallback } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import clsx from 'clsx'

import { makeStyles, useTheme, alpha } from '@material-ui/core/styles'
import MuiTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import usePrefs from '../../hooks/usePrefs'
import useWidth from '../../hooks/useWidth'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    padding: theme.spacing(2),
    maxWidth: 400,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  titleWrapper: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 20,
  },
  content: {
    marginBottom: theme.spacing(2),
    paddingRight: theme.spacing(4),
    '& > :first-child': {
      marginTop: 0,
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  skipButton: {
    marginRight: theme.spacing(4),
  },
  badge: {
    border: '0px none',
    borderRadius: '50%',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    cursor: 'help',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1,
    padding: 0,
    appearance: 'none',
    display: 'inline-block',
    height: theme.spacing(3),
    position: 'relative',
    width: theme.spacing(3),
  },
  badgePulseOuter: {
    animation: `${theme.transitions.duration.standard * 4}ms ${
      theme.transitions.easing.easeInOut
    } 0s infinite normal none running $badgePulseOuterAnimation`,
    backgroundColor: alpha(theme.palette.primary.dark, 0.15),
    border: `1px solid ${theme.palette.primary.light}`,
    position: 'absolute',
    top: '-25%',
    left: '-25%',
    width: '150%',
    height: '150%',
    borderRadius: '50%',
  },
  badgePulseInner: {
    animation: `${theme.transitions.duration.standard * 4}ms ${
      theme.transitions.easing.easeInOut
    } 0s infinite normal none running $badgePulseInnerAnimation`,
    backgroundColor: theme.palette.primary.light,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  badgeContentWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  badgeContent: {
    lineHeight: 1,
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  '@keyframes badgePulseInnerAnimation': {
    '20%': {
      opacity: 0.9,
    },
    '90%': {
      opacity: 0.1,
    },
  },
  '@keyframes badgePulseOuterAnimation': {
    '0%': {
      transform: 'scale(1)',
    },
    '45%': {
      opacity: 0.7,
      transform: 'scale(0.66)',
    },
    '100%': {
      opacity: 0.9,
      transform: 'scale(1)',
    },
  },
}))

export const Beacon = forwardRef(({ title, ...props }, ref) => {
  const classes = useStyles()

  return (
    <MuiTooltip title="Open Tour" arrow>
      <button className={classes.badge} ref={ref} {...props}>
        <div className={classes.badgePulseOuter} />
        <div className={classes.badgePulseInner} />
        <div className={classes.badgeContentWrapper}>
          <div className={classes.badgeContent}>?</div>
        </div>
      </button>
    </MuiTooltip>
  )
})

const Tooltip = ({
  continuous,
  index,
  step,
  skipProps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} {...tooltipProps}>
      <MuiTooltip title={step.locale.close}>
        <IconButton
          className={classes.closeButton}
          {...closeProps}
          title={undefined}
        >
          <CloseIcon />
        </IconButton>
      </MuiTooltip>
      {step.title && (
        <div className={classes.titleWrapper}>
          <Typography variant="h5" className={classes.title}>
            {step.title}
          </Typography>
        </div>
      )}
      <div className={classes.content}>{step.content}</div>
      <div className={classes.footer}>
        <div>
          {!isLastStep && (
            <Button
              className={clsx(classes.button, classes.skipButton)}
              {...skipProps}
            >
              {step.locale.skip}
            </Button>
          )}
        </div>
        <div>
          {index > 0 && (
            <Button
              className={classes.button}
              variant="outlined"
              {...backProps}
            >
              {step.locale.back}
            </Button>
          )}
          {continuous && (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              {...primaryProps}
            >
              {isLastStep ? step.locale.close : step.locale.next}
            </Button>
          )}
          {!continuous && (
            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              {...closeProps}
            >
              {step.locale.close}
            </Button>
          )}
        </div>
      </div>
    </Paper>
  )
}

const Tour = ({ name, run: _run = true, steps, ...props }) => {
  const theme = useTheme()
  const [{ tour = {} }, setPrefs] = usePrefs()

  const tourHasRun = !tour.hasRun?.[name]
  const tourEnabled = tour.enableTour ? true : false

  const setTourHasRun = useCallback(() => {
    setPrefs((prefs) => ({
      ...prefs,
      tour: {
        ...prefs.tour,
        hasRun: {
          ...prefs.tour?.hasRun,
          [name]: true,
        },
      },
    }))
  }, [setPrefs, name])

  const run = tourEnabled && _run && tourHasRun

  const [delayedRun, setDelayedRun] = useState(false)

  useEffect(() => {
    let timer

    if (run) {
      timer = setTimeout(() => {
        setDelayedRun(true)
      }, theme.transitions.duration.short + 17) // duration plus 1/60th of a second (a single frame)
    } else {
      setDelayedRun(false)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [run, theme])

  const width = useWidth()
  const isMobileOrTablet = width.match(/(xs|sm)/)

  const tourSteps = tourEnabled ? steps : []


  return isMobileOrTablet ? null : (
    <Joyride
      callback={({ status }) => {
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          setTourHasRun()
        }
      }}
      run={delayedRun}
      continuous
      disableScrolling
      beaconComponent={Beacon}
      tooltipComponent={Tooltip}
      floaterProps={{
        options: {
          preventOverflow: {
            // Default is the scrollParent, which leads to incorrect tooltip
            // placement
            boundariesElement: document.body,
          },
        },
      }}
      styles={{
        options: {
          arrowColor: theme.palette.background.paper,
          overlayColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1500,
        },
      }}
      {...props}
      steps={tourSteps}
    />
  )
}

export default Tour
