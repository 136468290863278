import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import clsx from 'clsx'
import { use100vh } from 'react-div-100vh'
import _get from 'lodash/fp/get'

import { makeStyles, lighten, darken, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'

import {
  useTopStreamsQuery,
  useStreamQuery,
  mergeQueryResultsWith,
} from '../../services/stream-manager'
import { useCalendarEventsQuery } from '../../services/microsoft-graph'
import useWidth from '../../hooks/useWidth'

import PageTitle from '../../components/PageTitle'

import Container from '../layout/Container'

import StreamsGrid from '../streams/StreamsGrid'
import StreamsList from '../streams/StreamsList'
import StreamPopup from '../streams/StreamPopup'
import Player from '../player/Player'
import NoticeList from './NoticeList'
import Tour from '../tour'


const isCalendarEnabled = Boolean(window.__ENV.REACT_APP_EVENT_CALENDAR)

const useStyles = makeStyles((theme) => ({
  outerWrapper: ({ isMobileOrTablet, fullHeight }) => ({
    width: '100%',
    height: fullHeight,
    position: 'absolute',
    top: 0,
    left: 0,
    ...(!isMobileOrTablet ? {
      display: 'flex',
      flexDirection: 'column',
    } : undefined)
  }),
  pageWrapper: ({ isMobileOrTablet }) => !isMobileOrTablet ? {
    flex: 1,
    alignItems: 'stretch',
    display: 'flex',
    overflow: 'hidden',
  } : undefined,
  contentWrapper: {
    flex: 1,
    // We're not directly querying `isMobileOrTablet` because that form has a
    // bug, it won't handle properties for which we've specified fallbacks
    '&$desktop': {
      overflow: 'overlay !important',
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  desktop: {},
  container: {
    paddingTop: 0,
  },
  mobileLogos: {
    width: '100%',
    display: 'flex',
    padding: '0 !important',
    '& img': {
      flex: 1,
      minWidth: 0,
      height: theme.spacing(5),
      margin: theme.spacing(1),
      objectFit: 'contain',
      objectPosition: 'left center',
    },
    '& img:last-child': {
      objectPosition: 'right center',
    }
  },
  nowStreaming: ({ isMobileOrTablet }) => isMobileOrTablet ? {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  } : undefined,
  playerWrapper: ({ isMobileOrTablet }) => ({
    height: '50vh',
    backgroundColor: theme.palette.grey[900],
    ...(isMobileOrTablet
      ? {
        width: '100%',
        height: 'unset',
        maxHeight: '50vh',
      } : {
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }),
  }),
  player: {
    margin: '0 auto',
    height: '100%',
    maxHeight: ({ isMobileOrTablet }) => isMobileOrTablet ? '50vh' : undefined,
  },
  sidebar: ({ isMobileOrTablet }) => ({
    width: isMobileOrTablet ? undefined : 350,
    margin: isMobileOrTablet ? theme.spacing(2) : undefined,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
  }),
  sectionTitle: {
    boxShadow: 'none',
  },
  topViewedTitle: {
    display: 'flex',
    '& > *': {
      margin: 0,
    },
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
    '& > :nth-child(2)': {
      position: 'relative',
      top: 2,
    },
  },
  content: {},
  section: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden !important',
    '& $content': {
      flex: '1',
      overflow: 'overlay !important',
      minHeight: 0,
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  streamsList: {
    backgroundColor: theme.palette.type === 'dark'
      ? darken(theme.palette.background.paper, 0.2)
      : lighten(theme.palette.primary.light, 0.8),
  },
}))

const useCalendarEventsWithStreams = mergeQueryResultsWith((events, streams) =>
  events?.map((event) => ({
    ...event,
    streams: streams?.filter(({ id }) => event.streamIds.includes(id)) ?? [],
  }))
)

const HomePage = () => {
  const theme = useTheme()
  const playerRef = useRef()
  const fullHeight = use100vh()
  const width = useWidth()
  const isMobileOrTablet = width.match(/(xs|sm)/)
  const classes = useStyles({ isMobileOrTablet, fullHeight })

  const [selectedStreamId, setSelectedStreamId] = useState(null)
  const [
    selectedRecommendedStreamId,
    setSelectedRecommendedStreamId,
  ] = useState(null)

  const [playSelected, setPlaySelected] = useState(false)
  const [inhibitPlaySelected, setInhibitPlaySelected] = useState(false)
  const [live, setLive] = useState(false)

  useEffect(() => {
    if (playSelected && !inhibitPlaySelected) {
      playerRef.current.play()
    } else {
      playerRef.current.pause()
    }
  }, [playSelected, inhibitPlaySelected])

  const startTime = moment().seconds(0).subtract(120, 'day').add(15, 'minute')
  const topStreamsQuery = useTopStreamsQuery({
    params: {
      inc_service_acc: false,
      start: startTime.unix(),
      limit: 24,
    },
  })
  const topStreamIds = topStreamsQuery.data?.map(_get('stream_id'))
  const streamsQuery = useStreamQuery(topStreamIds, {
    skip: !topStreamIds,
  })

  const topStreamsWithStats = mergeQueryResultsWith((streams, analytics) =>
    streams?.map((stream, i) => ({
      ...stream,
      user_count: analytics[i].user_count,
      duration: analytics[i].duration
    })),
  )

  const topStreamId = topStreamsQuery.data?.[0]?.stream_id

  useEffect(() => {
    if (topStreamId != null) {
      setSelectedStreamId(topStreamId)
    }
  }, [topStreamId])

  const calendarEventsQuery = useCalendarEventsQuery(undefined, {
    pollingInterval: 60 * 1000, // milliseconds
  })
  const eventStreamIds = [...new Set(calendarEventsQuery.data?.flatMap(_get('streamIds')))]
  const eventStreamsQuery = useStreamQuery(eventStreamIds, {
    skip: !eventStreamIds.length === 0,
  })

  const eventsQuery = useCalendarEventsWithStreams(
    calendarEventsQuery,
    eventStreamsQuery,
  )

  return (
    <>
      <Tour
        name="home"
        steps={[
          {
            target: '[data-tour="home-nowstreaming"]',
            content: (
              <>
                <strong>Now streaming</strong> plays the most viewed stream.
              </>
            ),
            placement: 'bottom',
          },
          {
            target: '[data-tour="home-topviewed-main"]',
            content: (
              <>
                See a list of <strong>top viewed streams</strong> (in the past 120 days) here.
              </>
            ),
            placement: 'top',
          },
          {
            target: '[data-tour="home-topviewed-sidebar"]',
            content: (
              <>
                Here you can also see the <strong>top viewed streams</strong> lists the most viewed streams.​{' '}
              </>
            ),
            placement: 'left',
          },
          {
            target: '[data-tour="home-events"]',
            content:
              'This section will show you upcoming events (e.g. a site blast). Click on the event to play the stream at the time of the event.​',
            placement: 'left',
          },
        ]}
      />
      <StreamPopup
        id={selectedRecommendedStreamId}
        open={Boolean(selectedRecommendedStreamId)}
        onClose={() => {
          setSelectedRecommendedStreamId(null)
          setInhibitPlaySelected(false)
          if (live) {
            playerRef.current.livePlayback()
          }
        }}
      />
      <div className={classes.outerWrapper}>
        <Toolbar />
        <div className={classes.pageWrapper}>
          <div className={clsx(classes.contentWrapper, {
            [classes.desktop]: !isMobileOrTablet,
          })}>
            {isMobileOrTablet && (theme.assets?.logoTitle || theme.assets?.logoSecondary) && (
              <PageTitle className={classes.mobileLogos}>
                {theme.assets?.logoTitle && <img src={`/theme/${theme.assets.logoTitle}`} alt="Logo" />}
                {theme.assets?.logoSecondary && <img src={`/theme/${theme.assets.logoSecondary}`} alt="Logo" />}
              </PageTitle>
            )}
            {((children) => isMobileOrTablet
              ? (
                <div className={classes.nowStreaming}>
                  {children}
                </div>
              ) : children
            )(
              <>
                <PageTitle data-tour="home-nowstreaming">Now Streaming</PageTitle>
                <div className={classes.playerWrapper}>
                  <Player
                    ref={playerRef}
                    className={classes.player}
                    id={selectedStreamId}
                    onPlay={() => setPlaySelected(true)}
                    onPause={() => { !inhibitPlaySelected && setPlaySelected(false) }}
                  />
                </div>
              </>
            )}
            <PageTitle data-tour="home-topviewed-main">
              Recommended Streams
            </PageTitle>
            <Container className={classes.container}>
              <StreamsGrid
                streamsQuery={{
                  ...streamsQuery,
                  data: streamsQuery.data?.filter((_, i) => i < 12),
                }}
                onStreamSelect={(id) => {
                  setSelectedStreamId(id)
                  setPlaySelected(true)
                }}
                selectedStreamId={selectedStreamId}
                playingStreamId={playSelected ? selectedStreamId : undefined}
              />
            </Container>
          </div>
          <Paper className={classes.sidebar}>
            <div className={classes.section}>
              <PageTitle
                data-tour="home-topviewed-sidebar"
                className={clsx(classes.sectionTitle, classes.topViewedTitle)}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="p"
                >
                  Top Viewed
                </Typography>
                <Typography
                  color="textSecondary"
                  component="p"
                >
                  in the past 120 days
                </Typography>
              </PageTitle>
              <div className={clsx(classes.content, classes.streamsList)}>
                <StreamsList
                  streamsQuery={topStreamsWithStats(streamsQuery, topStreamsQuery)}
                  onSelect={({ id }) => {
                    setLive(playerRef.current.live)
                    setInhibitPlaySelected(true)
                    setSelectedRecommendedStreamId(id)
                  }}
                  hideThumbnails
                  showRedDot
                  fullPath
                />
              </div>
            </div>
            {isCalendarEnabled && (
              <div className={classes.section}>
                <PageTitle className={classes.sectionTitle}>
                  Notice Board
                </PageTitle>
                {/* <Typography */}
                {/*   data-tour="home-events" */}
                {/*   gutterBottom */}
                {/*   variant="h6" */}
                {/*   component="p" */}
                {/*   className={classes.sectionTitle} */}
                {/* > */}
                {/*   Notice Board */}
                {/* </Typography> */}
                <div className={classes.content}>
                  <NoticeList eventsQuery={eventsQuery} />
                </div>
              </div>
            )}
          </Paper>
        </div>
      </div>
    </>
  )
}

export default HomePage
