import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import clsx from 'clsx'
import { skipToken } from '@reduxjs/toolkit/query/react'
import PropTypes from 'prop-types'
import { useRowSelect, useTable } from 'react-table'
import { Prompt } from 'react-router-dom'
import { FastField } from 'formik'
import * as yup from 'yup'
import _get from 'lodash/fp/get'
import _zipWith from 'lodash/fp/zipWith'

import { withStyles, makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import MuiTable from '@material-ui/core/Table'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import Autocomplete from '@material-ui/lab/Autocomplete'
import FilterIcon from '@material-ui/icons/FilterList'

import usePaginationParams from '../../hooks/usePaginationParams'
import useFilterParams from '../../hooks/useFilterParams'

import {
  useGroupsQuery,
  useMyGroupsQuery,
  useEntityGroupsQuery,
  mergeQueryResultsWith,
} from '../../services/stream-manager'
import { useIsAdmin } from '../../services/msal'


import AlertDialog from '../dialogs/AlertDialog'
import { AddEditButton } from '../dialogs/AddEditDialog'

import TablePaginationActions from './TablePaginationActions'
import TableToolbar from './TableToolbar'

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerCheckbox: {
    padding: 0,
  },
  filterIconButton: {
    padding: 0,
    margin: `-${theme.spacing(0.5)}px 0`,
  },
  filterIcon: {
    color: theme.palette.primary.contrastText,
  },
  spacer: {
    flex: 1,
  },
  select: {
    lineHeight: 1,
  },
  disabled: {
    whiteSpace: 'nowrap',
  },
  empty: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
  emptyLoading: {
    borderBottom: 'none',
  },
  emptyMessage: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
}))

const useActiveFilterButtonStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}))

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:not(:first-child)': {
      minWidth: 150,
    },
    fontSize: 14,
    whiteSpace: 'nowrap',
  },
}))(TableCell)

/*
 * The `indeterminate` state is the 3rd state a checkbox can be in, it means
 * it's neither checked nor unchecked. It's used when the item has multiple
 * sub-options that are neither all checked nor all unchecked.
 */
const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const checkBoxRef = useRef()
  useImperativeHandle(ref, () => checkBoxRef.current)

  useEffect(() => {
    checkBoxRef.current.indeterminate = indeterminate
  }, [indeterminate])

  return <Checkbox ref={checkBoxRef} indeterminate={indeterminate} {...rest} />
})

// Create cell renderer
const Cell = ({ value, column, mapColumns = {} }) => {
  const classes = useStyles()

  return mapColumns[column.id] == null ? (
    <div className={classes.disabled}>{[].concat(value).join(', ')}</div>
  ) : (
    <div className={classes.disabled}>
      {[]
        .concat(value)
        .map((value) => {
          const display = mapColumns[column.id].find(
            (val) => val.id === value,
          )?.name
          const title = value?.id ?? (value != null ? String(value) : value)
          return display === title ? (
            display
          ) : display ? (
            <Tooltip key={title} arrow title={title} placement="top">
              <span>{display}</span>
            </Tooltip>
          ) : null
        })
        .filter(Boolean)
        .flatMap((value, index, array) =>
          array.length - 1 !== index ? [value, ', '] : value,
        )}
    </div>
  )
}

export const groupsValidationSchema = yup.object({
  __groups__: yup
    .array(yup.string())
    .min(1, 'At least one group is required')
    .required('Group is required'),
})

export const GroupsSelect = () => {
  const isAdmin = useIsAdmin()
  const groupsQuery = useGroupsQuery(undefined, { skip: [undefined, false].includes(isAdmin) })
  const myGroupsQuery = useMyGroupsQuery(undefined, { skip: [undefined, true].includes(isAdmin) })
  const options = useMemo(
    () => (isAdmin ? groupsQuery : myGroupsQuery).data?.map(_get('group')) ?? [],
    [isAdmin, groupsQuery, myGroupsQuery],
  )

  return (
    <FastField name="__groups__">
      {({ field: { onChange, ...field }, meta }) => (
        <Autocomplete
          {...field}
          multiple
          ChipProps={{ color: 'primary', size: 'small' }}
          fullWidth
          onChange={(_, value) =>
            onChange({
              target: {
                name: field.name,
                multiple: true,
                value,
              },
            })
          }
          getOptionSelected={(option, value) => value === option}
          loading={groupsQuery.isFetching}
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Groups"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              margin="dense"
            />
          )}
        />
      )}
    </FastField>
  )
}

// Set our cell renderer as the default Cell renderer
const defaultColumn = { Cell }

const itemsByRowIds = (items, selectedRowIds) => {
  const indexes = Object.entries(selectedRowIds)
    .filter(([, isSelected]) => isSelected)
    .map(([index]) => Number(index))
  return items?.filter((_, i) => indexes.includes(i)) ?? []
}

const useZipItemsWithGroups = mergeQueryResultsWith(
  _zipWith((item, groups) => ({
    ...item,
    __groups__: groups?.map(_get('id')) ?? [],
  })),
)

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const dummyMutation = [undefined, {}]

const EnhancedTable = ({
  label,
  columns: _columns,
  mapColumns: _mapColumns,
  filterColumns,
  hiddenColumns = [],
  FormFields: InitialFormFields,
  createValidationSchema: _createValidationSchema,
  actions,
  bulkActions,
  onRowSelect,
  itemsQuery: _itemsQuery,
  downloadCsv,
  createMutation = dummyMutation,
  updateMutation = dummyMutation,
  deleteMutation = dummyMutation,
  urlKey,
  withGroups,
  onClose,
  search: searchable,
  loading,
  initialValues,
  pagination = true,
}) => {
  const [menuAnchors, setMenuAnchors] = useState({})

  const FormFields = useCallback(
    (props) =>
      !InitialFormFields ? null : withGroups ? (
        <>
          {<InitialFormFields {...props} />}
          {withGroups && <GroupsSelect />}
        </>
      ) : (
        <InitialFormFields {...props} />
      ),
    [withGroups, InitialFormFields],
  )

  const createValidationSchema = useMemo(
    () =>
      !_createValidationSchema
        ? undefined
        : (props) =>
          withGroups
            ? _createValidationSchema(props).concat(groupsValidationSchema)
            : _createValidationSchema(props),
    [withGroups, _createValidationSchema],
  )

  const [, createResult] = createMutation
  const [update, updateResult] = updateMutation
  const [del, deleteResult] = deleteMutation

  const [{ page, limit }, setPaginationParams] =
    usePaginationParams({ urlKey })
  const [_filterParams, setFilterParams] = useFilterParams({ urlKey })

  const filterParams = (({ division, site, section, ...rest }) => ({
    division: division?.id,
    site: site?.id,
    section: section?.id,
    ...rest,
  }))(_filterParams)

  const classes = useStyles()
  const activeFilterButtonClasses = useActiveFilterButtonStyles()

  const itemGroupsQuery = useEntityGroupsQuery(
    _itemsQuery.data?.map(_get('id')),
    { skip: !withGroups || _itemsQuery.data == null },
  )
  const itemsQuery = useZipItemsWithGroups(
    _itemsQuery,
    withGroups ? itemGroupsQuery : skipToken,
  )
  const groupsQuery = useGroupsQuery(undefined, { skip: !withGroups })
  const data = useMemo(() => itemsQuery.data ?? [], [itemsQuery.data])

  const combinedQueryStatus = useCombinedStatus(itemsQuery, groupsQuery)
  const combinedMutationStatus = useCombinedStatus(
    createResult,
    updateResult,
    deleteResult,
  )
  const combinedStatus = useCombinedStatus(
    combinedQueryStatus,
    combinedMutationStatus,
  )

  const columns = useMemo(() => {
    if (!withGroups) return _columns
    if (_columns.find(({ accessor }) => accessor === '__groups__'))
      return _columns
    return [..._columns, { Header: 'Groups', accessor: '__groups__' }]
  }, [_columns, withGroups])

  const mapColumns = useMemo(() => {
    if (!withGroups) return _mapColumns
    return {
      ..._mapColumns,
      __groups__:
        groupsQuery.data?.map(({ group, ...rest }) => ({
          name: group,
          ...rest,
        })) ?? [],
    }
  }, [_mapColumns, withGroups, groupsQuery.data])

  const { getTableProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns,
      },
      // In order to use a non-stale version of this in a cell renderer, we have
      // to add it here as an option and then reference it from the cell
      // renderer props
      updateMutation,
    },
    useRowSelect,
    (hooks) => {
      // Let's prepend a column for actions
      if (update || actions) {
        hooks.allColumns.push((columns) => [
          {
            id: 'actions',
            Header: () => 'Actions',
            Cell: (props) => (
              <>
                {update && (
                  <AddEditButton
                    editing
                    label={label}
                    initialValues={props.row.original}
                    updateMutation={props.updateMutation}
                    createValidationSchema={createValidationSchema}
                  >
                    {<FormFields />}
                  </AddEditButton>
                )}{' '}
                {actions?.(props)}
              </>
            ),
          },
          ...columns,
        ])
      }

      if (bulkActions || del) {
        // And a column for selection
        hooks.allColumns.push((columns) => [
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox. Pagination is a problem since this will select all
            // rows even though not all rows are on the current page. The solution should
            // be server side pagination. For one, the clients should not download all
            // rows in most cases. The client should only download data for the current page.
            // In that case, getToggleAllRowsSelectedProps works fine.
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox
                className={classes.headerCheckbox}
                {...getToggleAllRowsSelectedProps()}
              />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ])
      }
    },
  )

  const selectedItems = useMemo(
    () => itemsByRowIds(itemsQuery.data, state.selectedRowIds),
    [itemsQuery.data, state.selectedRowIds],
  )

  const setPage = useCallback(
    (_, index) => {
      setPaginationParams({ page: index + 1 })
    },
    [setPaginationParams],
  )

  const setLimit = useCallback(
    (event) => {
      const limit = Number(event.target.value)
      setPaginationParams({ limit })
    },
    [setPaginationParams],
  )

  const deleteHandler = useCallback(
    () => del?.(selectedItems),
    [del, selectedItems],
  )

  useEffect(() => {
    onRowSelect?.(selectedItems)
  }, [onRowSelect, selectedItems])

  // Render the UI for your table
  return (
    <>
      <AlertDialog
        open={groupsQuery.data?.length === 0}
        alert={
          <>
            No groups have been added —{' '}
            <strong>add those first before proceeding here!</strong>
          </>
        }
      />
      {[itemsQuery, groupsQuery, createResult, updateResult].map(
        (result, i) => (
          <AlertDialog
            key={result.startedTimeStamp || i}
            open={result.isError}
            severity="error"
            status={result.error?.status}
            alert={result.error?.data}
          />
        ),
      )}
      {deleteResult.isError && (
        <AlertDialog
          key={`${deleteResult.startedTimeStamp}`}
          open={true}
          severity="error"
          status={deleteResult.error?.status}
          alert={deleteResult.error?.data}
        />
      )}
      {combinedMutationStatus.isLoading && (
        <Prompt message="You have unsaved changes, are you sure?" />
      )}
      <div className={classes.toolbarContainer}>
        <TableToolbar
          label={label}
          selectedItems={selectedItems}
          columns={columns}
          deleteHandler={deleteHandler}
          bulkActions={bulkActions}
          search={searchable}
          withGroups={withGroups}
          FormFields={FormFields}
          createValidationSchema={createValidationSchema}
          createMutation={createMutation}
          downloadCsv={downloadCsv?.[0]}
          onClose={onClose}
          urlKey={urlKey}
          initialValues={initialValues}
        />
        {(loading || combinedStatus.isLoading || combinedStatus.isFetching) && (
          <LinearProgress className={classes.progress} />
        )}
      </div>
      <TableContainer className={classes.container}>
        <MuiTable size="small" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <StyledTableCell
                    key={column.id}
                    {...column.getHeaderProps()}
                    padding={column.id === 'selection' ? 'checkbox' : 'normal'}
                  >
                    <div className={classes.header}>
                      {column.render('Header')}
                      <div className={classes.spacer} />
                      {filterColumns &&
                        Object.keys(filterColumns).includes(column.id) && (
                          <>
                            <Menu
                              id={`filterMenu-${column.id}`}
                              anchorEl={menuAnchors[column.id]}
                              keepMounted
                              open={Boolean(menuAnchors[column.id])}
                              onClose={() =>
                                setMenuAnchors((anchors) => ({
                                  ...anchors,
                                  [column.id]: undefined,
                                }))
                              }
                            >
                              <MenuItem
                                disabled={!filterParams[column.id]}
                                onClick={() => {
                                  setFilterParams({ [column.id]: undefined })
                                  setMenuAnchors((anchors) => ({
                                    ...anchors,
                                    [column.id]: undefined,
                                  }))
                                }}
                              >
                                Clear filter
                              </MenuItem>
                              <Divider />
                              {filterColumns[column.id]?.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  disabled={
                                    filterParams[column.id] === option.id
                                  }
                                  onClick={() => {
                                    setFilterParams({ [column.id]: option.id })
                                    setMenuAnchors((anchors) => ({
                                      ...anchors,
                                      [column.id]: undefined,
                                    }))
                                  }}
                                >
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Menu>
                            <Tooltip title="Filter">
                              <IconButton
                                className={classes.filterIconButton}
                                classes={
                                  filterParams[column.id] != null
                                    ? activeFilterButtonClasses
                                    : undefined
                                }
                                size="small"
                                aria-controls={`filterMenu-${column.id}`}
                                aria-haspopup="true"
                                aria-label="Filter"
                                onClick={(e) =>
                                  setMenuAnchors((anchors) => ({
                                    ...anchors,
                                    [column.id]: e.currentTarget,
                                  }))
                                }
                              >
                                <FilterIcon className={classes.filterIcon} size="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell, i) => (
                    <TableCell
                      {...cell.getCellProps()}
                      padding={
                        ['selection', 'actions', 'access', 'status'].includes(
                          cell.column.id,
                        )
                          ? 'checkbox'
                          : 'normal'
                      }
                    >
                      {cell.render('Cell', { mapColumns })}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {rows.length === 0 && (
        <Container
          maxWidth={false}
          className={clsx(classes.empty, {
            [classes.emptyLoading]: combinedQueryStatus.isFetching,
          })}
        >
          {combinedQueryStatus.isFetching ? (
            <CircularProgress />
          ) : (
            <Typography
              variant="h6"
              component="p"
              className={classes.emptyMessage}
            >
              No results
            </Typography>
          )}
        </Container>
      )}
      {pagination && _itemsQuery.data?.count != null && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[
            5, 10, 25, 50, 100,
            // NOTE: We could offer an "all" option here, but I'm not sure that
            // it's a great idea. If the API has default pagination applied, we
            // do "walk the pages" programmatically (we will transparently fetch
            // all of them), but it may also that we implement windowing [1] to
            // draw the table rows.
            // [1]: https://reactjs.org/docs/optimizing-performance.html#virtualize-long-lists
          ]}
          count={_itemsQuery.data.count}
          rowsPerPage={limit}
          page={page - 1}
          SelectProps={{ inputProps: { 'aria-label': 'rows per page' } }}
          onPageChange={setPage}
          onRowsPerPageChange={setLimit}
          ActionsComponent={TablePaginationActions}
        />
      )}
    </>
  )
}

EnhancedTable.propTypes = {
  label: PropTypes.node.isRequired,
  columns: PropTypes.array.isRequired,
  hiddenColumns: PropTypes.array,
  mapColumns: PropTypes.object,
  FormFields: PropTypes.elementType,
  bulkActions: PropTypes.func,
  actions: PropTypes.func,
  onRowSelect: PropTypes.func,
  itemsQuery: PropTypes.object.isRequired,
  // TODO: try to be more precise than just `array` here
  downloadCsv: PropTypes.array,
  createMutation: PropTypes.array,
  updateMutation: PropTypes.array,
  deleteMutation: PropTypes.array,
  urlKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  withGroups: PropTypes.bool,
  search: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

export default EnhancedTable
