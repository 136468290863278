import React, { useMemo, useCallback, useState } from 'react'
import { Field, FastField } from 'formik'
import * as yup from 'yup'
import _get from 'lodash/fp/get'
import _zipWith from 'lodash/fp/zipWith'

import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import UpdateIcon from '@material-ui/icons/Update'

import { withAdmin } from '../../services/msal'

import {
  useDeviceMappingsQuery,
  useSshTunnelQuery,
  useSitesQuery,
  useServersQuery,
  useUnassignedDevicesQuery,
  useLinkServerToDeviceMutation,
  useUnlinkServerFromDeviceMutation,
  useCreateServerMutation,
  useUpdateServerMutation,
  useDeleteServerMutation,
  useEnableSshTunnelMutation,
  useDisableSshTunnelMutation,
  useConfigureServerMutation,
  useConfigureServerAndDownstreamMutation,
  mergeQueryResultsWith,
} from '../../services/stream-manager'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import EnhancedTable from '../table/EnhancedTable'
import ConfirmDialog from '../dialogs/ConfirmDialog'

import Status from './ServerStatus'

const DeviceFormFields = () => {
  const serversQuery = useServersQuery()
  const devicesQuery = useUnassignedDevicesQuery()

  return (
    <>
      <Field name="media_server_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Server"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {serversQuery.data?.map((server) => (
              <MenuItem key={server.id} value={server.id}>
                {`${server.name}: ${server.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </Field>
      <Field name="device_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Device"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {devicesQuery.data?.map((device) => (
              <MenuItem key={device} value={device}>
                {device}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </Field>
    </>
  )
}

const createDeviceValidationSchema = () =>
  yup.object({
    media_server_id: yup.string().required('Media server is required'),
    device_id: yup.string().required('Device is required'),
  })

const deviceColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'IoT Edge Device',
    accessor: 'device_id',
  },
]

const DeviceTable = () => (
  <EnhancedTable
    label="Linked devices"
    urlKey="devices"
    itemsQuery={usePaginatedFilteredQuery(
      useDeviceMappingsQuery,
      { urlKey: 'devices' }
    )()}
    createMutation={useLinkServerToDeviceMutation()}
    deleteMutation={useUnlinkServerFromDeviceMutation()}
    columns={deviceColumns}
    FormFields={DeviceFormFields}
    createValidationSchema={createDeviceValidationSchema}
  />
)

const ServerFormFields = () => {
  const sitesQuery = useSitesQuery()

  return (
    <>
      <FastField name="name">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Name"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="description">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Description"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="site_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Site"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {sitesQuery.data?.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {`${site.name}: ${site.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
      <FastField name="address">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Address"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="ssh_tunnel">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="SSH Tunnel"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            <MenuItem value={false}>no</MenuItem>
            <MenuItem value={true}>yes</MenuItem>
          </TextField>
        )}
      </FastField>
      <FastField name="disk_storage">
        {({ field, meta }) => (
          <TextField
            {...field}
            type="number"
            label="Disk Storage (MB)"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
    </>
  )
}

const createServerValidationSchema = () =>
  yup.object({
    name: yup
      .string()
      .min(4, 'The server name should have at least 4 characters')
      .max(80, 'The server name should have maximum 80 characters')
      .required('Server name is required'),
    description: yup.string().required('Server description is required'),
    site_id: yup.string().required('Site is required'),
    address: yup.string().required('Address is required'),
  })

const ReconfigureServers = ({ items }) => {
  const [open, setOpen] = useState(false)

  const [
    configureServerAndDownstream,
    configureServerAndDownstreamStatus,
  ] = useConfigureServerAndDownstreamMutation()

  const handleClose = useCallback(
    async (response) => {
      if (response === 'yes') {
        const ids = items.map(_get('id'))
        await configureServerAndDownstream(ids)
      }
      setOpen(false)
    },
    [configureServerAndDownstream, items],
  )

  return (
    <>
      <Tooltip title="Reconfigure">
        <IconButton aria-label="update" onClick={() => setOpen(true)}>
          <UpdateIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        loading={configureServerAndDownstreamStatus.isLoading}
        title="Reconfigure media server(s)?"
        text="Reconfigure media server(s)?"
        onClose={handleClose}
      />
    </>
  )
}

const serverColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Site',
    accessor: 'site_id',
  },
  {
    Header: 'Address',
    accessor: 'address',
  },
  {
    Header: 'SSH Tunnel',
    accessor: 'ssh_tunnel',
  },
  {
    Header: 'Disk Storage (MB)',
    accessor: 'disk_storage',
  },
]

const mapServerValuesToArg = ({ id, ...body }) => ({ id, body })

const useZipWithSshTunnel = mergeQueryResultsWith(
  _zipWith((deviceMapping, ssh_tunnel) => ({
    ...deviceMapping,
    ssh_tunnel,
  })),
)

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const useUpdateAndConfigureServerMutation = () => {
  const [updateServer, updateServerResult] = useUpdateServerMutation()
  const [enableSshTunnel, enableSshTunnelResult] = useEnableSshTunnelMutation()
  const [
    disableSshTunnel,
    disableSshTunnelResult,
  ] = useDisableSshTunnelMutation()
  const [configureServer, configureServerResult] = useConfigureServerMutation()
  const [
    configureServerAndDownstream,
    configureServerAndDownstreamResult,
  ] = useConfigureServerAndDownstreamMutation()

  const deviceMappingsQuery = useDeviceMappingsQuery()
  const assignedServerIds = useMemo(
    () => deviceMappingsQuery.data?.map(_get('id')) ?? [],
    [deviceMappingsQuery.data],
  )

  const configureAssignedServer = useCallback(
    (id) => {
      if (assignedServerIds.includes(id)) {
        return configureServer(id)
      }
    },
    [configureServer, assignedServerIds],
  )

  const configureAssignedServerAndDownstream = useCallback(
    (id) => {
      if (assignedServerIds.includes(id)) {
        return configureServerAndDownstream(id)
      }
    },
    [configureServerAndDownstream, assignedServerIds],
  )

  const update = useCallback(
    async ({ id, body: { ssh_tunnel, ...body } = {} }) => {
      if (typeof ssh_tunnel === 'boolean') {
        if (ssh_tunnel === true) {
          await enableSshTunnel(id)
        } else {
          await disableSshTunnel(id)
        }
      }

      if (body) {
        await updateServer({ id, body })
        return configureAssignedServerAndDownstream(id)
      }

      return configureAssignedServer(id)
    },
    [
      enableSshTunnel,
      disableSshTunnel,
      updateServer,
      configureAssignedServer,
      configureAssignedServerAndDownstream,
    ],
  )

  const updateStatus = useCombinedStatus(
    enableSshTunnelResult,
    disableSshTunnelResult,
    updateServerResult,
    configureServerResult,
    configureServerAndDownstreamResult,
  )

  return [update, updateStatus]
}

const ServerTable = () => {
  const serversQuery = usePaginatedFilteredQuery(
    useServersQuery,
    { urlKey: 'servers' }
  )()
  const sshTunnelsQuery = useSshTunnelQuery(
    serversQuery.data?.map(_get('id')),
    { skip: serversQuery.data == null },
  )
  const itemsQuery = useZipWithSshTunnel(serversQuery, sshTunnelsQuery)

  const sitesQuery = useSitesQuery()

  const mapColumns = useMemo(
    () => ({
      site_id: sitesQuery.data,
      ssh_tunnel: [
        { id: true, name: 'yes' },
        { id: false, name: 'no' },
      ],
    }),
    [sitesQuery.data],
  )

  return (
    <EnhancedTable
      label="Media servers"
      urlKey="servers"
      itemsQuery={itemsQuery}
      createMutation={useMapValuesToArg(
        useCreateServerMutation,
        mapServerValuesToArg,
      )}
      updateMutation={useMapValuesToArg(
        useUpdateAndConfigureServerMutation,
        mapServerValuesToArg,
      )}
      deleteMutation={useDeleteServerMutation()}
      createValidationSchema={createServerValidationSchema}
      columns={serverColumns}
      actions={({ row }) => <Status entity={row.original} />}
      FormFields={ServerFormFields}
      mapColumns={mapColumns}
      bulkActions={(props) => <ReconfigureServers {...props} />}
    />
  )
}

const ServerManagement = () => (
  <>
    <Paper>
      <ServerTable />
    </Paper>
    <br />
    <Paper>
      <DeviceTable />
    </Paper>
  </>
)

export const ServerManagementPage = withAdmin(ServerManagement)
