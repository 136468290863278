import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import BarChartIcon from '@material-ui/icons/BarChart'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import { useIsAdmin } from '../../services/msal'
import {
  useMeQuery,
  useUserViewedStreamsQuery,
} from '../../services/stream-manager'

import EnhancedTable from '../table/EnhancedTable'

import { formatDuration, formatTime } from './utils'

const MostViewedStreamsButton = (props) => (
  <Tooltip title="Top viewed streams">
    <IconButton {...props} aria-label="Top viewed streams" size="small">
      <BarChartIcon />
    </IconButton>
  </Tooltip>
)

MostViewedStreamsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const columns = [
  {
    accessor: 'name',
    Header: 'Stream name',
  },
  {
    accessor: 'duration',
    Header: 'Total view time',
    Cell: ({ value }) => formatDuration(value * 1000),
  },
  {
    accessor: 'last_viewed',
    Header: 'Last viewed',
    Cell: ({ value }) => formatTime(value),
  },
]

export function MostViewedStreams() {
  const isAdmin = useIsAdmin()
  const [open, setOpen] = useState(false)
  const urlKey = useState()
  const meQuery = useMeQuery()

  const itemsQuery = usePaginatedFilteredQuery(
    useUserViewedStreamsQuery,
    { urlKey },
  )(
    { id: meQuery.data?.id },
    { skip: !open || !meQuery.data?.id || !isAdmin },
  )

  return (
    <>
      {isAdmin && <MostViewedStreamsButton onClick={() => setOpen(true)} />}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
        <EnhancedTable
          label={
            <>
              <em>{meQuery.data?.name}&rsquo;s</em> top viewed streams
            </>
          }
          columns={columns}
          itemsQuery={itemsQuery}
          onClose={() => setOpen(false)}
          urlKey={urlKey}
        />
      </Dialog>
    </>
  )
}
