import { makeStyles } from '@material-ui/core/styles'

import boxToDropShadow from '../../styles/boxToDropShadow'
import boxToTextShadow from '../../styles/boxToTextShadow'

export default makeStyles((theme) => ({
  playerRoot: {
    outline: 'none',
    position: 'relative',
    aspectRatio: ({ width = 1280, height = 720 }) => `${width} / ${height}`,
    maxWidth: ({ width }) => width,
    maxHeight: '100%',
    backgroundColor: theme.palette.grey[900],
    cursor: 'pointer',
    overflow: 'hidden !important',
    '&:fullscreen': {
      maxWidth: '100%',
    },
    '&:-webkit-full-screen': {
      maxWidth: '100%',
    },
  },
  titleBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    color: theme.palette.common.white,
    cursor: 'default',
    backgroundColor: 'rgba(10, 10, 10, 0.5)',
    transition: 'transform 150ms',
    '&$hidden': {
      transform: 'translateY(-100%)',
    },
  },
  hidden: {},
  title: {
    flex: '1',
    padding: theme.spacing(1),
  },
  closeButton: {
    color: theme.palette.common.white,
  },
  bottomBar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    transition: 'transform 150ms',
    '&$hidden': {
      transform: 'translateY(100%)',
    },
  },
  notificationsIcon: {
    color: theme.palette.common.white,
  },
  countdown: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'absolute',
    bottom: '100%',
    right: 0,
    cursor: 'default',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(10, 10, 10, 0.5)',
    padding: theme.spacing(1),
  },
  controlsBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: 'rgba(10, 10, 10, 0.5)',
    cursor: 'default',
  },
  playIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: ({ isMobile, isMobileOrTablet }) =>
      isMobile ? 96 : isMobileOrTablet ? 128 : 256,
    color: theme.palette.primary.contrastText,
    filter: boxToDropShadow(theme.shadows[1]),
    pointerEvents: 'none',
  },
  videoElement: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    aspectRatio: ({ width, height }) => `${width} / ${height}`,
    width: ({ width, digitalSignage }) => (digitalSignage ? '100%' : width),
    height: 'auto',
    isolation: 'isolate',
    '$playerRoot:fullscreen &': {
      width: '100%',
    },
    '$playerRoot:-webkit-full-screen &': {
      width: '100%',
    },
  },
  progressBar: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '100%',
    maxWidth: `calc(100% - ${theme.spacing(4)}px)`,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingBottom: 0,
  },
  progressTime: ({ isMobile }) => ({
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    ...(isMobile
      ? {
          padding: theme.spacing(1),
          fontSize: 'smaller',
        }
      : undefined),
  }),
  icon: {
    color: theme.palette.common.white,
  },
  disabledIconButton: {
    cursor: 'default',
  },
  liveButtonSelected: {
    color: 'red !important',
  },
  rightIcons: {
    marginLeft: 'auto',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  loadingBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  spinner: {
    margin: 'auto',
    filter: boxToDropShadow(theme.shadows[1]),
  },
  loadingText: ({ isMobile, isMobileOrTablet }) => ({
    paddingTop: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    fontSize: isMobile ? 10 : isMobileOrTablet ? 16 : 24,
    textShadow: boxToTextShadow(theme.shadows[1]),
  }),
  timePicker: {
    padding: theme.spacing(2),
  },
}))
