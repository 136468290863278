import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'

import useWidth from '../../hooks/useWidth'

import Player from '../player/Player'

const useStyles = makeStyles((theme) => ({
  modal: ({ isMobile }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isMobile ? 0 : theme.spacing(8),
  }),
  backdrop: ({ isMobile }) => ({
    backgroundColor: isMobile ? 'black !important' : undefined,
  }),
  player: ({ isMobile }) => ({
    borderRadius: isMobile ? undefined : theme.shape.borderRadius,
  }),
}))

const StreamPopup = ({ id, initDate, onClose, open, ...props }) => {
  const width = useWidth()
  const isMobile = width.match(/(xs|sm)/)
  const classes = useStyles({ isMobile })

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        classes: { root: classes.backdrop },
        onClick: onClose,
      }}
      {...props}
    >
      <Player
        id={id}
        className={classes.player}
        onClose={onClose}
        initDate={initDate}
        play={open}
      />
    </Modal>
  )
}

StreamPopup.propTypes = {
  initDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func,
  id: PropTypes.string,
  open: PropTypes.bool,
}

export default StreamPopup
