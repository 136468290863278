import React, { useCallback, useState, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { useAppAuth } from '../../services/msal'
import { useVersionQuery } from '../../services/stream-manager'

import useSurfaceStyles from './styles'


const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
))

const useStyles = makeStyles({
  loading: {
    display: 'inline-block',
    verticalAlign: 'text-top',
  },
})

const SmVersion = () => {
  const classes = useStyles()
  const versionQuery = useVersionQuery(undefined, {
    refetchOnMountOrArgChange: true,
  })

  return versionQuery.isFetching ? (
    <CircularProgress
      component="span"
      className={classes.loading}
      size="1rem"
    />
  ) : (
    <>v{versionQuery.data}</>
  )
}

const AccountInfoList = ({ onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const [open, setOpen] = useState(false)

  const handleClickOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    onSelect()
    setOpen(false)
  }, [onSelect])

  const appAuth = useAppAuth()

  return (
    <>
      <List>
        <ListItem
          className={surfaceClasses.listItem}
          classes={{ selected: surfaceClasses.listItemSelected }}
          selected={open}
          button
          onClick={handleClickOpen}
        >
          <ListItemIcon>
            <AccountCircleIcon className={surfaceClasses.drawerIcons} />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
      </List>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Account Information'}
        </DialogTitle>
        <DialogContent id="alert-dialog-slide-description">
          <DialogContentText>
            <b>Name:</b> {appAuth.data?.account.name}
          </DialogContentText>
          <DialogContentText>
            <b>Username:</b> {appAuth.data?.account.username}
          </DialogContentText>
          <DialogContentText>
            <b>UI:</b> v{process.env.REACT_APP_VERSION}
          </DialogContentText>
          <DialogContentText>
            <b>API:</b> {open && <SmVersion />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

AccountInfoList.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default AccountInfoList
