import { useMemo, useCallback } from 'react'
import moment from 'moment'
import slug from 'slug'

import store from '../store'

import streamManager, {
  useDivisionsQuery,
  useSitesQuery,
  useSectionsQuery,
  useGroupsQuery,
} from '../services/stream-manager'

import useURLParams from './useURLParams'


const useFilterParams = ({
  urlKey,
  group: defaultGroup,
  division: defaultDivision,
  site: defaultSite,
  section: defaultSection,
  tags: defaultTags,
  from: defaultFrom,
  until: defaultUntil,
  ...defaults
} = {}) => {
  const [{
    group = defaultGroup?.id ?? defaultGroup,
    division = defaultDivision?.id ?? defaultDivision,
    site = defaultSite?.id ?? defaultSite,
    section = defaultSection?.id ?? defaultSection,
    tags: _tags = typeof defaultTags === 'string'
      ? defaultTags.split(',')
      : defaultTags,
    from: _from = moment.isMoment(defaultFrom)
      ? defaultFrom.format()
      : defaultFrom instanceof Date
        ? moment(defaultFrom).format()
        : defaultFrom,
    until: _until = moment.isMoment(defaultUntil)
      ? defaultUntil.format()
      : defaultUntil instanceof Date
        ? moment(defaultUntil).format()
        : defaultUntil,
    ...rest
  }, setURLParams] = useURLParams(urlKey)

  /*
   * Make queries for _all_ the divisions, sites, sections, and groups, and
   * select the relevant ones from the results. This query is likely cached
   * already, whereas queries for the individual entities may not yet be.
   *
   * Even if they're not already cached, it'll prime the cache, these queries
   * (i.e. for all the entities in the collection), are made a lot.
   */

  const divisionQuery = useDivisionsQuery(undefined, {
    skip: !division,
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.find((d) => slug(d.name) === division),
      ...rest,
    }),
  })

  const siteQuery = useSitesQuery(undefined, {
    skip: !site || !division,
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.find(
        (s) =>
          slug(s.name) === site && s.division_id === divisionQuery.data?.id,
      ),
      ...rest,
    }),
  })

  const sectionQuery = useSectionsQuery(undefined, {
    skip: !section || !site || !division,
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.find(
        (s) => slug(s.name) === section && s.site_id === siteQuery.data?.id,
      ),
      ...rest,
    }),
  })

  const groupQuery = useGroupsQuery(undefined, {
    skip: !group,
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.find((g) => slug(g.name) === group),
      ...rest,
    }),
  })

  const tags = useMemo(() => Array.isArray(_tags)
    ? _tags.map((tag) => ({
      tag,
      id: tag,
      name: tag,
    }))
    : typeof _tags === 'string'
      ? [
        {
          tag: _tags,
          id: _tags,
          name: _tags,
        },
      ]
      : undefined, [_tags])

  const from = useMemo(() => _from && moment(_from.replace(' ', '+')), [_from])
  const until = useMemo(() => _until && moment(_until.replace(' ', '+')), [_until])

  const filterParams = {
    isLoading:
      division && ['uninitialized', 'pending'].includes(divisionQuery.status) ||
      site && ['uninitialized', 'pending'].includes(siteQuery.status) ||
      section && ['uninitialized', 'pending'].includes(sectionQuery.status) ||
      group && ['uninitialized', 'pending'].includes(groupQuery.status),
    division: divisionQuery.data,
    site: siteQuery.data,
    section: sectionQuery.data,
    group: groupQuery.data,
    tags,
    from,
    until,
    ...defaults,
    ...rest,
  }

  const setFilterParams = useCallback(
    async (params) => {
      const keys = Object.keys(params)

      /*
       * Setting the division should clear the site and section, since
       * they're in a hierarchy.
       */
      if (keys.includes('division')) {
        params.site = null
        params.section = null
      }

      /*
       * Setting the site should clear the section, since
       * they're in a hierarchy.
       */
      if (keys.includes('site')) {
        params.section = null
      }

      const sectionsQuery = params.section != null
            ? store.dispatch(streamManager.endpoints.sections.initiate(undefined, { subscribe: false }))
        : Promise.resolve()

      const sitesQuery = params.site != null || params.section != null
            ? store.dispatch(streamManager.endpoints.sites.initiate(undefined, { subscribe: false }))
        : Promise.resolve()

      const divisionsQuery = params.division != null || params.site != null || params.section != null
            ? store.dispatch(streamManager.endpoints.divisions.initiate(undefined, { subscribe: false }))
        : Promise.resolve()

      const groupsQuery = params.group != null
            ? store.dispatch(streamManager.endpoints.groups.initiate(undefined, { subscribe: false }))
        : Promise.resolve()

      let [
        section,
        site,
        division,
        group,
      ] = await Promise.all([
        sectionsQuery.then((res) => res?.data?.find(({ id }) => id === (params.section?.id ?? params.section))),
        sitesQuery.then((res) => res?.data?.find(({ id }) => id === (params.site?.id ?? params.site))),
        divisionsQuery.then((res) => res?.data?.find(({ id }) => id === (params.division?.id ?? params.division))),
        groupsQuery.then((res) => res?.data?.find(({ id }) => id === (params.group?.id ?? params.group))),
      ])

      /*
       * Setting the section should set the site and division, too, since
       * they're in a hierarchy
       */
      if (section) {
        site = await sitesQuery.then((res) => res?.data?.find(({ id }) => id === section.site_id))
        params.site = site.id
      }

      /*
       * Setting the division should set the site, too, since
       * they're in a hierarchy
       */
      if (site) {
        division = await divisionsQuery.then((res) => res?.data?.find(({ id }) => id === site.division_id))
        params.division = division.id
      }

      return setURLParams(
        Object.entries(params).reduce((acc, [key, value]) => {
          // When setting these values in the URL, if the value is the same as
          // its default value, don't actually reflect in the URL, instead just
          // set it to undefined (which will remove it from the URL). The
          // default value will still be returned from the hook, i.e. we're not
          // unsetting the value the consumer gets, merely what's shown in the
          // URL.
          if (key === 'division') {
            acc.division = division
              && division.id !== defaultDivision?.id
              && division.id !== defaultDivision
              ? slug(division.name)
              : undefined
          } else if (key === 'site') {
            acc.site = site
              && site.id !== defaultSite?.id
              && site.id !== defaultDivision
              ? slug(site.name)
              : undefined
          } else if (key === 'section') {
            acc.section = section
              && section.id !== defaultSection?.id
              && section.id !== defaultSection
              ? slug(section.name)
              : undefined
          } else if (key === 'group') {
            acc.group = group
              && group.id !== defaultGroup?.id
              && group.id !== defaultGroup
              ? slug(group.name)
              : undefined
          } else if (key === 'tags') {
            const _defaultTags = [].concat(typeof defaultTags === 'string' ? defaultTags.split(',') : defaultTags)
            acc.tags = value && ![].concat(value).every((tag) => _defaultTags.includes(tag))
              ? value
              : undefined
          } else if (key === 'from') {
            acc.from = value && !moment(value).isSame(moment(defaultFrom))
              ? value
              : undefined
          } else if (key === 'until') {
            acc.until = value && !moment(value).isSame(moment(defaultUntil))
              ? value
              : undefined
          } else {
            acc[key] = value === '' || defaults[key] === value
              ? undefined
              : value
          }
          return acc
        }, {}),
      )
    },
    [
      setURLParams,
      defaultGroup,
      defaultDivision,
      defaultSite,
      defaultSection,
      defaultTags,
      defaultFrom,
      defaultUntil,
      defaults,
    ],
  )

  return [filterParams, setFilterParams]
}

export default useFilterParams
