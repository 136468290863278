import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import slug from 'slug'
import qs from 'qs'

import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import useFilterParams from '../../hooks/useFilterParams'

import SectionList from './SectionList'

import useSurfaceStyles from './styles'


const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(9),
  },
  iconButton: {
    margin: `-${theme.spacing(2)}px 0`,
  },
}))

const SiteListItem = ({ site, division, onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const classes = useStyles()

  const routeMatch = useRouteMatch('/streams')
  const [filter] = useFilterParams()

  const [open, setOpen] = useState(
    routeMatch &&
    filter.division?.id === division.id &&
    filter.site?.id === site.id
  )

  const selected =
    routeMatch &&
    !filter.section &&
    filter.division?.id === division.id &&
    filter.site?.id === site.id

  return (
    <>
      <ListItem
        selected={selected}
        button
        className={clsx(surfaceClasses.listItem, classes.listItem)}
        classes={{ selected: surfaceClasses.listItemSelected }}
        onClick={() => {
          setOpen(true)
          onSelect()
        }}
        component={Link}
        to={`/streams?${qs.stringify({
          division: slug(division.name),
          site: slug(site.name),
        })}`}
      >
        <ListItemText primary={site.name} />
        <Tooltip title={`${open ? 'Collapse' : 'Expand'} site`}>
          <IconButton
            className={classes.iconButton}
            size="small"
            color="inherit"
            arial-label={`${open ? 'Collapse' : 'Expand'} site`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setOpen((open) => !open)
            }}
          >
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Tooltip>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SectionList site={site} division={division} onSelect={onSelect} />
      </Collapse>
    </>
  )
}

SiteListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  division: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default SiteListItem
