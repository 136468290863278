import React, { useMemo } from 'react'
import clsx from 'clsx'
import slug from 'slug'
import _groupBy from 'lodash/groupBy'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

import {
  useSectionsQuery,
  useSitesQuery,
  useDivisionsQuery,
  useServersQuery,
  mergeQueryResultsWith,
} from '../../services/stream-manager'

import { TopStreamViewers } from '../analytics/TopStreamViewers'

import StreamCard from './StreamCard'

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  gridItem: {
    display: 'flex',
  },
  streamCard: {
    flexGrow: 1,
    '&$selected': {
      boxShadow: `0px 0px 4px 2px ${theme.palette.primary.main}`,
    },
  },
  selected: {},
  streamsContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  empty: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 300,
  },
  emptyMessage: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    maxWidth: '40ch',
    '&$custom': {
      textTransform: 'none',
    },
  },
  custom: {},
}))

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const StreamsGrid = ({
  className,
  streamsQuery,
  onStreamSelect,
  selectedStreamId,
  playingStreamId,
  group,
  emptyMessage,
}) => {
  const classes = useStyles()

  const sectionsQuery = useSectionsQuery()
  const sitesQuery = useSitesQuery()
  const divisionsQuery = useDivisionsQuery()
  const serversQuery = useServersQuery()

  const combinedStatus = useCombinedStatus(
    sectionsQuery,
    sitesQuery,
    divisionsQuery,
    serversQuery,
    streamsQuery,
  )

  const groupedStreams = useMemo(() => {
    if (
      !streamsQuery.data ||
      !divisionsQuery.data ||
      !sectionsQuery.data ||
      !serversQuery.data ||
      !sitesQuery.data
    )
      return {}

    if (!group || !window.__ENV.REACT_APP_STREAM_GROUPING_WORDS) {
      return { all: streamsQuery.data }
    }

    // TODO: this is cosmetic until we have some kind of tag system to better
    // group related streams together.
    const { other, ...keyedStreams } = _groupBy(streamsQuery.data, (stream) => {
      const words = (window.__ENV.REACT_APP_STREAM_GROUPING_WORDS ?? '')
        .split(',')
        .map((str) => str.trim())
      for (const word of words) {
        if (stream.name.toLowerCase().includes(word.toLowerCase())) {
          return word
        }
        if (stream.description.toLowerCase().includes(word.toLowerCase())) {
          return word
        }
      }
      return 'other'
    })

    return other
      ? // Put ‘other’ at the end
      {
        ...keyedStreams,
        other,
      }
      : keyedStreams
  }, [
    group,
    streamsQuery.data,
    divisionsQuery.data,
    sectionsQuery.data,
    serversQuery.data,
    sitesQuery.data,
  ])

  return (
    <div className={clsx(className, classes.container)}>
      {streamsQuery.data?.length > 0 && groupedStreams ? (
        Object.entries(groupedStreams).map(([groupName, streams]) => (
          <React.Fragment key={groupName}>
            {group && groupName.toLowerCase() !== 'all' && (
              <Typography gutterBottom variant="h5" component="h2">
                <Link
                  href={`#${slug(groupName)}`}
                  color="inherit"
                  underline="none"
                >
                  {groupName.charAt(0).toUpperCase() + groupName.slice(1)}
                </Link>
              </Typography>
            )}
            <Grid
              container
              className={classes.streamsContainer}
              spacing={3}
              alignItems="stretch"
            >
              {streams.map((stream) => (
                <Grid
                  key={stream.id}
                  className={classes.gridItem}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <StreamCard
                    className={clsx(classes.streamCard, {
                      [classes.selected]: selectedStreamId === stream.id,
                    })}
                    playing={playingStreamId === stream.id}
                    stream={stream}
                    onClick={() => onStreamSelect(stream.id)}
                    action={<TopStreamViewers stream={stream} />}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))
      ) : (
        <div className={classes.empty}>
          {combinedStatus.isLoading ? (
            <CircularProgress />
          ) : (
            <Typography
              variant="h6"
              component="p"
              className={clsx(classes.emptyMessage, {
                [classes.custom]: emptyMessage,
              })}
            >
              {emptyMessage || 'No results'}
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}

export default StreamsGrid
