import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _debounce from 'lodash/debounce'
import _get from 'lodash/fp/get'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import Badge from '@material-ui/core/Badge'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search'
import CancelIcon from '@material-ui/icons/Cancel'
import TuneIcon from '@material-ui/icons/Tune'
import GroupIcon from '@material-ui/icons/Group'
import { DateTimePicker } from '@material-ui/pickers'

import {
  useDivisionsQuery,
  useSitesQuery,
  useSectionsQuery,
  useGroupsQuery,
  useTagsQuery,
} from '../../services/stream-manager'

import DynamicMultipleSelect from '../../components/DynamicMultipleSelect'

import useFilterParams from '../../hooks/useFilterParams'

import Tour from '../tour'


const debounce = (fn) => _debounce(fn, 300, { maxWait: 1000 })

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
    borderRadius: theme.shape.borderRadius,
  },
  inputRoot: {
    color: 'inherit',
    padding: `0 ${theme.spacing(1)}px !important`,
    flexWrap: 'nowrap !important',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
  },
  spacer: {
    width: 30,
  },
  inputInput: {
    padding: `${theme.spacing(1)}px !important`,
    transition: theme.transitions.create('width'),
    width: '100% !important',
    [theme.breakpoints.up('md')]: {
      width: '200px !important',
    },
  },
  advancedSearch: {
    width: 300,
    padding: theme.spacing(2),
  },
  badge: {
    top: '10% !important',
    right: '2.5% !important',
  },
  formControl: {
    width: '100%',
  },
  chip: {
    margin: `0 ${theme.spacing(0.25)}px`,
  },
}))

const GlobalFilter = ({
  className,
  location: locationFilter,
  groups: groupFilter,
  tags: tagFilter,
  type: typeFilter,
  dateTime: dateTimeFilter,
  urlKey,
}) => {
  const classes = useStyles()
  const [advancedSearch, setAdvancedSearch] = useState(null)
  const [
    { division, site, section, group, tags, type, from, until, search: initialSearch },
    setFilterParams,
  ] = useFilterParams({ urlKey })

  const hideBadge = !((tagFilter && tags) || (typeFilter && type) || (dateTimeFilter && (from || until)))

  const [search, _setSearch] = useState(initialSearch)
  const _setSearchParam = useCallback((search) => setFilterParams({ search }), [setFilterParams])
  const setSearchParam = useMemo(() => debounce(_setSearchParam), [_setSearchParam])
  const setSearch = useCallback((search) => {
    _setSearch(search)
    setSearchParam(search)
  }, [setSearchParam])

  const divisionsQuery = useDivisionsQuery(undefined, { skip: !locationFilter })
  const sitesQuery = useSitesQuery(undefined, { skip: !locationFilter })
  const sectionsQuery = useSectionsQuery(undefined, { skip: !locationFilter })
  const groupsQuery = useGroupsQuery(undefined, { skip: !groupFilter })

  const handleFromDateChange = useCallback(
    (from) => {
      // Always set new selected date.
      setFilterParams({
        from,
        // If user chooses start date > current stop date, adjust current stop
        // date so that start date < new stop date.
        // TODO: Currently we default to 1 day although that might be a bit
        // long.
        ...(until && from?.isSameOrAfter(until) ? {
          until: from.clone().add(1, 'day')
        } : undefined),
      })
    },
    [setFilterParams, until],
  )

  const handleUntilDateChange = useCallback(
    (until) => {
      // Always set new selected date.
      setFilterParams({
        ...(from && until?.isSameOrBefore(from) ? {
          from: until.clone().subtract(1, 'day')
        } : undefined),
        until,
      })
    },
    [setFilterParams, from],
  )

  const handleSpecialKeys = useCallback(
    (e) => {
      if (['Clear'].includes(e.key)) {
        setSearch(undefined)
      } else if (locationFilter && e.key === 'Backspace' && !search) {
        if (section != null) {
          setFilterParams({ section: undefined })
        } else if (site != null) {
          setFilterParams({ site: undefined })
        } else if (division != null) {
          setFilterParams({ division: undefined })
        } else if (group != null) {
          setFilterParams({ group: undefined })
        }
      }
    },
    [
      locationFilter,
      search,
      division,
      site,
      section,
      group,
      setSearch,
      setFilterParams
    ],
  )

  /*
   * Since we're mixing Divisions, Sites, Sections, and Groups together in the
   * autocomplete suggestions, we need to be able to tell them apart later, so
   * we're adding a `type`.
   *
   * Also, for convenience, we're adding the parents to the children, so we
   * don't have to do lookups later.
   *
   * And then memoize it for performance.
   */
  const options = useMemo(() => locationFilter && [
    ...divisionsQuery.data?.map(division => ({
      ...division,
      type: 'division',
    })) ?? [],
    ...sitesQuery.data?.map(site => ({
      ...site,
      type: 'site',
      division: divisionsQuery.data?.find(({ id }) => id === site.division_id),
    })) ?? [],
    ...sectionsQuery.data?.map(section => ({
      ...section,
      type: 'section',
      site: (site => site && {
        ...site, division: divisionsQuery.data?.find(({ id }) => id === site.division_id),
      })(sitesQuery.data?.find(({ id }) => id === section.site_id)),
    })) ?? [],
    ...groupsQuery.data?.map(group => ({
      ...group,
      type: 'group',
    })) ?? [],
  ], [
    locationFilter,
    divisionsQuery.data,
    sitesQuery.data,
    sectionsQuery.data,
    groupsQuery.data,
  ])

  return (
    <>
      <Tour
        name="globalfilter"
        steps={[
          {
            target: '[data-tour="globalfilter-search"]',
            title: 'Search',
            content:
              'Type in a word from the name or description of a stream or uploaded video to filter the streams. The full word is required.',
            placement: 'left',
          },
        ]}
      />
      <Tour
        name="globalfilter-tags"
        run={Boolean(advancedSearch && tagFilter)}
        steps={[
          {
            target: '[data-tour="globalfilter-tags"]',
            content: 'Select tags to filter by',
            placement: 'left',
          },
        ]}
      />
      <Tour
        name="globalfilter-groups"
        run={Boolean(advancedSearch && groupFilter)}
        steps={[
          {
            target: '[data-tour="globalfilter-groups"]',
            content: 'Select a group to filter by',
            placement: 'left',
          },
        ]}
      />
      <Tour
        name="globalfilter-type"
        run={Boolean(advancedSearch && typeFilter)}
        steps={[
          {
            target: '[data-tour="globalfilter-streamstype"]',
            content: (
              <>
                <p>
                  Choose <strong>Live streams</strong> to filter only live
                  streaming cameras or choose <strong>Videos</strong> to filter
                  only videos uploaded from movable cameras (e.g. drones).
                </p>
                <p>
                  If you zoom close enough, a <strong>Show GPS tracks</strong>{' '}
                  toggle will appear allowing you to show the GPS tracks if they
                  were uploaded with the video.
                </p>
              </>
            ),
            placement: 'left',
          },
        ]}
      />
      <Tour
        name="globalfilter-datetime"
        run={Boolean(advancedSearch && dateTimeFilter)}
        steps={[
          {
            target: '[data-tour="globalfilter-datetime"]',
            content:
              'For videos uploaded from movable cameras (e.g. drones), you can filter on a date & time range.​',
            placement: 'left',
          },
        ]}
      />
      <Autocomplete
        data-tour="globalfilter-search"
        className={clsx(classes.root, className)}
        autoComplete
        autoHighlight
        freeSolo
        disableClearable
        forcePopupIcon={false}
        options={locationFilter && search ? options : []}
        getOptionLabel={(option) => [
          option.division?.name,
          option.site?.division?.name,
          option.site?.name,
          option.name,
        ].filter(Boolean).join(' / ')}
        onChange={(_, option) => {
          if (typeof option === 'string') return

          switch (option.type) {
            case 'division': {
              setFilterParams({ division: option.id })
              break
            }
            case 'site': {
              setFilterParams({ site: option.id })
              break
            }
            case 'section': {
              setFilterParams({ section: option.id })
              break
            }
            case 'group': {
              setFilterParams({ group: option.id })
              break
            }
            default:
              console.error(new Error(`Unknown option type: ${option.type}`))
          }

          setSearch(undefined)
        }}
        value={null}
        onInputChange={(e) => setSearch(e?.target.value || undefined)}
        renderInput={({ InputProps, inputProps, ...params }) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            className={classes.search}
            placeholder="Search"
            inputProps={{
              ...inputProps,
              value: search || '',
              'aria-label': 'Search',
              onKeyDown: handleSpecialKeys,
            }}
            InputProps={{
              ...InputProps,
              classes: {
                root: classes.inputRoot,
                input: classes.inputInput,
              },
              startAdornment: (
                <>
                  <SearchIcon />
                  {groupFilter && (
                    <>
                      {group != null && (
                        <Chip
                          className={classes.chip}
                          size="small"
                          color="primary"
                          icon={<GroupIcon />}
                          label={group.name}
                          onDelete={() =>
                            setFilterParams({ group: undefined })
                          }
                        />
                      )}
                    </>
                  )}
                  {locationFilter && (
                    <>
                      {division != null && (
                        <Chip
                          className={classes.chip}
                          size="small"
                          color="primary"
                          label={division.name}
                          onDelete={() =>
                            setFilterParams({ division: undefined })
                          }
                        />
                      )}
                      {site != null && (
                        <Chip
                          className={classes.chip}
                          size="small"
                          color="primary"
                          label={site.name}
                          onDelete={() => setFilterParams({ site: undefined })}
                        />
                      )}
                      {section != null && (
                        <Chip
                          className={classes.chip}
                          size="small"
                          color="primary"
                          label={section.name}
                          onDelete={() =>
                            setFilterParams({ section: undefined })
                          }
                        />
                      )}
                    </>
                  )}
                </>
              ),
              endAdornment: (
                <>
                  {search ? (
                    <Tooltip title="Clear" >
                      <IconButton
                        size="small"
                        aria-label="Clear search query"
                        onClick={() => setSearch(undefined)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <div className={classes.spacer} />
                  )}
                  {
                    (locationFilter || groupFilter || tagFilter || typeFilter || dateTimeFilter) && (
                      <>
                        <Popover
                          classes={{ paper: classes.advancedSearch }}
                          id={advancedSearch ? 'advanced-search-popover' : undefined}
                          open={Boolean(advancedSearch)}
                          anchorEl={advancedSearch}
                          onClose={() => setAdvancedSearch(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {tagFilter && (
                            <DynamicMultipleSelect
                              data-tour="globalfilter-tags"
                              margin="dense"
                              value={tags || []}
                              onChange={(tags) =>
                                setFilterParams({ tags: tags?.map(_get('tag')) })
                              }
                              fullWidth
                              label="Tags"
                              optionLabel={(name) => `#${name}`}
                              useItemsQuery={useTagsQuery}
                            />
                          )}
                          {groupFilter && (
                            <FormControl
                              data-tour="globalfilter-groups"
                              className={classes.formControl}
                              margin="dense"
                            >
                              <InputLabel id="group-select-input-label" margin="dense">
                                Group
                              </InputLabel>
                              <Select
                                labelId="group-select-label"
                                id="group-select"
                                value={group ? group.id : "__NONE__"}
                                onChange={({ target: { value } }) => setFilterParams({
                                  group: value !== "__NONE__" ? value : undefined
                                })}
                                label="Group"
                                margin="dense"
                              >
                                <MenuItem value="__NONE__">
                                  <em>No group</em>
                                </MenuItem>
                                {groupsQuery.data?.map((group) => (
                                  <MenuItem value={group.id} key={group.id}>
                                    {group.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                          {locationFilter && (
                            <>
                              <FormControl
                                className={classes.formControl}
                                margin="dense"
                              >
                                <InputLabel
                                  id="division-select-input-label"
                                  margin="dense"
                                >
                                  Division
                                </InputLabel>
                                <Select
                                  labelId="division-select-label"
                                  id="division-select"
                                  value={division ? division.id : "__NONE__"}
                                  onChange={({ target: { value } }) => setFilterParams({
                                    division: value !== "__NONE__" ? value : undefined
                                  })}
                                  label="Division"
                                  margin="dense"
                                >
                                  <MenuItem value="__NONE__">
                                    <em>All</em>
                                  </MenuItem>
                                  {divisionsQuery.data?.map((division) => (
                                    <MenuItem value={division.id} key={division.id}>
                                      {division.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <br />
                              <FormControl
                                className={classes.formControl}
                                margin="dense"
                              >
                                <InputLabel id="site-select-input-label" margin="dense">
                                  Site
                                </InputLabel>
                                <Select
                                  labelId="site-select-label"
                                  id="site-select"
                                  value={site ? site.id : "__NONE__"}
                                  onChange={({ target: { value } }) => setFilterParams({
                                    site: value !== "__NONE__" ? value : undefined
                                  })}
                                  label="Site"
                                  margin="dense"
                                >
                                  <MenuItem value="__NONE__">
                                    <em>All</em>
                                  </MenuItem>
                                  {sitesQuery.data?.map((site) =>
                                    division != null &&
                                      site.division_id === division?.id ? (
                                      <MenuItem value={site.id} key={site.id}>
                                        {site.name}
                                      </MenuItem>
                                    ) : null,
                                  )}
                                </Select>
                              </FormControl>
                              <br />
                              <FormControl
                                className={classes.formControl}
                                margin="dense"
                              >
                                <InputLabel
                                  id="section-select-input-label"
                                  margin="dense"
                                >
                                  Section
                                </InputLabel>
                                <Select
                                  labelId="section-select-label"
                                  id="section-select"
                                  value={section ? section.id : "__NONE__"}
                                  onChange={({ target: { value } }) => setFilterParams({
                                    section: value !== "__NONE__" ? value : undefined
                                  })}
                                  label="Section"
                                  margin="dense"
                                >
                                  <MenuItem value="__NONE__">
                                    <em>All</em>
                                  </MenuItem>
                                  {sectionsQuery.data?.map((section) =>
                                    site != null && section.site_id === site?.id ? (
                                      <MenuItem value={section.id} key={section.id}>
                                        {section.name}
                                      </MenuItem>
                                    ) : null,
                                  )}
                                </Select>
                              </FormControl>
                            </>
                          )}
                          {window.__ENV.REACT_APP_VIDEO_UPLOADS === 'true' &&
                            typeFilter && (
                              <FormControl
                                data-tour="globalfilter-streamstype"
                                component="fieldset"
                                className={classes.formControl}
                                margin="dense"
                              >
                                <FormLabel component="legend">Type</FormLabel>
                                <FormGroup row className={classes.formGroup}>
                                  <FormControlLabel
                                    label="Live streams"
                                    control={
                                      <Checkbox
                                        checked={type === 'stream'}
                                        onChange={() => {
                                          if (type === 'stream') {
                                            setFilterParams({ type: undefined })
                                          } else {
                                            setFilterParams({ type: 'stream' })
                                          }
                                        }}
                                        name="streamType"
                                        color="primary"
                                      />
                                    }
                                  />
                                  <FormControlLabel
                                    label="Videos"
                                    control={
                                      <Checkbox
                                        checked={type === 'video'}
                                        onChange={() => {
                                          if (type === 'video') {
                                            setFilterParams({ type: undefined })
                                          } else {
                                            setFilterParams({ type: 'video' })
                                          }
                                        }}
                                        name="videoType"
                                        color="primary"
                                      />
                                    }
                                  />
                                </FormGroup>
                              </FormControl>
                            )}
                          {window.__ENV.REACT_APP_VIDEO_UPLOADS === 'true' &&
                            dateTimeFilter && (
                              <div data-tour="globalfilter-datetime">
                                <DateTimePicker
                                  label="From"
                                  format="DD MMM HH:mm:ss"
                                  onChange={handleFromDateChange}
                                  value={from ?? null}
                                  disableFuture
                                  clearable
                                  margin="dense"
                                />
                                <DateTimePicker
                                  label="Until"
                                  format="DD MMM HH:mm:ss"
                                  onChange={handleUntilDateChange}
                                  value={until ?? null}
                                  clearable
                                  disableFuture
                                  margin="dense"
                                />
                              </div>
                            )}
                        </Popover>
                        <Tooltip title="Advanced search">
                          <IconButton
                            size="small"
                            aria-label="Advanced search"
                            aria-describedby={
                              advancedSearch ? 'advanced-search-popover' : undefined
                            }
                            onClick={(e) => {
                              if (advancedSearch) {
                                setAdvancedSearch(null)
                              } else {
                                setAdvancedSearch(e.currentTarget)
                              }
                            }}
                          >
                            <Badge
                              classes={{ badge: classes.badge }}
                              color="primary"
                              variant="dot"
                              invisible={hideBadge}
                            >
                              <TuneIcon />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                      </>
                    )
                  }
                </>
              )
            }}
          />
        )}
      />
    </>
  )
}

GlobalFilter.propTypes = {
  location: PropTypes.bool,
  tag: PropTypes.bool,
  type: PropTypes.bool,
  dateTime: PropTypes.bool,
  urlKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
}

export default GlobalFilter
