import React, { useState, useEffect } from 'react'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'

const UpdateApp = ({ waitingServiceWorker }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (waitingServiceWorker) {
      setOpen(true)
    }
  }, [waitingServiceWorker])

  const handleClose = () => {
    waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      message="A new version of this app is available!"
      action={
        <Button color="primary" size="small" onClick={handleClose}>
          UPDATE
        </Button>
      }
    />
  )
}

export default UpdateApp
