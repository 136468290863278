import React, { useEffect, useState, useCallback, useMemo } from 'react'
import moment from 'moment'
import { useInterval } from 'react-use'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { DateTimePicker } from '@material-ui/pickers'

import { withAdmin } from '../../services/msal'

import useFilterParams from '../../hooks/useFilterParams'

import StreamHealth from './StreamHealth'
import ServiceHealth from './ServiceHealth'
import MediaServerHealth from './MediaServerHealth'


const UPDATE_SECONDS =
  Number(window.__ENV.REACT_APP_MONITOR_PAGE_UPDATE_SECONDS ?? null) || 60

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 150,
    marginRight: theme.spacing(1),
  },
}))

const Monitor = () => {
  const classes = useStyles()

  const now = useMemo(() => moment(), [])
  const hourAgo = useMemo(() => moment().subtract(60, 'minute'), [])

  const [
    {
      monitor_period: _monitorPeriod,
      from: urlFrom,
      until: urlUntil,
    },
    setFilterParams,
  ] = useFilterParams()

  const [
    {
      from: stateFrom,
      until: stateUntil,
    },
    setFilterState,
  ] = useFilterParams({ urlKey: useState() })

  const monitorPeriod = (!_monitorPeriod && (urlFrom || urlUntil) ? 'custom' : _monitorPeriod) ?? 'hour'
  const from = (monitorPeriod === 'custom' ? urlFrom : stateFrom) ?? hourAgo
  const until = (monitorPeriod === 'custom' ? urlUntil : stateUntil) ?? now

  const setFrom = useCallback((from) => setFilterParams({
    from,
    monitor_period: undefined,
  }), [setFilterParams])

  const setUntil = useCallback((until) => setFilterParams({
    until,
    monitor_period: undefined,
  }), [setFilterParams])

  const setMonitorPeriod = useCallback((monitorPeriod) => {
    if (monitorPeriod === 'custom') {
      setFilterParams({
        monitor_period: undefined,
        from: stateFrom,
        until: stateUntil,
      })
      return
    }

    setFilterParams({
      monitor_period: monitorPeriod === 'hour' ? undefined : monitorPeriod,
      from: undefined,
      until: undefined,
    })

    setFilterState({
      from: monitorPeriod === 'hour'
        ? moment().subtract(1, 'hour')
        : monitorPeriod === '6hour'
          ? moment().subtract(6, 'hour')
          : monitorPeriod === '24hour'
            ? moment().subtract(24, 'hour')
            : monitorPeriod === 'week'
              ? moment().subtract(7, 'day')
              : hourAgo,
      until: moment(),
    })
  }, [
    setFilterParams,
    setFilterState,
    hourAgo,
    stateFrom,
    stateUntil,
  ])

  useInterval(
    () => setMonitorPeriod(monitorPeriod),
    monitorPeriod === 'custom' ? null : UPDATE_SECONDS * 1000
  )

  useEffect(() => {
    if (until.isSameOrBefore(from, 'second')) {
      setFrom(until.clone().subtract(1, 'hour'))
    }
  }, [from, until, setFrom])

  if (until.isSameOrBefore(from, 'second')) {
    return null
  }

  return (
    <>
      <Box p={3}>
        <FormControl className={classes.formControl} margin="dense">
          <InputLabel id="monitor-select-label" margin="dense">
            Monitor period
          </InputLabel>
          <Select
            labelId="monitor-select-label"
            id="monitor-select"
            value={monitorPeriod}
            onChange={(e) => setMonitorPeriod(e.target.value)}
            label="Monitor period"
            margin="dense"
          >
            <MenuItem value="hour">Last hour</MenuItem>
            <MenuItem value="6hour">Last 6 hours</MenuItem>
            <MenuItem value="24hour">Last 24 hours</MenuItem>
            <MenuItem value="week">Last week</MenuItem>
            <MenuItem value="custom"><em>Custom</em></MenuItem>
          </Select>
        </FormControl>
        <DateTimePicker
          className={classes.formControl}
          label="From"
          format="DD MMM YYYY HH:mm"
          onChange={setFrom}
          value={from}
          maxDate={now}
          margin="dense"
          disabled={monitorPeriod !== 'custom'}
        />
        <DateTimePicker
          className={classes.formControl}
          label="Until"
          format="DD MMM YYYY HH:mm"
          onChange={setUntil}
          value={until}
          maxDate={now}
          margin="dense"
          disabled={monitorPeriod !== 'custom'}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5}><StreamHealth start={from.unix()} stop={until.unix()} /></Grid>
        <Grid item xs={12} sm={6} md={5}><MediaServerHealth start={from.unix()} stop={until.unix()} /></Grid>
        <Grid item xs={12} sm={6} md={2}><ServiceHealth start={from.unix()} stop={until.unix()} /></Grid>
      </Grid>
    </>
  )
}

export const MonitorPage = withAdmin(Monitor)
