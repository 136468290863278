import React from 'react'
import {
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import { withAdmin } from '../../services/msal'
import { useMeQuery } from '../../services/stream-manager'

import Container from '../layout/Container'
import { ServerManagementPage } from '../crud/ServerManagementPage'
import { StreamManagementPage } from '../crud/StreamManagementPage'
import { VideoManagementPage } from '../crud/VideoManagementPage'
import { SiteManagementPage } from '../crud/SiteManagementPage'
import { CreateAssignmentPage } from '../crud/CreateAssignmentPage'
import { GroupManagementPage } from '../crud/GroupManagementPage'
import { UserManagementPage } from '../crud/UserManagementPage'
import { UpdateLinksPage } from '../crud/UpdateLinksPage'
import { TagManagementPage } from '../crud/TagManagementPage'
import { LogsPage } from '../crud/LogsPage'
import { MonitorPage } from '../monitor/MonitorPage'
import { ReportPage } from '../report/ReportPage'

import PageTitle from '../../components/PageTitle'

const useStyles = makeStyles({
  tabBarWrapper: {
    borderRadius: 0,
    width: '100%',
  },
})

const SettingsPageComponent = () => {
  const { url, path } = useRouteMatch()
  const location = useLocation()

  const classes = useStyles()
  const meQuery = useMeQuery()

  return (
    <>
      <PageTitle>Admin</PageTitle>
      <Paper className={classes.tabBarWrapper}>
        <Tabs
          value={location.pathname !== url && location.pathname}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="desktop"
        >
          <Tab
            component={Link}
            label="Users"
            value={`${url}/users`}
            to={`${url}/users`}
          />
          <Tab
            component={Link}
            label="Groups"
            value={`${url}/groups`}
            to={`${url}/groups`}
          />
          <Tab
            component={Link}
            label="Assign Groups"
            value={`${url}/assign-groups`}
            to={`${url}/assign-groups`}
          />
          <Tab
            component={Link}
            label="Sites"
            value={`${url}/sites`}
            to={`${url}/sites`}
          />
          <Tab
            component={Link}
            label="Servers"
            value={`${url}/servers`}
            to={`${url}/servers`}
          />
          <Tab
            component={Link}
            label="Streams"
            value={`${url}/streams`}
            to={`${url}/streams`}
          />
          {window.__ENV.REACT_APP_VIDEO_UPLOADS === 'true' && (
            <Tab
              component={Link}
              label="Videos"
              value={`${url}/videos`}
              to={`${url}/videos`}
            />
          )}
          <Tab
            component={Link}
            label="Link Servers"
            value={`${url}/link-servers`}
            to={`${url}/link-servers`}
          />
          <Tab
            component={Link}
            label="Tags"
            value={`${url}/tags`}
            to={`${url}/tags`}
          />
          {window.__ENV.REACT_APP_ENABLE_MONITOR === 'true' && window.__ENV.REACT_APP_MONITOR_USERS.includes(meQuery.data?.id) && (
            <Tab
              component={Link}
              label="Monitor"
              value={`${url}/monitor`}
              to={`${url}/monitor`}
            />
          )}
          {window.__ENV.REACT_APP_ENABLE_REPORT === 'true' && window.__ENV.REACT_APP_REPORT_USERS.includes(meQuery.data?.id) && (
            <Tab
              component={Link}
              label="Report"
              value={`${url}/report`}
              to={`${url}/report`}
            />
          )}
          <Tab
            component={Link}
            label="Logs"
            value={`${url}/logs`}
            to={`${url}/logs`}
          />
          {/* <Tab component={Link} label="Branding" value={`${url}/branding`} to={`${url}/branding`} /> */}
        </Tabs>
      </Paper>
      <Container>
        <Box p={3}>
          <Switch>
            <Route path={`${path}/users`} component={UserManagementPage} />
            <Route path={`${path}/groups`} component={GroupManagementPage} />
            <Route
              path={`${path}/assign-groups`}
              component={CreateAssignmentPage}
            />
            <Route path={`${path}/sites`} component={SiteManagementPage} />
            <Route path={`${path}/servers`} component={ServerManagementPage} />
            <Route path={`${path}/streams`} component={StreamManagementPage} />
            {window.__ENV.REACT_APP_VIDEO_UPLOADS === 'true' && (
              <Route path={`${path}/videos`} component={VideoManagementPage} />
            )}
            <Route path={`${path}/link-servers`} component={UpdateLinksPage} />
            <Route path={`${path}/tags`} component={TagManagementPage} />
            {window.__ENV.REACT_APP_ENABLE_MONITOR === 'true' && window.__ENV.REACT_APP_MONITOR_USERS.includes(meQuery.data?.id) && (
              <Route path={`${path}/monitor`} component={MonitorPage} />
            )}
            {window.__ENV.REACT_APP_ENABLE_REPORT === 'true' && window.__ENV.REACT_APP_REPORT_USERS.includes(meQuery.data?.id) && (
              <Route path={`${path}/report`} component={ReportPage} />
            )}
            <Route path={`${path}/logs`} component={LogsPage} />
            {/* <Route path={`${path}/branding`} component={BrandingPage} /> */}
            {meQuery.data && <Redirect from={`${path}`} to={`${url}/users`} />}
          </Switch>
        </Box>
      </Container>
    </>
  )
}

const SettingsPage = withAdmin(SettingsPageComponent)

export default SettingsPage
