import React, { useMemo, useCallback } from 'react'
import { FastField } from 'formik'
import * as yup from 'yup'
import _get from 'lodash/fp/get'
import _difference from 'lodash/fp/difference'
import _keys from 'lodash/fp/keys'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { withAdmin } from '../../services/msal'
import {
  useSectionsQuery,
  useSitesQuery,
  useDivisionsQuery,
  useStreamsQuery,
  useServersQuery,
  useCreateStreamMutation as _useCreateStreamMutation,
  useUpdateStreamMutation as _useUpdateStreamMutation,
  useDeleteStreamMutation,
  useDownloadCsv,
  useTagsQuery,
  useCreateTagMutation,
  useAddTagToStreamMutation,
  useRemoveTagFromStreamMutation,
  mergeQueryResultsWith,
} from '../../services/stream-manager'

import formatAPIParams from '../../utils/formatAPIParams'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'
import DynamicMultipleSelect from '../../components/DynamicMultipleSelect'

import EnhancedTable from '../table/EnhancedTable'

import StreamHealth from '../streams/StreamHealth'

import Access from './AccessPage'
import Status from './ServerStatus'
import { TagFormFields, createTagValidationSchema } from './TagManagementPage'

const StreamFormFields = () => {
  const serversQuery = useServersQuery()
  const sectionsQuery = useSectionsQuery()

  const createTagMutation = useMapValuesToArg(useCreateTagMutation, (body) => ({
    body,
  }))

  return (
    <>
      <FastField name="name">
        {({ field, meta }) => (
          <TextField
            {...field}
            autoFocus
            label="Name"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="description">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Description"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="uri">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="URI"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="lat">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Latitude"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="long">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Longitude"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="section_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Section"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {sectionsQuery.data?.map((section) => (
              <MenuItem key={section.id} value={section.id}>
                {`${section.name}: ${section.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
      <FastField name="media_server_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Media Server"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {serversQuery.data?.map((server) => (
              <MenuItem key={server.id} value={server.id}>
                {`${server.name}: ${server.description}`}
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
      <FastField name="tags">
        {({ field: { value, onChange, ...field }, meta }) => (
          <DynamicMultipleSelect
            {...field}
            value={value || []}
            onChange={(value) =>
              onChange({
                target: {
                  name: field.name,
                  multiple: true,
                  value,
                },
              })
            }
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
            label="Tags"
            optionLabel={(name) => `#${name}`}
            useItemsQuery={useTagsQuery}
            createMutation={createTagMutation}
            error={meta.touched && Boolean(meta.error)}
            createValidationSchema={createTagValidationSchema}
            createInitialValues={(tag) => ({
              tag,
              description: '',
            })}
            FormFields={TagFormFields}
          />
        )}
      </FastField>
    </>
  )
}

const mapValuesToArg = ({ id, group, tags, ...body }) => ({
  id,
  group,
  tags,
  body,
})

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const useCreateStreamMutation = () => {
  const [createStream, createStreamResult] = _useCreateStreamMutation()
  const [addTagToStream, addTagToStreamResult] = useAddTagToStreamMutation()

  const create = useCallback(
    async ({ group, tags, body }) => {
      const { data: stream } = await createStream({ group, body })
      const addResponse = await addTagToStream(
        tags.map((tag) => ({ tag_id: tag.id, stream_id: stream.id })),
      )
      if (addResponse.error)
        return {
          error: true,
        }
    },
    [createStream, addTagToStream],
  )

  const createStatus = useCombinedStatus(
    createStreamResult,
    addTagToStreamResult,
  )

  return [create, createStatus]
}

const useUpdateStreamMutation = () => {
  const [updateStream, updateStreamResult] = _useUpdateStreamMutation()
  const [addTagToStream, addTagToStreamResult] = useAddTagToStreamMutation()
  const [
    removeTagFromStream,
    removeTagFromStreamResult,
  ] = useRemoveTagFromStreamMutation()

  const update = useCallback(
    async ({ tags, body }, initialValues) => {
      if (_keys(body).length > 0) {
        await updateStream({ id: initialValues.id, body })
      }

      const newTags = _difference(
        tags.map(_get('id')),
        initialValues.tags.map(_get('id')),
      )
      if (newTags.length) {
        const addResponse = await addTagToStream(
          newTags.map((tag_id) => ({ tag_id, stream_id: initialValues.id })),
        )
        if (addResponse.error)
          return {
            error: true,
          }
      }

      const oldTags = _difference(
        initialValues.tags.map(_get('id')),
        tags.map(_get('id')),
      )
      if (oldTags.length) {
        const removeResponse = await removeTagFromStream(
          oldTags.map((tag_id) => ({ tag_id, stream_id: initialValues.id })),
        )
        if (removeResponse.error)
          return {
            error: true,
          }
      }
    },
    [updateStream, addTagToStream, removeTagFromStream],
  )

  const updateStatus = useCombinedStatus(
    updateStreamResult,
    addTagToStreamResult,
    removeTagFromStreamResult,
  )

  return [update, updateStatus]
}

const createStreamValidationSchema = () =>
  yup.object({
    name: yup
      .string()
      .min(4, 'The stream name should have at least 4 characters')
      .max(80, 'The stream name should have maximum 80 characters')
      .required('Stream name is required'),
    description: yup.string().required('Stream description is required'),
    section_id: yup.string().required('Section is required'),
    uri: yup.string().required('URI is required'),
    media_server_id: yup.string().required('Media Server is required'),
  })

const useStyles = makeStyles((theme) => ({
  tags: {
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
  },
  streamHealth: {
    display: 'flex',
    '& svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
    '& svg:nth-child(1)': {
      order: 2,
    },
    '& svg:nth-child(2)': {
      order: 1,
    },
  },
}))

const StreamStatus = ({ value }) => {
  const classes = useStyles()

  return (
    <div className={classes.streamHealth}>
      <StreamHealth id={value} />
    </div>
  )
}

const StreamManagement = () => {
  const classes = useStyles()

  const sectionsQuery = useSectionsQuery()
  const sitesQuery = useSitesQuery()
  const divisionsQuery = useDivisionsQuery()
  const serversQuery = useServersQuery()

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Cell: ({ value }) => (
          <span className={classes.tags}>
            {value?.map(({ tag }) => `#${tag}`).join(', ') ?? null}
          </span>
        ),
      },
      {
        Header: 'Status',
        accessor: 'id',
        Cell: StreamStatus,
      },
      {
        Header: 'Section',
        id: 'section',
        accessor: 'section_id',
      },
      {
        Header: 'Site',
        id: 'site',
        accessor: 'site_id',
      },
      {
        Header: 'Division',
        id: 'division',
        accessor: 'division_id',
      },
      {
        Header: 'URI',
        accessor: 'uri',
      },
      {
        Header: 'Media Server',
        id: 'media_server',
        accessor: 'media_server_id',
      },
      {
        Header: 'Latitude',
        accessor: 'lat',
      },
      {
        Header: 'Longitude',
        accessor: 'long',
      },
    ],
    [classes],
  )

  const mapColumns = useMemo(
    () => ({
      section: sectionsQuery.data,
      site: sitesQuery.data,
      division: divisionsQuery.data,
      media_server: serversQuery.data,
    }),
    [
      sectionsQuery.data,
      sitesQuery.data,
      divisionsQuery.data,
      serversQuery.data,
    ],
  )

  const filterColumns = useMemo(
    () => ({
      section: sectionsQuery.data,
      site: sitesQuery.data,
      division: divisionsQuery.data,
    }),
    [sectionsQuery.data, sitesQuery.data, divisionsQuery.data],
  )

  const streamsQuery = usePaginatedFilteredQuery(useStreamsQuery)({
    params: formatAPIParams({ type: 'stream' }),
  })

  const streams = useMemo(() => {
    const data = streamsQuery.data?.map(
      ({ section, site, division, server, ...stream }) => ({
        ...stream,
        media_server_id: server?.id,
        section_id: section?.id,
        site_id: site?.id,
        division_id: division?.id,
      }),
    )

    if (data) {
      data.count = streamsQuery.data?.count
    }

    return {
      ...streamsQuery,
      data,
    }
  }, [streamsQuery])

  return (
    <Paper>
      <EnhancedTable
        label="Streams"
        columns={columns}
        actions={({ row }) => (
          <>
            <Access entity={row.original} />{' '}
            <Status entity={row.original} type="stream" />
          </>
        )}
        FormFields={StreamFormFields}
        createValidationSchema={createStreamValidationSchema}
        mapColumns={mapColumns}
        filterColumns={filterColumns}
        itemsQuery={streams}
        createMutation={useMapValuesToArg(
          useCreateStreamMutation,
          mapValuesToArg,
        )}
        updateMutation={useMapValuesToArg(
          useUpdateStreamMutation,
          mapValuesToArg,
        )}
        deleteMutation={useDeleteStreamMutation()}
        downloadCsv={useDownloadCsv(usePaginatedFilteredQuery(useStreamsQuery))({
          params: formatAPIParams({ type: 'stream' }),
        }, {
          sections: false,
          sites: false,
          divisions: false,
          servers: false,
          tags: false,
        })}
        withGroups
        search
      />
    </Paper>
  )
}

export const StreamManagementPage = withAdmin(StreamManagement)
