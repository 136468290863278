import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import msalApi from './services/msal'
import microsoftGraph from './services/microsoft-graph'
import streamManager from './services/stream-manager'

const reducer = {
  [msalApi.reducerPath]: msalApi.reducer,
  [microsoftGraph.reducerPath]: microsoftGraph.reducer,
  [streamManager.reducerPath]: streamManager.reducer,
}

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      msalApi.middleware,
      microsoftGraph.middleware,
      streamManager.middleware,
    ]),
  reducer,
})

setupListeners(store.dispatch)

export default store

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept(
    [
      './services/msal',
      './services/microsoft-graph',
      './services/stream-manager',
    ],
    () => store.replaceReducer(reducer),
  )
}
