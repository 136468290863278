import React, { useRef, useState, useLayoutEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import clsx from 'clsx'
import { sanitize } from 'dompurify'
import _difference from 'lodash/fp/difference'

import { makeStyles, lighten, darken } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { VideoThumb } from '../streams/Thumbnail'


const domParser = new DOMParser()
const xmlSerializer = new XMLSerializer()

const useStyles = makeStyles((theme) => {
  const backgroundColor = theme.palette.type === 'dark'
    ? darken(theme.palette.background.paper, 0.2)
    : lighten(theme.palette.primary.light, 0.8)
  return {
    noticeCard: {
      margin: theme.spacing(2),
      backgroundColor,
      borderColor: theme.palette.type === 'dark'
        ? darken(theme.palette.background.paper, 0.5)
        : theme.palette.primary.main,
    },
    dialogPaper: {
      minWidth: `min(calc(100% - ${theme.spacing(4)}px), ${theme.spacing(60)}px)`
    },
    cardContent: {
      paddingBottom: `${theme.spacing(2)}px !important`,
      overflow: 'hidden',
    },
    clampHeight: {
      maxHeight: theme.spacing(20),
      position: 'relative',
      '&$clamped::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        background: `linear-gradient(transparent, ${backgroundColor})`,
        width: '100%',
        height: theme.spacing(4),
        bottom: theme.spacing(-2),
      }
    },
    clamped: {},
    cardActions: {
      paddingTop: '0 !important',
      justifyContent: 'flex-end',
    },
    clickable: {
      cursor: 'pointer',
    },
    spinner: {
      display: 'block',
      margin: `${theme.spacing(2)}px auto`,
    },
    emptyMessage: {
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    listItem: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    thumbnail: {
      flexShrink: '0',
      maxWidth: theme.spacing(16),
      maxHeight: theme.spacing(9),
      marginRight: theme.spacing(2),
      overflow: 'hidden',
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    description: {
      flex: 1,
      fontSize: 14,
      lineHeight: 1.2,
      marginBottom: theme.spacing(1),
      '&, & [class*="elementToProof"]': {
        overflowWrap: 'break-word',
        hyphens: 'auto',
        color: `${theme.palette.text.primary} !important`,
        fontFamily: 'inherit !important',
        fontSize: 'inherit !important',
      },
      '& br': {
        height: theme.spacing(1),
      },
      '& a[href]': {
        color: `${theme.palette.primary.main} !important`,
      },
      '& img, & video': {
        maxWidth: '100% !important',
      },
    },
    time: {
      fontSize: 10,
      fontStyle: 'italic',
      marginBottom: theme.spacing(1),
    },
    bottomRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    streamHealth: {
      display: 'flex',
      '& svg': {
        fontSize: '1rem',
        marginLeft: theme.spacing(1),
      },
    },
    streamLink: {
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'none',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(2),
      },
    },
  }
})

const Notice = ({ event }) => {
  const classes = useStyles()
  const clampRef = useRef()

  const [isClamped, setIsClamped] = useState()

  const { subject: title, streams } = event

  useLayoutEffect(() => {
    setIsClamped(clampRef.current.scrollHeight > clampRef.current.clientHeight)

    const observer = new ResizeObserver(([{ target }]) => {
      setIsClamped(target.scrollHeight > target.clientHeight)
    })
    observer.observe(clampRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  const __html = useMemo(() => {
    const eventDOM = domParser.parseFromString(sanitize(event.body.content), 'text/html')
    if (event.originalStreamLinks.length > 0) {
      const streamLinkAnchors = eventDOM.querySelectorAll(
        event.originalStreamLinks
          .map((link) => `a[href="${link}"]`)
          .join(', ')
      )

      streamLinkAnchors.forEach((anchor) => {
        if (event.originalStreamLinks.includes(anchor.innerHTML.trim())) {
          anchor.remove()
        } else {
          anchor.replaceWith(...anchor.childNodes)
        }
      })
    }
    return xmlSerializer.serializeToString(eventDOM)
  }, [event])

  const videoThumbnails = useMemo(() =>
    streams.map((stream, i) => (
      <Link
        key={stream.id}
        className={classes.streamLink}
        to={event.streamLinks[i]}
      >
        <VideoThumb
          className={classes.thumbnail}
          stream={stream}
        />
        <div className={classes.text}>
          <Typography>{stream.name}</Typography>
          <Typography className={classes.description}>
            {stream.description}
          </Typography>
        </div>
      </Link>
    )),
    [event, streams, classes]
  )

  const [open, setOpen] = useState(false)

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby={`notice-dialog-title-${event.id}`}
        aria-describedby={`notice-dialog-description-${event.id}`}
      >
        {title && <DialogTitle id={`notice-dialog-title-${event.id}`}>{title}</DialogTitle>}
        <DialogContent className={classes.description}>
          <DialogContentText
            id={`notice-dialog-description-${event.id}`}
            dangerouslySetInnerHTML={{ __html }}
          />
          {videoThumbnails}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Card variant="outlined" className={classes.noticeCard}>
        <CardContent className={classes.cardContent}>
          <div
            ref={clampRef}
            className={clsx(classes.clampHeight, {
              [classes.clamped]: isClamped,
            })}
          >
            {title && <Typography className={classes.title}>{title}</Typography>}
            <Typography
              className={classes.time}
              color="textSecondary"
              component="p"
            >
              {(() => {
                const start = moment
                  .utc(event.start.dateTime)
                  .local()
                  .format('ddd, D MMM, H:mm')
                const end = moment
                  .utc(event.end.dateTime)
                  .local()
                  .format('ddd, D MMM, H:mm')

                const startTerms = start.split(', ')
                const endTerms = end.split(', ')

                const until = _difference(endTerms, startTerms).join(', ')

                return `${start} — ${until}`
              })()}
            </Typography>
            <Typography
              className={classes.description}
              color="textSecondary"
              component="div"
              dangerouslySetInnerHTML={{ __html }}
            />
            {videoThumbnails}
          </div>
        </CardContent>
        {isClamped && (
          <CardActions className={classes.cardActions}>
            <Button size="small" onClick={() => setOpen(true)}>Read more</Button>
          </CardActions>
        )}
      </Card>
    </>
  )
}

const NoticeList = ({ eventsQuery }) => {
  const classes = useStyles()

  return eventsQuery.isLoading ? (
    <CircularProgress className={classes.spinner} />
  ) : eventsQuery.isError ? (
    <Card
      variant="outlined"
      className={classes.noticeCard}
    >
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="p" className={classes.emptyMessage}>
          Notice board access error
        </Typography>
      </CardContent>
    </Card>
  ) : eventsQuery.data?.length === 0 ? (
    <Card
      variant="outlined"
      className={classes.noticeCard}
    >
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" component="p" className={classes.emptyMessage}>
          No new notices
        </Typography>
      </CardContent>
    </Card>
  ) : (
    eventsQuery.data?.map((event) => (
      <Notice key={event.id} event={event} />
    ))
  )
}

export default NoticeList
