import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { VegaLite } from 'react-vega'
import { Grid } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import _cloneDeep from 'lodash/fp/cloneDeep'

import { useMonitorMediaServersQuery } from '../../services/stream-manager'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    height: '100%'
  },
  title: {
    padding: theme.spacing(1),
  },
  tableContainer: {
    padding: theme.spacing(1),
  },
  graphContainer: {
    width: "100%",
  },
}))

const MediaServerHealth = ({ start, stop }) => {
  const classes = useStyles()

  // Get the data
  const mediaServerStatusQuery = useMonitorMediaServersQuery({ params: { start_time: start*1000, end_time: stop*1000}})

  // We need a deepcopy of the graphspec into modifiable value, cause state is muted.  
  var moddedSpec = null
  var longestServerOff = "0s"
  if (mediaServerStatusQuery.data?.graph_spec != null) {
    moddedSpec = _cloneDeep(mediaServerStatusQuery.data?.graph_spec);
    moddedSpec["width"] = "container";
    moddedSpec["height"] = 300;
    moddedSpec["padding"] = 20;
    moddedSpec["autosize"] = {
      type: "fit",
      contains: "padding"
    };
    moddedSpec["encoding"]["color"]["legend"] = {
      "orient": "none",
      "direction": "horizontal",
      "legendX": 10,
      "legendY": -15,
      "title": null
    }
    moddedSpec["encoding"]["y"]["axis"]["tickMinStep"] = 1
    moddedSpec["data"]["format"] = {
      "parse": {"d": "utc:'%Y-%m-%dT%H:%M:%S'"}
    }

    if (mediaServerStatusQuery.data.longest_server_off_secs > 0) {
      var h = Math.floor(mediaServerStatusQuery.data.longest_server_off_secs / 3600);
      var m = Math.floor(mediaServerStatusQuery.data.longest_server_off_secs % 3600 / 60);
      var s = Math.floor(mediaServerStatusQuery.data.longest_server_off_secs % 3600 % 60);
      var hDisplay = h > 0 ? h + "h ": "";
      var mDisplay = m > 0 ? m + "m ": "";
      var sDisplay = s > 0 ? s + "s": "";
      longestServerOff = "> " + hDisplay + mDisplay + sDisplay; 
    }
  }

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" component="p" className={classes.title}>
        Media Server Health
      </Typography>
      {(mediaServerStatusQuery.isFetching || mediaServerStatusQuery.data == null) ? (
        <Typography align='center' variant="body2" color="textSecondary" component="p">
        ...
        </Typography>
      ) : (
        <Grid container spacing={0} className={classes.grid}>
          <Grid item xs={12}>
            <div className={classes.graphContainer}>
                <VegaLite style={{width: "100%"}} spec={moddedSpec} actions={false} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Box} className={classes.tableContainer}>
              <Table size="small" aria-label="stream health info">
                <TableBody>
                  <TableRow
                    key="total_servers"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Servers total
                    </TableCell>
                    <TableCell align="right">{mediaServerStatusQuery.data.servers}</TableCell>
                  </TableRow>
                  <TableRow
                    key="servers_off"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Servers off
                    </TableCell>
                    <TableCell align="right">{mediaServerStatusQuery.data.servers_off}</TableCell>
                  </TableRow>
                  <TableRow
                    key="server_off_secs"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Server off time
                    </TableCell>
                    <TableCell align="right">{longestServerOff}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </Paper>
  )
}

MediaServerHealth.propTypes = {
  start: PropTypes.number,
  stop: PropTypes.number,
}

export default MediaServerHealth
