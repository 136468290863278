import React, { useState, useCallback, useMemo } from 'react'
import SVG from 'react-inlinesvg'

import { makeStyles, darken, lighten, alpha } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import LinkIcon from '@material-ui/icons/Link'
import LinkOffIcon from '@material-ui/icons/LinkOff'

import { withAdmin } from '../../services/msal'
import {
  useServersQuery,
  useDivisionsQuery,
  useSitesQuery,
  useServersLayoutQuery,
  useDeviceMappingsQuery,
  useUpstreamServersQuery,
  useLinkUpstreamServerMutation,
  useUnlinkUpstreamServerMutation,
  useConfigureServerMutation,
  mergeQueryResultsWith,
} from '../../services/stream-manager'
import DisableFormFields from '../../styles/DisableFormFields'

import AlertDialog from '../dialogs/AlertDialog'

const makeServerName = (server, sites, divisions) => {
  try {
    const site = sites.find((site) => site.id === server.site_id)
    const division = divisions.find(
      (division) => division.id === site.division_id,
    )
    return `${division.name}/${site.name}/${server.name}`
  } catch {
    return server.name
  }
}

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const helperText = {
  server: 'Select the server to be relayed through the upstream server',
  upstreamServer: 'Select the upstream media server',
}

const useStyles = makeStyles((theme) => ({
  icons: {
    paddingTop: `${theme.spacing(2)}px !important`,
    display: 'flex',
    justifyContent: 'center',
  },
  layout: {
    maxWidth: '550px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  layoutImage: {
    width: '100%',
    maxWidth: 850,
    height: 'auto',
    '& *': {
      fill: (theme.palette.type === 'dark' ? darken : lighten)(
        alpha(theme.palette.text.primary, 1),
        0.3,
      ),
      stroke: (theme.palette.type === 'dark' ? darken : lighten)(
        alpha(theme.palette.text.primary, 1),
        0.3,
      ),
    },
    '& text': {
      fontFamily: 'Roboto, serif',
      stroke: 'transparent',
    },
    '& ellipse': {
      fill: 'transparent',
    },
    '& title + polygon': {
      fill: 'transparent',
      stroke: 'transparent',
    },
  },
  progress: {
    marginBottom: -theme.spacing(0.5),
  },
}))

const LinksPage = () => {
  const classes = useStyles()

  const serversQuery = useServersQuery()
  const divisionsQuery = useDivisionsQuery()
  const sitesQuery = useSitesQuery()
  const layoutQuery = useServersLayoutQuery()
  const deviceMappingsQuery = useDeviceMappingsQuery()

  const combinedQueryStatus = useCombinedStatus(
    serversQuery,
    divisionsQuery,
    sitesQuery,
    layoutQuery,
    deviceMappingsQuery,
  )

  const [linkServer, linkedServerResult] = useLinkUpstreamServerMutation()
  const [unlinkServer, unlinkedServerResult] = useUnlinkUpstreamServerMutation()
  const [configureServer, configuredServerResult] = useConfigureServerMutation()

  const combinedMutationStatus = useCombinedStatus(
    linkedServerResult,
    unlinkedServerResult,
    configuredServerResult,
  )

  const [selectedServerId, setSelectedServerId] = useState('')
  const [selectedUpstreamServerId, setSelectedUpstreamServerId] = useState('')

  const handleChangeServer = useCallback((e) => {
    setSelectedServerId(e.target.value)
  }, [])

  const handleChangeUpstreamServer = useCallback((e) => {
    setSelectedUpstreamServerId(e.target.value)
  }, [])

  const upstreamServersQuery = useUpstreamServersQuery(selectedServerId, {
    skip: selectedServerId === '',
  })

  const serversAreLinked = useMemo(() => {
    if (selectedServerId === '' || selectedUpstreamServerId === '')
      return undefined
    return upstreamServersQuery.data?.some(
      ({ id }) => id === selectedUpstreamServerId,
    )
  }, [selectedServerId, selectedUpstreamServerId, upstreamServersQuery])

  const configureServers = useCallback(() => {
    if (
      deviceMappingsQuery.data?.find(({ id }) => id === selectedServerId) !== ''
    ) {
      configureServer(selectedServerId)
    }
    if (
      deviceMappingsQuery.data?.find(
        ({ id }) => id === selectedUpstreamServerId,
      ) !== ''
    ) {
      configureServer(selectedUpstreamServerId)
    }
  }, [
    deviceMappingsQuery.data,
    selectedServerId,
    selectedUpstreamServerId,
    configureServer,
  ])

  const handleLink = useCallback(async () => {
    if (selectedServerId === '' || selectedUpstreamServerId === '') return
    await linkServer({
      id: selectedServerId,
      upstream_id: selectedUpstreamServerId,
    })
    configureServers()
  }, [selectedServerId, selectedUpstreamServerId, linkServer, configureServers])

  const handleUnlink = useCallback(async () => {
    if (selectedServerId === '' || selectedUpstreamServerId === '') return
    await unlinkServer({
      id: selectedServerId,
      upstream_id: selectedUpstreamServerId,
    })
    configureServers()
  }, [
    selectedServerId,
    selectedUpstreamServerId,
    unlinkServer,
    configureServers,
  ])

  const handleReset = useCallback(() => {
    setSelectedServerId('')
    setSelectedUpstreamServerId('')
  }, [])

  return (
    <>
      {[linkedServerResult, unlinkedServerResult, configuredServerResult].map(
        (result, i) => (
          <React.Fragment key={i}>
            <AlertDialog
              key={`error-${result.startedTimeStamp || i}`}
              open={result.isError}
              severity="error"
              title="Update Failure"
              status={result.error?.status}
              alert={result.error?.data}
            />
            <AlertDialog
              key={`success-${result.startedTimeStamp || i}`}
              open={result.isSuccess}
              title="Update Success"
              alert={
                <>
                  All steps completed — <strong>you&apos;re finished</strong>
                </>
              }
              onClose={handleReset}
            />
          </React.Fragment>
        ),
      )}
      <div>
        <Typography gutterBottom variant="h5" component="h2">
          Link media servers
        </Typography>
        {(combinedQueryStatus.isFetching ||
          combinedMutationStatus.isLoading) && (
            <LinearProgress className={classes.progress} />
          )}
        <br />
        <DisableFormFields disabled={combinedMutationStatus.isLoading}>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            alignContent="center"
          >
            <Grid item xs={5}>
              <TextField
                name="server"
                label="Media server"
                select
                fullWidth
                helperText={helperText.server}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangeServer}
                value={selectedServerId}
                disabled={combinedMutationStatus.isLoading}
              >
                {serversQuery.data?.map((server) => (
                  <MenuItem key={server.id} value={server.id}>
                    {makeServerName(
                      server,
                      sitesQuery.data,
                      divisionsQuery.data,
                    )}
                  </MenuItem>
                )) ?? <MenuItem value="" />}
              </TextField>
            </Grid>
            <Grid item xs={2} className={classes.icons}>
              {selectedServerId !== '' &&
                (serversAreLinked ? (
                  <LinkOffIcon fontSize="large" color="action" />
                ) : (
                  <LinkIcon fontSize="large" color="action" />
                ))}
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="upstreamServer"
                label="Upstream media server"
                select
                fullWidth
                helperText={helperText.upstreamServer}
                InputLabelProps={{ shrink: true }}
                onChange={handleChangeUpstreamServer}
                value={selectedUpstreamServerId ?? ''}
                disabled={combinedMutationStatus.isLoading}
              >
                {(selectedServerId !== '' &&
                  serversQuery.data
                    ?.filter(({ id }) => id !== selectedServerId)
                    .map((server) => (
                      <MenuItem key={server.id} value={server.id}>
                        {makeServerName(
                          server,
                          sitesQuery.data,
                          divisionsQuery.data,
                        )}
                      </MenuItem>
                    ))) || <MenuItem value="" />}
              </TextField>
            </Grid>
          </Grid>
          <br />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLink}
                disabled={Boolean(
                  combinedMutationStatus.isLoading ||
                  serversAreLinked !== false,
                )}
              >
                Link
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUnlink}
                disabled={Boolean(
                  combinedMutationStatus.isLoading || serversAreLinked !== true,
                )}
              >
                Unlink
              </Button>
            </Grid>
          </Grid>
        </DisableFormFields>
        {layoutQuery.data && (
          <>
            <br />
            <Card className={classes.layout}>
              <CardActionArea>
                <CardMedia>
                  <SVG
                    className={classes.layoutImage}
                    src={layoutQuery.data}
                  />
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Current media server link layout
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </>
        )}
      </div>
    </>
  )
}

export const UpdateLinksPage = withAdmin(LinksPage)
