import React from 'react'
import moment from 'moment'
import clsx from 'clsx'
import _difference from 'lodash/fp/difference'

import { makeStyles } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import GPSIcon from 'mdi-material-ui/MapMarkerPath'

import { VideoThumb } from './Thumbnail'
import StreamHealth from './StreamHealth'
import { formatDurationFromSeconds } from './../analytics/utils'

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
  },
  emptyMessage: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  listItem: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  thumbnail: {
    flexShrink: '0',
    width: theme.spacing(16),
    marginRight: theme.spacing(2),
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'stretch',
  },
  description: {
    flex: 1,
    fontSize: 14,
    lineHeight: 1.2,
  },
  location: {
    fontSize: 10,
    fontStyle: 'italic',
    marginTop: theme.spacing(0.5),
    display: 'flex',
    '& :last-child': {
      flex: 1,
    },
  },
  redDot: {
    marginTop: '0.2em',
    width: '1em',
    height: '1em',
    marginRight: '0.5em',
    borderRadius: '0.5em',
    backgroundColor: red[500],
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  streamHealth: {
    display: 'flex',
    '& svg': {
      fontSize: '1rem',
      marginLeft: theme.spacing(1),
    },
  },
}))

const StreamsList = ({
  streamsQuery,
  onSelect,
  hideThumbnails,
  showRedDot,
  disableHealth,
}) => {
  const classes = useStyles()

  return streamsQuery.isLoading ? (
    <CircularProgress className={classes.spinner} />
  ) : streamsQuery.data?.length === 0 ? (
    <Typography variant="h6" component="p" className={classes.emptyMessage}>
      No results
    </Typography>
  ) : (
    <List>
      {streamsQuery.data?.map((stream) => (
        <ListItem
          key={stream.id}
          className={classes.listItem}
          button
          onClick={() => onSelect(stream)}
        >
          {!hideThumbnails && <VideoThumb className={classes.thumbnail} stream={stream} />}
          <div className={classes.text}>
            <Typography>{stream.name}</Typography>
            <Typography
              className={classes.description}
              color="textSecondary"
              component="p"
            >
              {[
                stream?.division?.name,
                stream?.site?.name,
                stream?.section?.name,
              ]
                .filter(Boolean)
                .join(' / ')}
            </Typography>
            {stream.user_count != null ? (
              <Typography
                className={classes.location}
                color="textSecondary"
                component="p"
              >
                {showRedDot && <span className={classes.redDot} />}
                <span>
                  {'Watched by ' +
                    (stream.user_count === 1
                      ? '1 user'
                      : `${stream.user_count} users`) + ` for ${formatDurationFromSeconds(stream.duration)}`}
                </span>
              </Typography>
            ) : stream.event ? (
              <Typography
                className={classes.location}
                color="textSecondary"
                component="p"
              >
                {(() => {
                  const start = moment
                    .utc(stream.event.start.dateTime)
                    .local()
                    .format('ddd, D MMM, H:mm')
                  const end = moment
                    .utc(stream.event.end.dateTime)
                    .local()
                    .format('ddd, D MMM, H:mm')

                  const startTerms = start.split(', ')
                  const endTerms = end.split(', ')

                  const until = _difference(endTerms, startTerms).join(', ')

                  return `${start} — ${until}`
                })()}
              </Typography>
            ) : (
              <div className={classes.bottomRow}>
                <div>
                  {stream.recorded && (
                    <Typography
                      className={clsx(classes.contentBody, classes.location)}
                      color="textSecondary"
                      component="p"
                    >
                      {moment.utc(stream.recorded).format('D MMM YYYY HH:mm')}
                    </Typography>
                  )}
                  <Typography
                    className={clsx(classes.contentBody, classes.location)}
                    color="textSecondary"
                    component="p"
                  >
                    {[
                      stream.division?.name,
                      stream.site?.name,
                      stream.section?.name,
                    ]
                      .filter(Boolean)
                      .join(' / ')}
                  </Typography>
                </div>
                <div className={classes.streamHealth}>
                  {!disableHealth && <StreamHealth id={stream.id} />}
                  {stream.has_track && (
                    <Tooltip title={`Has a GPS track`}>
                      <GPSIcon />
                    </Tooltip>
                  )}
                </div>
              </div>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  )
}

export default StreamsList
