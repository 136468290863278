import { useMemo, useCallback } from 'react'

import useURLParams from './useURLParams'


const DEFAULT_PAGE_SIZE = 10

const usePaginationParams = ({ urlKey, limit: _limit } = {}) => {
  const [{
    page = 1,
    limit = _limit ?? DEFAULT_PAGE_SIZE,
  }, setURLParams] = useURLParams(urlKey)

  const paginationParams = useMemo(() => ({
    page: Number(page),
    limit: Number(limit),
  }), [page, limit])

  const setPaginationParams = useCallback(
    async (params) => {
      const keys = Object.keys(params)

      return setURLParams({
        ...params,
        ...(keys.includes('page')
          ? { page: params.page === 1 ? undefined : params.page }
          : undefined),
        ...(keys.includes('limit')
          ? { limit: params.limit === DEFAULT_PAGE_SIZE ? undefined : params.limit }
          : undefined),
      })
    },
    [setURLParams],
  )

  return [paginationParams, setPaginationParams]
}

export default usePaginationParams
