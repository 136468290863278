import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Dialog from '@material-ui/core/Dialog'
import BarChartIcon from '@material-ui/icons/BarChart'

import EnhancedTable from '../table/EnhancedTable'

import { useIsAdmin } from '../../services/msal'
import { useStreamViewersQuery } from '../../services/stream-manager'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import { formatDuration, formatTime } from './utils'

const TopStreamViewersButton = (props) => (
  <Tooltip arrow title="Show Top Viewers" placement="top">
    <IconButton aria-label="Show Top Viewers" {...props}>
      <BarChartIcon />
    </IconButton>
  </Tooltip>
)

TopStreamViewersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const columns = [
  {
    accessor: 'name',
    Header: 'User name',
  },
  {
    accessor: 'duration',
    Header: 'Total view time',
    Cell: ({ value }) => formatDuration(value * 1000),
  },
  {
    accessor: 'last_viewed',
    Header: 'Last viewed',
    Cell: ({ value }) => formatTime(value),
  },
]

export const TopStreamViewers = ({ stream }) => {
  const isAdmin = useIsAdmin()
  const [open, setOpen] = useState(false)
  const urlKey = useState()

  const itemsQuery = usePaginatedFilteredQuery(
    useStreamViewersQuery,
    { urlKey },
  )(
    stream.id,
    { skip: !open || !isAdmin }
  )

  return (
    <>
      {isAdmin && <TopStreamViewersButton onClick={() => setOpen(true)} />}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
        <EnhancedTable
          label={
            <>
              Top viewers of <em>{stream.name}</em>
            </>
          }
          columns={columns}
          itemsQuery={itemsQuery}
          onClose={() => setOpen(false)}
          urlKey={urlKey}
        />
      </Dialog>
    </>
  )
}

TopStreamViewers.propTypes = {
  stream: PropTypes.object.isRequired,
}
