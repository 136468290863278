import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useFormikContext, Field } from 'formik'
import _debounce from 'lodash/debounce'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import CircularProgress from '@material-ui/core/CircularProgress'
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AccountIcon from '@material-ui/icons/AccountCircle'

import {
  useSearchNewUserQuery,
  useUsersQuery,
  useRegisterUserMutation,
  useSetServiceAccountMutation,
  useDeleteUserMutation,
} from '../../services/stream-manager'
import { withAdmin } from '../../services/msal'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import EnhancedTable from '../table/EnhancedTable'

import Access from './AccessPage'

const debounce = (fn) => _debounce(fn, 300, { maxWait: 1000 })

const getOptionLabel = ({ name, email }) => `${name} <${email}>`

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
  },
  noOptions: ({ value }) =>
    value == null
      ? undefined
      : {
        display: 'none',
      },
  popupIndicator: ({ value }) =>
    value == null
      ? undefined
      : {
        display: 'none',
      },
})

const UserFormFields = () => {
  const { values } = useFormikContext()

  const [query, _setQuery] = useState('')
  const setQuery = useMemo(() => debounce(_setQuery), [])

  const [value, setValue] = useState(() =>
    values?.name != null && values?.provider_id != null
      ? {
        name: values.name,
        provider_id: values.provider_id,
      }
      : null,
  )
  const [inputValue, setInputValue] = useState('')
  const [byEmail, setByEmail] = useState(false)

  const classes = useStyles({ value })

  const handleInputChange = useCallback(
    (e, inputValue) => {
      if (value == null) {
        setInputValue(inputValue)
      }
    },
    [value],
  )

  const handleChipDelete = useCallback((e) => {
    setValue(null)
    setInputValue('')
  }, [])

  useEffect(() => {
    setQuery(
      value != null && getOptionLabel(value).sub(inputValue) ? '' : inputValue,
    )
  }, [value, inputValue, setQuery])

  const searchNewUserQuery = useSearchNewUserQuery(
    { query, byEmail },
    { skip: query.length < 3 },
  )

  return (
    <>
      <Field name="provider_id">
        {({ field: { onChange, ...field } }) => (
          <Autocomplete
            {...field}
            classes={classes}
            value={value}
            onChange={(_, value) => {
              setValue(value)
              return onChange({
                target: {
                  name: 'provider_id',
                  value: value?.provider_id ?? '',
                },
              })
            }}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            fullWidth
            options={(query.length >= 3 && searchNewUserQuery.data) || []}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) =>
              option.provider_id === value?.provider_id
            }
            loading={searchNewUserQuery.isFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User"
                helperText="Type at least the first 3 letters"
                margin="dense"
                inputProps={{
                  ...params.inputProps,
                  value: value == null ? inputValue : '',
                  onKeyDown: (e) => {
                    if (
                      value != null &&
                      ['Backspace', 'Delete', 'Clear'].includes(e.key)
                    ) {
                      setInputValue('')
                      setValue(null)
                    }
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  type: 'autocomplete',
                  startAdornment:
                    value != null ? (
                      <Chip
                        size="small"
                        color="primary"
                        icon={<AccountIcon />}
                        label={value.name}
                        onDelete={handleChipDelete}
                      />
                    ) : (
                      <></>
                    ),
                  endAdornment: (
                    <>
                      {searchNewUserQuery.isFetching && (
                        <CircularProgress size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
      </Field>
      <FormControlLabel
        label="Search by email"
        control={
          <Switch
            checked={byEmail}
            onChange={() => setByEmail((checked) => !checked)}
          />
        }
      />
    </>
  )
}

const ServiceAccountCheckbox = ({ name, checked, onChange }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  return (
    <Checkbox
      size="small"
      color="default"
      className={classes.checkbox}
      name={name}
      checked={checked}
      disabled={loading}
      onChange={async (e) => {
        setLoading(true)
        await onChange(e)
        setLoading(false)
      }}
    />
  )
}

const UserManagement = () => {
  const [setServiceAccount, setServiceAccountResult] = useSetServiceAccountMutation()

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Service Account?',
      accessor: 'service_account',
      Cell: ({ value, row }) => (
        <ServiceAccountCheckbox
          name={`user::${row.original.id}`}
          checked={Boolean(value)}
          onChange={() => setServiceAccount({
            id: row.original.id,
            value: !value,
          })}
        />
      ),
    },
  ], [setServiceAccount])

  return (
    <Paper>
      <EnhancedTable
        search
        loading={setServiceAccountResult.isLoading}
        label="Users"
        columns={columns}
        actions={({ row }) => <Access type="user" entity={row.original} />}
        FormFields={UserFormFields}
        itemsQuery={usePaginatedFilteredQuery(useUsersQuery)()}
        createMutation={useRegisterUserMutation()}
        deleteMutation={useDeleteUserMutation()}
      />
    </Paper>
  )
}

export const UserManagementPage = withAdmin(UserManagement)
