import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import qs from 'qs'
import clsx from 'clsx'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import ContentCopyIcon from 'mdi-material-ui/ContentCopy'
import GPSIcon from 'mdi-material-ui/MapMarkerPath'

import {
  useIsDSDManager,
  useGetDsdStreams,
  useCreateCredentialsMutation,
} from '../../services/stream-manager'
import { uuidToBase64 } from '../../utils/uuid'
import DynamicMultipleSelect from '../../components/DynamicMultipleSelect'
import PageTitle from '../../components/PageTitle'

import Container from '../layout/Container'
import { VideoThumb } from '../streams/Thumbnail'
import StreamHealth from '../streams/StreamHealth'

import Tour from '../tour'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(2),
    },
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  nameWrapper: {
    display: 'flex',
    justifyContents: 'stretch',
    alignItems: 'flex-end',
  },
  name: {
    flex: 1,
  },
  requestTokenButton: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  codeCard: {
    marginTop: theme.spacing(2),
  },
  codeWrapper: {
    position: 'relative',
  },
  icons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& > :last-child': {
      marginRight: 0,
    },
  },
  pre: {
    margin: 0,
    padding: theme.spacing(2),
    overflow: 'overlay !important',
    fallbacks: {
      overflow: 'auto !important',
    },
  },
  iframe: {
    display: 'block',
    aspectRatio: '16 / 9',
    marginTop: theme.spacing(2),
  },
  spinner: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
  },
  option: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  thumbnail: {
    flexShrink: '0',
    width: theme.spacing(16),
    marginRight: theme.spacing(2),
  },
  text: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  description: {
    flex: 1,
    fontSize: 14,
    lineHeight: 1.2,
  },
  location: {
    fontSize: 10,
    fontStyle: 'italic',
    marginTop: theme.spacing(0.5),
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  streamHealth: {
    display: 'flex',
    '& svg': {
      fontSize: '1rem',
      marginLeft: theme.spacing(1),
    },
  },
}))

const { origin } = new URL(window.location.href)

const ManagementPage = () => {
  const classes = useStyles()

  const isDSDManager = useIsDSDManager()

  const [name, setName] = useState('')
  const [cycleTime, setCycleTime] = useState(15)
  const [streams, setStreams] = useState([])
  const [accessToken, setAccessToken] = useState()

  const streamIds = useMemo(
    () => streams?.map(({ id }) => uuidToBase64(id)) ?? [],
    [streams],
  )

  const [
    createCredentialsTrigger,
    createCredentialsResult,
  ] = useCreateCredentialsMutation()

  useEffect(() => {
    setAccessToken()
  }, [name])

  useEffect(() => {
    if (createCredentialsResult.data) {
      setAccessToken(
        btoa(
          createCredentialsResult.data.client_id +
            ':' +
            createCredentialsResult.data.token,
        ),
      )
    }
  }, [createCredentialsResult.data])

  const queryString = useMemo(
    () =>
      qs.stringify(
        {
          cycleTime,
          streamIds,
          accessToken,
        },
        {
          encode: false,
          strictNullHandling: true,
          arrayFormat: 'comma',
        },
      ),
    [cycleTime, streamIds, accessToken],
  )

  const src = `${origin}/digital-signage/display?${queryString}`

  const embedCode =
    '<iframe\n' +
    [
      'style="display: block"',
      'frameBorder="0"',
      'scrolling="no"',
      'width="100%"',
      'height="100%"',
      name && `title="${name} Display"`,
      `src="${src}"`,
    ]
      .filter(Boolean)
      .map((str) => `  ${str}`)
      .join('\n') +
    '\n/>'

  const [showSecondTour, setShowSecondTour] = useState(false)

  useEffect(() => {
    let timer
    if (accessToken && streamIds.length > 0) {
      timer = setTimeout(() => {
        setShowSecondTour(true)
      }, 300)
    }
    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      <Tour
        run={isDSDManager}
        name="digital-signage-1"
        steps={[
          {
            target: '[data-tour="digitalsignage-accesstoken"]',
            content: (
              <>
                <p>
                  An access token is required to ensure that access to the
                  streams is properly authenticated.
                </p>
                <p>
                  Choose a display name to be used for the token. The display
                  name will not be shown in any views; it is used solely for
                  describing the token.​
                </p>
                <p>
                  Once you have chosen a display name, click{' '}
                  <strong>REQUEST ACCESS TOKEN</strong> to generate the token.
                  You will not be able to proceed until this process is
                  completed.​
                </p>
              </>
            ),
            placement: 'right',
          },
          {
            target: '[data-tour="digitalsignage-cycletime"]',
            content:
              'Choose the cycle time (in seconds) to specify how for long each stream is displayed.',
            placement: 'right',
          },
          {
            target: '[data-tour="digitalsignage-streams"]',
            content: 'Choose which streams should be cycled.​',
            placement: 'right',
          },
        ]}
      />
      <Tour
        run={showSecondTour}
        name="digital-signage-2"
        steps={[
          {
            target: '[data-tour="digitalsignage-embedcode"]',
            content:
              'Copy and paste this HMTL embed code into ScreenCloud Playground  HTML editor.​',
            placement: 'top',
          },
          {
            target: '[data-tour="digitalsignage-preview"]',
            content: 'A preview of the streams is provided in this space.​',
            placement: 'top',
          },
        ]}
      />
      <PageTitle>Digital Signage</PageTitle>
      <Container>
        <Paper className={classes.paper}>
          <Typography
            className={classes.title}
            color="inherit"
            variant="h6"
            component="h1"
          >
            Create Digital Signage Display embed code
          </Typography>
          {isDSDManager ? (
            <>
              <div className={classes.form}>
                <div
                  data-tour="digitalsignage-accesstoken"
                  className={classes.nameWrapper}
                >
                  <TextField
                    className={classes.name}
                    label="Display name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    margin="dense"
                  />
                  <Button
                    className={classes.requestTokenButton}
                    variant="contained"
                    color="primary"
                    disabled={Boolean(accessToken || !name)}
                    onClick={() =>
                      createCredentialsTrigger({
                        body: {
                          name,
                          group: window.__ENV.REACT_APP_DSD_MANAGER_GROUP,
                        },
                      })
                    }
                  >
                    Request access token
                  </Button>
                </div>
                <TextField
                  label="Access token"
                  variant="outlined"
                  disabled
                  value={accessToken || ''}
                  margin="dense"
                />
                <TextField
                  data-tour="digitalsignage-cycletime"
                  label="Cycle time"
                  type="number"
                  value={cycleTime}
                  onChange={(event) => setCycleTime(Number(event.target.value))}
                  margin="dense"
                />
                <DynamicMultipleSelect
                  data-tour="digitalsignage-streams"
                  margin="dense"
                  value={streams || []}
                  onChange={(streams) => setStreams(streams ?? [])}
                  fullWidth
                  label="Streams"
                  useItemsQuery={useGetDsdStreams}
                  renderOption={(stream) => (
                    <div className={classes.option}>
                      <VideoThumb
                        className={classes.thumbnail}
                        stream={stream}
                      />
                      <div className={classes.text}>
                        <Typography>{stream.name}</Typography>
                        <Typography
                          className={classes.description}
                          color="textSecondary"
                          component="p"
                        >
                          {stream.description}
                        </Typography>
                        <div className={classes.bottomRow}>
                          <div>
                            {stream.recorded && (
                              <Typography
                                className={clsx(
                                  classes.contentBody,
                                  classes.location,
                                )}
                                color="textSecondary"
                                component="p"
                              >
                                {moment
                                  .utc(stream.recorded)
                                  .format('D MMM YYYY HH:mm')}
                              </Typography>
                            )}
                            <Typography
                              className={clsx(
                                classes.contentBody,
                                classes.location,
                              )}
                              color="textSecondary"
                              component="p"
                            >
                              {[
                                stream.division?.name,
                                stream.site?.name,
                                stream.section?.name,
                              ]
                                .filter(Boolean)
                                .join(' / ')}
                            </Typography>
                          </div>
                          <div className={classes.streamHealth}>
                            <StreamHealth id={stream.id} />
                            {stream.has_track && (
                              <Tooltip title={`Has a GPS track`}>
                                <GPSIcon />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
              {accessToken && streamIds.length > 0 && (
                <>
                  <Card
                    data-tour="digitalsignage-embedcode"
                    className={classes.codeCard}
                    variant="outlined"
                  >
                    <div className={classes.codeWrapper}>
                      <div className={classes.icons}>
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={<ContentCopyIcon />}
                          onClick={() =>
                            navigator.clipboard.writeText(embedCode)
                          }
                        >
                          Copy
                        </Button>
                        <Button
                          variant="outlined"
                          component={Link}
                          to={`preview?${queryString}`}
                          startIcon={<OndemandVideoIcon />}
                          target="_blank"
                        >
                          Standalone Preview
                        </Button>
                      </div>
                      <pre className={classes.pre}>
                        <code>{embedCode}</code>
                      </pre>
                    </div>
                  </Card>
                  <iframe
                    data-tour="digitalsignage-preview"
                    className={classes.iframe}
                    frameBorder="0"
                    scrolling="no"
                    width="100%"
                    height="100%"
                    title={`${name} Display`}
                    src={src}
                  />
                </>
              )}
            </>
          ) : (
            <Typography>
              You do not have permission to access this page.
            </Typography>
          )}
        </Paper>
      </Container>
    </>
  )
}

export default ManagementPage
