import * as uuid from 'uuid'

/* Convert base64 <-> UUID  */

export const uuidToBase64 = (str) =>
  btoa(String.fromCharCode.apply(null, uuid.parse(str)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')

export const base64ToUuid = (str) =>
  uuid.stringify(
    new Uint8Array(
      atob(
        str
          .padEnd(str.length % 4, '=')
          .replace(/-/g, '+')
          .replace(/_/g, '/'),
      )
        .split('')
        .map((c) => c.charCodeAt(0)),
    ),
  )
