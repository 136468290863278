import { useStreamQuery } from '../services/stream-manager'

import { streamHealth } from '../features/streams/utils'

import { MODE } from '../features/player/state'

const useStreamHealth = (id) => {
  const { data: stream } = useStreamQuery(id, { skip: !id, status: true })

  const mode = !stream
    ? undefined
    : stream.is_vod
      ? MODE.VOD
      : !stream.status
        ? undefined
        : stream.status.currently_streaming
          ? MODE.LIVE
          : MODE.HISTORY

  const health = stream?.status
    ? streamHealth(stream.status.timeline)
    : undefined

  return {
    mode,
    health,
  }
}

export default useStreamHealth
