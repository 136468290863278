import React from 'react'
import { FastField } from 'formik'

import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'

import {
  useGroupsQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
} from '../../services/stream-manager'
import { withAdmin } from '../../services/msal'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import EnhancedTable from '../table/EnhancedTable'

const GroupFormFields = () => (
  <>
    <FastField name="group">
      {({ field, meta }) => (
        <TextField
          {...field}
          autoFocus
          label="Group"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
    <FastField name="description">
      {({ field, meta }) => (
        <TextField
          {...field}
          label="Description"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          margin="dense"
          fullWidth
        />
      )}
    </FastField>
  </>
)

const mapValuesToArg = (body) => ({ body })

const groupColumns = [
  {
    Header: 'Group',
    accessor: 'group',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
]

const GroupManagement = () => (
  <Paper>
    <EnhancedTable
      search
      label="Group"
      columns={groupColumns}
      FormFields={GroupFormFields}
      itemsQuery={usePaginatedFilteredQuery(useGroupsQuery)()}
      createMutation={useMapValuesToArg(useCreateGroupMutation, mapValuesToArg)}
      deleteMutation={useDeleteGroupMutation()}
      pagination={false}
    />
  </Paper>
)

export const GroupManagementPage = withAdmin(GroupManagement)
