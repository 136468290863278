/*
 * We use Immer.js [1] to allow us to change the state in a mutable fashion, whilst
 * giving React a new state object that has been changed in an immutable
 * fashion.
 *
 * This is for convenience, and becasue Redux Toolkit (which we're using) does the same.
 *
 * [1]: https://immerjs.github.io/immer/
 */

import flow from 'lodash/flow'
import { produce } from 'immer'

import { timelineToMs, newLiveTimeline, MILLISECONDS_IN_MINUTE } from './utils'

// Log (nearly) all actions to the debug console
const DEBUG = false
// The TIME_UPDATE and SHOW_CONTROLS actions are pretty noisy in the log,
// to show them you have to explicitly enable their debug flags, too
const DEBUG_TIME_UPDATE = false
const DEBUG_SHOW_CONTROLS = false

// If the user is scrubbing to the end of the timeline, how far away from the actual
// live stream do they have to be before we "snap" to the live feed? In milliseconds
const LIVE_MARGIN = MILLISECONDS_IN_MINUTE
const HISTORY_STARTING_POSITION_OFFSET = 15 * MILLISECONDS_IN_MINUTE

export const MODE = {
  LIVE: 'LIVE', // Normal live stream
  HISTORY: 'HISTORY', // Stream currently has no live data
  VOD: 'VOD', // Video on demand; normal recorded video, not really a live stream
}

/**
 * Given the playlist base URL, return a timeshift version that can be used for
 * live viewing with programDateTime metadata.
 */
const getLiveFromPlaylistBaseUrl = (playlistBaseUrl) => {
  // 0 seconds behind realtime
  const shift = 0
  // 15 seconds of realtime scrubbing depth
  // this is similar to the normal dvr=0 live mode which usually returns 3 x 5s at a time
  const depth = 15
  return playlistBaseUrl.replace(/\.m3u8/, `_timeshift-${shift}-${depth}.m3u8`)
}

/**
 * Given the playlist base URL, return a playlist covering a specific timespan,
 * denoted by UTCSTART and DURATION, both in seconds.
 */
const getHistoricFromPlaylistBaseUrl = (playlistBaseUrl, utcStart, duration) =>
  playlistBaseUrl.replace(/\.m3u8/, `_range-${utcStart}-${duration}.m3u8`)

// Used when generating the video poster
const canvas = document.createElement('canvas')

export const initialState = {
  loading: undefined,
  live: undefined,
  currentTimeUCT: undefined,
  position: undefined,
  paused: true, // Start videos paused by default
  muted: true,
  seeking: undefined,
  chunkLoading: undefined,
  fullscreen: undefined,
  controls: undefined,
  poster: undefined,
  src: undefined,
  chunk: {
    start: undefined,
    end: undefined,
  },
  timeline: undefined, // array of {start, end, duration} timespans (all in seconds) that are available for this stream
  // The state below this line isn't actually state, it's static (per video),
  // but they're used in the calculation of some other state values. They
  // should only be set on (re-)initialization.
  mode: undefined,
  width: 1920,
  height: 1080,
  duration: undefined,
  downloadTimeLimit: undefined,
  playlistBaseUrl: undefined,
}

export const ACTIONS = {
  TIME_UPDATE: 'TIME_UPDATE',
  SET_SEEKING: 'SET_SEEKING',
  SEEK: 'SEEK',
  LIVE_PLAYBACK: 'LIVE_PLAYBACK',
  SET_LOADING: 'SET_LOADING',
  SET_FULLSCREEN: 'SET_FULLSCREEN',
  SET_VIDEO_PROPERTIES: 'SET_VIDEO_PROPERTIES',
  SHOW_CONTROLS: 'SHOW_CONTROLS',
  PAUSE: 'PAUSE',
  PLAY: 'PLAY',
  MUTE: 'MUTE',
  SET_CHUNK: 'SET_CHUNK',
  SET_CHUNK_LOADING: 'SET_CHUNK_LOADING',
  SET_POSTER: 'SET_POSTER',
  INIT: 'INIT',
}

const makeActionCreator = (type) => (payload) => ({
  type,
  payload,
})

// Action creators
export const init = makeActionCreator(ACTIONS.INIT)
export const timeUpdate = makeActionCreator(ACTIONS.TIME_UPDATE)
export const setSeeking = makeActionCreator(ACTIONS.SET_SEEKING)
export const seek = makeActionCreator(ACTIONS.SEEK)
export const livePlayback = makeActionCreator(ACTIONS.LIVE_PLAYBACK)
export const setLoading = makeActionCreator(ACTIONS.SET_LOADING)
export const setChunkLoading = makeActionCreator(ACTIONS.SET_CHUNK_LOADING)
export const setFullscreen = makeActionCreator(ACTIONS.SET_FULLSCREEN)
export const setVideoProperties = makeActionCreator(
  ACTIONS.SET_VIDEO_PROPERTIES,
)
export const showControls = makeActionCreator(ACTIONS.SHOW_CONTROLS)
export const pause = makeActionCreator(ACTIONS.PAUSE)
export const play = makeActionCreator(ACTIONS.PLAY)
export const mute = makeActionCreator(ACTIONS.MUTE)
export const setChunk = makeActionCreator(ACTIONS.SET_CHUNK)
export const setPoster = (v) => {
  // Check that we have at least a frame
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState#value
  if (v.readyState < 2) return
  return {
    type: ACTIONS.SET_POSTER,
    payload: v,
  }
}

/* The reducer is composed of two functions, they both run in sequence when
 * updating the state.
 *
 * In the first, we do the heavy lifting: we handle the actions and make changes
 * to the state.
 *
 * In the second, we set derived state: state which depends on other state.
 *
 * Separating it out like this means that we don't have to remember to handle
 * derived state updates in more than one place (since more than one action might
 * update state on which derived state depends).
 */
export const reducer = (state, { type, payload } = {}) =>
  flow(
    produce((state) => {
      switch (type) {
        case ACTIONS.TIME_UPDATE: {
          // if this stream has live video, extend the end of the timeline until
          // the current time, since the timeline will otherwise be out of date.
          // Loading a new timeline from the API is pretty currently heavy on
          // the backend.
          if (state.mode === MODE.LIVE) {
            state.timeline = newLiveTimeline(state.timeline)
          }
          state.currentTimeUCT = (state.chunk.start ?? 0) + payload * 1000
          break
        }

        case ACTIONS.SET_SEEKING: {
          state.seeking = true
          break
        }

        case ACTIONS.SEEK: {
          state.seeking = false

          if (state.mode === MODE.VOD) {
            state.position = payload
          } else {
            // The timespans in the timeline are not necessarily
            // contiguous. We need to find the timespan in the timeline
            // which contains the desired time, or, failing that, is
            // right after it.

            const timespan = state.timeline.find(
              ({ start, end }) => start <= payload && payload <= end,
            )

            if (timespan) {
              if (
                state.timeline[state.timeline.length - 1].end - payload <=
                LIVE_MARGIN
              ) {
                state.position = state.timeline[state.timeline.length - 1].end
              } else {
                state.position = payload
              }
            } else {
              if (payload > state.timeline[state.timeline.length - 1].end) {
                state.position = state.timeline[state.timeline.length - 1].end
              } else {
                // Go to the start of the 1st timespan that we're behind
                state.position = state.timeline.find(
                  ({ start }) => payload < start,
                ).start
              }
            }
          }

          break
        }

        case ACTIONS.LIVE_PLAYBACK: {
          state.position = state.timeline[state.timeline.length - 1].end
          break
        }

        case ACTIONS.SET_LOADING: {
          state.loading = payload
          break
        }

        case ACTIONS.SET_CHUNK_LOADING: {
          state.chunkLoading = payload
          break
        }

        case ACTIONS.SET_FULLSCREEN: {
          state.fullscreen = payload
          break
        }

        case ACTIONS.SHOW_CONTROLS: {
          state.controls = payload
          break
        }

        case ACTIONS.PAUSE: {
          state.paused = true
          break
        }

        case ACTIONS.PLAY: {
          state.paused = false
          break
        }

        case ACTIONS.MUTE: {
          state.muted = payload
          break
        }

        case ACTIONS.SET_CHUNK: {
          Object.assign(state.chunk, payload)
          break
        }

        /**
         * When the user is seeking, we pause the video, and we want to show the
         * user the most recent still frame. We do this by setting the poster
         * attribute
         */
        case ACTIONS.SET_POSTER: {
          // Generate the JPEG data from the video
          canvas.width = state.width
          canvas.height = state.height
          const ctx = canvas.getContext('2d')
          try {
            ctx.drawImage(payload, 0, 0, state.width, state.height)
          } catch (error) {
            // Firefox has a bug where it throws this error even though it shouldn't:
            // https://bugzilla.mozilla.org/show_bug.cgi?id=1629381
            if (error.name === 'InvalidStateError') {
              console.error(error)
              break
            }
            throw error
          }
          state.poster = canvas.toDataURL('image/jpeg')
          break
        }

        /**
         * We currently aren't getting all of the following information reliably
         * from an API, so we wait until the HTMLVideoElement is ready to play,
         * and then we set these properties in the state.
         */
        case ACTIONS.SET_VIDEO_PROPERTIES: {
          const { width, height, duration, audio } = payload
          state.width = width || state.width
          state.height = height || state.height
          state.duration = duration ?? state.duration
          state.audio = audio ?? state.audio
          if (state.mode === MODE.VOD) {
            state.timeline = [
              {
                start: 0,
                duration: state.duration * 1000,
                end: state.duration * 1000,
              },
            ]
          }
          break
        }

        /**
         * When we have (nearly) all the starting information about the video,
         * initialize the player state
         */
        case ACTIONS.INIT: {
          const {
            stream,
            timeline,
            download_limit,
            resolution,
            currently_streaming,
            playlistBaseUrl,
          } = payload
          if (stream.is_vod) {
            state.mode = MODE.VOD
            state.position = 0
          } else if (currently_streaming) {
            state.mode = MODE.LIVE
            state.timeline = newLiveTimeline(timelineToMs(timeline))
            state.position = state.timeline[state.timeline.length - 1].end
          } else {
            state.mode = MODE.HISTORY
            state.timeline = timelineToMs(timeline)
            state.position =
              state.timeline[state.timeline.length - 1].end -
              HISTORY_STARTING_POSITION_OFFSET
          }
          if (resolution) {
            const [width, height] = resolution.split('x') ?? []
            state.width = Number(width ?? null) || initialState.width
            state.height = Number(height ?? null) || initialState.height
          }
          state.currentTimeUCT = state.position
          state.downloadTimeLimit = download_limit * 1000
          state.playlistBaseUrl = playlistBaseUrl
          break
        }

        default:
          if (type != null) {
            console.error(new Error(`Unrecognized action type: ${type}`))
          }
      }
    }),

    // Set derived state
    produce((state) => {
      // Set whether we're currently playing live video
      if (
        state.mode === MODE.LIVE &&
        (type === ACTIONS.INIT || type === ACTIONS.TIME_UPDATE)
      ) {
        state.live =
          Math.abs(
            state.timeline[state.timeline.length - 1].end -
            state.currentTimeUCT,
          ) <= LIVE_MARGIN
      }

      // Set src
      if (
        state.playlistBaseUrl &&
        (type === ACTIONS.INIT || type === ACTIONS.LIVE_PLAYBACK)
      ) {
        if (state.mode === MODE.VOD) {
          state.src = state.playlistBaseUrl
        } else {
          state.chunkLoading = true

          if (state.live) {
            state.src = getLiveFromPlaylistBaseUrl(state.playlistBaseUrl)
          } else if (state.position != null) {
            state.src = getHistoricFromPlaylistBaseUrl(
              state.playlistBaseUrl,
              // from 5 minutes before the requested point
              Math.round(state.position / 1000 - 5 * 60),
              // to 10 minutes after it, so 15 minutes in total
              10 * 60,
            )
          }
        }
      }

      // Check whether we should load a new chunk. The new chunk start is 5
      // minutes before the desired playback time and end is 10 minutes after.
      if (state.mode !== MODE.VOD) {
        // If we're not live, we want to load a new chunk when we're 30
        // seconds away from the end of this one.
        if (
          !state.live &&
          type === ACTIONS.TIME_UPDATE &&
          (state.currentTimeUCT < state.chunk.start ||
            // FIXME: Sometimes, usually when stream-manager performance is low,
            // the player gets into a state where we're not playing live, but
            // the chunk is less than 30 seconds long (should only happen for
            // live playback), this check and its effects below cause us to yoyo
            // between 2 different playlists. I have fixed it by reducing the
            // margin until the chunk end to 5 seconds, which should be fine
            // even for live-sized chunks. This introduces a higher risk of
            // buffering. This feels like a workaround rather than a proper fix.
            // state.currentTimeUCT > state.chunk.end - 30 * 1000)
            state.currentTimeUCT > state.chunk.end - 5 * 1000)
        ) {
          state.chunkLoading = true
          state.src = getHistoricFromPlaylistBaseUrl(
            state.playlistBaseUrl,
            // from 5 minutes before the requested point
            Math.round(state.currentTimeUCT / 1000 - 5 * 60),
            // to 10 minutes after it, so 15 minutes in total
            10 * 60,
          )
        }

        if (
          type === ACTIONS.SEEK &&
          (state.position < state.chunk.start ||
            state.position > state.chunk.end - 30 * 1000)
        ) {
          state.live = false
          state.chunkLoading = true
          state.src = getHistoricFromPlaylistBaseUrl(
            state.playlistBaseUrl,
            // from 5 minutes before the requested point
            Math.round(state.position / 1000 - 5 * 60),
            // to 10 minutes after it, so 15 minutes in total
            10 * 60,
          )
        }
      }
    }),

    // This function doesn't change any state, it's just for debugging, to
    // enable/disable, see the flags near the top of the file.
    (newState) => {
      if (
        DEBUG &&
        (!DEBUG_TIME_UPDATE ? type !== ACTIONS.TIME_UPDATE : true) &&
        (!DEBUG_SHOW_CONTROLS ? type !== ACTIONS.SHOW_CONTROLS : true)
      ) {
        console.debug(
          'state:',
          state,
          'action:',
          { type, payload },
          'new state:',
          newState,
        )
      }
      return newState
    },
  )(state)
