/* Adapted from https://github.com/gragland/usehooks/blob/master/src/pages/useLocalStorage.md */
import { useCallback, useMemo } from 'react'
import { createGlobalState } from 'react-use'

const PREFS_STORAGE_KEY = 'preferences'

const initialValue = {}

const useState = createGlobalState(() => {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(PREFS_STORAGE_KEY)
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    console.error(error)
    return initialValue
  }
})

// Hook
function usePrefs() {
  const [storedValue, setStoredValue] = useState()

  // Return a wrapped version of useState's setter function that ...
  // ... first reads storedValue from localStorage, in case multiple instances
  // of this hook are mounted--as they wouldn't have the useState in sync.
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value) => {
      try {
        // Allow value to be a function so we have same API as useState
        const newValue = value instanceof Function ? value(storedValue) : value

        const valueToStore = {
          ...storedValue,
          ...newValue,
        }

        const stringified = JSON.stringify(valueToStore)

        // Save state--parsing the stringified version so that it's 1-1 with
        // what would be provided if the user hard-refreshed and initialized
        // straight from localStorage again. E.g. JSON.stringify removes
        // properties set to undefined, instead of leaving them there and
        // explicitly setting the property to the value undefined. This matters
        // in some cases.
        setStoredValue(JSON.parse(stringified))
        // Save to local storage
        window.localStorage.setItem(PREFS_STORAGE_KEY, stringified)
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error(error)
      }
    },
    [storedValue, setStoredValue],
  )

  return useMemo(() => [storedValue, setValue], [storedValue, setValue])
}

export default usePrefs
