import React, { useCallback, useMemo } from 'react'
import {
  useLocation,
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  Link,
} from 'react-router-dom'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { DateTimePicker } from '@material-ui/pickers'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useFilterParams from '../../hooks/useFilterParams'

import PageTitle from '../../components/PageTitle'

import {
  useTopUsersQuery,
  useTopStreamsQuery,
  useDivisionsQuery,
  useSitesQuery,
  useSectionsQuery,
} from '../../services/stream-manager'

import Container from '../layout/Container'
import EnhancedTable from '../table/EnhancedTable'

import { formatDuration, formatTime } from './utils'


const usersAnalyticsColumns = [
  {
    accessor: 'name',
    Header: 'User name',
  },
  {
    accessor: 'duration',
    Header: 'Total view time',
    Cell: ({ value }) => formatDuration(value * 1000),
  },
  {
    accessor: 'stream_count',
    Header: 'Streams',
  },
  {
    accessor: 'last_viewed',
    Header: 'Last active',
    Cell: ({ value }) => formatTime(value),
  },
]

const streamsAnalyticsColumns = [
  {
    accessor: 'name',
    Header: 'Stream name',
  },
  {
    accessor: 'user_count',
    Header: 'Users viewed',
  },
  {
    accessor: 'duration',
    Header: 'View time',
    Cell: ({ value }) => formatDuration(value * 1000),
  },
  {
    accessor: 'last_viewed',
    Header: 'Last viewed',
    Cell: ({ value }) => formatTime(value),
  },
]

const useStyles = makeStyles((theme) => ({
  tabBarWrapper: {
    borderRadius: 0,
  },
  formControl: {
    minWidth: 100,
    marginRight: theme.spacing(1),
  },
}))

function AnalyticsPage() {
  const classes = useStyles()

  const today = useMemo(() => moment(), [])
  const yesterday = useMemo(() => moment().subtract(1, 'day'), [])

  const { url, path } = useRouteMatch()
  const location = useLocation()

  const divisionsQuery = useDivisionsQuery()
  const sitesQuery = useSitesQuery()
  const sectionsQuery = useSectionsQuery()

  const [{ division, site, section, from: _from, until: _until }, setFilterParams] = useFilterParams()

  const from = _from ?? yesterday
  const until = _until ?? today

  const handleFromDateChange = useCallback(
    (from) => {
      // Always set new selected date.
      setFilterParams({
        from,
        // If user chooses start date > current stop date, adjust current stop
        // date so that start date < new stop date.
        // TODO: Currently we default to 1 day although that might be a bit
        // long.
        ...(until && from.isSameOrAfter(until) ? {
          until: from.clone().add(1, 'day')
        } : undefined),
      })
    },
    [setFilterParams, until],
  )

  const handleUntilDateChange = useCallback(
    (until) => {
      // Always set new selected date.
      setFilterParams({
        ...(from && until?.isSameOrBefore(from) ? {
          from: until.clone().subtract(1, 'day')
        } : undefined),
        until,
      })
    },
    [setFilterParams, from],
  )

  const search = location.search.replace(/^\?/, '')

  const usersAnalyticsQuery = usePaginatedFilteredQuery(useTopUsersQuery)(
    undefined,
    { skip: location.pathname !== `${url}/users` }
  )
  const streamsAnalyticsQuery = usePaginatedFilteredQuery(useTopStreamsQuery)(
    undefined,
    { skip: location.pathname !== `${url}/streams` }
  )

  return (
    <>
      <PageTitle>Analytics</PageTitle>
      <Paper className={classes.tabBarWrapper}>
        <Tabs
          value={location.pathname !== url && location.pathname}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            component={Link}
            label="Users"
            value={`${url}/users`}
            to={{
              pathname: `${url}/users`,
              search,
            }}
          />
          <Tab
            component={Link}
            label="Streams"
            value={`${url}/streams`}
            to={{
              pathname: `${url}/streams`,
              search,
            }}
          />
        </Tabs>
      </Paper>
      <Container>
        <Box p={3}>
          <DateTimePicker
            className={classes.formControl}
            label="From"
            format="DD MMM HH:mm:ss"
            onChange={handleFromDateChange}
            value={from}
            maxDate={today}
            margin="dense"
          />
          <DateTimePicker
            className={classes.formControl}
            label="Until"
            format="DD MMM HH:mm:ss"
            onChange={handleUntilDateChange}
            value={until}
            maxDate={today}
            margin="dense"
          />
          <FormControl className={classes.formControl} margin="dense">
            <InputLabel id="division-select-input-label" margin="dense">
              Division
            </InputLabel>
            <Select
              labelId="division-select-label"
              id="division-select"
              value={division?.id ?? "__NONE__"}
              onChange={(e) => setFilterParams({ division: e.target.value })}
              label="Division"
              margin="dense"
            >
              <MenuItem value="__NONE__">
                <em>All</em>
              </MenuItem>
              {divisionsQuery.data?.map((division) => (
                <MenuItem value={division.id} key={division.id}>
                  {division.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} margin="dense">
            <InputLabel id="site-select-input-label" margin="dense">
              Site
            </InputLabel>
            <Select
              labelId="site-select-label"
              id="site-select"
              value={site?.id ?? "__NONE__"}
              onChange={(e) => setFilterParams({ site: e.target.value })}
              label="Site"
              margin="dense"
            >
              <MenuItem value="__NONE__">
                <em>All</em>
              </MenuItem>
              {sitesQuery.data?.map((site) =>
                division?.id != null && site.division_id === division.id ? (
                  <MenuItem value={site.id} key={site.id}>
                    {site.name}
                  </MenuItem>
                ) : null,
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} margin="dense">
            <InputLabel id="section-select-input-label" margin="dense">
              Section
            </InputLabel>
            <Select
              labelId="section-select-label"
              id="section-select"
              value={section?.id ?? "__NONE__"}
              onChange={(e) => setFilterParams({ section: e.target.value })}
              label="Section"
              margin="dense"
            >
              <MenuItem value="__NONE__">
                <em>All</em>
              </MenuItem>
              {sectionsQuery.data?.map((section) =>
                site?.id != null && section.site_id === site?.id ? (
                  <MenuItem value={section.id} key={section.id}>
                    {section.name}
                  </MenuItem>
                ) : null,
              )}
            </Select>
          </FormControl>
          <br />
          <br />
          <Paper>
            <Switch>
              <Route
                path={`${path}/users`}
                render={() => (
                  <EnhancedTable
                    label="Users Analytics"
                    itemsQuery={usersAnalyticsQuery}
                    columns={usersAnalyticsColumns}
                  />
                )}
              />
              <Route
                path={`${path}/streams`}
                render={() => (
                  <EnhancedTable
                    label="Streams Analytics"
                    itemsQuery={streamsAnalyticsQuery}
                    columns={streamsAnalyticsColumns}
                  />
                )}
              />
              <Redirect to={`${url}/users`} />
            </Switch>
          </Paper>
        </Box>
      </Container>
    </>
  )
}

export default AnalyticsPage
