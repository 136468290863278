import React, { useEffect, useCallback, useMemo } from 'react'
import _get from 'lodash/fp/get'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'

import { withAdmin } from '../../services/msal'

import {
  useGroupsQuery,
  useStreamsQuery,
  useSectionsQuery,
  useSitesQuery,
  useDivisionsQuery,
  useUsersQuery,
  useAssignGroupToUserMutation,
  useRevokeGroupFromUserMutation,
  useAddDivisionToGroupMutation,
  useRemoveDivisionFromGroupMutation,
  useAddSiteToGroupMutation,
  useRemoveSiteFromGroupMutation,
  useAddStreamToGroupMutation,
  useRemoveStreamFromGroupMutation,
  useAddSectionToGroupMutation,
  useRemoveSectionFromGroupMutation,
  useEntityGroupsQuery,
  useUserGroupsQuery,
} from '../../services/stream-manager'
import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useURLParams from '../../hooks/useURLParams'

import EnhancedTable from '../table/EnhancedTable'

import AlertDialog from '../dialogs/AlertDialog'

// depending on the assigment type chosen, the entities array will hold
// either a list of streams or a list of users
const entityTypes = ['users', 'streams', 'divisions', 'sites', 'sections']

const useStyles = makeStyles({
  checkbox: {
    padding: 0,
  },
})

const AssignmentPage = () => {
  // the assignment type chosen in the drop down box: either users or streams
  const [{ entityType: _entityType }, setURLParams] = useURLParams()

  const entityType = _entityType ?? 'streams'
  const setEntityType = useCallback(
    (entityType) => setURLParams({ entityType }),
    [setURLParams],
  )

  useEffect(() => {
    if (_entityType == null) {
      setEntityType('streams')
    }
  }, [_entityType, setEntityType])

  // fetch all the required information from the redux store
  const groupsQuery = useGroupsQuery()
  const streamsQuery = usePaginatedFilteredQuery(useStreamsQuery)(undefined, {
    skip: entityType !== 'streams',
  })
  const sectionsQuery = usePaginatedFilteredQuery(useSectionsQuery)(undefined, {
    skip: entityType !== 'sections',
  })
  const sitesQuery = usePaginatedFilteredQuery(useSitesQuery)(undefined, {
    skip: entityType !== 'sites',
  })
  const divisionsQuery = usePaginatedFilteredQuery(useDivisionsQuery)(undefined, {
    skip: entityType !== 'divisions',
  })
  const usersQuery = usePaginatedFilteredQuery(useUsersQuery)(undefined, {
    skip: entityType !== 'users',
  })

  const entitiesQuery = (() => {
    switch (entityType) {
      case 'streams':
        return streamsQuery
      case 'users':
        return usersQuery
      case 'divisions':
        return divisionsQuery
      case 'sites':
        return sitesQuery
      case 'sections':
        return sectionsQuery
      default:
        console.error(`Don't know how to handle items of type "${entityType}"`)
    }
  })()

  const [
    assignGroupToUser,
    assignGroupToUserResult,
  ] = useAssignGroupToUserMutation()
  const [
    revokeGroupFromUser,
    revokeGroupFromUserResult,
  ] = useRevokeGroupFromUserMutation()
  const [
    addDivisionToGroup,
    addDivisionToGroupResult,
  ] = useAddDivisionToGroupMutation()
  const [
    removeDivisionFromGroup,
    removeDivisionFromGroupResult,
  ] = useRemoveDivisionFromGroupMutation()
  const [addSiteToGroup, addSiteToGroupResult] = useAddSiteToGroupMutation()
  const [
    removeSiteFromGroup,
    removeSiteFromGroupResult,
  ] = useRemoveSiteFromGroupMutation()
  const [
    addStreamToGroup,
    addStreamToGroupResult,
  ] = useAddStreamToGroupMutation()
  const [
    removeStreamFromGroup,
    removeStreamFromGroupResult,
  ] = useRemoveStreamFromGroupMutation()
  const [
    addSectionToGroup,
    addSectionToGroupResult,
  ] = useAddSectionToGroupMutation()
  const [
    removeSectionFromGroup,
    removeSectionFromGroupResult,
  ] = useRemoveSectionFromGroupMutation()

  const addToGroup = useCallback(
    ({ id, group }) => {
      switch (entityType) {
        case 'streams':
          addStreamToGroup({ group, stream_id: id })
          break
        case 'users':
          assignGroupToUser({ group, user_id: id })
          break
        case 'divisions':
          addDivisionToGroup({ group, division_id: id })
          break
        case 'sites':
          addSiteToGroup({ group, site_id: id })
          break
        case 'sections':
          addSectionToGroup({ group, section_id: id })
          break
        default:
          console.error(`Unknown entity type "${entityType}"`)
      }
    },
    [
      addStreamToGroup,
      assignGroupToUser,
      addDivisionToGroup,
      addSiteToGroup,
      addSectionToGroup,
      entityType,
    ],
  )

  const removeFromGroup = useCallback(
    ({ id, group }) => {
      switch (entityType) {
        case 'streams':
          removeStreamFromGroup({ group, stream_id: id })
          break
        case 'users':
          revokeGroupFromUser({ group, user_id: id })
          break
        case 'divisions':
          removeDivisionFromGroup({ group, division_id: id })
          break
        case 'sites':
          removeSiteFromGroup({ group, site_id: id })
          break
        case 'sections':
          removeSectionFromGroup({ group, section_id: id })
          break
        default:
          console.error(`Unknown entity type "${entityType}"`)
      }
    },
    [
      removeStreamFromGroup,
      revokeGroupFromUser,
      removeDivisionFromGroup,
      removeSiteFromGroup,
      removeSectionFromGroup,
      entityType,
    ],
  )

  const _entityGroupsQuery = useEntityGroupsQuery(
    entitiesQuery.data?.map(_get('id')),
    {
      skip: entityType === 'users' || entitiesQuery.data == null,
    },
  )
  const userGroupsQuery = useUserGroupsQuery(
    entitiesQuery.data?.map(_get('id')),
    {
      skip: entityType !== 'users' || entitiesQuery.data == null,
    },
  )

  const entityGroupsQuery =
    entityType === 'users' ? userGroupsQuery : _entityGroupsQuery

  const columns = useMemo(
    () =>
      [
        {
          Header: (
            <strong>
              {
                {
                  users: 'User',
                  streams: 'Stream',
                  divisions: 'Division',
                  sites: 'Site',
                  sections: 'Section',
                }[entityType]
              }
            </strong>
          ),
          accessor: 'name',
        },
      ].concat(
        groupsQuery.data?.map(({ group }) => ({
          Header: group,
          id: group,
          accessor: 'id',
          Cell: ({ value: id }) => {
            const classes = useStyles()
            if (entitiesQuery.isLoading) return null

            const index = entitiesQuery.data?.findIndex(
              (entity) => entity.id === id,
            )
            if (!(index >= 0)) return null

            const groups = entityGroupsQuery.data?.[index]
            if (groups == null) return null

            const checked = Boolean(groups.find((g) => g.group === group))

            return (
              <Checkbox
                className={classes.checkbox}
                checked={checked}
                onChange={() => {
                  if (checked) {
                    removeFromGroup({ id, group })
                  } else {
                    addToGroup({ id, group })
                  }
                }}
                name={`${id}::${group}`}
                size="small"
                color="default"
              />
            )
          },
        })) ?? [],
      ),
    [
      entityType,
      groupsQuery.data,
      entitiesQuery.isLoading,
      entitiesQuery.data,
      entityGroupsQuery.data,
      addToGroup,
      removeFromGroup,
    ],
  )

  return (
    <>
      <AlertDialog
        open={groupsQuery.data?.length === 0}
        alert={
          <>
            No groups have been added —{' '}
            <strong>add those first before proceeding here!</strong>
          </>
        }
      />
      {[
        assignGroupToUserResult,
        revokeGroupFromUserResult,
        addDivisionToGroupResult,
        removeDivisionFromGroupResult,
        addSiteToGroupResult,
        removeSiteFromGroupResult,
        addStreamToGroupResult,
        removeStreamFromGroupResult,
        addSectionToGroupResult,
        removeSectionFromGroupResult,
      ].map((result, i) => (
        <AlertDialog
          key={`error-${result.startedTimeStamp || i}`}
          open={result.isError}
          severity="error"
          status={result.error?.status}
          alert={result.error?.data}
        />
      ))}
      <TextField
        name="assignment:type"
        label="Assignment Type"
        select
        fullWidth
        helperText="Choose type of resource from selection"
        InputLabelProps={{ shrink: true }}
        onChange={(event) => setEntityType(event.target.value)}
        value={entityType}
      >
        {entityTypes.map((entity) => (
          <MenuItem key={entity} value={entity}>
            {entity}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <br />
      <Paper>
        <EnhancedTable
          label="Assign groups"
          columns={columns}
          itemsQuery={entitiesQuery}
          search
        />
      </Paper>
    </>
  )
}

export const CreateAssignmentPage = withAdmin(AssignmentPage)
