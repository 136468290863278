import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _get from 'lodash/fp/get'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import MuiLink from '@material-ui/core/Link'
import GPSIcon from 'mdi-material-ui/MapMarkerPath'

import { StreamProperties } from '../../proptypes/proptypes'

import FavouriteButton from '../dashboard/FavouriteButton'

import { VideoThumb } from './Thumbnail'
import { streamPath } from './utils'
import StreamHealth from './StreamHealth'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '8px 16px',
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  contentBody: {
    verticalAlign: 'top',
  },
  description: {
    marginBottom: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  header: {
    padding: [theme.spacing(2), theme.spacing(2), 0, theme.spacing(2)]
      .map((size) => `${size}px`)
      .join(' '),
  },
  imageContainer: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
  },
  title: {
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '-7px',
  },
  streamCardTopPanel: {
    position: 'absolute',
    padding: theme.spacing(2),
    top: 0,
    right: 0,
  },
  favouriteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
  },
  tags: {
    minHeight: '1em',
    marginBottom: theme.spacing(1),
  },
  vodInfoTable: {
    marginBottom: theme.spacing(1),
    fontSize: 'smaller',
    '& th': {
      textAlign: 'left',
      padding: 0,
    },
    '& td': {
      padding: 0,
    },
  },
  vodInfoFresh: {
    color: theme.palette.success.main,
  },
  vodInfoStale: {
    color: theme.palette.warning.main,
  },
  vodInfoExpiring: {
    color: theme.palette.error.main,
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  location: {
    fontSize: '0.7rem',
  },
  streamHealth: {
    display: 'flex',
    '& svg': {
      fontSize: '1rem',
      marginLeft: theme.spacing(1),
    },
  },
}))

const StreamCard = ({
  stream,
  onClick,
  action,
  playing,
  className,
  ...props
}) => {
  const classes = useStyles()

  return (
    <>
      <Card className={clsx(classes.root, className)} {...props}>
        <div className={classes.imageContainer} onClick={onClick}>
          <VideoThumb playing={playing} stream={stream} />
          <div className={classes.streamCardTopPanel}>
            <FavouriteButton
              className={classes.favouriteButton}
              streamId={stream.id}
            />
          </div>
        </div>

        <CardHeader
          classes={{ root: classes.header, title: classes.title }}
          title={
            window.__ENV.REACT_APP_VIDEO_URLS === 'true' ? (
              <MuiLink component={Link} color="inherit" to={streamPath(stream)}>
                {stream.name}
              </MuiLink>
            ) : (
              stream.name
            )
          }
          action={action}
        />
        <CardContent className={classes.content}>
          {stream.description && (
            <Typography
              className={clsx(classes.contentBody, classes.description)}
              color="textSecondary"
              component="p"
            >
              {stream.description}
            </Typography>
          )}
          {stream.is_vod && (() => {
            const now = moment.utc()
            const expires = stream.uploaded && moment
              .utc(stream.uploaded)
              .add(window.__ENV.REACT_APP_VOD_EXPIRY_WINDOW, 'seconds')

            return (
              <table
                className={clsx(
                  classes.vodInfoTable,
                  expires?.diff(now, 'days') < 1
                    ? classes.vodInfoExpiring
                    : expires?.diff(now, 'weeks') < 1
                      ? classes.vodInfoStale
                      : classes.vodInfoFresh
                )}
              >
                <tbody>
                  {stream.video_id && (
                    <tr>
                      <th scope="row">ID:</th>
                      <td>{stream.video_id}</td>
                    </tr>
                  )}
                  {stream.video_type && (
                    <tr>
                      <th scope="row">Type:</th>
                      <td>{stream.video_type}</td>
                    </tr>
                  )}
                  {stream.source && (
                    <tr>
                      <th scope="row">Source:</th>
                      <td>{stream.source}</td>
                    </tr>
                  )}
                  {stream.uploaded && (
                    <>
                      <tr>
                        <th scope="row">Uploaded:</th>
                        <td>{moment.utc(stream.uploaded).format('D/MM/YYYY')}</td>
                      </tr>
                      <tr>
                        <th scope="row">Expires:</th>
                        <td>{expires.format('D/MM/YYYY')}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )
          })()}
          <div className={classes.spacer} />
          {stream.tags?.length > 0 && (
            <Typography
              className={clsx(
                classes.tags,
                classes.contentBody,
                classes.location,
              )}
              color="primary"
              component="p"
            >
              {stream.tags
                .map(_get('tag'))
                .map((tag) => `#${tag}`)
                .join(' ')}
            </Typography>
          )}
          <div className={classes.bottomRow}>
            <div>
              {stream.recorded && (
                <Typography
                  className={clsx(classes.contentBody, classes.location)}
                  color="textSecondary"
                  component="p"
                >
                  {moment.utc(stream.recorded).format('D MMM YYYY HH:mm')}
                </Typography>
              )}
              <Typography
                className={clsx(classes.contentBody, classes.location)}
                color="textSecondary"
                component="p"
              >
                {[
                  stream.division?.name,
                  stream.site?.name,
                  stream.section?.name,
                ]
                  .filter(Boolean)
                  .join(' / ')}
              </Typography>
            </div>
            <div className={classes.streamHealth}>
              <StreamHealth id={stream.id} />
              {stream.has_track && (
                <Tooltip title={`Has a GPS track`}>
                  <GPSIcon />
                </Tooltip>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

StreamCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  stream: StreamProperties.isRequired,
  action: PropTypes.node,
}

export default StreamCard
