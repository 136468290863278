import React from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'

import { useSectionsQuery } from '../../services/stream-manager'

import SectionListItem from './SectionListItem'

const SectionList = ({ site, division, onSelect }) => {
  // get list of sections that belong to site identified by site.id
  const sectionsQuery = useSectionsQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      data: data?.filter(({ site_id }) => site_id === site.id),
      ...rest,
    }),
  })

  return (
    <List>
      {sectionsQuery.data?.map((section) => (
        <SectionListItem
          key={section.id}
          section={section}
          site={site}
          division={division}
          onSelect={onSelect}
        />
      ))}
    </List>
  )
}

SectionList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  division: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default SectionList
