import slug from 'slug'

import { uuidToBase64 } from '../../utils/uuid'

export const streamPath = (stream) =>
  stream?.id != null
    ? `/stream/${uuidToBase64(stream.id)}/${slug(stream.name ?? '')}`
    : undefined

export const streamUrl = (stream) => window.location.origin + streamPath(stream)

export const streamHealth = (timeline) => {
  const totalDuration = timeline[timeline.length - 1].end - timeline[0].start

  const totalGaps = timeline.reduce((acc, currentSegment, i, timeline) => {
    const nextSegment = timeline[i + 1]
    if (!nextSegment) return acc
    return acc + nextSegment.start - currentSegment.end
  }, 0)

  return (totalDuration - totalGaps) / totalDuration
}
