import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import BadHealthIcon from 'mdi-material-ui/NetworkStrength1Alert'
import PoorHealthIcon from 'mdi-material-ui/NetworkStrength2Alert'
import FairHealthIcon from 'mdi-material-ui/NetworkStrength3'
import GoodHealthIcon from 'mdi-material-ui/NetworkStrength4'
import VODIcon from 'mdi-material-ui/Vhs'
import LiveStreamIcon from 'mdi-material-ui/Cctv'
import HistoryIcon from 'mdi-material-ui/History'

import useStreamHealth from '../../hooks/useStreamHealth'

import { MODE } from '../player/state'

const POOR_HEALTH = 0.85
const FAIR_HEALTH = 0.95
const GOOD_HEALTH = 1

const useStyles = makeStyles((theme) => ({
  badHealth: {
    color: theme.palette.error.main,
  },
  poorHealth: {
    color: theme.palette.warning.main,
  },
  fairHealth: {
    color: theme.palette.success.main,
  },
  goodHealth: {
    color: theme.palette.success.main,
  },
}))

const StreamHealth = (id) => {
  const classes = useStyles()

  const { mode, health } = useStreamHealth(id)

  return (
    <>
      {health != null && (
        <Tooltip title={`Stream health: ${(health * 100).toFixed(2)}%`}>
          {health < POOR_HEALTH ? (
            <BadHealthIcon className={classes.badHealth} />
          ) : health < FAIR_HEALTH ? (
            <PoorHealthIcon className={classes.poorHealth} />
          ) : health < GOOD_HEALTH ? (
            <FairHealthIcon className={classes.fairHealth} />
          ) : (
            <GoodHealthIcon className={classes.goodHealth} />
          )}
        </Tooltip>
      )}
      {mode === MODE.VOD ? (
        <Tooltip title="Uploaded video">
          <VODIcon />
        </Tooltip>
      ) : mode === MODE.LIVE ? (
        <Tooltip title="Streaming live">
          <LiveStreamIcon />
        </Tooltip>
      ) : mode === MODE.HISTORY ? (
        <Tooltip title="No live feed">
          <HistoryIcon />
        </Tooltip>
      ) : undefined}
    </>
  )
}

export default StreamHealth
