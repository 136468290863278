/* eslint-disable import/no-unused-modules */
import { useMemo, useState, useCallback, useEffect } from 'react'
import {
  createApi,
  fetchBaseQuery,
  retry,
  skipToken,
} from '@reduxjs/toolkit/query/react'

import { v4 as uuid } from 'uuid'

import _zipWith from 'lodash/fp/zipWith'
import _pipe from 'lodash/fp/pipe'
import _map from 'lodash/fp/map'
import _get from 'lodash/fp/get'
import _uniqBy from 'lodash/fp/uniqBy'
import _identity from 'lodash/fp/identity'

import msalApi, { selectAppAuth } from './msal'

// Log tag provision and invalidation to the console
const DEBUG_TAGS = false
const MAX_FAVOURITE_STREAMS =
  Number(window.__ENV.REACT_APP_MAX_FAVOURITE_STREAMS ?? null) || 15
const THUMBNAIL_CACHE_SECONDS =
  Number(window.__ENV.REACT_APP_THUMBNAIL_CACHE_SECONDS ?? null) || 300
const SECONDS_IN_MINUTE = 60

export const TAG = {
  VERSION: 'VERSION',
  STATUS: 'STATUS',
  DEVICE: 'DEVICE',
  DEVICE_MAPPING: 'DEVICE_MAPPING',
  DIVISION: 'DIVISION',
  TOPIC: 'TOPIC',
  TOPIC_ACK: 'TOPIC_ACK',
  EVENT: 'EVENT',
  GROUP: 'GROUP',
  SERVER: 'SERVER',
  SERVER_LAYOUT: 'SERVER_LAYOUT',
  SECTION: 'SECTION',
  SITE: 'SITE',
  STREAM: 'STREAM',
  STREAM_STATUS: 'STREAM_STATUS',
  STREAM_URL: 'STREAM_URL',
  STREAM_THUMBNAIL: 'STREAM_THUMBNAIL',
  STREAM_TOKEN: 'STREAM_TOKEN',
  STREAM_THUMBNAIL_TOKEN: 'STREAM_THUMBNAIL_TOKEN',
  STREAM_TRACK: 'STREAM_TRACK',
  VIDEO_UPLOAD_STATUS: 'VIDEO_UPLOAD_STATUS',
  TAG: 'TAG',
  USER: 'USER',
  DISCLAIMER_LOG: 'DISCLAIMER_LOG',
  USER_ANALYTICS: 'USER_ANALYTICS',
  STREAM_ANALYTICS: 'STREAM_ANALYTICS',
  FAVOURITE: 'FAVOURITE',
  CREDENTIAL: 'CREDENTIAL',
  MONITOR_STREAMS: 'MONITOR_STREAMS',
  MONITOR_SERVERS: 'MONITOR_SERVERS',
  MONITOR_SERVICES: 'MONITOR_SERVICES',
  UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

const LIST_ID = '__LIST__'

const defaultIdFn = (result, arg) => _get('id', result) ?? _get('id', arg)

const tagsFromResultsOrArg = (type, idFn = defaultIdFn, results, arg) =>
  (typeof idFn !== 'function'
    ? [{ type, id: idFn }]
    : (!Array.isArray(results)
      ? [results]
      : results.length === 0
        ? [undefined]
        : results
    )
      .map((result) => {
        const tag = {
          type,
          id: idFn(
            result,
            ['string', 'number'].includes(typeof arg) ? { id: arg } : arg,
          ),
        }
        return idFn === defaultIdFn && tag.id === undefined ? undefined : tag
      })
      .filter(Boolean)
  ).flatMap(({ type, id }) => [].concat(id).map((id) => ({ type, id })))

const tagsFromResultsOrArgs = (type, idFn, results, args) =>
  // If the arg is an array, multiple calls were made, arg and result are
  // collections for the multiple calls and we should get the tags per set of
  // args.
  Array.isArray(args)
    ? args
      .map((arg, i) => tagsFromResultsOrArg(type, idFn, results[i], arg))
      .flat()
    : tagsFromResultsOrArg(type, idFn, results, args)

const tagsFromError = (_, error) =>
  error?.status === 401
    ? // If there is an auth error, set the following tag on the "result".  When
    // we have a successful login, it will invalidate that tag, and all of the
    // results that have it will have their calls re-fetched.
    // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#providing-errors-to-the-cache
    [TAG.UNAUTHORIZED_ERROR]
    : [TAG.UNKNOWN_ERROR]

const idFnByType = (types) =>
  // The tag types can either be
  // * a plain string (single tag type)
  // * an array of strings (different tag types), or
  // * an object, where
  //   * keys are the tag types, and
  //   * values are either:
  //     * nullish
  //     * functions with signature (result, arg) => id | [id]
  //     * a static value
  // For string tag types or nullish object values, a default function that gets
  // the id prop of the result or arg is used.
  //
  // If the provided or resolved ID is undefined, the tag will be treated as a
  // _general_ [1] tag by RTK-Query, which is probably not what you want: that
  // only makes sense in cases where there is only ever going to be _one_ entity
  // of the type. In cases where the ID is unknowable, such as the `POST`/create
  // calls for the stream-manager API (neither known up front nor returned in
  // the result), you can provide the `LIST_ID` special value, or a random ID.
  //
  // [1] https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#tag-invalidation-behavior
  Array.isArray(types) || typeof types !== 'object'
    ? Object.fromEntries([].concat(types).map((type) => [type]))
    : types

const providesTags = (types) => (result, error, arg) => {
  const ret = error
    ? tagsFromError(types, error, arg)
    : Object.entries(idFnByType(types))
      .map(([type, idFn]) =>
        _uniqBy(
          'id',
          tagsFromResultsOrArgs(type, idFn, result, arg)
            // For collections or multiple calls, add a tag of this type with a
            // special `LIST_ID` so that we can be clever about invalidating
            // collection calls when we trigger mutations to items that should
            // case invalidations but we don't have IDs for (e.g. _new_ items in
            // `POST`/create calls). Coupled with `selectFromResult`, this makes
            // sure we automatically refetch fresh data when necessary, but still
            // minimize unnecessary API calls.
            // https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#advanced-invalidation-with-abstract-tag-ids
            // https://redux-toolkit.js.org/rtk-query/usage/queries#selecting-data-from-a-query-result
            .concat(Array.isArray(result) ? { type, id: LIST_ID } : []),
        ),
      )
      .flat()

  DEBUG_TAGS &&
    console.debug('providesTags:', { types, result, error, arg }, ret)

  return ret
}

const invalidatesTags = (types) => (result, error, arg) => {
  const ret = Object.entries(idFnByType(types))
    .map(([type, idFn]) =>
      _uniqBy('id', tagsFromResultsOrArgs(type, idFn, result, arg)),
    )
    .flat()

  DEBUG_TAGS &&
    console.debug('invalidatesTags:', { types, result, error, arg }, ret)

  return ret
}

const setIdFrom = (prop) => (obj) => ({
  ...obj,
  id: obj[prop],
})

const setNameFrom = (prop) => (obj) => ({
  ...obj,
  name: obj[prop],
})

const baseQueryConfig = {
  baseUrl: window.__ENV.REACT_APP_STREAM_MANAGER_API,
  prepareHeaders: (headers, { getState }) => {
    if (headers.get('Authorization')) return headers
    const { accessToken } = selectAppAuth(getState()).data ?? {}
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
      headers.set('X-Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
  // We wrap `fetch` here so that we can add the `X-Original-URI` header. We
  // can't add it in `prepareHeaders` above because we need access to the
  // request URL.
  // TODO: it's possible that this is only necessary for the "auth by stream
  // token" API call, and we can just add it there instead of for all calls
  fetchFn: (request) => {
    request.headers.set('X-Original-URI', request.url)
    return window.fetch(request)
  },
}

// Wrap `fetchBaseQuery` to:
// * retry requests with a sensible backoff policy [1]
// * fake a `401 Unauthorized` response if we don't have a token in the store,
//   we know that that'll be the response anyway,
// * trigger a re-auth if a real response comes back as `401 Unauthorized`
//   (presumably due to a stale token).
//
// [1]: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
const myFetchBaseQuery = (config) => {
  const baseQuery = fetchBaseQuery(config)

  return retry(async (args, api, extraOptions) => {
    // Fake a 401 if we don't have a token yet, no need to actually try make the
    // request, this will set the unauthorized tag on the query, so it will be
    // automatically re-tried after auth.
    if (
      !selectAppAuth(api.getState()).data?.accessToken &&
      !args.headers?.Authorization
    )
      return { error: { status: 401 } }

    const response = await baseQuery(args, api, extraOptions)

    // bail out of re-tries immediately if 4xx, because we know successive
    // re-retries would be redundant, since the problem is on our side
    if (response.error?.status >= 400 && response.error?.status < 500) {
      retry.fail(response.error)
    }

    // If there's a real 401 it's probably an expired token
    if (response.error?.status === 401) {
      api.dispatch(
        msalApi.endpoints.login.initiate(undefined, {
          subscribe: false,
          // This will still only result in 1 active request even if multiple API
          // calls return 401 and we fire `initiate` a bunch of times
          forceRefetch: true,
        }),
      )
    }

    if (response.error?.status === 403) {
      const msg = `403 ${response.error.statusText}`
      console.error(new Error(msg))
    }

    return response
  })
}

const baseQuery = myFetchBaseQuery(baseQueryConfig)

// The same as the normal `baseQuery`, but considers the API URL origin to be
// the base URL for requests
const apiBaseQuery = myFetchBaseQuery({
  ...baseQueryConfig,
  baseUrl: new URL(window.__ENV.REACT_APP_STREAM_MANAGER_API).origin + '/api',
})

const assign = (...args) => {
  const objs = args.filter(Boolean) // Remove falsy args
  if (objs.length === 0) return
  if (objs.length === 1) return objs[0]
  return Object.assign({}, ...args)
}

const transformResponse = (tx) => (data, meta) => {
  const ret = Array.isArray(meta)
    ? meta.map((_, i) => tx(data[i], meta[i]))
    : tx(data, meta)

  if (data.count != null) ret.count = data.count
  return ret
}

const downloadResponse = (blob, meta) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  document.body.appendChild(a)
  a.href = URL.createObjectURL(blob)

  const filename =
    meta.response.headers
      .get('content-disposition')
      ?.match(/filename="(?<filename>.*?)"/)?.groups.filename ?? 'download.txt'

  // Use download attribute to set set desired file name
  a.setAttribute('download', filename)
  a.click()

  // Cleanup
  window.URL.revokeObjectURL(a.href)
  document.body.removeChild(a)

  return { data: filename }
}

export const normalizeArg = (arg = {}) => {
  const {
    // If the arg is a string or number, treat that as the ID, otherwise the
    // arg should be an object with an `id` prop
    id = typeof arg === 'string' || typeof arg === 'number' ? arg : undefined,
    ...extraArgs
  } = arg

  return {
    id,
    ...extraArgs,
  }
}

/*
 * This constructs the actual query function that will be used by the
 * query/mutation builder
 */
const queryFn = ({
  url,
  method,
  headers: baseHeaders,
  body: baseBody,
  params: baseParams,
  responseHandler,
  transformResponse = _identity,
  ...rest
}) => {
  const wrappedBaseQueryFn = async (
    arg, // This is the argument that was provided to the hook/initiate call
    api,
    extraOptions,
    baseQuery,
  ) => {
    const {
      id,
      params = {},
      headers,
      body,
      download,
      ...extraArgs
    } = normalizeArg(arg)

    let res,
      allRes,
      pageSize,
      page = 1,
      offset = params.offset

    do {
      res = await baseQuery(
        {
          url:
            typeof url === 'function'
              ? // If the `url` is a function, call it with some of the args so that
              // an arbitrary URL structure can be used
              url({ id, ...extraArgs })
              : // Else, just assume a conventional REST url structure
              url + (id != null ? `/${id}` : ''),
          method:
            typeof method === 'function'
              ? method({ id, ...extraArgs })
              : method,
          headers: assign(
            baseHeaders,
            typeof headers === 'function'
              ? headers({ id, ...extraArgs })
              : headers,
          ),
          params: assign(
            baseParams,
            typeof params === 'function'
              ? params({ id, ...extraArgs })
              : params,
            { offset },
          ),
          body: assign(
            baseBody,
            typeof body === 'function' ? body({ id, ...extraArgs }) : body,
          ),
          responseHandler: (r) =>
            download && r.ok ? r.blob() : responseHandler?.(r) ?? r.json(),
          ...rest,
        },
        api,
        extraOptions,
      )

      // If it's an error response, we're done
      if (res.error != null) break

      // If this is not a paged API endpoint, we're done
      if (res.data?.count == null || typeof res.data.count !== 'number') break

      // If there was a limit or offset set by the caller, we're done
      if (params.limit != null || params.offset != null) break

      // We need to fetch all the results. However, the API enforces limits, we
      // can't get them all at once, so we need to fetch all the pages one after
      // the other.

      // There should be only key other than `count`, what it's called differs
      // per API endpoint (e.g. `sites` or `divisions`), and it should be a
      // collection.  This is the "data" key.
      const otherKeys = Object.keys(res.data).filter((key) => key !== 'count')
      if (otherKeys.length !== 1) break
      const [dataKey] = otherKeys
      if (!Array.isArray(res.data[dataKey])) break

      // Tally up the total results so far
      if (page === 1) {
        allRes = res
      } else {
        allRes.data[dataKey] = allRes.data[dataKey].concat(res.data[dataKey])
      }

      if (allRes.data[dataKey].length === allRes.data.count) {
        // Final page, we've got 'em all
        res = allRes
        break
      }

      // Not all API endpoints are well behaved. Some ignore offset & limit
      // despite returning a collection. Some report an inaccurate count.
      // We need to do some sanity checks to avoid an infinite request loop.

      if (page === 1) {
        // Since we're defaulting to the server's default page size, we don't
        // know it. Assume the 1st page is the correct page size.
        pageSize = res.data[dataKey].length
      } else if (pageSize !== res.data[dataKey].length) {
        // This page is not the same size as the 1st page, but it's also not the
        // final page. This should never happen, something is wrong.
        console.error(new Error('Page size mismatch!'))
        break
      } else if (allRes.data[dataKey].length > res.count) {
        // We've got more results than are supposed exist.
        console.error(new Error('More results than expected!'))
        break
      } else if (allRes.data.count !== res.data.count) {
        // The count changed from one page to the next. It should probably
        // remain constant. There is a small chance that the collection was
        // updated before fetching this specific page, so let's not actually
        // stop loading. If this error keeps showing, it's worth investigating
        // what's happening.
        console.error(new Error('Count mismatch!'))
      }

      // If we made it this far, everything is fine, we need to get the next
      // page.
      offset = allRes.data[dataKey].length
      page++
    } while (true) // eslint-disable-line no-constant-condition

    return res
  }

  return (...args) =>
    // If the first argument is an array, make multiple network calls and merge
    // the results.
    // TODO: if some of these are rejected, we need to populate the error instead
    // of the data, else we cannot easily integrate with hook consumers that
    // expect `error` and `isError` to be set when there is an error
    (Array.isArray(args[0])
      ? Promise.all(
        args[0].map((arg) => wrappedBaseQueryFn(arg, ...args.slice(1))),
      ).then((responses) => {
        const { error, meta } = responses.find(({ error }) => Boolean(error)) ?? {}
        if (error) {
          return { error, meta: [meta] }
        }

        return responses.reduce(
          (acc, { data, meta }) => {
            acc.data.push(data)
            acc.meta.push(meta)
            return acc
          },
          { data: [], meta: [] },
        )
      })
      : wrappedBaseQueryFn(...args)
    ).then(async (r) => ({
      ...r,
      data:
        r.data !== undefined
          ? (args[0]?.download ? downloadResponse : transformResponse)(
            r.data,
            r.meta,
          )
          : undefined,
    }))
}

const withBaseQuery = (baseQuery, queryFn) => (...args) =>
  queryFn(...args.slice(0, 3), baseQuery)

const refreshToken = (queryFn) =>
  async function refreshToken(
    arg,
    {
      dispatch,
      getState,
      cacheEntryRemoved,
      cacheDataLoaded,
      getCacheEntry,
      updateCachedData,
    },
  ) {
    await cacheDataLoaded
    const { data: { expires } = {} } = getCacheEntry()
    const validFor = Math.max(expires * 1000 - Date.now() - 60 * 1000, 0)

    const timeoutId = setTimeout(async () => {
      const { data } = await queryFn(
        arg,
        { dispatch, getState },
        undefined,
        baseQuery,
      )
      if (data) updateCachedData(() => data)
      refreshToken(arg, {
        dispatch,
        getState,
        cacheEntryRemoved,
        cacheDataLoaded,
        getCacheEntry,
        updateCachedData,
      })
    }, validFor)

    await cacheEntryRemoved
    clearTimeout(timeoutId)
  }

const streamManager = createApi({
  reducerPath: 'streamManager',

  baseQuery,

  refetchOnMountOrArgChange: 5 * SECONDS_IN_MINUTE,
  refetchOnReconnect: true,

  tagTypes: Object.values(TAG),

  endpoints: (build) => ({
    // Access
    userDivisions: build.query({
      queryFn: queryFn({
        url: ({ id }) => `access/user/${id}?entity_type=division`,
        transformResponse: transformResponse(_get('divisions')),
      }),
      providesTags: providesTags(TAG.DIVISION),
    }),
    userSites: build.query({
      queryFn: queryFn({
        url: ({ id }) => `access/user/${id}?entity_type=site`,
        transformResponse: transformResponse(_get('sites')),
      }),
      providesTags: providesTags(TAG.SITE),
    }),
    userSections: build.query({
      queryFn: queryFn({
        url: ({ id }) => `access/user/${id}?entity_type=section`,
        transformResponse: transformResponse(_get('sections')),
      }),
      providesTags: providesTags(TAG.SECTION),
    }),
    userStreams: build.query({
      queryFn: queryFn({
        url: ({ id }) => `access/user/${id}?entity_type=stream`,
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),
    entityAccess: build.query({
      queryFn: queryFn({
        url: 'access/entity',
        transformResponse: transformResponse(_get('users')),
      }),
      providesTags: providesTags(TAG.USER),
    }),
    userEntityLinkingGroups: build.query({
      queryFn: queryFn({
        url: ({ user_id, entity_id }) =>
          `access/linking_groups/${user_id}/${entity_id}`,
        transformResponse: transformResponse(
          _pipe(
            _get('groups'),
            _map(setIdFrom('group')),
            _map(setNameFrom('group')),
          ),
        ),
      }),
      providesTags: providesTags({
        [TAG.GROUP]: undefined,
        [TAG.USER]: (_, { user_id }) => user_id,
      }),
    }),

    // Administration
    version: build.query({
      queryFn: withBaseQuery(
        apiBaseQuery,
        queryFn({
          url: 'version',
          transformResponse: transformResponse(_get('version')),
        }),
      ),
      providesTags: providesTags(TAG.VERSION),
    }),
    status: build.query({
      queryFn: withBaseQuery(
        apiBaseQuery,
        queryFn({
          url: 'status',
          transformResponse: transformResponse(_get('status')),
        }),
      ),
      providesTags: providesTags(TAG.STATUS),
    }),
    authStreamByToken: build.mutation({
      queryFn: withBaseQuery(
        apiBaseQuery,
        queryFn({
          url: 'auth/stream/token',
          method: 'GET',
        }),
      ),
    }),

    // Device Manager
    devices: build.query({
      queryFn: queryFn({
        url: 'devices',
        transformResponse: transformResponse(_get('device_ids')),
      }),
      providesTags: providesTags(TAG.DEVICE),
    }),
    deviceMappings: build.query({
      queryFn: queryFn({
        url: 'devices/assignments',
        transformResponse: transformResponse(
          _pipe(_get('device_mapping'), (mappingsByDeviceId) =>
            Object.entries(mappingsByDeviceId).reduce(
              (acc, [device_id, mapping]) => {
                acc.push({
                  ...mapping,
                  device_id,
                })
                return acc
              },
              [],
            ),
          ),
        ),
      }),
      providesTags: providesTags(TAG.DEVICE_MAPPING),
    }),
    unassignedDevices: build.query({
      queryFn: queryFn({
        url: 'devices/unassigned',
        transformResponse: transformResponse(_get('device_ids')),
      }),
      providesTags: providesTags(TAG.DEVICE),
    }),

    // Divisions
    divisions: build.query({
      queryFn: queryFn({
        url: 'division',
        transformResponse: transformResponse(_get('divisions')),
      }),
      providesTags: providesTags(TAG.DIVISION),
    }),
    division: build.query({
      queryFn: queryFn({ url: 'division' }),
      providesTags: providesTags(TAG.DIVISION),
    }),
    createDivision: build.mutation({
      queryFn: queryFn({
        url: ({ group }) => `division/${group}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.DIVISION]: LIST_ID }),
    }),
    updateDivision: build.mutation({
      queryFn: queryFn({ url: 'division', method: 'PATCH' }),
      invalidatesTags: invalidatesTags(TAG.DIVISION),
    }),
    deleteDivision: build.mutation({
      queryFn: queryFn({ url: 'division', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.DIVISION),
    }),

    // Events
    topics: build.query({
      queryFn: queryFn({
        url: ({ name }) =>
          `event/topic${name ? '' : `?name=${[].concat(name).join('+')}`}`,
        transformResponse: transformResponse(
          _pipe(_get('topics'), _map(setIdFrom('name'))),
        ),
      }),
      providesTags: providesTags(TAG.TOPIC),
    }),
    createTopic: build.mutation({
      queryFn: queryFn({ url: 'event/topic', method: 'POST' }),
      invalidatesTags: invalidatesTags({ [TAG.TOPIC]: LIST_ID }),
    }),
    topicAcks: build.query({
      queryFn: queryFn({
        url: ({ id }) => `event/topic/${id}/ack`,
        transformResponse: transformResponse(_get('event_acks')),
      }),
      providesTags: providesTags(TAG.TOPIC_ACK),
    }),
    ackTopic: build.mutation({
      queryFn: queryFn({
        url: ({ id }) => `event/topic/${id}/ack`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags(TAG.TOPIC_ACK),
    }),
    topic: build.query({
      queryFn: queryFn({ url: 'event/topic' }),
      providesTags: providesTags(TAG.TOPIC),
    }),
    topicEvents: build.query({
      queryFn: queryFn({
        url: ({ id }) => `event/topic/${id}/events`,
        transformResponse: transformResponse(_get('events')),
      }),
      providesTags: providesTags(TAG.EVENT),
    }),
    createTopicEvent: build.mutation({
      queryFn: queryFn({
        url: ({ id }) => `event/topic/${id}/events`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.EVENT]: LIST_ID }),
    }),
    acks: build.query({
      queryFn: queryFn({
        url: 'event/ack',
        transformResponse: transformResponse(_get('event_acks')),
      }),
      providesTags: providesTags(TAG.EVENT_ACKS),
    }),
    eventsAcks: build.query({
      queryFn: queryFn({
        url: 'event/ack',
        method: 'POST',
        transformResponse: transformResponse(_get('event_acks')),
      }),
      providesTags: providesTags(TAG.EVENT_ACKS),
    }),
    eventAcks: build.query({
      queryFn: queryFn({
        url: ({ id }) => `event/${id}/ack`,
        transformResponse: transformResponse(_get('event_acks')),
      }),
      providesTags: providesTags(TAG.EVENT_ACKS),
    }),
    ackEvent: build.query({
      queryFn: queryFn({ url: ({ id }) => `event/${id}/ack` }),
      providesTags: providesTags(TAG.EVENT_ACKS),
    }),
    eventStreams: build.query({
      queryFn: queryFn({
        url: ({ id }) => `event/${id}/streams`,
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),
    streamEvents: build.query({
      queryFn: queryFn({ url: ({ id }) => `stream/${id}/events` }),
      providesTags: providesTags(TAG.EVENT),
    }),
    events: build.query({
      queryFn: queryFn({
        url: 'event',
        transformResponse: transformResponse(_get('events')),
      }),
      providesTags: providesTags(TAG.EVENT),
    }),
    event: build.query({
      queryFn: queryFn({ url: 'event' }),
      providesTags: providesTags(TAG.EVENT),
    }),

    // Groups
    groups: build.query({
      queryFn: queryFn({
        url: 'group',
        transformResponse: transformResponse(
          _pipe(
            _get('groups'),
            _map(setIdFrom('group')),
            _map(setNameFrom('group')),
          ),
        ),
      }),
      providesTags: providesTags(TAG.GROUP),
    }),
    createGroup: build.mutation({
      queryFn: queryFn({ url: 'group', method: 'POST' }),
      invalidatesTags: invalidatesTags({ [TAG.GROUP]: LIST_ID }),
    }),
    deleteGroup: build.mutation({
      queryFn: queryFn({ url: 'group', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.GROUP),
    }),
    assignGroupToUser: build.mutation({
      queryFn: queryFn({
        url: ({ group, user_id }) => `group/${group}/user/${user_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.USER]: (_, { user_id }) => [user_id, LIST_ID],
      }),
    }),
    revokeGroupFromUser: build.mutation({
      queryFn: queryFn({
        url: ({ group, user_id }) => `group/${group}/user/${user_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.USER]: (_, { user_id }) => [user_id, LIST_ID],
      }),
    }),
    userGroups: build.query({
      queryFn: queryFn({
        url: 'group/user',
        transformResponse: transformResponse(
          _pipe(
            _get('groups'),
            _map(setIdFrom('group')),
            _map(setNameFrom('group')),
          ),
        ),
      }),
      providesTags: providesTags(TAG.GROUP),
    }),
    myGroups: build.query({
      queryFn: queryFn({
        url: 'group/me',
        transformResponse: transformResponse(
          _pipe(
            _get('groups'),
            _map(setIdFrom('group')),
            _map(setNameFrom('group')),
          ),
        ),
      }),
      providesTags: providesTags(TAG.GROUP),
    }),
    addEntityToGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, entity_id }) => `group/${group}/entity/${entity_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.DIVISION]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.SITE]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.SECTION]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.STREAM]: (_, { entity_id }) => [entity_id, LIST_ID],
      }),
    }),
    removeEntityFromGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, entity_id }) => `group/${group}/entity/${entity_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.DIVISION]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.SITE]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.SECTION]: (_, { entity_id }) => [entity_id, LIST_ID],
        [TAG.STREAM]: (_, { entity_id }) => [entity_id, LIST_ID],
      }),
    }),
    addDivisionToGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, division_id }) => `group/${group}/entity/${division_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.DIVISION]: (_, { division_id }) => [division_id, LIST_ID],
      }),
    }),
    removeDivisionFromGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, division_id }) => `group/${group}/entity/${division_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.DIVISION]: (_, { division_id }) => [division_id, LIST_ID],
      }),
    }),
    addSiteToGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, site_id }) => `group/${group}/entity/${site_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.SITE]: (_, { site_id }) => [site_id, LIST_ID],
      }),
    }),
    removeSiteFromGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, site_id }) => `group/${group}/entity/${site_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.SITE]: (_, { site_id }) => [site_id, LIST_ID],
      }),
    }),
    addSectionToGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, section_id }) => `group/${group}/entity/${section_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.SECTION]: (_, { section_id }) => [section_id, LIST_ID],
      }),
    }),
    removeSectionFromGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, section_id }) => `group/${group}/entity/${section_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.SECTION]: (_, { section_id }) => [section_id, LIST_ID],
      }),
    }),
    addStreamToGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, stream_id }) => `group/${group}/entity/${stream_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.STREAM]: (_, { stream_id }) => [stream_id, LIST_ID],
      }),
    }),
    removeStreamFromGroup: build.mutation({
      queryFn: queryFn({
        url: ({ group, stream_id }) => `group/${group}/entity/${stream_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.GROUP]: (_, { group }) => [group, LIST_ID],
        [TAG.STREAM]: (_, { stream_id }) => [stream_id, LIST_ID],
      }),
    }),
    entityGroups: build.query({
      queryFn: queryFn({
        url: 'group/entity',
        transformResponse: transformResponse(
          _pipe(
            _get('groups'),
            _map(setIdFrom('group')),
            _map(setNameFrom('group')),
          ),
        ),
      }),
      providesTags: providesTags(TAG.GROUP),
    }),
    entitiesWithoutGroups: build.query({
      queryFn: queryFn({ url: 'group/orphaned' }),
      providesTags: providesTags({
        [TAG.DIVISION]: undefined,
        [TAG.SITE]: undefined,
        [TAG.SECTION]: undefined,
        [TAG.STREAM]: undefined,
      }),
    }),

    // Media Servers
    serversLayout: build.query({
      queryFn: queryFn({
        url: 'media/layout',
        responseHandler: (response) =>
          response.ok ? response.blob() : response.json(),
        transformResponse: transformResponse((response) =>
          URL.createObjectURL(response),
        ),
      }),
      providesTags: providesTags([TAG.SERVER, TAG.SERVER_LAYOUT]),
      onQueryStarted: async (arg, { getState, queryFulfilled }) => {
        // Object URLs need to be revoked when we're done with them, or we'll
        // have a memory leak.
        try {
          const {
            data: previousObjectUrl,
          } = streamManager.endpoints.serversLayout.select(arg)(getState())
          await queryFulfilled
          URL.revokeObjectURL(previousObjectUrl)
        } catch (error) {
          if (error?.error) {
            // Do nothing, this is the result of a failed query, we don't need to do anything
          } else {
            // Don't know what happened here, re-throw it
            throw error
          }
        }
      },
    }),
    servers: build.query({
      queryFn: queryFn({
        url: 'media',
        transformResponse: transformResponse(_get('servers')),
      }),
      providesTags: providesTags(TAG.SERVER),
    }),
    createServer: build.mutation({
      queryFn: queryFn({ url: 'media', method: 'POST' }),
      invalidatesTags: invalidatesTags({ [TAG.SERVER]: LIST_ID }),
    }),
    server: build.query({
      queryFn: queryFn({ url: 'media' }),
      providesTags: providesTags(TAG.SERVER),
    }),
    deleteServer: build.mutation({
      queryFn: queryFn({ url: 'media', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.SERVER),
    }),
    updateServer: build.mutation({
      queryFn: queryFn({ url: 'media', method: 'PATCH' }),
      invalidatesTags: invalidatesTags(TAG.SERVER),
    }),
    serverStreams: build.query({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/stream`,
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),
    sshTunnel: build.query({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/ssh_tunnel`,
        transformResponse: transformResponse(_get('ssh_enabled')),
      }),
      providesTags: providesTags(TAG.SERVER),
    }),
    enableSshTunnel: build.mutation({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/ssh_tunnel`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags(TAG.SERVER),
    }),
    disableSshTunnel: build.mutation({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/ssh_tunnel`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags(TAG.SERVER),
    }),
    ...(() => {
      const configureServerMutation = queryFn({
        url: ({ id }) => `media/${id}/configure`,
        method: 'POST',
      })

      const downstreamServersQuery = queryFn({
        url: ({ id }) => `media/${id}/downstream`,
        transformResponse: transformResponse(_get('servers')),
      })

      return {
        configureServer: build.mutation({
          queryFn: configureServerMutation,
          invalidatesTags: invalidatesTags(TAG.SERVER),
        }),
        configureServerAndDownstream: build.mutation({
          queryFn: async (arg, ...rest) => {
            const args = [].concat(arg) // Make sure it's an array even if we were given just one element

            const firstResponse = await configureServerMutation(args, ...rest)

            if (firstResponse.error) {
              return firstResponse
            }

            const downstreamServers = await downstreamServersQuery(
              args,
              ...rest,
            )

            if (downstreamServers.error) {
              return downstreamServers
            }

            const downstreamServerIds = downstreamServers.data.flatMap(
              ({ value }) => value.map(({ id }) => id),
            )

            const uniqueDownstreamServerIds = [...new Set(downstreamServerIds)]

            const downstreamResponses = await configureServerMutation(
              uniqueDownstreamServerIds,
              ...rest,
            )

            return {
              data: firstResponse.data.concat(
                downstreamServers.data,
                downstreamResponses.data,
              ),
              meta: firstResponse.meta.concat(
                downstreamServers.meta,
                downstreamResponses.meta,
              ),
            }
          },
          invalidatesTags: invalidatesTags(TAG.SERVER),
        }),
        upstreamServers: build.query({
          queryFn: queryFn({
            url: ({ id }) => `media/${id}/upstream`,
            transformResponse: transformResponse(_get('servers')),
          }),
          providesTags: providesTags(TAG.SERVER),
        }),
        downstreamServers: build.query({
          queryFn: downstreamServersQuery,
          providesTags: providesTags(TAG.SERVER),
        }),
      }
    })(),
    linkUpstreamServer: build.mutation({
      queryFn: queryFn({
        url: ({ id, upstream_id }) => `media/${id}/link/${upstream_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.SERVER]: (_, { id, upstream_id }) => [id, upstream_id],
        [TAG.SERVER_LAYOUT]: () => undefined,
      }),
    }),
    unlinkUpstreamServer: build.mutation({
      queryFn: queryFn({
        url: ({ id, upstream_id }) => `media/${id}/unlink/${upstream_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.SERVER]: (_, { id, upstream_id }) => [id, upstream_id],
        [TAG.SERVER_LAYOUT]: () => undefined,
      }),
    }),
    linkServerToDevice: build.mutation({
      queryFn: queryFn({
        url: ({ media_server_id, device_id }) =>
          `media/${media_server_id}/device/${device_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.SERVER]: (_, { media_server_id }) => media_server_id,
        [TAG.DEVICE_MAPPING]: LIST_ID,
      }),
    }),
    serverDevice: build.query({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/device`,
        transformResponse: transformResponse(_get('device_id')),
      }),
      providesTags: providesTags(TAG.DEVICE),
    }),
    unlinkServerFromDevice: build.mutation({
      queryFn: queryFn({
        url: ({ id }) => `media/${id}/device`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.SERVER]: (_, { id }) => id,
        [TAG.DEVICE_MAPPING]: LIST_ID,
      }),
    }),
    serverStatus: build.query({
      queryFn: queryFn({ url: ({ id }) => `media/${id}/status` }),
      providesTags: providesTags(TAG.SERVER),
    }),

    // Sections
    sections: build.query({
      queryFn: queryFn({
        url: 'section',
        transformResponse: transformResponse(_get('sections')),
      }),
      providesTags: providesTags(TAG.SECTION),
    }),
    section: build.query({
      queryFn: queryFn({ url: 'section' }),
      providesTags: providesTags(TAG.SECTION),
    }),
    createSection: build.mutation({
      queryFn: queryFn({
        url: ({ group }) => `section/${group}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.SECTION]: LIST_ID }),
    }),
    updateSection: build.mutation({
      queryFn: queryFn({ url: 'section', method: 'PATCH' }),
      invalidatesTags: invalidatesTags(TAG.SECTION),
    }),
    deleteSection: build.mutation({
      queryFn: queryFn({ url: 'section', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.SECTION),
    }),
    sectionStreams: build.query({
      queryFn: queryFn({
        url: ({ id }) => `section/${id}/stream`,
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),

    // Sites
    sites: build.query({
      queryFn: queryFn({
        url: 'site',
        transformResponse: transformResponse(_get('sites')),
      }),
      providesTags: providesTags(TAG.SITE),
    }),
    site: build.query({
      queryFn: queryFn({ url: 'site' }),
      providesTags: providesTags(TAG.SITE),
    }),
    createSite: build.mutation({
      queryFn: queryFn({
        url: ({ group }) => `site/${group}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.SITE]: LIST_ID }),
    }),
    updateSite: build.mutation({
      queryFn: queryFn({ url: 'site', method: 'PATCH' }),
      invalidatesTags: invalidatesTags(TAG.SITE),
    }),
    deleteSite: build.mutation({
      queryFn: queryFn({ url: 'site', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.SITE),
    }),

    // Streams
    createStream: build.mutation({
      queryFn: queryFn({
        url: ({ group }) => `stream/${group}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.STREAM]: LIST_ID }),
    }),
    createStreams: build.mutation({
      queryFn: queryFn({
        url: ({ group }) => `stream/bulk_upload/${group}`,
        method: 'POST',
        transformResponse: transformResponse(_get('streams')),
      }),
      invalidatesTags: invalidatesTags({
        [TAG.STREAM]: _identity,
      }),
    }),
    streams: build.query({
      queryFn: queryFn({
        url: 'stream',
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),
    recommendedStreams: build.query({
      queryFn: queryFn({
        url: 'stream/recommended',
        params: {
          limit: 15,
          inc_service_acc: false,
        },
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM),
    }),
    stream: build.query({
      queryFn: queryFn({ url: 'stream' }),
      providesTags: providesTags(TAG.STREAM),
    }),
    streamViewers: build.query({
      queryFn: queryFn({
        url: ({ id }) => `stream/${id}/viewers`,
        transformResponse: transformResponse(
          _pipe(_get('users'), _map(setIdFrom('user_id'))),
        ),
      }),
    }),
    deleteStream: build.mutation({
      queryFn: queryFn({ url: 'stream', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.STREAM),
    }),
    updateStream: build.mutation({
      queryFn: queryFn({ url: 'stream', method: 'PATCH' }),
      invalidatesTags: invalidatesTags(TAG.STREAM),
    }),
    streamUrl: build.query({
      // without the dvr=1, we get the super compact live .../playlist.m3u8 with
      // only three 5-second fragments that gets automatically reloaded when we
      // reach the end. however, this is missing the frag.programDateTime metadata
      // which we use to calculate exact time of current frame, so we rather
      // request ../playlist_dvr.m3u8 which we can either use as live by adding
      // timeshift param, or as history by adding start + duration params.
      // TODO: Could it be that our nimble can be reconfigured to give real-time
      // playlist (playlist.m3u8) that will also contain the
      // EXT-X-PROGRAM-DATE-TIME tag?
      queryFn: queryFn({
        url: ({ id, vod }) => `stream/${id}/url?dvr=${vod ? 0 : 1}`,
        transformResponse: transformResponse(_get('url')),
      }),
      providesTags: providesTags(TAG.STREAM_URL),
    }),
    updateStreamSection: build.mutation({
      queryFn: queryFn({
        url: ({ stream_id, section_id }) =>
          `stream/${stream_id}/move/${section_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.STREAM]: (_, { stream_id }) => stream_id,
        [TAG.SECTION]: (_, { section_id }) => section_id,
      }),
    }),
    streamStatus: build.query({
      queryFn: queryFn({ url: ({ id }) => `stream/${id}/status` }),
      providesTags: providesTags(TAG.STREAM_STATUS),
    }),
    streamDownload: build.query({
      queryFn: queryFn({
        url: ({ id, start, end, token }) =>
          `stream/download/${id}/${start}/${end}?token=${token}`,
      }),
    }),
    streamThumbnail: build.query({
      queryFn: async (arg, api, extraOptions, baseQuery) => {
        // Subtract THUMBNAIL_CACHE_SECONDS to go a bit back into history
        // Date.now returns epoch milliseconds, so divide by 1000 to get secs
        const epoch_secs = Math.floor(+Date.now() / 1000) - THUMBNAIL_CACHE_SECONDS
        // Round up to the next THUMBNAIL_CACHE_SECONDS interval.
        // Example, 08:08:40 will be shifted to 08:03:40 then rounded up to 08:05:00.
        const closest = Math.ceil(epoch_secs / THUMBNAIL_CACHE_SECONDS) * THUMBNAIL_CACHE_SECONDS

        const res = await queryFn({
          url: ({ id, media_server_id, token }) =>
            `media/thumb/${media_server_id}/${id}/dvr_thumbnail_${closest}.mp4?token=${token}`,
          responseHandler: (response) =>
            response.ok ? response.blob() : response.json(),
          transformResponse: transformResponse((response) =>
            URL.createObjectURL(response),
          ),
        })(arg, api, { ...extraOptions, maxRetries: 0 }, baseQuery)

        if (res.data) return res

        // Re-attempt the request, this time not asking for the closest
        // thumbnail.
        return queryFn({
          url: ({ id, media_server_id, token }) =>
            `media/thumb/${media_server_id}/${id}/dvr_thumbnail.mp4?token=${token}`,
          responseHandler: (response) =>
            response.ok ? response.blob() : response.json(),
          transformResponse: transformResponse((response) =>
            URL.createObjectURL(response),
          ),
        })(arg, api, extraOptions, baseQuery)
      },
      providesTags: providesTags(TAG.STREAM_THUMBNAIL),
      onQueryStarted: async (arg, { getState, queryFulfilled }) => {
        // Object URLs need to be revoked when we're done with them, or we'll
        // have a memory leak.
        try {
          const {
            data: previousObjectUrl,
          } = streamManager.endpoints.streamThumbnail.select(arg)(getState())
          await queryFulfilled
          URL.revokeObjectURL(previousObjectUrl)
        } catch (error) {
          if (error?.error) {
            // Do nothing, this is the result of a failed query, we don't need to do anything
          } else {
            // Don't know what happened here, re-throw it
            throw error
          }
        }
      },
    }),
    streamThumbnailToken: (() => {
      const _queryFn = queryFn({ url: 'stream/thumbnail/token' })
      return build.query({
        queryFn: _queryFn,
        onCacheEntryAdded: refreshToken(_queryFn),
        providesTags: providesTags(TAG.STREAM_THUMBNAIL_TOKEN),
      })
    })(),
    streamToken: (() => {
      const _queryFn = queryFn({
        url: ({ id, method }) => `stream/${id}/token?method=${method}`,
      })
      return build.query({
        queryFn: _queryFn,
        onCacheEntryAdded: refreshToken(_queryFn),
        providesTags: providesTags({
          [TAG.STREAM_TOKEN]: (_, { id, method }) => `${method}/${id}`,
        }),
      })
    })(),
    streamTrack: build.query({
      queryFn: queryFn({
        url: `stream/track`,
        transformResponse: transformResponse(
          _pipe(
            _get('track'),
            _map(_get('location')),
            _map(JSON.parse),
            _map(_get('coordinates')),
            (coordinates) => ({
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates,
              },
            }),
          ),
        ),
      }),
      providesTags: providesTags(TAG.STREAM_TRACK),
    }),

    // Videos
    uploadVideoStatus: build.query({
      queryFn: () => ({
        data: {
          progress: 0,
          status: null,
        },
      }),
    }),
    uploadVideo: (() => {
      const videoFileUrls = new Map()
      return build.mutation({
        queryFn: queryFn({
          url: 'content/upload',
          method: 'POST',
          transformResponse: transformResponse(setIdFrom('stream_id')),
        }),
        invalidatesTags: invalidatesTags({
          [TAG.VIDEO_UPLOAD_STATUS]: LIST_ID,
          [TAG.STREAM]: LIST_ID,
        }),
        onQueryStarted: async (
          { video, body, ...arg },
          { dispatch, queryFulfilled, requestId },
        ) => {
          const updateQueryData = (update) =>
            dispatch(
              streamManager.util.updateQueryData(
                'uploadVideoStatus',
                requestId,
                update,
              ),
            )

          const videoFileUrl = URL.createObjectURL(video)
          videoFileUrls.set(requestId, videoFileUrl)

          let data

          try {
            const res = await queryFulfilled
            data = res.data
          } catch {
            return
          }

          updateQueryData(() => ({
            ...data,
            ...arg,
            ...body,
            videoFileUrl,
            progress: 0,
            status: null,
          }))

          await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()

            xhr.open('PUT', data.url, true)
            xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob')

            const handleError = () => {
              updateQueryData((draft) => {
                draft.status = 'rejected'
              })
              reject(xhr.response)
            }

            xhr.upload.addEventListener(
              'loadstart',
              () =>
                updateQueryData((draft) => {
                  draft.status = 'pending'
                }),
              false,
            )
            xhr.upload.addEventListener(
              'progress',
              ({ lengthComputable, loaded, total }) =>
                updateQueryData((draft) => {
                  draft.progress = lengthComputable ? loaded / total : null
                }),
              false,
            )
            xhr.upload.addEventListener('error', handleError, false)
            xhr.upload.addEventListener('abort', handleError, false)
            xhr.upload.addEventListener('timeout', handleError, false)
            xhr.upload.addEventListener(
              'load',
              () => {
                updateQueryData((draft) => {
                  draft.progress = 1
                  draft.status = 'fulfilled'
                })
                streamManager.util.invalidateTags([
                  { type: TAG.VIDEO_UPLOAD_STATUS, id: data.stream_id },
                  { type: TAG.VIDEO_UPLOAD_STATUS, id: LIST_ID },
                  { type: TAG.STREAM, id: data.stream_id },
                  { type: TAG.STREAM, id: LIST_ID },
                ])
                resolve()
              },
              false,
            )

            xhr.send(video)
          })
        },
        onCacheEntryAdded: async (_, { requestId, cacheEntryRemoved }) => {
          await cacheEntryRemoved
          URL.revokeObjectURL(videoFileUrls.get(requestId))
          videoFileUrls.delete(requestId)
        },
      })
    })(),
    uploadedVideosStatus: build.query({
      queryFn: queryFn({
        url: 'content/upload/status',
        transformResponse: transformResponse(
          _pipe(_get('status'), _map(setIdFrom('stream_id'))),
        ),
      }),
      providesTags: providesTags(TAG.VIDEO_UPLOAD_STATUS),
    }),
    uploadedVideoStatus: build.query({
      queryFn: queryFn({
        url: 'content/upload/status',
        transformResponse: transformResponse(
          _pipe(_get('status'), setIdFrom('stream_id')),
        ),
      }),
      providesTags: providesTags(TAG.VIDEO_UPLOAD_STATUS),
    }),
    updateUploadedVideo: build.mutation({
      queryFn: queryFn({
        url: 'content/upload',
        method: 'PATCH',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.VIDEO_UPLOAD_STATUS]: (_, { id }) => id,
        [TAG.STREAM]: (_, { id }) => id,
      }),
    }),
    deleteUploadedVideo: build.mutation({
      queryFn: queryFn({
        url: 'content/upload/state',
        body: {
          state: 'delete',
          status: 'Deleting',
        },
        method: 'PATCH',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.VIDEO_UPLOAD_STATUS]: (_, { id }) => id,
        [TAG.STREAM]: (_, { id }) => id,
      }),
    }),

    // Tags
    tags: build.query({
      queryFn: queryFn({
        url: 'tag',
        transformResponse: transformResponse(
          _pipe(_get('tags'), _map(setIdFrom('tag')), _map(setNameFrom('tag'))),
        ),
      }),
      providesTags: providesTags(TAG.TAG),
    }),
    createTag: build.mutation({
      queryFn: queryFn({
        url: 'tag',
        method: 'POST',
        // the create tag mutation doesn't return any data, but we can stub it
        // based on the input
        transformResponse: transformResponse((_, meta) =>
          meta.request.json().then(({ tag, ...rest }) => ({
            tag,
            id: tag,
            name: tag,
            ...rest,
          })),
        ),
      }),
      invalidatesTags: invalidatesTags({ [TAG.TAG]: LIST_ID }),
    }),
    deleteTag: build.mutation({
      queryFn: queryFn({ url: 'tag', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.TAG),
    }),
    streamTags: build.query({
      queryFn: queryFn({
        url: ({ id }) => `stream/${id}/tags`,
        transformResponse: transformResponse(
          _pipe(_get('tags'), _map(setIdFrom('tag')), _map(setNameFrom('tag'))),
        ),
      }),
      providesTags: providesTags({
        [TAG.STREAM]: (_, { id } = {}) => id,
      }),
    }),
    addTagToStream: build.mutation({
      queryFn: queryFn({
        url: ({ tag_id, stream_id }) => `stream/${stream_id}/tag/${tag_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.STREAM]: (_, { stream_id }) => stream_id,
      }),
    }),
    removeTagFromStream: build.mutation({
      queryFn: queryFn({
        url: ({ tag_id, stream_id }) => `stream/${stream_id}/tag/${tag_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: invalidatesTags({
        [TAG.STREAM]: (_, { stream_id }) => stream_id,
      }),
    }),

    // Users
    users: build.query({
      queryFn: queryFn({
        url: 'user',
        transformResponse: transformResponse(_get('users')),
      }),
      providesTags: providesTags(TAG.USER),
    }),
    me: build.query({
      queryFn: queryFn({ url: 'user/me' }),
      providesTags: providesTags(TAG.USER),
    }),
    registerMe: build.mutation({
      queryFn: queryFn({ url: 'user/me', method: 'POST' }),
      invalidatesTags: invalidatesTags(TAG.USER),
    }),
    acknowledgeDisclaimer: build.mutation({
      queryFn: queryFn({ url: 'user/acknowledge_disclaimer', method: 'POST' }),
      invalidatesTags: invalidatesTags({
        [TAG.DISCLAIMER_LOG]: LIST_ID,
        // This invalidates all USER queries
        [TAG.USER]: () => undefined
      }),
    }),
    disclaimerLog: build.query({
      queryFn: queryFn({
        url: 'user/disclaimer_log',
        transformResponse: transformResponse(_get('events')),
      }),
      providesTags: providesTags(TAG.DISCLAIMER_LOG),
    }),
    setServiceAccount: build.mutation({
      queryFn: queryFn({
        url: 'user/service_account',
        method: ({ value }) => (value ? 'POST' : 'DELETE'),
      }),
      invalidatesTags: invalidatesTags(TAG.USER),
    }),
    deleteUser: build.mutation({
      queryFn: queryFn({ url: 'user', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.USER),
    }),
    searchNewUser: build.query({
      queryFn: queryFn({
        url: ({ query, byEmail }) =>
          `user/search/new?search=${query}&by=${byEmail ? 'email' : 'name'}`,
        transformResponse: transformResponse(_get('users')),
      }),
    }),
    registerUser: build.mutation({
      queryFn: queryFn({
        url: ({ provider_id }) => `user/register/${provider_id}`,
        method: 'POST',
      }),
      invalidatesTags: invalidatesTags({ [TAG.USER]: LIST_ID }),
    }),
    userViewedStreams: build.query({
      queryFn: queryFn({
        url: ({ id }) => `user/${id}/viewed`,
        transformResponse: transformResponse(
          _pipe(_get('streams'), _map(setIdFrom('stream_id'))),
        ),
      }),
    }),

    // Analytics
    topUsers: build.query({
      queryFn: queryFn({
        url: 'analytics/top_users',
        transformResponse: transformResponse(_get('users')),
      }),
      providesTags: providesTags(TAG.USER_ANALYTICS),
    }),
    topStreams: build.query({
      queryFn: queryFn({
        url: 'analytics/top_streams',
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags(TAG.STREAM_ANALYTICS),
    }),
    topStreamsWithUsers: build.query({
      queryFn: queryFn({
        url: 'analytics/top',
        transformResponse: transformResponse(_get('streams')),
      }),
      providesTags: providesTags([TAG.STREAM_ANALYTICS, TAG.USER_ANALYTICS]),
    }),

    // Monitor
    monitorMediaServers: build.query({
      queryFn: queryFn({
        url: 'monitor/mediaservers'
      }),
      providesTags: providesTags([TAG.MONITOR_SERVERS]),
    }),
    monitorStreams: build.query({
      queryFn: queryFn({ url: 'monitor/streams' }),
      providesTags: providesTags([TAG.MONITOR_STREAMS]),
    }),
    monitorServices: build.query({
      queryFn: queryFn({ url: 'monitor/services' }),
      providesTags: providesTags([TAG.MONITOR_SERVICES]),
    }),

    //Reports
    report: build.query({
      queryFn: queryFn({
        url: 'report',
        params: { fmt: 'json' }, // This is just a default
        transformResponse: transformResponse(_get('events')),
      }),
    }),

    // Favourites
    ...(() => {
      const favouriteStreamsQuery = queryFn({
        url: 'favourite/streams',
        transformResponse: transformResponse(_get('streams')),
      })

      const markStreamFavouriteMutation = queryFn({
        url: 'favourite/streams',
        method: 'POST',
      })

      return {
        favouriteStreams: build.query({
          queryFn: favouriteStreamsQuery,
          providesTags: providesTags([TAG.FAVOURITE, TAG.STREAM]),
        }),
        isFavouriteStream: build.query({
          queryFn: queryFn({ url: 'favourite/streams' }),
          providesTags: (result, error, arg) => {
            // This API call returns 404 when the stream is _not_ a favourite stream
            if (error?.status === 404) {
              return tagsFromResultsOrArgs(
                TAG.FAVOURITE,
                undefined,
                result,
                arg,
              )
            }
            return providesTags(TAG.FAVOURITE)(result, error, arg)
          },
        }),
        // TODO: ideally this restriction is implemented on the backend
        markStreamFavourite: build.mutation({
          queryFn: async (arg, ...rest) => {
            const favouriteStreamsResponse = await favouriteStreamsQuery(
              undefined,
              ...rest,
            )

            if (favouriteStreamsResponse.error) {
              return favouriteStreamsResponse
            }

            const favouriteStreams = favouriteStreamsResponse.data.length

            if (favouriteStreams >= MAX_FAVOURITE_STREAMS) {
              return {
                error: {
                  data: {
                    detail: `You cannot have more than ${MAX_FAVOURITE_STREAMS} streams on your dashboard, you already have ${favouriteStreams}. Remove ${favouriteStreams - MAX_FAVOURITE_STREAMS + 1
                      } from your dashboard before adding this one.`,
                  },
                },
              }
            }

            return markStreamFavouriteMutation(arg, ...rest)
          },
          invalidatesTags: (...args) => {
            const tags = invalidatesTags([TAG.FAVOURITE, TAG.STREAM])(...args)
            return tags.concat({ type: TAG.FAVOURITE, id: LIST_ID })
          },
        }),
        unmarkStreamFavourite: build.mutation({
          queryFn: queryFn({ url: 'favourite/streams', method: 'DELETE' }),
          invalidatesTags: (...args) => {
            const tags = invalidatesTags([TAG.FAVOURITE, TAG.STREAM])(...args)
            return tags.concat({ type: TAG.FAVOURITE, id: LIST_ID })
          },
        }),
      }
    })(),

    // Client
    credentials: build.query({
      queryFn: queryFn({ url: 'client_id' }),
      providesTags: providesTags(TAG.CREDENTIAL),
    }),
    createCredentials: build.mutation({
      queryFn: queryFn({ url: 'client_id', method: 'POST' }),
      invalidatesTags: invalidatesTags({ [TAG.CREDENTIAL]: LIST_ID }),
    }),
    deleteCredentials: build.mutation({
      queryFn: queryFn({ url: 'client_id', method: 'DELETE' }),
      invalidatesTags: invalidatesTags(TAG.CREDENTIAL),
    }),

    refetchErroredQueries: build.mutation({
      queryFn: () => ({ data: null }),
      invalidatesTags: [TAG.UNKNOWN_ERROR],
    }),
  }),
})

export default streamManager

// Export the hooks for easy access
export const {
  // Access
  useUserDivisionsQuery,
  useUserSitesQuery,
  useUserSectionsQuery,
  useUserStreamsQuery,
  useEntityAccessQuery,
  useUserEntityLinkingGroupsQuery,

  // Administration
  useVersionQuery,
  useStatusQuery,
  useAuthStreamByTokenMutation,

  // Device Manager
  useDevicesQuery,
  useDeviceMappingsQuery,
  useUnassignedDevicesQuery,

  // Divisions
  useDivisionsQuery,
  useDivisionQuery,
  useCreateDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,

  // Events
  useTopicsQuery,
  useCreateTopicMutation,
  useTopicAcksQuery,
  useAckTopicMutation,
  useTopicQuery,
  useTopicEventsQuery,
  useCreateTopicEventsMutation,
  useAcksQuery,
  useEventsAcksQuery,
  useEventAcksQuery,
  useAckEventMutation,
  useEventStreamsQuery,
  useStreamEventsQuery,
  useEventsQuery,
  useEventQuery,

  // Groups
  useGroupsQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useAssignGroupToUserMutation,
  useRevokeGroupFromUserMutation,
  useUserGroupsQuery,
  useMyGroupsQuery,
  useAddEntityToGroupMutation,
  useRemoveEntityFromGroupMutation,
  useAddDivisionToGroupMutation,
  useRemoveDivisionFromGroupMutation,
  useAddSiteToGroupMutation,
  useRemoveSiteFromGroupMutation,
  useAddSectionToGroupMutation,
  useRemoveSectionFromGroupMutation,
  useAddStreamToGroupMutation,
  useRemoveStreamFromGroupMutation,
  useEntityGroupsQuery,
  useEntitiesWithoutGroupsQuery,

  // Media Servers
  useServersLayoutQuery,
  useServersQuery,
  useCreateServerMutation,
  useServerQuery,
  useDeleteServerMutation,
  useUpdateServerMutation,
  useServerStreamsQuery,
  useSshTunnelQuery,
  useEnableSshTunnelMutation,
  useDisableSshTunnelMutation,
  useConfigureServerMutation,
  useConfigureServerAndDownstreamMutation,
  useUpstreamServersQuery,
  useDownstreamServersQuery,
  useLinkUpstreamServerMutation,
  useUnlinkUpstreamServerMutation,
  useLinkServerToDeviceMutation,
  useServerDeviceQuery,
  useUnlinkServerFromDeviceMutation,
  useServerStatusQuery,

  // Sections
  useSectionsQuery,
  useSectionQuery,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
  useSectionStreamsQuery,

  // Sites
  useSitesQuery,
  useSiteQuery,
  useCreateSiteMutation,
  useUpdateSiteMutation,
  useDeleteSiteMutation,

  // Streams
  useCreateStreamMutation,
  useCreateStreamsMutation,
  // We wrap these below
  // useStreamsQuery,
  // useRecommendedStreamsQuery,
  // useStreamQuery,
  useStreamViewersQuery,
  useDeleteStreamMutation,
  useUpdateStreamMutation,
  useStreamUrlQuery,
  useUpdateStreamSectionMutation,
  useStreamStatusQuery,
  useStreamTokenQuery,
  useStreamTrackQuery,

  // Videos
  // useUploadVideoMutation,
  useUploadedVideosStatusQuery,
  useUploadedVideoStatusQuery,
  useUpdateUploadedVideoMutation,
  useDeleteUploadedVideoMutation,

  // Tags
  useTagsQuery,
  useCreateTagMutation,
  useDeleteTagMutation,
  useStreamTagsQuery,
  useAddTagToStreamMutation,
  useRemoveTagFromStreamMutation,

  // Users
  useUsersQuery,
  useMeQuery,
  useRegisterMeMutation,
  useAcknowledgeDisclaimerMutation,
  useDisclaimerLogQuery,
  useSetServiceAccountMutation,
  useDeleteUserMutation,
  useSearchNewUserQuery,
  useRegisterUserMutation,
  useUserViewedStreamsQuery,

  // Analytics
  useTopUsersQuery,
  useTopStreamsQuery,
  useTopStreamsWithUsersQuery,

  // Monitor
  useMonitorMediaServersQuery,
  useMonitorStreamsQuery,
  useMonitorServicesQuery,

  // Reports
  useReportQuery,

  // Favourites
  // We wrap this one below
  // useFavouriteStreamsQuery,
  useIsFavouriteStreamQuery,
  useMarkStreamFavouriteMutation,
  useUnmarkStreamFavouriteMutation,

  // Client
  useCredentialsQuery,
  useCreateCredentialsMutation,
  useDeleteCredentialsMutation,

  useRefetchErroredQueriesMutation,
} = streamManager

export const useLazyQuery = (useQuery) =>
  function useWrappedQuery(_arg, options) {
    const [queryCacheNonce, setQueryCacheNonce] = useState(() => uuid(), [])
    const [skip, setSkip] = useState(true)

    const trigger = useCallback(() => {
      setQueryCacheNonce(() => uuid())
      setSkip(false)
    }, [])

    const arg = useMemo(() => {
      if (Array.isArray(_arg)) {
        return _arg.map((arg) => ({
          ...normalizeArg(arg),
          queryCacheNonce,
        }))
      }

      return {
        ...normalizeArg(_arg),
        queryCacheNonce,
      }
    }, [_arg, queryCacheNonce])

    const { refetch: _, ...rest } = useQuery(arg, {
      ...options,
      pollingInterval: 0,
      refetchOnFocus: false,
      refetchOnReconnect: false,
      refetchOnMountOrArgChange: false,
      skip: options?.skip ?? skip,
    })

    useEffect(() => {
      if (!skip) {
        setSkip(true)
      }
    }, [skip])

    return [trigger, rest]
  }

export const useDownloadCsv = (useQuery) =>
  function useWrappedQuery(arg = {}, options) {
    return useLazyQuery(useQuery)(
      {
        ...arg,
        params: {
          limit: undefined,
          ...arg.params,
          fmt: 'csv',
        },
        download: true,
      },
      options,
    )
  }

export const mergeQueryResultsWith = (mergeData) =>
  function useMergeQueryResults(...args) {
    const skipped = args.some((arg) => arg === skipToken)
    const datas = args.map(_get('data'))
    const errors = args.map(_get('error'))
    const isUninitializeds = args.map(_get('isUninitialized'))
    const isLoadings = args.map(_get('isLoading'))
    const isFetchings = args.map(_get('isFetching'))
    const isSuccesses = args.map(_get('isSuccess'))
    const isErrors = args.map(_get('isError'))
    const refetches = args.map(_get('refetch'))

    const data = useMemo(() => {
      if (skipped) return datas[0]
      const ret = datas.reduce(mergeData)
      if (ret != null && datas[0]?.count != null) ret.count = datas[0].count
      return ret
    }, datas.concat(skipped)) // eslint-disable-line react-hooks/exhaustive-deps

    const error = useMemo(
      () =>
        skipped
          ? errors[0]
          : _zipWith(
            (isError, error) => ({ isError, error }),
            isErrors,
            errors,
          ).reduce(
            (acc, next) => acc || (next.isError && next.error) || undefined,
            undefined,
          ),
      isErrors.concat([errors, skipped]), // eslint-disable-line react-hooks/exhaustive-deps
    )

    const isUninitialized = useMemo(
      () =>
        skipped
          ? isUninitializeds[0]
          : isUninitializeds.reduce((acc, next) => acc && next),
      isUninitializeds.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )
    const isLoading = useMemo(
      () =>
        skipped ? isLoadings[0] : isLoadings.reduce((acc, next) => acc || next),
      isLoadings.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )
    const isFetching = useMemo(
      () =>
        skipped
          ? isFetchings[0]
          : isFetchings.reduce((acc, next) => acc || next),
      isFetchings.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )
    const isSuccess = useMemo(
      () =>
        skipped
          ? isSuccesses[0]
          : isSuccesses.reduce((acc, next) => acc || next),
      isSuccesses.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )
    const isError = useMemo(
      () =>
        skipped ? isErrors[0] : isErrors.reduce((acc, next) => acc || next),
      isErrors.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )

    const refetch = useCallback(
      (...args) =>
        skipped
          ? refetches[0]
          : refetches.forEach((refetch) => refetch()),
      refetches.concat(skipped), // eslint-disable-line react-hooks/exhaustive-deps
    )

    return {
      data,
      error,
      isUninitialized,
      isLoading,
      isFetching,
      isSuccess,
      isError,
      refetch,
    }
  }

export const useStreamThumbnailQuery = (arg, options) => {
  const streamThumbnailTokenQuery = streamManager.useStreamThumbnailTokenQuery(
    undefined,
    { skip: options?.skip },
  )
  const streamThumbnailQuery = streamManager.useStreamThumbnailQuery(
    { ...arg, token: streamThumbnailTokenQuery.data?.token },
    { ...options, skip: !streamThumbnailTokenQuery.data || options?.skip },
  )
  return mergeQueryResultsWith(
    (first) => first,
  )(
    streamThumbnailQuery,
    streamThumbnailTokenQuery,
  )
}

export const useStreamDownloadQuery = ({ id, start, end }, options) => {
  const streamTokenQuery = useStreamTokenQuery(
    { id, method: 'download' },
    { skip: options?.skip },
  )
  return useLazyQuery(
    streamManager.useStreamDownloadQuery,
  )(
    { id, start, end, download: true, token: streamTokenQuery.data?.token },
    { ...options, skip: !streamTokenQuery.data || options?.skip },
  )
}

export const querySetAtProp = function useQuerySetAtProp(prop, query) {
  return useMemo(() => ({
    ...query,
    data: { [prop]: query.data },
  }),
    [prop, query]
  )
}

const withStreamRelations = (useStreamQuery) => (
  arg,
  {
    status = false,
    // TODO: make these opt-in rather than opt-out
    sections = true,
    sites = true,
    divisions = true,
    servers = true,
    tags = true,
    ...options
  } = {}
) => {
  const streamQuery = useStreamQuery(arg, options)

  const sectionsQuery = useSectionsQuery(undefined, { ...options, skip: options.skip || !sections })
  const sitesQuery = useSitesQuery(undefined, { ...options, skip: options.skip || !sites })
  const divisionsQuery = useDivisionsQuery(undefined, { ...options, skip: options.skip || !divisions })
  const serversQuery = useServersQuery(undefined, { ...options, skip: options.skip || !servers })

  const streamIds = useMemo(() => {
    if (!tags) return

    if (Array.isArray(arg)) {
      return arg.map(normalizeArg).map(_get('id'))
    }

    const { id } = normalizeArg(arg)

    if (id != null) return id

    if (!streamQuery.data) return streamQuery.data

    if (!Array.isArray(streamQuery.data)) {
      return [];
    }

    return streamQuery.data.map(_get('id'))
  }, [tags, arg, streamQuery.data])

  const streamTagsQuery = useStreamTagsQuery(streamIds, {
    ...options,
    skip: options.skip || !tags || !streamIds,
  })

  const nonVODIds = useMemo(() =>
    [].concat(streamQuery.data)
      .filter(({ is_vod } = {}) => !is_vod)
      .map(_get('id'))
      .filter(Boolean),
    [streamQuery.data],
  )

  const streamStatusQuery = useStreamStatusQuery(nonVODIds, {
    ...options,
    skip: options.skip || !status || nonVODIds.length === 0,
  })

  // TODO: we may want to memoize, debounce, or throttle this somehow. It's possible that
  // this is being run a lot as results come in and causing stutter/lag
  return mergeQueryResultsWith(
    (stream, { sections, sites, divisions, servers, tags, statuses } = {}) => {
      const addRelations = (stream, { tags }) => stream && {
        ...stream,
        tags: tags ?? stream.tags,
        server: servers?.find(({ id }) => id === stream.media_server_id) ?? stream.server,
        section: sections?.find(({ id }) => id === stream.section_id) ?? stream.section,
        site: sites?.find(({ id }) => id === stream.section?.site_id) ?? stream.site,
        division: divisions?.find(({ id }) => id === stream.site?.division_id) ?? stream.division,
        status: statuses?.find((_, i) => i === nonVODIds.findIndex((id) => id === stream.id)) ?? stream.status,
      }

      return Array.isArray(stream)
        ? stream.map((stream, i) =>
          addRelations(stream, { tags: tags?.[i] }))
        : addRelations(stream, { tags })
    })(streamQuery,
      querySetAtProp('sections', sectionsQuery),
      querySetAtProp('sites', sitesQuery),
      querySetAtProp('divisions', divisionsQuery),
      querySetAtProp('servers', serversQuery),
      querySetAtProp('tags', streamTagsQuery),
      querySetAtProp('statuses', streamStatusQuery),
    )
}

export const useStreamQuery = withStreamRelations(streamManager.useStreamQuery)
export const useStreamsQuery = withStreamRelations(streamManager.useStreamsQuery)
export const useFavouriteStreamsQuery = withStreamRelations(streamManager.useFavouriteStreamsQuery)
export const useRecommendedStreamsQuery = withStreamRelations(streamManager.useRecommendedStreamsQuery)

export const useUploadVideoMutation = (...args) => {
  const [requestId, setRequestId] = useState()
  const [
    _uploadVideoMutationTrigger,
    uploadVideoMutationResult,
  ] = streamManager.useUploadVideoMutation(...args)

  const uploadVideoMutationTrigger = useCallback(
    (...args) => {
      const mutation = _uploadVideoMutationTrigger(...args)
      setRequestId(mutation.requestId)
      return mutation
    },
    [_uploadVideoMutationTrigger],
  )

  const { data } = streamManager.useUploadVideoStatusQuery(requestId, {
    skip: !requestId,
  })

  return [
    uploadVideoMutationTrigger,
    {
      ...uploadVideoMutationResult,
      data,
    },
  ]
}

export const useHasGroup = (group) => {
  const meQuery = useMeQuery()
  const myGroupsQuery = useMyGroupsQuery(undefined, {
    skip: !meQuery.data,
  })
  return myGroupsQuery.data != null
    ? Boolean(myGroupsQuery.data.find((x) => x.group === group))
    : undefined
}

export const useIsDSDManager = () =>
  useHasGroup(window.__ENV.REACT_APP_DSD_MANAGER_GROUP)

export const useGetDsdStreams = () =>
  useStreamsQuery({
    params: { groups: window.__ENV.REACT_APP_DSD_MANAGER_GROUP },
  })
