import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import useWidth from '../../hooks/useWidth'

const useStyles = makeStyles((theme) => ({
    content: {
        padding: 0,
    },
    titleRoot: {
        display: 'flex',
        alignItems: 'start',
    },
    title: {
        flex: '1',
    },
    closeButton: {
        color: theme.palette.grey[500],
        marginTop: -theme.spacing(1),
        marginRight: -theme.spacing(2),
    },
}))

const DialogTitle = ({ children, onClose, ...props }) => {
    const classes = useStyles()

    return (
        <MuiDialogTitle disableTypography className={classes.titleRoot} {...props}>
            <Typography className={classes.title} variant="h6">{children}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </MuiDialogTitle>
    )
}

DialogTitle.propTypes = {
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
}

const Popup = ({ title, open, onClose, children, ...props }) => {
    const classes = useStyles()
    const width = useWidth()
    const isMobile = useMemo(() => width.match(/(xs|sm)/), [width])

    const handleClose = useCallback(() => onClose(), [onClose])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={Boolean(isMobile)}
            {...props}
        >
            <DialogTitle onClose={handleClose}>{title}</DialogTitle>
            <DialogContent classes={{ root: classes.content }}>{children}</DialogContent>
        </Dialog>
    )
}

Popup.defaultProps = {
    title: '',
}

Popup.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default Popup
