import React, { useRef, useState } from 'react'
import _get from 'lodash/fp/get'
import _zipWith from 'lodash/fp/zipWith'

import Dialog from '@material-ui/core/Dialog'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import LockIcon from '@material-ui/icons/Lock'

import {
    useUserDivisionsQuery,
    useUserSitesQuery,
    useUserSectionsQuery,
    useUserStreamsQuery,
    useEntityAccessQuery,
    useUserEntityLinkingGroupsQuery,
    mergeQueryResultsWith,
} from '../../services/stream-manager'

import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import EnhancedTable from '../table/EnhancedTable'


const useEntityAccessWithLinkingGroupsQuery = mergeQueryResultsWith(_zipWith((user, groups) => ({
    ...user,
    linkingGroups: groups?.map(_get('id')),
})))

const entityAccessColumns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Linking groups',
        accessor: 'linkingGroups',
    },
]

const EntityAccess = ({ entity }) => {
    const [open, setOpen] = useState(false)
    const urlKey = useState()

    const entityAccessQuery = usePaginatedFilteredQuery(
        useEntityAccessQuery,
        { urlKey },
    )(
        { id: entity.id },
        { skip: !open },
    )

    const linkingGroupsQuery = useUserEntityLinkingGroupsQuery(
        entityAccessQuery.data?.map(user => ({
            user_id: user.id,
            entity_id: entity.id,
        })),
        { skip: !entityAccessQuery.data },
    )

    const entityAccessWithLinkingGroupsQuery =
        useEntityAccessWithLinkingGroupsQuery(
            entityAccessQuery,
            linkingGroupsQuery
        )

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={false}
            >
                <EnhancedTable
                    label={<>Users with access to <em>{entity.name}</em></>}
                    columns={entityAccessColumns}
                    itemsQuery={entityAccessWithLinkingGroupsQuery}
                    onClose={() => setOpen(false)}
                    urlKey={urlKey}
                />
            </Dialog>
            <Tooltip title={`Users with access to ${entity.name}`}>
                <IconButton
                    aria-label={`Users with access to ${entity.name}`}
                    aria-haspopup="true"
                    onClick={() => setOpen(true)}
                    size="small"
                >
                    <LockIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    )
}

const useUserEntityWithLinkingGroupsQuery = mergeQueryResultsWith(
    _zipWith((entity, groups) => ({
        ...entity,
        linkingGroups: groups?.map(_get('id')),
    }))
)

const userAccessColumns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: 'Linking groups',
        accessor: 'linkingGroups',
    },
]

const UserAccessDialog = ({ open, onClose, label, useUserEntityQuery, user }) => {
    const urlKey = useState()

    const userEntityQuery = usePaginatedFilteredQuery(
        useUserEntityQuery,
        { urlKey },
    )(
        { id: user.provider_id },
        { skip: !open },
    )

    const linkingGroupsQuery = useUserEntityLinkingGroupsQuery(
        userEntityQuery.data?.map(entity => ({
            user_id: user.id,
            entity_id: entity.id,
        })),
        { skip: !userEntityQuery.data }
    )

    const userEntityWithLinkingGroupsQuery = useUserEntityWithLinkingGroupsQuery(
        userEntityQuery,
        linkingGroupsQuery,
    )

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={false}
        >
            <EnhancedTable
                label={label}
                columns={userAccessColumns}
                itemsQuery={userEntityWithLinkingGroupsQuery}
                onClose={onClose}
                urlKey={urlKey}
            />
        </Dialog>
    )
}

const UserAccess = ({ user }) => {
    const anchorRef = useRef()

    const [menuOpen, setMenuOpen] = useState(false)
    const [divisionsOpen, setDivisionsOpen] = useState(false)
    const [sitesOpen, setSitesOpen] = useState(false)
    const [sectionsOpen, setSectionsOpen] = useState(false)
    const [streamsOpen, setStreamsOpen] = useState(false)

    return (
        <>
            <UserAccessDialog
                user={user}
                label={<>Divisions accessible to <em>{user.name}</em></>}
                useUserEntityQuery={useUserDivisionsQuery}
                open={divisionsOpen}
                onClose={() => setDivisionsOpen(false)}
            />
            <UserAccessDialog
                user={user}
                label={<>Sites accessible to <em>{user.name}</em></>}
                useUserEntityQuery={useUserSitesQuery}
                open={sitesOpen}
                onClose={() => setSitesOpen(false)}
            />
            <UserAccessDialog
                user={user}
                label={<>Sections accessible to <em>{user.name}</em></>}
                useUserEntityQuery={useUserSectionsQuery}
                open={sectionsOpen}
                onClose={() => setSectionsOpen(false)}
            />
            <UserAccessDialog
                user={user}
                label={<>Streams accessible to <em>{user.name}</em></>}
                useUserEntityQuery={useUserStreamsQuery}
                open={streamsOpen}
                onClose={() => setStreamsOpen(false)}
            />
            <Menu
                id='entity-select-menu'
                anchorEl={anchorRef.current}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                <MenuItem
                    onClick={() => {
                        setMenuOpen(false)
                        setDivisionsOpen(true)
                    }}
                >
                    Divisions
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setMenuOpen(false)
                        setSitesOpen(true)
                    }}
                >
                    Sites
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setMenuOpen(false)
                        setSectionsOpen(true)
                    }}
                >
                    Sections
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setMenuOpen(false)
                        setStreamsOpen(true)
                    }}
                >
                    Streams
                </MenuItem>
            </Menu>
            <Tooltip title={`Access permissions for ${user.name}`}>
                <IconButton
                    ref={anchorRef}
                    aria-label={`Access permissions for ${user.name}`}
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => setMenuOpen(true)}
                    size="small"
                >
                    <LockIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    )
}

const Access = ({ entity, type }) =>
    type === 'user' ? (
        <UserAccess user={entity} />
    ) : (
        <EntityAccess entity={entity} />
    )

export default Access
