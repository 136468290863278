import moment from 'moment'

export const formatDuration = (value) => {
  const duration = moment.duration(value)
  if (duration.asHours() > 1) {
    return (
      Math.floor(duration.asHours()) +
      'h ' +
      moment.utc(duration.asMilliseconds()).format('mm[m] ss[s]')
    )
  }
  return moment.utc(duration.asMilliseconds()).format('mm[m] ss[s]')
}

export const formatTime = (value) => moment.utc(value).format('ll')

export const formatDurationFromSeconds = (value) => {
  const duration = moment.duration(value, 'seconds')
  return (
    Math.floor(duration.asHours()) +
    'h ' +
    moment.utc(duration.asMilliseconds()).format('mm[m]')
  )
}
