import { useCallback, useEffect, useRef } from 'react'

const useTimeout = (fn, delay) => {
  const timeout = useRef()
  const callback = useRef(fn)

  const set = useCallback(() => {
    timeout.current && clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      delay != null && callback.current()
    }, delay ?? 0)
  }, [delay])

  const clear = useCallback(() => {
    timeout.current && clearTimeout(timeout.current)
  }, [])

  // update ref when function changes
  useEffect(() => {
    callback.current = fn
  }, [fn])

  // set on mount, clear on unmount
  useEffect(() => {
    set()

    return clear
  }, [set, clear])

  return [set, clear]
}

export default useTimeout
