import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

const useStyles = makeStyles((theme) => ({
  alert: {
    boxShadow: theme.shadows[1],
  },
  actionWrapper: {
    alignItems: 'start',
  },
}))

const AlertDialog = ({
  open: _open,
  title,
  severity = 'warning',
  alert,
  status,
  onClose,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState()

  useEffect(() => {
    if (_open) setOpen(true)
  }, [_open])

  const errorMsg = useMemo(() => {
    if (!alert) return []
    return []
      .concat(alert)
      .map((alert) => {
        if (typeof alert?.detail === 'string') return alert.detail
        if (Array.isArray(alert.detail)) {
          return alert.detail.map(({ loc, msg }) => `${loc.join('.')}: ${msg}`)
        }
        return alert
      })
      .flat()
  }, [alert])

  const handleClose = useCallback(
    (e) => {
      e?.preventDefault()
      e?.stopPropagation()
      onClose?.()
      setOpen(false)
    },
    [onClose],
  )

  return (
    <Snackbar
      key={alert}
      open={open}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      autoHideDuration={['info', 'success'].includes(severity) ? 3000 : null}
    >
      <Alert
        className={classes.alert}
        classes={{ action: classes.actionWrapper }}
        severity={severity}
        onClose={handleClose}
        variant="filled"
      >
        <AlertTitle>
          {title ||
            severity.charAt(0).toUpperCase() +
              severity.slice(1) +
              (status != null ? `: ${status}` : '')}
        </AlertTitle>
        {errorMsg.map((err) => (
          <p key={err}>{err}</p>
        ))}
      </Alert>
    </Snackbar>
  )
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  severity: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  alert: PropTypes.any,
  status: PropTypes.number,
  onClose: PropTypes.func,
}

export default AlertDialog
