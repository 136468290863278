import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import CheckIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Cancel'

import { useMonitorServicesQuery } from '../../services/stream-manager'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    height: '100%'
  },
  title: {
    padding: theme.spacing(1),
  },
}))

const ServiceHealth = ({ start, stop }) => {
  const classes = useStyles()

  // Get the data
  const monitorServiceQuery = useMonitorServicesQuery({ params: { start_time: start*1000, end_time: stop*1000}})

  return (
    <Paper className={classes.container}>
      <Typography variant="h6" component="p" className={classes.title}>
        Service Health
      </Typography>
      {(monitorServiceQuery.isFetching || monitorServiceQuery.data == null) ? (
        <Typography align='center' variant="body2" color="textSecondary" component="p">
        ...
        </Typography>
      ) : (
        <Box sx={{ width: '100%', p: 0}}>
          { monitorServiceQuery.data?.services.map((service) => (
            <Chip
              key={service.n}
              label={service.n}
              style={{margin: '2px 0px', display:'flex', justifyContent:"space-between"}}
              icon={service.s === 'on' ? <CheckIcon style={{color: "green"}} /> : <ErrorIcon style={{color: "red"}} />}
            />
          ))}
        </Box>
      )}
    </Paper>
  )
}



ServiceHealth.propTypes = {
  start: PropTypes.number,
  stop: PropTypes.number,
}

export default ServiceHealth