import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { FastField, useFormikContext } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import { parse as parseCsv } from 'csv-parse/dist/esm'

import { makeStyles, alpha } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DateTimePicker } from '@material-ui/pickers'
import CloseIcon from '@material-ui/icons/Close'
import HelpIcon from '@material-ui/icons/HelpOutline'

import { withAdmin } from '../../services/msal'
import {
  useSectionsQuery,
  useSitesQuery,
  useDivisionsQuery,
  useStreamsQuery,
  useServersQuery,
  useUploadVideoMutation,
  useUploadedVideosStatusQuery,
  useUpdateUploadedVideoMutation,
  useDeleteUploadedVideoMutation,
  useDownloadCsv,
  mergeQueryResultsWith,
  querySetAtProp,
} from '../../services/stream-manager'

import formatAPIParams from '../../utils/formatAPIParams'

import usePrefs from '../../hooks/usePrefs'
import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'
import useMapValuesToArg from '../../hooks/useMapValuesToArg'

import VideoUpload from '../../components/VideoUpload'
import FileUpload from '../../components/FileUpload'

import EnhancedTable from '../table/EnhancedTable'

import Tour from '../tour'

import Access from './AccessPage'
import gpsLogExample from './gps-log-example.png'

const POLLING_INTERVAL = 60 * 1000

const useStyles = makeStyles((theme) => ({
  videoLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& > *': {
      marginRight: theme.spacing(2),
      flex: 1,
    },
    '& > :last-child': {
      marginRight: 0,
    },
    '& > :not(:first-child)': {
      fontSize: 'smaller',
    },
  },
  menuItemSubtitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  video: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  contents: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    color: '#fafafa',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    overflow: 'hidden !important',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'white',
    backgroundColor: alpha(theme.palette.grey[800], 0.5),
    '&:hover': {
      backgroundColor: alpha(theme.palette.grey[800], 0.6),
    },
  },
  percentage: {
    paddingTop: theme.spacing(2),
  },
  completed: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  linearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  circularProgress: {
    margin: theme.spacing(2),
  },
  resetTour: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}))

const useSectionsWithRelationsQuery = mergeQueryResultsWith(
  (sections, { sites, divisions }) =>
    sections?.map((section) => section && {
      ...section,
      site: sites?.find(({ id }) => id === section.site_id) ?? section.site,
      division: divisions?.find(({ id }) => id === section.site?.division_id) ?? section.division,
    }),
)

export const VideoUploadFormFields = ({ editing }) => {
  const today = useMemo(() => moment(), [])
  const classes = useStyles()

  const [{ tour = {} }, setPrefs] = usePrefs()

  const resetTour = useCallback(() => {
    setPrefs((prefs) => ({
      ...prefs,
      tour: {
        ...prefs.tour,
        hasRun: {
          ...prefs.tour?.hasRun,
          videoupload: false,
        },
      },
    }))
  }, [setPrefs])

  const sectionsQuery = useSectionsQuery()
  const sitesQuery = useSitesQuery()
  const divisionsQuery = useDivisionsQuery()

  const sectionsWithRelationsQuery = useSectionsWithRelationsQuery(
    sectionsQuery,
    querySetAtProp('sites', sitesQuery),
    querySetAtProp('divisions', divisionsQuery),
  )

  const { values, handleChange } = useFormikContext()

  useEffect(() => {
    if (values.video) {
      handleChange({
        target: {
          name: 'name',
          value: values.video.name.replace(/\.\w+$/, ''),
        },
      })
    }
  }, [handleChange, values.video])

  useEffect(() => {
    if (values.gps_log) {
      ; (async () => {
        try {
          parseCsv(
            await values.gps_log.text(),
            {
              columns: true,
              comment: 'sep=,',
            },
            (err, records) => {
              const { date, time, latitude, longitude } = records[0]
              handleChange({ target: { name: 'lat', value: latitude } })
              handleChange({ target: { name: 'long', value: longitude } })
              handleChange({
                target: {
                  name: 'recorded',
                  value: moment(
                    `${date} ${time}`,
                    'M/D/YYYY h:mm:ss.S A',
                  ),
                },
              })
            },
          )
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [handleChange, values.gps_log])

  const uploadDate = useMemo(() => moment(), [])
  const expiryDate = useMemo(() =>
    uploadDate.clone().add(window.__ENV.REACT_APP_VOD_EXPIRY_WINDOW, 'seconds')
    , [uploadDate])

  return (
    <>
      {!editing && (
        <Tour
          run
          name="videoupload"
          steps={[
            {
              disableBeacon: true,
              target: '[data-tour="videoupload-dragndrop"]',
              content:
                'Drag and drop your video file here. Standard video files are supported.',
              placement: 'left',
            },
            {
              target: '[data-tour="videoupload-description"]',
              content:
                'Enter a description of the video here. The description will be displayed below the video.',
              placement: 'left',
            },
            {
              target: '[data-tour="videoupload-gpslog"]',
              content: (
                <>
                  <p>
                    If the equipment that recorded the video has logged GPS
                    tracks you can upload them here. You will then be able to
                    view the GPS tracks on the Map view.
                  </p>
                  <p>The logs must be in the following CSV format:​</p>
                  <p>
                    <em>Text encoding: UTF-8​</em>
                  </p>
                  <img
                    alt="GPL log file structure example"
                    src={gpsLogExample}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </>
              ),
              placement: 'left',
            },
            {
              target: '[data-tour="videoupload-recorded"]',
              content: (
                <>
                  <p>
                    🕗 Enter the start date & time of the recorded footage here.
                    This will enable you to search for videos from a specific
                    time period. If you don’t know the time you can check if it
                    is available in the video’s properties using Windows’ File
                    Explorer.
                  </p>
                  <p>
                    If you include a GPS log, the time will automatically be set
                    to the start of the log.
                  </p>
                </>
              ),
              placement: 'left',
            },
            {
              target: '[data-tour="videoupload-latlon"]',
              content: (
                <>
                  <p>
                    🧭 Enter the start location of the recorded footage here.
                    This will enable you to search for videos in a specific
                    area. If you don’t know the location you can check if it is
                    available in the video’s properties using Windows’ File
                    Explorer.
                  </p>
                  <p>
                    If you include a GPS log, the location will automatically be
                    set to whatever is recorded at the start of the log.
                  </p>
                </>
              ),
              placement: 'left',
            },
          ]}
        />
      )}
      {tour.hasRun?.videoupload && (
        <Tooltip title="Reset the video upload help tour">
          <IconButton className={classes.resetTour} onClick={resetTour}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
      {!editing && (
        <FastField name="video">
          {({ field, meta }) => (
            <VideoUpload
              data-tour="videoupload-dragndrop"
              {...field}
              label={(
                <div className={classes.videoLabel}>
                  <div>Video File</div>
                  <div />
                  <div>Upload Date: {uploadDate.format('D/MM/YYYY')}</div>
                  <div />
                  <div>Expiry Date: {expiryDate.format('D/MM/YYYY')}</div>
                </div>
              )}
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
            />
          )}
        </FastField>
      )}
      <FastField name="name">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Name"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="video_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Video ID"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="description">
        {({ field, meta }) => (
          <TextField
            data-tour="videoupload-description"
            {...field}
            label="Description"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          />
        )}
      </FastField>
      <FastField name="video_type">
        {({ field, meta }) => (
          <TextField
            {...field}
            value={field.value ?? ''}
            select
            label="Type"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {[
              'Blasting',
              'Inspection',
              'Pit Visit',
              'Drilling',
              'Load & Haul',
              'Security',
              'Other',
            ].map((str) => (
              <MenuItem key={str} value={str}>{str}</MenuItem>
            ))}
          </TextField>
        )}
      </FastField>
      <FastField name="source">
        {({ field, meta }) => (
          <TextField
            {...field}
            value={field.value ?? ''}
            select
            label="Source"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {[
              'Drone',
              'Highspeed',
              'Normal Camera',
              'Cellphone/Tablet',
              'GoPro',
              'Fixed Camera',
              'Other',
            ].map((str) => (
              <MenuItem key={str} value={str}>{str}</MenuItem>
            ))}
          </TextField>
        )}
      </FastField>
      <FastField name="section_id">
        {({ field, meta }) => (
          <TextField
            {...field}
            select
            label="Section"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
            margin="dense"
            fullWidth
          >
            {sectionsWithRelationsQuery.data?.map((section) => (
              <MenuItem key={section.id} value={section.id}>
                <div>
                  {`${section.name}: ${section.description}`}
                  {section.division && section.site && (
                    <Typography
                      variant="subtitle2"
                      className={classes.menuItemSubtitle}
                    >
                      {`${section.division.name} / ${section.site.name}`}
                    </Typography>
                  )}
                </div>
              </MenuItem>
            )) ?? <MenuItem value="" />}
          </TextField>
        )}
      </FastField>
      <FastField name="gps_log">
        {({ field, meta }) => (
          <FileUpload
            data-tour="videoupload-gpslog"
            {...field}
            label="GPS log"
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      </FastField>
      <FastField name="recorded">
        {({ field: { value, onChange } }) => (
          <DateTimePicker
            data-tour="videoupload-recorded"
            label="Recorded at"
            format="DD MMM HH:mm:ss"
            onChange={(date) => onChange({
              target: {
                name: 'recorded',
                value: date,
              }
            })}
            value={value ? moment(value) : today}
            maxDate={today}
            margin="dense"
          />
        )}
      </FastField>
      <div data-tour="videoupload-latlon">
        <FastField name="lat">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Latitude"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: Boolean(meta.value) }}
            />
          )}
        </FastField>
        <FastField name="long">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Longitude"
              error={meta.touched && Boolean(meta.error)}
              helperText={meta.touched && meta.error}
              margin="dense"
              fullWidth
              InputLabelProps={{ shrink: Boolean(meta.value) }}
            />
          )}
        </FastField>
      </div>
    </>
  )
}

export const mapValuesToArg = async ({
  id,
  group,
  groups,
  video,
  gps_log,
  recorded,
  ...body
}) => ({
  id,
  group,
  video,
  body: {
    ...body,
    ...(gps_log
      ? {
        gps_log: await gps_log.text(),
      }
      : undefined),
    ...(recorded ? {
      recorded: recorded.unix(),
    } : undefined),
    file_name: video?.name,
    content_type: video?.type,
    groups,
  },
})

export const createUploadValidationSchema = ({ editing }) =>
  yup.object({
    name: yup
      .string()
      .min(4, 'The video name should have at least 4 characters')
      .max(80, 'The video name should have maximum 80 characters')
      .required('Video name is required'),
    video_id: yup.string().required('Video ID is required'),
    description: yup.string().required('Video description is required'),
    video_type: yup.string().required('Type is required'),
    source: yup.string().required('Source is required'),
    section_id: yup.string().required('Section is required'),
    ...(editing
      ? undefined
      : {
        video: yup.mixed().required('The video file is required'),
      }),
  })

export const initialValues = {
  name: '',
  video_id: '',
  description: '',
  video_type: '',
  source: '',
  section_id: '',
  site_id: '',
  division_id: '',
  uri: '',
  __groups__: [],
}

export const UploadProgressDialog = ({
  open,
  setOpen,
  uploadVideoResult
}) => {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      open={open}
      onClose={
        uploadVideoResult.data?.progress === 1
          ? () => setOpen(false)
          : undefined
      }
    >
      {uploadVideoResult.data?.videoFileUrl && (
        <video
          className={classes.video}
          disablePictureInPicture
          src={uploadVideoResult.data.videoFileUrl}
        />
      )}
      {uploadVideoResult.data?.progress === 1 && (
        <Tooltip title="close">
          <IconButton
            classes={{ root: classes.closeIcon }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className={classes.contents}>
        <Typography>Uploading</Typography>
        <Typography>
          <em>{uploadVideoResult.data?.file_name}</em>
        </Typography>
        {uploadVideoResult.data?.progress != null ? (
          <>
            <Typography className={classes.percentage}>
              {(uploadVideoResult.data.progress * 100).toFixed(2)}%
            </Typography>
            {uploadVideoResult.data.progress === 1 && (
              <Typography variant="caption" className={classes.completed}>
                Your upload has succesfully completed. The video will now be
                transcoded by the server. You may close this dialog.
              </Typography>
            )}
            <LinearProgress
              className={classes.linearProgress}
              variant="determinate"
              value={Math.round(uploadVideoResult.data?.progress * 100)}
            />
          </>
        ) : (
          <CircularProgress className={classes.circularProgress} />
        )}
      </div>
    </Dialog>
  )
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Section',
    accessor: 'section_id',
  },
  {
    Header: 'Site',
    accessor: 'site_id',
  },
  {
    Header: 'Division',
    accessor: 'division_id',
  },
  {
    Header: 'URI',
    accessor: 'uri',
  },
]

const VideoManagement = () => {
  const sectionsQuery = useSectionsQuery()
  const sitesQuery = useSitesQuery()
  const divisionsQuery = useDivisionsQuery()
  const serversQuery = useServersQuery()

  const mapColumns = useMemo(
    () => ({
      section_id: sectionsQuery.data,
      site_id: sitesQuery.data,
      division_id: divisionsQuery.data,
      media_server_id: serversQuery.data,
    }),
    [
      sectionsQuery.data,
      sitesQuery.data,
      divisionsQuery.data,
      serversQuery.data,
    ],
  )

  const filterColumns = useMemo(
    () => ({
      section_id: sectionsQuery.data,
      site_id: sitesQuery.data,
      division_id: divisionsQuery.data,
    }),
    [sectionsQuery.data, sitesQuery.data, divisionsQuery.data],
  )

  const streamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { urlKey: 'videos' },
  )(
    { params: formatAPIParams({ type: 'video' }) },
    { pollingInterval: POLLING_INTERVAL },
  )

  const streams = useMemo(() => {
    const data = streamsQuery.data?.map(
      ({ section, site, division, server, ...stream }) => ({
        ...stream,
        media_server_id: server?.id,
        section_id: section?.id,
        site_id: site?.id,
        division_id: division?.id,
      }),
    )

    if (data) {
      data.count = streamsQuery.data?.count
    }

    return {
      ...streamsQuery,
      data,
    }
  }, [streamsQuery])

  const [createTrigger, createResult] = useMapValuesToArg(
    useUploadVideoMutation,
    mapValuesToArg,
  )

  const [loadingProgressOpen, setLoadingProgressOpen] = useState(false)
  useEffect(() => {
    if (createResult.data?.status != null) {
      setLoadingProgressOpen(true)
    }
  }, [createResult.data?.status])

  return (
    <>
      <Tour
        run
        name="videomanagement"
        steps={[
          {
            target: '[data-tour="enhancedtable-addedit"]',
            title: 'Upload a video',
            content:
              'Click here to upload a video from a movable camera (e.g. a drone).​',
            placement: 'right',
          },
        ]}
      />
      <UploadProgressDialog
        open={loadingProgressOpen}
        setOpen={setLoadingProgressOpen}
        uploadVideoResult={createResult}
      />
      <Paper>
        <EnhancedTable
          label="Videos"
          columns={columns}
          actions={({ row }) => (
            <>
              <Access entity={row.original} />{' '}
            </>
          )}
          initialValues={initialValues}
          FormFields={VideoUploadFormFields}
          createValidationSchema={createUploadValidationSchema}
          mapColumns={mapColumns}
          filterColumns={filterColumns}
          itemsQuery={streams}
          createMutation={[createTrigger, createResult]}
          updateMutation={useMapValuesToArg(
            useUpdateUploadedVideoMutation,
            mapValuesToArg,
          )}
          deleteMutation={useDeleteUploadedVideoMutation()}
          urlKey="videos"
          downloadCsv={
            useDownloadCsv(
              usePaginatedFilteredQuery(useStreamsQuery, { urlKey: 'videos' }),
            )({
              params: formatAPIParams({ type: 'video' }),
            }, {
              sections: false,
              sites: false,
              divisions: false,
              servers: false,
              tags: false,
            })
          }
          withGroups
          search
        />
      </Paper>
      <br />
      <Paper>
        <EnhancedTable
          label="Video processing status"
          columns={[
            {
              Header: 'Name',
              accessor: 'stream_name',
            },
            {
              Header: 'File name',
              accessor: 'file_name',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
            {
              Header: 'Uploaded by',
              accessor: 'user_name',
            },
          ]}
          itemsQuery={usePaginatedFilteredQuery(
            useUploadedVideosStatusQuery,
            { urlKey: 'status' },
          )(
            { params: { exclude_states: 'ready' } },
            { pollingInterval: POLLING_INTERVAL },
          )}
          urlKey="status"
          deleteMutation={useDeleteUploadedVideoMutation()}
        />
      </Paper>
    </>
  )
}

export const VideoManagementPage = withAdmin(VideoManagement)
