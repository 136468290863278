import {
  Children,
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
  cloneElement,
} from 'react'
import { useIntersection } from 'react-use'

const IntersectionObserver = ({
  onIntersection,
  root,
  rootMargin,
  threshold,
  intersectionRatio,
  children: child,
}) => {
  // Make sure this is a single child
  Children.only(child)

  // We need a ref to the child, but we don't want to just clobber any
  // existing ref
  const childRef = useRef()
  useImperativeHandle(child.ref, () => childRef)

  const [isInView, setIsInView] = useState(false)

  const intersection = useIntersection(childRef, {
    root,
    rootMargin,
    threshold,
  })

  useEffect(() => {
    if (intersection?.intersectionRatio > intersectionRatio) {
      setIsInView(true)
    } else {
      setIsInView(false)
    }
  }, [intersectionRatio, intersection])

  useEffect(() => {
    if (isInView) {
      onIntersection?.()
    }
  }, [onIntersection, isInView])

  return cloneElement(child, {
    ref: childRef,
  })
}

export default IntersectionObserver
