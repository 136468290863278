import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  titleWrapper: {
    borderRadius: 0,
  },
})

const PageTitle = ({
  className,
  children,
  variant = 'h6',
  'data-tour': dataTour,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Toolbar
      component={Paper}
      className={clsx(classes.titleWrapper, className)}
      data-tour={typeof children !== 'string' ? dataTour : undefined}
      {...props}
    >
      {typeof children === 'string' ? (
        <Typography
          variant={variant}
          component="h1"
          data-tour={dataTour}
        >
          {children}
        </Typography>
      ) : children}
    </Toolbar>
  )
}

export default PageTitle
