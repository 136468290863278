import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
    }
  },
  listItemSelected: {
    backgroundColor: 'rgba(255, 255, 255, 0.08) !important',
  },
  drawerIcons: {
    fontSize: 36,
    color: theme.palette.primary.contrastText,
  },
}))
