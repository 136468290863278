import React, { memo, useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetAppIcon from '@material-ui/icons/GetApp'
import { DateTimePicker } from '@material-ui/pickers'

import { StreamProperties } from '../../proptypes/proptypes'

import { useStreamDownloadQuery } from '../../services/stream-manager'

import Popup from '../popup/Popup'
import AlertDialog from '../dialogs/AlertDialog'

import { generateDvrTimelineMarks } from './utils'

const DEFAULT_DURATION = 15 * (60 * 1000) // milliseconds

const MILLISECONDS_IN_MINUTE = 60 * 1000

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(3),
  },
  loading: {
    marginRight: theme.spacing(2),
  },
}))

const DownloadStreamModal = memo(
  ({
    open,
    setOpen,
    stream,
    currentTime,
    container,
    timeline,
    maxDuration,
  }) => {
    const classes = useStyles()

    // Initial duration is minimum of time limit and default duration
    const [duration, setDuration] = useState(
      Math.min(maxDuration, DEFAULT_DURATION),
    )
    const [minStart, minStartDate] = useMemo(() => {
      const minStart = timeline[0].value
      return [minStart, new Date(minStart)]
    }, [timeline])
    const [maxStart, maxStartDate] = useMemo(() => {
      const maxStart =
        timeline[timeline.length - 1].value - MILLISECONDS_IN_MINUTE
      return [maxStart, new Date(maxStart)]
    }, [timeline])

    const getInitialTimeRange = useCallback(() => {
      // Initial start time should be the current playback currentTime
      // Initial end time should be start + initial duration
      // If that is _past_ the max available time, subtract the difference from both
      const overflow = Math.max(
        0,
        currentTime + duration - timeline[timeline.length - 1].value,
      )
      const start = currentTime - overflow
      const end = start + duration
      return [start, end]
    }, [currentTime, duration, timeline])

    const [timeRange, setTimeRange] = useState(getInitialTimeRange)

    const [streamDownload, streamDownloadQuery] = useStreamDownloadQuery({
      id: stream?.id,
      start: Math.floor(timeRange[0] / 1000),
      end: Math.floor(timeRange[1] / 1000),
    })

    const handleTimeRangeChange = useCallback(
      ({ start, duration }) => {
        // The user may remove the numerical value completely if they’re using the backspace key.
        // The value of the element is then the empty string. If we don’t allow for that as a possible
        // value of `duration`, the user won’t be able to actually remove the last character.
        // So, if this is the case, set `duration` to the empty string, but take no further action.
        if (duration === '') {
          setDuration('')
          return
        }

        setTimeRange(([oldStart, oldEnd]) => {
          const oldDuration = oldEnd - oldStart
          let newDuration
          let newStart

          if (duration != null) {
            // Make sure the duration is at least 1 minute
            newDuration =
              duration < MILLISECONDS_IN_MINUTE
                ? MILLISECONDS_IN_MINUTE
                : duration

            // If the new duration takes us past the end of the stream, move the start back
            const overflow = Math.max(
              0,
              oldStart + newDuration - timeline[timeline.length - 1].value,
            )
            if (overflow) {
              newStart = oldStart - overflow
            }
          } else if (start != null) {
            // Make sure the start is not after 1 minute before the end of the timeline, nor
            // before the start of the timeline
            newStart =
              start > maxStart ? maxStart : start < minStart ? minStart : start
            // If the new start would take the new end past the end of the stream, reduce the duration
            const overflow = Math.max(
              0,
              newStart + oldDuration - timeline[timeline.length - 1].value,
            )
            if (overflow) {
              newDuration = oldDuration - overflow
            }
          }

          if (newDuration) setDuration(newDuration)

          const _start = newStart ?? oldStart
          const _duration = newDuration ?? oldDuration
          return [_start, _start + _duration]
        })
      },
      [timeline, maxStart, minStart],
    )

    useEffect(() => {
      setTimeRange(getInitialTimeRange)
    }, [getInitialTimeRange])

    useEffect(() => {
      if (duration === '') return
      if (duration < MILLISECONDS_IN_MINUTE) {
        setDuration(MILLISECONDS_IN_MINUTE)
        return
      }
      if (duration > maxDuration) {
        setDuration(maxDuration)
        return
      }
      setTimeRange(([start]) => [start, start + duration])
    }, [duration, maxDuration])

    return (
      <Popup
        title={
          <>
            Download Video Clip of{' '}
            <em>
              {stream?.name} ({stream?.description})
            </em>
          </>
        }
        open={open}
        onClose={() => setOpen(false)}
        container={container}
      >
        <AlertDialog
          key={streamDownloadQuery.startedTimeStamp}
          open={streamDownloadQuery.isError}
          status={streamDownloadQuery.error?.status}
          alert={streamDownloadQuery.error?.data}
        />
        <Container className={classes.container}>
          <Typography>
            The maximum download video length is{' '}
            <strong>
              {Math.floor(maxDuration / MILLISECONDS_IN_MINUTE)} minutes
            </strong>
            .
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={6}>
              <DateTimePicker
                label="start"
                minDate={minStartDate}
                maxDate={maxStartDate}
                format="DD MMM HH:mm"
                value={new Date(+timeRange[0])}
                onChange={(d) => handleTimeRangeChange({ start: +d })}
              />
            </Grid>
            <Grid item xs={10} sm={4} md={6}>
              <TextField
                label="duration (minutes)"
                min={1}
                max={maxDuration / MILLISECONDS_IN_MINUTE}
                type="number"
                value={duration === '' ? '' : duration / MILLISECONDS_IN_MINUTE}
                onChange={({ target: { value } }) =>
                  handleTimeRangeChange({
                    duration:
                      value === '' ? '' : value * MILLISECONDS_IN_MINUTE,
                  })
                }
              />
            </Grid>
          </Grid>
          <br />
          <br />
          {streamDownloadQuery.isFetching ? (
            <Box display="flex" alignItems="center">
              <CircularProgress className={classes.loading} />
              <Typography>Preparing your video clip…</Typography>
            </Box>
          ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<GetAppIcon />}
              onClick={() => streamDownload()}
            >
              Download
            </Button>
          )}
        </Container>
      </Popup>
    )
  },
)

DownloadStreamModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  stream: StreamProperties.isRequired,
  timeline: PropTypes.array.isRequired,
  maxDuration: PropTypes.number,
  currentTime: PropTypes.number.isRequired,
  container: PropTypes.instanceOf(HTMLElement).isRequired,
}

export const DownloadStreamModalAndIcon = ({
  className,
  currentTime,
  timeline,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [cachedCurrentTime, setCachedCurrentTime] = useState()
  const [cachedTimeline, setCachedTimeline] = useState(timeline)

  const handleOpen = () => {
    setCachedCurrentTime(currentTime)
    setCachedTimeline(generateDvrTimelineMarks(timeline))
    setOpen(true)
  }

  useEffect(
    function setInitialCachedTimeline() {
      if (cachedTimeline == null && timeline != null) {
        setCachedTimeline(generateDvrTimelineMarks(timeline))
      }
    },
    [timeline, cachedTimeline],
  )

  useEffect(
    function setInitialCachedCurrentTime() {
      if (cachedCurrentTime == null && currentTime != null) {
        setCachedCurrentTime(currentTime)
      }
    },
    [currentTime, cachedCurrentTime],
  )

  return (
    <>
      <Tooltip title="Download Video Clip" arrow placement="top">
        <IconButton className={className} onClick={handleOpen}>
          <GetAppIcon />
        </IconButton>
      </Tooltip>
      {cachedTimeline != null && cachedCurrentTime != null && (
        <DownloadStreamModal
          open={open}
          setOpen={setOpen}
          currentTime={cachedCurrentTime}
          timeline={cachedTimeline}
          {...props}
        />
      )}
    </>
  )
}

DownloadStreamModalAndIcon.propTypes = {
  stream: StreamProperties,
  timeline: PropTypes.array,
  maxDuration: PropTypes.number,
  currentTime: PropTypes.number,
  container: PropTypes.instanceOf(HTMLElement),
}
