import React from 'react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import slug from 'slug'
import qs from 'qs'

import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import useFilterParams from '../../hooks/useFilterParams'

import useSurfaceStyles from './styles'


const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingLeft: theme.spacing(11),
  },
}))

const SectionListItem = ({ section, site, division, onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const classes = useStyles()

  const routeMatch = useRouteMatch('/streams')
  const [filter] = useFilterParams()

  const selected =
    routeMatch &&
    filter.division?.id === division.id &&
    filter.site?.id === site.id &&
    filter.section?.id === section.id

  return (
    <ListItem
      className={clsx(surfaceClasses.listItem, classes.listItem)}
      classes={{ selected: surfaceClasses.listItemSelected }}
      selected={selected}
      button
      component={Link}
      to={`/streams?${qs.stringify({
        division: slug(division.name),
        site: slug(site.name),
        section: slug(section.name),
      })}`}
      onClick={onSelect}
    >
      <ListItemText primary={section.name} />
    </ListItem>
  )
}

SectionListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  section: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  site: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  division: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default SectionListItem
