import React, {
  Children,
  cloneElement,
  forwardRef,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'
import { useUpdateEffect } from 'react-use'
import clsx from 'clsx'
import _keyBy from 'lodash/fp/keyBy'
import _values from 'lodash/fp/values'
import _throttle from 'lodash/fp/throttle'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import AddIcon from '@material-ui/icons/AddCircleOutlined'

import IntersectionObserver from '../utils/IntersectionObserver'
import AddEditDialog from '../features/dialogs/AddEditDialog'

const DEFAULT_LIMIT = 50
const LOADING_ID = '__LOADING__'
const CREATE_ID = '__CREATE__'

const filter = createFilterOptions()

// TODO: windowing: https://v4.mui.com/components/autocomplete/#virtualization

const Option = forwardRef(({ onInView, className, children: child }, ref) => {
  // Make sure this is a single child
  Children.only(child)

  const [listboxEl, setListboxEl] = useState()

  // TODO: see if we can do this on the child directly, instead of a
  // wrapping element
  return (
    <IntersectionObserver
      root={listboxEl}
      intersectionRatio={0}
      onIntersection={listboxEl ? onInView : undefined}
    >
      {cloneElement(child, {
        ref: (elem) => {
          if (ref) {
            ref.current = elem?.current
          }
          setListboxEl(elem?.current?.closest('[role="listbox"]'))
        },
        className: clsx(child.className, className),
      })}
    </IntersectionObserver>
  )
})

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'relative',
    top: theme.spacing(-1.25),
  },
  option: {
    flex: 1,
    display: 'flex',
  },
  stringOption: {
    marginLeft: theme.spacing(0.5),
  },
  loadingOption: {
    fontStyle: 'italic',
  },
  createOptionIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    marginRight: '0.25em',
  },
  createOptionLabel: {
    fontStyle: 'italic',
    marginLeft: '0.25em',
  },
}))

const defaultOptionLabel = (name) => name ?? ''

const DynamicMultipleSelect = ({
  useItemsQuery,
  queryArg = {},
  optionLabel = defaultOptionLabel,
  renderOption,
  onChange,
  value,
  label,
  placeholder,
  pageSize = DEFAULT_LIMIT,
  FormFields,
  createMutation: _createMutation,
  createInitialValues,
  createValidationSchema,
  'data-tour': dataTour,
  ...props
}) => {
  const classes = useStyles()

  const getOptionLabel = useCallback(
    (option) => optionLabel(typeof option === 'string' ? option : option.name),
    [optionLabel],
  )

  const [values, setValues] = useState(value || [])
  const [open, setOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  const [options, setOptions] = useState(null)

  const { data, isFetching } = useItemsQuery(
    {
      ...queryArg,
      params: {
        ...queryArg?.params,
        limit: pageSize,
        offset: (page - 1) * pageSize || undefined,
        search: searchQuery || undefined,
      },
    },
    {
      skip: !open,
    },
  )

  // Determine whether we should load the next page
  const maybeLoadNextPage = useCallback(() => {
    if (!isFetching && options?.length < data.count) {
      setPage((page) => page + 1)
    }
  }, [isFetching, options, data])

  // Call onChange when the value/input changes
  useUpdateEffect(() => {
    onChange?.(values)
  }, [onChange, values])

  // Reset current page and results when the user types
  useEffect(() => {
    setPage(1)
    setOptions(null)
  }, [searchQuery])

  // When we have new data, set the options
  useEffect(() => {
    setOptions((options) =>
      _values({
        ..._keyBy('id', options),
        ..._keyBy('id', data),
      }),
    )
  }, [data])

  const [initialValues, setInitialValues] = useState()

  const createMutation = useMemo(
    () =>
      _createMutation
        ? [
            async (...args) => {
              const { data } = await _createMutation[0](...args)
              setInitialValues(createInitialValues(''))
              if (!data) return
              setOptions((options) =>
                _values({
                  ..._keyBy('id', options),
                  [data.id]: data,
                }),
              )
              setValues((values) =>
                _values({
                  ..._keyBy('id', values),
                  [data.id]: data,
                }),
              )
            },
            _createMutation[1],
          ]
        : undefined,
    [_createMutation, createInitialValues],
  )

  return (
    <>
      {createMutation?.[0] && FormFields && initialValues && (
        <AddEditDialog
          open={createOpen}
          setOpen={setCreateOpen}
          label={label}
          initialValues={initialValues}
          createMutation={createMutation}
          createValidationSchema={createValidationSchema}
        >
          <FormFields />
        </AddEditDialog>
      )}
      <Autocomplete
        data-tour={dataTour}
        openOnFocus
        multiple
        autoHighlight
        onChange={useCallback(
          async (_, options) => {
            const createOption = options.find(({ id }) => id === CREATE_ID)
            if (createOption) {
              setInitialValues(createInitialValues(createOption.name))
              setCreateOpen(true)
            } else {
              setValues(options)
            }
          },
          [createInitialValues],
        )}
        onInputChange={useMemo(
          () => _throttle(500, (_, value) => setSearchQuery(value || '')),
          [setSearchQuery],
        )}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionDisabled={(option) => option.id === LOADING_ID}
        getOptionLabel={getOptionLabel}
        renderOption={useCallback(
          (option) =>
            option.id === LOADING_ID ? (
              <div className={clsx(classes.option, classes.loadingOption)}>
                {option.name}
              </div>
            ) : option.id === CREATE_ID ? (
              <div className={clsx(classes.option, classes.createOption)}>
                <AddIcon className={classes.createOptionIcon} color="primary" />{' '}
                Create
                <span className={classes.createOptionLabel}>
                  {getOptionLabel(option)}
                </span>
              </div>
            ) : (
              <Option
                className={classes.option}
                option={option}
                onInView={
                  option.id === options?.[options?.length - 1]?.id
                    ? maybeLoadNextPage
                    : undefined
                }
              >
                {renderOption?.(option) ?? <div>{getOptionLabel(option)}</div>}
              </Option>
            ),
          [classes, getOptionLabel, maybeLoadNextPage, options, renderOption],
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...props}
            label={label}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching ? (
                    <CircularProgress className={classes.progress} size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) =>
            typeof option === 'string' ? (
              <span key="string-option" className={classes.stringOption}>
                {option}
              </span>
            ) : (
              <Chip
                color="primary"
                size="small"
                label={getOptionLabel(option)}
                {...getTagProps({ index })}
              />
            ),
          )
        }
        open={open}
        value={values}
        filterOptions={useCallback(
          (options, params) => {
            const filtered = filter(options, params)

            if (isFetching) {
              filtered.push({
                id: LOADING_ID,
                name: 'Loading…',
              })
            } else if (
              createMutation &&
              params.inputValue !== '' &&
              !options.some(
                ({ name }) =>
                  optionLabel(name) === optionLabel(params.inputValue),
              )
            ) {
              filtered.push({
                id: CREATE_ID,
                name: params.inputValue,
              })
            }

            return filtered
          },
          [optionLabel, createMutation, isFetching],
        )}
        options={options || []}
        loading={isFetching}
      />
    </>
  )
}

export default DynamicMultipleSelect
