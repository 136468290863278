import React, { useEffect, useMemo } from 'react'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import { DateTimePicker } from '@material-ui/pickers'

import { withAdmin } from '../../services/msal'
import { useDownloadCsv, useReportQuery } from '../../services/stream-manager'

import useFilterParams from '../../hooks/useFilterParams'
import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import AlertDialog from '../dialogs/AlertDialog'


const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%"
  },
  downloadButton: {
    marginTop: theme.spacing(1),
    height: "80%"
  }
}))

const Report = () => {
  const classes = useStyles()

  const today = useMemo(() => moment(), [])
  const monthAgo = useMemo(() => moment().subtract(1, 'month'), [])

  const paramDefaults = {
    from: monthAgo,
    until: today,
    report_type: 'stream_audit',
  }

  const [{ from, until, report_type }, setFilterParams] = useFilterParams(paramDefaults)

  useEffect(() => {
    if (until.isSameOrBefore(from, 'second')) {
      setFilterParams({ from: until.clone().subtract(1, 'month') })
    }
  }, [from, until, setFilterParams])

  const [downloadReportTrigger, downloadReportResult] =
    useDownloadCsv(usePaginatedFilteredQuery(useReportQuery, paramDefaults))({
      params: {
        start: from.unix(),
        stop: until.unix(),
        report_type,
      }
    })

  return (
    <>
      <AlertDialog
        open={downloadReportResult.isError}
        status={downloadReportResult.error?.status}
        alert={downloadReportResult.error?.data}
      />
      <Box p={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <DateTimePicker
              className={classes.formControl}
              label="From"
              format="DD MMM YYYY HH:mm"
              onChange={(from) => setFilterParams({ from })}
              value={from}
              maxDate={today}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DateTimePicker
              className={classes.formControl}
              label="Until"
              format="DD MMM YYYY HH:mm"
              onChange={(until) => setFilterParams({ until })}
              value={until}
              maxDate={today}
              margin="dense"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl} margin="dense">
              <InputLabel id="division-select-input-label" margin="dense">
                Report Type
              </InputLabel>
              <Select
                labelId="report-type-label"
                id="report-type-select"
                value={report_type}
                onChange={(e) => setFilterParams({ report_type: e.target.value })}
                label="Type"
                margin="dense"
              >
                <MenuItem value="stream_audit" key="stream_audit">
                  Stream audit
                </MenuItem>
                <MenuItem value="user_audit" key="user_audit">
                  User audit
                </MenuItem>
                <MenuItem value="media_server_audit" key="media_server_audit">
                  Media server audit
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadReportTrigger}
              className={classes.downloadButton}
              fullWidth
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export const ReportPage = withAdmin(Report)
