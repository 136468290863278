import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import slug from 'slug'
import qs from 'qs'

import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'

import useFilterParams from '../../hooks/useFilterParams'

import SiteList from './SiteList'

import useSurfaceStyles from './styles'


const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: 24,
  },
  iconButton: {
    margin: `-${theme.spacing(2)}px 0`,
  },
}))

const DivisionListItem = ({ division, onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const classes = useStyles()

  const routeMatch = useRouteMatch('/streams')
  const [filter] = useFilterParams()

  const [open, setOpen] = useState(
    routeMatch &&
    filter.division?.id === division.id
  )

  const selected =
    routeMatch &&
    !filter.site &&
    !filter.section &&
    filter.division?.id === division.id &&
    filter.type !== 'video'

  const avatar = division.name.slice(0, 2)

  return (
    <div>
      <ListItem
        className={surfaceClasses.listItem}
        classes={{ selected: surfaceClasses.listItemSelected }}
        selected={selected}
        button
        onClick={() => {
          setOpen(true)
          onSelect()
        }}
        component={Link}
        to={`/streams?${qs.stringify({
          division: slug(division.name),
        })}`}
      >
        <ListItemIcon>
          <Avatar className={clsx(surfaceClasses.drawerIcons, classes.avatar)}>
            {avatar}
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={division.name} />
        <Tooltip title={`${open ? 'Collapse' : 'Expand'} division`}>
          <IconButton
            className={classes.iconButton}
            size="small"
            color="inherit"
            arial-label={`${open ? 'Collapse' : 'Expand'} division`}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setOpen((open) => !open)
            }}
          >
            {open ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        </Tooltip>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <SiteList division={division} onSelect={onSelect} />
      </Collapse>
    </div>
  )
}

DivisionListItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  division: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
}

export default DivisionListItem
