import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles((theme) => ({
  progress: {
    marginBottom: -theme.spacing(0.5),
  },
}))

const ConfirmDialog = ({ title, text, onClose, open, loading }) => {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!loading) {
          onClose('no')
        }
      }}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      {loading && <LinearProgress className={classes.progress} />}
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose('no')}
          color="primary"
          autoFocus
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose('yes')}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
}

export default ConfirmDialog
