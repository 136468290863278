import React, {
  forwardRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { sanitize } from 'dompurify'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { use100vh } from 'react-div-100vh'
import _reverse from 'lodash/fp/reverse'

import { makeStyles, useTheme, alpha } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import { useFavouriteStreamsQuery } from '../../services/stream-manager'
import {
  useTeamsChannelQuery,
  useTeamsChannelMessagesQuery,
} from '../../services/microsoft-graph'

import usePrefs from '../../hooks/usePrefs'
import useWidth from '../../hooks/useWidth'

import PageTitle from '../../components/PageTitle'

import Tour from '../tour'

import Container from '../layout/Container'
import Player from '../player/Player'

import FavouriteButton from './FavouriteButton'

import teamsIcon from './teams-icon.svg'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'


const isTeamsEnabled = Boolean(window.__ENV.REACT_APP_TEAMS_TEAM_ID && window.__ENV.REACT_APP_TEAMS_CHANNEL_ID)

const GridLayout = WidthProvider(Responsive)

const useStyles = makeStyles((theme) => ({
  pageWrapper: ({ isMobileOrTablet, fullHeight }) => !isMobileOrTablet ? {
    position: 'absolute',
    top: 0,
    height: fullHeight,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  } : undefined,
  desktop: {},
  layoutWrapper: {
    '&$desktop': {
      flex: 1,
      display: 'flex',
      overflow: 'hidden !important',
    },
  },
  contentWrapper: ({ isMobileOrTablet }) => !isMobileOrTablet ? {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  } : undefined,
  container: {
    paddingTop: `${theme.spacing(4)}px !important`,
    '&$desktop': {
      flex: 1,
      overflow: 'overlay !important',
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  sidebar: {
    width: 350,
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
  },
  sectionHeader: {
    position: 'sticky',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    height: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
  sectionIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    margin: theme.spacing(1),
  },
  sectionTitles: {
    flex: '1 1 0',
    overflow: 'hidden !important',
  },
  sectionTitle: {
    color: theme.palette.text.primary,
    textOverflow: 'ellipsis',
    overflow: 'hidden !important',
    whiteSpace: 'nowrap',
  },
  sectionSubtitle: {
    fontSize: '0.75em',
    color: theme.palette.text.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden !important',
    whiteSpace: 'nowrap',
  },
  section: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden !important',
    '& $content': {
      flex: '1',
      overflow: 'overlay !important',
      minHeight: 0,
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '&$loading': {
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&$empty': {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  loading: {},
  spinner: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto`,
  },
  emptyMessage: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },
  messageWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    '&:hover > $messageLink': {
      display: 'block',
    },
    '& $messageWrapper': {
      marginTop: 0,
    },
  },
  messageLink: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'none',
    fontSize: theme.spacing(2),
  },
  messageAuthor: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.75em',
  },
  message: {
    margin: theme.spacing(1),
    '& img, & video': {
      maxWidth: 'max-content',
      minHeight: 0,
      width: '100% !important',
      height: 'auto !important',
    },
  },
  messageDate: {
    margin: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.75em',
    textAlign: 'right',
  },
  messageReplies: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
  },
  messageReply: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  '@global': {
    '.react-grid-item.react-grid-placeholder': {
      background: theme.palette.primary.main,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  grid: {
    margin: -theme.spacing(2),
  },
  player: {
    width: '100%',
    height: 'auto',
  },
  empty: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  favouriteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(1),
  },
  notDraggable: {},
  resizeHandle: {
    position: 'absolute',
    opacity: 0,
    minWidth: theme.spacing(1),
    minHeight: theme.spacing(1),
  },
  'resizeHandle-n': {
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'ns-resize',
  },
  'resizeHandle-s': {
    bottom: 0,
    left: 0,
    width: '100%',
    cursor: 'ns-resize',
  },
  'resizeHandle-w': {
    top: 0,
    left: 0,
    height: '100%',
    cursor: 'ew-resize',
  },
  'resizeHandle-e': {
    top: 0,
    right: 0,
    height: '100%',
    cursor: 'ew-resize',
  },
  'resizeHandle-nw': {
    top: 0,
    left: 0,
    zIndex: 1,
    cursor: 'nwse-resize',
  },
  'resizeHandle-ne': {
    top: 0,
    right: 0,
    zIndex: 1,
    cursor: 'nesw-resize',
  },
  'resizeHandle-se': {
    bottom: 0,
    right: 0,
    zIndex: 1,
    cursor: 'nwse-resize',
  },
  'resizeHandle-sw': {
    bottom: 0,
    left: 0,
    zIndex: 1,
    cursor: 'nesw-resize',
  },
  gridItem: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    cursor: 'grab',
  },
  dragging: {
    '& $gridItem': {
      cursor: 'grabbing',
    },
  },
}))

// Resizing from the top or left is generally not intuitive.
// const resizeHandles = ['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne'];
const resizeHandles = ['s', 'e', 'se']

const columns = {
  lg: 4,
  md: 4,
  sm: 3,
  xs: 2,
  xxs: 1,
}

const layout = (cols) => (stream, i) => ({
  i: stream.id,
  x: i % cols,
  y: Math.floor(i / cols),
  w: 1,
  h: 1,
})

const ResizeHandle = forwardRef(
  ({ className, style, handleAxis, ...props }, ref) => {
    const classes = useStyles()
    return (
      <div
        ref={ref}
        style={style}
        className={clsx(
          classes.notDraggable,
          classes.resizeHandle,
          classes[`resizeHandle-${handleAxis}`],
          className,
        )}
        {...props}
      />
    )
  },
)

const DashboardStream = ({ id }) => {
  const classes = useStyles()

  const [{ dashboardPlaying }, setPrefs] = usePrefs()

  const handlePlay = useCallback(() => {
    if (!dashboardPlaying?.[id]) {
      return setPrefs({
        dashboardPlaying: { ...dashboardPlaying, [id]: true },
      })
    }
  }, [id, dashboardPlaying, setPrefs])

  const handlePause = useCallback(() => {
    if (dashboardPlaying?.[id]) {
      const { [id]: _, ..._dashboardPlaying } = dashboardPlaying
      return setPrefs({
        dashboardPlaying: _dashboardPlaying,
      })
    }
  }, [id, dashboardPlaying, setPrefs])

  return (
    <Player
      className={classes.player}
      notDraggable={classes.notDraggable}
      id={id}
      play={dashboardPlaying?.[id]}
      onPlay={handlePlay}
      onPause={handlePause}
      extraActions={
        <FavouriteButton
          className={classes.favouriteButton}
          streamId={id}
          inPlayer
        />
      }
    />
  )
}

const DashboardPage = () => {
  const width = useWidth()
  const isMobile = width.match(/xs/)
  const isMobileOrTablet = width.match(/(xs|sm)/)
  const fullHeight = use100vh()
  const classes = useStyles({ isMobileOrTablet, fullHeight })
  const theme = useTheme()

  const streamsQuery = useFavouriteStreamsQuery()

  const [{ dashboardLayouts }, setPrefs] = usePrefs()

  const layouts = useMemo(
    () =>
      dashboardLayouts ??
      Object.fromEntries(
        Object.entries(columns).map(([breakpoint, columns]) => [
          breakpoint,
          streamsQuery.data?.map(layout(columns)) ?? [],
        ]),
      ),
    [dashboardLayouts, streamsQuery.data],
  )

  const streams = useMemo(
    () =>
      streamsQuery.data?.map(({ id }, i) => (
        <div data-tour="dashboard-stream" className={classes.gridItem} key={id}>
          <DashboardStream id={id} />
        </div>
      )) ?? [],
    [streamsQuery.data, classes.gridItem],
  )

  const [dragging, setDragging] = useState(false)

  const [showTour, setShowTour] = useState(false)

  useEffect(() => {
    let timer
    if (streamsQuery.data) {
      timer = setTimeout(() => {
        setShowTour(true)
      }, 300)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [streamsQuery.data])

  const teamsChannelQuery = useTeamsChannelQuery(undefined, { skip: isMobile })
  const teamsChannelMessagesQuery = useTeamsChannelMessagesQuery(undefined, {
    skip: isMobile,
  })

  return (
    <>
      <Tour
        name="dashboard"
        run={showTour}
        steps={[
          {
            target: '[data-tour="dashboard-stream"]',
            title: '⭐ Add a stream',
            content: 'Star a stream on any page to add them to this page.​',
            placement: 'right-start',
          },
          {
            target: '[data-tour="dashboard-stream"]',
            title: '👆 Drag and drop a stream​',
            content:
              'Click and drag the stream when you see the 👆 pointer to move its position around the page.​',
            placement: 'bottom',
          },
          {
            target: '[data-tour="dashboard-stream"]',
            title: '↔ Resize a stream​',
            content:
              'Click on the edge of the stream when you see the ↔ pointer to change its size.​',
            placement: 'right-end',
          },
        ]}
      />
      <div className={classes.pageWrapper}>
        {!isMobileOrTablet && <Toolbar />}
        <div
          className={clsx(classes.layoutWrapper, {
            [classes.desktop]: !isMobileOrTablet,
          })}
        >
          <div className={classes.contentWrapper}>
            <PageTitle>My Dashboard</PageTitle>
            <Container
              className={clsx(classes.container, {
                [classes.desktop]: !isMobileOrTablet,
              })}
            >
              {streams.length > 0 ? (
                <GridLayout
                  isDraggable={!isMobile}
                  isResizable={!isMobile}
                  className={clsx(classes.grid, { [classes.dragging]: dragging })}
                  margin={Array.from({ length: 2 }, () => theme.spacing(2))}
                  cols={columns}
                  layouts={layouts}
                  onLayoutChange={(_, dashboardLayouts) => {
                    setPrefs({ dashboardLayouts })
                  }}
                  draggableCancel={`.${classes.notDraggable}`}
                  resizeHandles={resizeHandles}
                  resizeHandle={<ResizeHandle />}
                  onDrag={() => setDragging(true)}
                  onDragStop={() => setDragging(false)}
                >
                  {streams}
                </GridLayout>
              ) : (
                <div className={classes.empty}>
                  {streamsQuery.isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.emptyMessage}
                    >
                      You have no favourite streams. Mark a stream as a favourite
                      by clicking on its star icon.
                    </Typography>
                  )}
                </div>
              )}
            </Container>
          </div>
          {isTeamsEnabled && !isMobileOrTablet && (
            <Paper className={classes.sidebar}>
              <div className={classes.section}>
                <a
                  className={classes.sectionHeader}
                  href={teamsChannelQuery.data?.webUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img
                    className={classes.sectionIcon}
                    src={teamsIcon}
                    alt="Microsoft Teams Logo"
                  />
                  <div className={classes.sectionTitles}>
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.sectionTitle}
                    >
                      {teamsChannelQuery.data?.displayName}
                    </Typography>
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.sectionSubtitle}
                    >
                      {teamsChannelQuery.data?.description}
                    </Typography>
                  </div>
                  <Tooltip title="Open this channel in the Microsoft Teams app">
                    <IconButton className={classes.sectionButton}>
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </a>
                <div
                  className={clsx(classes.content, {
                    [classes.loading]: teamsChannelMessagesQuery.isLoading,
                    [classes.empty]: teamsChannelMessagesQuery.data?.length === 0,
                  })}
                >
                  {teamsChannelMessagesQuery.isFetching ? (
                    <CircularProgress className={classes.spinner} />
                  ) : teamsChannelMessagesQuery.data?.length === 0 ? (
                    <Typography
                      variant="h6"
                      component="p"
                      className={classes.emptyMessage}
                    >
                      No messages yet
                    </Typography>
                  ) : (
                    _reverse(teamsChannelMessagesQuery.data).map((message) => (
                      <Paper key={message.id} className={classes.messageWrapper}>
                        <Tooltip title="Open message in Microsoft Teams App">
                          <IconButton
                            className={classes.messageLink}
                            component="a"
                            href={message.webUrl}
                            target="_blank"
                            rel="noreferrer noopener"
                            size="small"
                          >
                            <OpenInNewIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                        <div className={classes.messageAuthor}>
                          {message.from?.user.displayName}
                        </div>
                        <div
                          className={classes.message}
                          dangerouslySetInnerHTML={{
                            __html: sanitize(message.body.content),
                          }}
                        />
                        {message.attachments.length > 0 && (
                          <div className={classes.messageAttachments}>
                            {message.attachments.length > 0 && (
                              <div className={classes.messageAttachment}></div>
                            )}
                          </div>
                        )}
                        {message.reactions.length > 0 && (
                          <div className={classes.messageReactions}>
                            {message.reactions.length > 0 && (
                              <div className={classes.messageReaction}></div>
                            )}
                          </div>
                        )}
                        <div className={classes.messageDate}>
                          {moment(message.lastModifiedDateTime).calendar({
                            sameDay: 'LT',
                            sameElse: 'DD/MM/YYYY [at] LT',
                          })}
                        </div>
                        {message.replies?.length > 0 && (
                          <div className={classes.messageReplies}>
                            {message.replies.map((reply) => (
                              <Paper
                                key={reply.id}
                                variant="outlined"
                                className={clsx(
                                  classes.messageReply,
                                  classes.messageWrapper,
                                )}
                              >
                                <Tooltip title="Open message in Microsoft Teams App">
                                  <IconButton
                                    className={classes.messageLink}
                                    component="a"
                                    href={reply.webUrl}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    size="small"
                                  >
                                    <OpenInNewIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <div className={classes.messageAuthor}>
                                  {reply.from.user.displayName}
                                </div>
                                <div
                                  className={classes.message}
                                  dangerouslySetInnerHTML={{
                                    __html: sanitize(reply.body.content),
                                  }}
                                />
                                {reply.attachments.length > 0 && (
                                  <div className={classes.messageAttachments}>
                                    {reply.attachments.length > 0 && (
                                      <div
                                        className={classes.messageAttachment}
                                      ></div>
                                    )}
                                  </div>
                                )}
                                {reply.reactions.length > 0 && (
                                  <div className={classes.messageReactions}>
                                    {reply.reactions.length > 0 && (
                                      <div
                                        className={classes.messageReaction}
                                      ></div>
                                    )}
                                  </div>
                                )}
                                <div className={classes.messageDate}>
                                  {moment(reply.lastModifiedDateTime).calendar({
                                    sameDay: 'LT',
                                    sameElse: 'DD/MM/YYYY [at] LT',
                                  })}
                                </div>
                              </Paper>
                            ))}
                          </div>
                        )}
                      </Paper>
                    ))
                  )}
                </div>
              </div>
            </Paper>
          )}
        </div>
      </div>
    </>
  )
}

export default DashboardPage
