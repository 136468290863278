import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
  MapContainer,
  MapConsumer,
  Marker,
  Popup,
  GeoJSON,
  ScaleControl,
} from 'react-leaflet'
import { useHistory } from 'react-router-dom'
import { TiledMapLayer } from 'react-esri-leaflet'
import { useInterval } from 'react-use'
import L from 'leaflet'
import clsx from 'clsx'
import slug from 'slug'
import qs from 'qs'
import _get from 'lodash/fp/get'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Toolbar from '@material-ui/core/Toolbar'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import StreamIcon from 'mdi-material-ui/Cctv'
import VODIcon from 'mdi-material-ui/Vhs'
import GPSIcon from 'mdi-material-ui/MapMarkerPath'

import useFilterParams from '../../hooks/useFilterParams'
import usePaginatedFilteredQuery from '../../hooks/usePaginatedFilteredQuery'

import PageTitle from '../../components/PageTitle'

import {
  useSitesQuery,
  useDivisionsQuery,
  useStreamsQuery,
  useStreamTrackQuery,
} from '../../services/stream-manager'

import GlobalFilter from '../table/GlobalFilter'

import StreamPopup from '../streams/StreamPopup'
import StreamCard from '../streams/StreamCard'
import StreamsList from '../streams/StreamsList'

import Tour from '../tour'

import 'leaflet/dist/leaflet.css'
import './leaflet-darkmode.css'

const INITIAL_POSITION = [-28.748, 24.73] // TODO: get this from the user somehow (or set as deployment/site config)
const GPS_TRACKS_ZOOM_LEVEL = 13 // Level at which GPS tracks can be viewed

const panZoomOptions = {
  duration: 0.5,
  easeLinearity: 0.5,
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.leaflet-container a': {
      color: 'inherit !important',
    },
    '.leaflet-popup-content-wrapper': {
      padding: '0 !important',
    },
    '.leaflet-popup-content': {
      margin: '0 !important',
      width: 'unset !important',
    },
    '.leaflet-popup-content p': {
      margin: '0 !important',
    },
    '.leaflet-popup-close-button': {
      display: 'none',
    },
    '.leaflet-popup-tip': {
      background: theme.palette.background.paper,
    },
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mapWrapper: {
    position: 'relative',
    flex: 1,
  },
  streamCard: {
    width: 300,
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    position: 'absolute',
    maxHeight: `calc(100% - ${theme.spacing(19)}px)`,
    top: theme.spacing(4),
    right: theme.spacing(2),
  },
  search: {
    backgroundColor: `${theme.palette.background.paper} !important`,
    boxShadow: theme.shadows[2],
    marginRight: 0,
  },
  searchResults: {
    display: 'flow-root',
    flexShrink: 1,
    overflow: 'overlay !important',
    marginTop: theme.spacing(2),
    minWidth: '100%',
    maxWidth: theme.spacing(60),
    transition: theme.transitions.create(['opacity'], {
      duration: theme.transitions.duration.shorter,
    }),
    opacity: 0.7,
    '&:hover, &:focus-within': {
      opacity: 1,
    },
    fallbacks: {
      overflow: 'auto !important',
    },
  },
  gpsTrackToggleWrapper: {
    position: 'absolute',
    paddingLeft: theme.spacing(2),
    top: 12,
    left: 56,
  },
  streamMarker: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    borderRadius: '50% 50% 50% 0',
    transform: 'rotate(-45deg)',
  },
  streamMarkerIcon: {
    fontSize: theme.spacing(2.5),
    color: theme.palette.primary.main,
    transform: 'rotate(45deg) translateX(12.5%)',
  },
  siteMarker: {
    width: '0 !important',
    height: '0 !important',
  },
  siteMarkerText: {
    display: 'block',
    width: 'fit-content',
    height: 'fit-content',
    transform: 'translate(-50%, -50%)',
    fontFamily: 'Arial, sans-serif',
    fontWeight: 'bold',
    fontSize: 16,
    textDecoration: 'none',
    '&[class][class]': {
      color: `${theme.palette.common.white} !important`,
    }
  },
}))

// https://codesandbox.io/s/how-to-call-fitbounds-and-getbounds-in-react-leaflet-1m50m?file=/src/Map.js:0-5296

const mapStyle = {
  width: '100%',
  height: '100%',
  // This is to create a stacking context,
  // Leaflet has an element with z-index 400 in its DOM
  // which is just way too much. This isolates that from the rest.
  // https://www.joshwcomeau.com/css/stacking-contexts/
  isolation: 'isolate',
}

const MapPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const mapRef = useRef()

  const [selectedStreamId, setSelectedStreamId] = useState(null)
  const [showGpsTracksToggle, setShowGpsTracksToggle] = useState(false)
  const [showGpsTracks, setShowGpsTracks] = useState(false)
  const [streamsInView, setStreamsInView] = useState([])
  const [sitesInView, setSitesInView] = useState([])

  const [filterParams] = useFilterParams()

  const sitesQuery = useSitesQuery()
  const divisionsQuery = useDivisionsQuery()
  const streamsQuery = usePaginatedFilteredQuery(
    useStreamsQuery,
    { pagination: false },
  )()
  const streamsTrackQuery = useStreamTrackQuery(streamsInView.map(_get('id')), {
    skip: !showGpsTracksToggle || !showGpsTracks || streamsInView.length === 0,
  })

  useEffect(
    function setVisible() {
      const map = mapRef.current
      // Wait until the map's been loaded
      if (!map) {
        window.requestAnimationFrame(setVisible)
        return
      }

      const trackToggle = () => {
        const zoom = map.getZoom()

        if (zoom >= GPS_TRACKS_ZOOM_LEVEL) {
          setShowGpsTracksToggle(true)
        } else {
          setShowGpsTracksToggle(false)
        }
      }

      const streamsInView = () => {
        const bounds = map.getBounds()

        setStreamsInView(
          streamsQuery.data?.filter(
            (stream) =>
              stream.lat &&
              stream.long &&
              bounds.contains(L.latLng(stream.lat, stream.long)),
          ) ?? [],
        )
      }

      const sitesInView = () => {
        const zoom = map.getZoom()

        if (zoom < Number(window.__ENV.REACT_APP_MAP_SITE_LABEL_VISIBITILY_ZOOM_LEVEL ?? 5)) {
          setSitesInView([])
          return
        }

        const bounds = map.getBounds()

        setSitesInView(
          sitesQuery.data?.filter(
            (site) =>
              site.lat &&
              site.long &&
              bounds.contains(L.latLng(site.lat, site.long)),
          ) ?? [],
        )
      }

      trackToggle()
      streamsInView()
      sitesInView()

      map.on('zoomend', trackToggle)
      map.on('zoomend', streamsInView)
      map.on('moveend', streamsInView)
      map.on('zoomend', sitesInView)
      map.on('moveend', sitesInView)

      return () => {
        map?.off('zoomend', trackToggle)
        map?.off('zoomend', streamsInView)
        map?.off('moveend', streamsInView)
        map?.off('zoomend', sitesInView)
        map?.off('moveend', sitesInView)
      }
    },
    [streamsQuery.data, sitesQuery.data],
  )

  const streamMarkers = useMemo(() =>
    streamsInView.map((stream) => (
      <Marker
        key={stream.id}
        position={[stream.lat, stream.long]}
        icon={L.divIcon({
          className: '',
          // If you rotate a pin element in CSS, keep in mind that the result
          // icon size may be different than pin element size. In our example,
          // we rotate the element to 45 degrees, the height of the result
          // element is 30px * √2 = 42px
          iconSize: [theme.spacing(3), theme.spacing(3) * Math.SQRT2],
          iconAnchor: [theme.spacing(3) / 2, theme.spacing(3) * Math.SQRT2],
          html: ReactDOMServer.renderToString(
            <div data-tour="map-marker" className={classes.streamMarker}
              title={
                stream.has_track
                  ? 'Video with GPS track'
                  : stream.is_vod
                    ? 'Video'
                    : 'Live stream'
              }
            >
              {stream.has_track ? (
                <GPSIcon className={classes.streamMarkerIcon} />
              ) : stream.is_vod ? (
                <VODIcon className={classes.streamMarkerIcon} />
              ) : (
                <StreamIcon className={classes.streamMarkerIcon} />
              )}
            </div>
          ),
        })}
      >
        <Popup className={classes.popup}>
          <StreamCard
            className={classes.streamCard}
            stream={stream}
            onClick={() => setSelectedStreamId(stream.id)}
          />
        </Popup>
      </Marker>
    ))
    , [
      streamsInView,
      theme,
      classes.popup,
      classes.streamCard,
      classes.streamMarker,
      classes.streamMarkerIcon,
    ])

  const [siteMarkers, setSiteMarkers] = useState([])

  useEffect(function setSiteLabelInteractivity() {
    const map = mapRef.current
    // Wait until the map's been loaded
    if (!map) {
      window.requestAnimationFrame(setSiteLabelInteractivity)
      return
    }

    const linkZoomLevel = Number(window.__ENV.REACT_APP_MAP_SITE_LABEL_LINK_ZOOM_LEVEL ?? 14)

    const siteMarkers = () => {
      setSiteMarkers(sitesInView.map((site) => {
        if (site.lat == null || site.long == null) return null

        return (
          <Marker
            key={site.id}
            position={[site.lat, site.long]}
            icon={
              L.divIcon({
                className: classes.siteMarker,
                html: ReactDOMServer.renderToString(
                  <div
                    id={`siteMarker-${site.id}`}
                    className={classes.siteMarkerText}
                  >
                    {site.name}
                  </div>
                ),
              })
            }
          />
        )
      }).filter(Boolean))
    }

    const onClick = (e) => {
      if (e.target.classList.contains(classes.siteMarkerText)) {
        const zoom = map.getZoom()
        const siteId = e.target.id.match(/^siteMarker-(.*)/)[1]
        const site = sitesInView.find(({ id }) => id === siteId)

        if (zoom < linkZoomLevel && site) {
          map.flyTo([site.lat, site.long], linkZoomLevel, panZoomOptions)
        } else if (divisionsQuery.data && site) {
          history.push(`/streams?${qs.stringify({
            division: slug(divisionsQuery.data.find(({ id }) => id === site.division_id)?.name),
            site: slug(site.name),
          })}`)
        }
      }
    }

    siteMarkers()

    map._container.addEventListener('click', onClick)
    map.on('zoomend', siteMarkers)

    return () => {
      map?._container.removeEventListener('click', onClick)
      map?.off('zoomend', siteMarkers)
    }
  }, [
    history,
    sitesInView,
    divisionsQuery.data,
    classes.siteMarker,
    classes.siteMarkerText,
  ])

  const tracks = useMemo(
    () =>
      showGpsTracksToggle && showGpsTracks
        ? streamsTrackQuery.data
          ?.map((data, i) => (
            <GeoJSON
              key={streamsInView[i]?.id || i}
              data={data}
              style={{
                color: theme.palette.primary.light,
              }}
            />
          ))
        : undefined,
    [
      streamsTrackQuery.data,
      streamsInView,
      showGpsTracksToggle,
      showGpsTracks,
      theme,
    ],
  )

  // Fit the map to the bounds of the markers
  useEffect(
    function zoomMap() {
      const map = mapRef.current
      // Wait until the map's been loaded
      if (!map) {
        window.requestAnimationFrame(zoomMap)
        return
      }

      const timer = setTimeout(() => {
        if (streamsQuery.data?.length) {
          const bounds = L.latLngBounds(
            streamsQuery.data
              .filter((stream) => stream.lat && stream.long)
              .map((stream) => L.latLng(stream.lat, stream.long)),
          )
          if (bounds.isValid()) {
            map.fitBounds(bounds, {
              padding: Array.from({ length: 2 }, () => theme.spacing(4)),
              ...panZoomOptions,
            })
          }
        }
      }, 0)

      return () => {
        clearTimeout(timer)
      }
    },
    [streamsQuery.data, theme],
  )

  // FIXME: The proxy internally handles the token, but it doesn't refresh it.
  // This manually triggers a token refresh on the proxy side.
  // Remove once the proxy grows up and can handle token refreshing on its own :-)
  const [esriTokenRefreshed, setEsriTokenRefreshed] = useState(false)
  const refreshEsriToken = useCallback(() => {
    fetch(
      `${window.__ENV.REACT_APP_BASE_TILE_PROXY}?${window.__ENV.REACT_APP_BASE_TILE_LAYER_ANGLO}/?f=json`,
    )
      .catch(() => {}) // No-op
      .finally(() => setEsriTokenRefreshed(true))
  }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(refreshEsriToken, []) // On component mount
  useInterval(refreshEsriToken, 15 * 60 * 1000) // And periodically; token should last about an hour, but refreshing every 15 min to be safe

  return (
    <>
      <StreamPopup
        id={selectedStreamId}
        open={Boolean(selectedStreamId)}
        onClose={() => setSelectedStreamId(null)}
      />
      <Tour
        name="map"
        steps={[
          {
            target: '.leaflet-control-zoom',
            title: 'Zooming',
            content: (
              <>
                Click on these buttons to zoom in and out of the map. If you
                zoom close enough, a <strong>Show GPS tracks</strong> toggle
                will appear allowing you to show the GPS tracks recorded from a
                movable camera (e.g. drone) if they were uploaded along with the
                video.
              </>
            ),
            placement: 'right',
          },
          {
            target: '[data-tour="map-marker"]',
            title: 'Legend',
            content: (
              <>
                <p>
                  Markers show you where a stream or uploaded video is located.
                </p>
                <p>
                  A <strong>camera</strong> marker indicates the source is fixed
                  camera.
                </p>
                <p>
                  A <strong>cassette</strong> marker indicates an uploaded video
                  from a movable camera (e.g. drone).
                </p>
                <p>
                  A <strong>path</strong> marker indicates an uploaded video
                  with GPS tracks available. If you zoom close enough, a{' '}
                  <strong>Show GPS tracks</strong> toggle will appear allowing
                  you to show the GPS tracks.
                </p>
              </>
            ),
            placement: 'right',
          },
        ]}
      />
      <div
        className={clsx(classes.wrapper, {
          'leaflet-darkmode': theme.palette.type === 'dark',
        })}
      >
        <Toolbar />
        <PageTitle>Map</PageTitle>
        <div className={classes.mapWrapper}>
          <MapContainer
            center={INITIAL_POSITION}
            zoom={7}
            bounceAtZoomLimits={true}
            tap={false} // https://github.com/Leaflet/Leaflet/issues/7255#issuecomment-732082150
            style={mapStyle}
          >
            <ScaleControl position="bottomleft" />
            <MapConsumer>
              {(map) => {
                mapRef.current = map
                return (
                  <>
                    <TiledMapLayer
                      maxZoom={Number(window.__ENV.REACT_APP_BASE_TILE_MAX_ZOOM) ?? 21}
                      maxNativeZoom={Number(window.__ENV.REACT_APP_BASE_TILE_MAX_ZOOM_GLOBAL) ?? 17}
                      url={window.__ENV.REACT_APP_BASE_TILE_LAYER}
                      useCors={false}
                    />
                    {window.__ENV.REACT_APP_BASE_TILE_PROXY &&
                      window.__ENV.REACT_APP_BASE_TILE_LAYER_ANGLO &&
                      esriTokenRefreshed && (
                        <TiledMapLayer
                          minZoom={Number(window.__ENV.REACT_APP_BASE_TILE_MIN_ZOOM_ANGLO) ?? 15}
                          maxZoom={Number(window.__ENV.REACT_APP_BASE_TILE_MAX_ZOOM) ?? 21}
                          maxNativeZoom={Number(window.__ENV.REACT_APP_BASE_TILE_MAX_ZOOM_ANGLO) ?? 19}
                          url={window.__ENV.REACT_APP_BASE_TILE_LAYER_ANGLO}
                          proxy={window.__ENV.REACT_APP_BASE_TILE_PROXY}
                          useCors={false}
                        />
                      )}
                    {streamMarkers}
                    {siteMarkers}
                    {tracks}
                  </>
                )
              }}
            </MapConsumer>
          </MapContainer>

          <div className={classes.searchWrapper}>
            <GlobalFilter
              className={classes.search}
              tags
              groups
              location
              type
              dateTime
            />
            {(Object.values(filterParams).some(Boolean)) && (
              <Paper className={classes.searchResults}>
                <StreamsList
                  streamsQuery={streamsQuery}
                  onSelect={({ id }) => setSelectedStreamId(id)}
                  disableHealth
                />
              </Paper>
            )}
          </div>

          {showGpsTracksToggle && (
            <Paper className={classes.gpsTrackToggleWrapper}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showGpsTracks}
                    onChange={() =>
                      setShowGpsTracks((showGpsTracks) => !showGpsTracks)
                    }
                    name="gpsTrack"
                    color="primary"
                  />
                }
                label="Show GPS tracks"
              />{' '}
            </Paper>
          )}
        </div>
      </div>
    </>
  )
}

export default MapPage
