/**
 * Video stream player component - see Player docstring for more info.
 */

import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { usePrevious, useUpdateEffect } from 'react-use'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import throttle from 'lodash/throttle'
import Hls from 'hls.js'
import { useResizeDetector } from 'react-resize-detector'
import moment from 'moment'

import { useTheme, ThemeProvider } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeMuteIcon from '@material-ui/icons/VolumeMute'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import PlayIcon from '@material-ui/icons/PlayCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import CopyIcon from '@material-ui/icons/Assignment'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { DateTimePicker } from '@material-ui/pickers'

import useTimeout from '../../hooks/useTimeout'
import usePrefs from '../../hooks/usePrefs'

import {
  useStreamQuery,
  useStreamUrlQuery,
  useStreamTokenQuery,
} from '../../services/stream-manager'

import { streamUrl } from '../streams/utils'

import { DownloadStreamModalAndIcon } from './StreamDownload'

import DVRTimeline from './DVRTimeline'
import useStyles from './useStyles'
import {
  dateToStringSecs,
  dateToStringShort,
  msToTime,
  goFullScreen,
  exitFullScreen,
  playVideoSafely,
  MILLISECONDS_IN_MINUTE,
} from './utils'
import {
  MODE,
  timeUpdate,
  setSeeking,
  seek,
  livePlayback,
  setLoading,
  setChunkLoading,
  setFullscreen,
  setVideoProperties,
  showControls,
  pause,
  play,
  mute,
  setChunk,
  setPoster,
  init,
} from './state'
import { useOrchestratedReducer } from './Orchestrator'

// All in milliseconds
const DBLCLICK_INTERVAL = 300
const HIDE_CONTROLS_DELAY = 2000
const ADVANCE_NOTIFICATION = 2 * 60 * 1000
const MIN_TIME_BEFORE_ADVANCE_NOTIFICATION = 5 * 60 * 1000

// check for HLS.js support first, see
// https://github.com/video-dev/hls.js/#getting-started
const NATIVE_HLS = Hls.isSupported()
  ? false
  : document.createElement('video').canPlayType('application/vnd.apple.mpegurl')

const formatDuration = (time) =>
  ((duration) =>
    (
      String(duration.days()) +
      '.' +
      String(duration.hours()).padStart(2, '0') +
      ':' +
      String(duration.minutes()).padStart(2, '0') +
      ':' +
      String(duration.seconds()).padStart(2, '0')
    ).replace(/^0\./, ''))(moment.duration(time))

const formatUrlTime = (mode, time) =>
  mode === MODE.VOD ? formatDuration(time) : moment(time).format()

const TimePicker = ({
  timeline,
  currentTimeUCT,
  stream,
  mode,
  anchorPosition,
  open,
  setOpen,
}) => {
  const classes = useStyles()
  const [time, setTime] = useState(currentTimeUCT)

  const previousOpen = usePrevious(open)

  useEffect(() => {
    if (open && !previousOpen && currentTimeUCT != null) {
      setTime(currentTimeUCT)
    }
  }, [open, previousOpen, currentTimeUCT])

  return (
    <Popover
      classes={{ paper: classes.timePicker }}
      open={open}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={() => setOpen(false)}
    >
      {mode === MODE.VOD ? (
        <TextField
          label="Video URL time"
          value={formatUrlTime(mode, time)}
          onChange={(e) => {
            const value = e.target.value
              .replace(/[^\d]/g, '')
              .replace(/^0+/, '')
              .padStart(6, '0')
            const duration =
              value.length === 6
                ? value.replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3')
                : [
                  ...[...value]
                    .reverse()
                    .join('')
                    .replace(/(\d{2})(\d{2})(\d{2})(\d+)/, '$1:$2:$3.$4'),
                ]
                  .reverse()
                  .join('')
            const time = moment.duration(duration).asMilliseconds()
            const start = timeline?.[0]?.start
            const end = timeline?.[timeline?.length - 1]?.end
            if (start <= time && time <= end) {
              setTime(time)
            }
          }}
        />
      ) : (
        <>
          <DateTimePicker
            label="Video URL time"
            value={new Date(time)}
            minDate={new Date(timeline?.[0]?.start ?? 0)}
            onChange={(date) => setTime(date.valueOf())}
          />
        </>
      )}
      <Slider
        track={false}
        min={timeline?.[0]?.start}
        max={timeline?.[timeline?.length - 1]?.end}
        value={time}
        onChange={(_, value) => setTime(value)}
      />
      <Button
        variant="contained"
        onClick={async () => {
          if (stream) {
            await navigator.clipboard.writeText(
              `${streamUrl(stream)}?time=${formatUrlTime(mode, time)}`,
            )
            setOpen(false)
          }
        }}
      >
        Copy video URL at{' '}
        {mode === MODE.VOD ? msToTime(time) : dateToStringShort(new Date(time))}
      </Button>
    </Popover>
  )
}

/**
 * Video stream player component.
 *
 * This uses native HLS support on Mobile Safari and Hls.js on other platforms
 * to play Hls streams from our nimble backend.
 *
 * The player has three main modes: live video, historic video, and video on
 * demand,
 *
 * In live mode, we set the Hls playlist src, and we're done. We only have to
 * keep the current wallclock time up to date to display that correctly.
 *
 * In historic mode, we have to manage the dynamic loading of 15 minute
 * chunklists as the user keeps viewing, or jumps around.
 *
 * In video on demand mode, we don't have a timeline, it's just a recorded video
 * so we create a fake timeline and make a few tweaks here and there.
 *
 * Important sub-components
 * ========================
 * - FRAG_CHANGED: update wallclock start time of currently loaded chunklist for
 *   Hls.js (in utils.js)
 * - handleLoadedData: ditto for Safari
 * - handleTimeUpdate: Maintain the wallclock time of the currently playing frame
 *   based on video.currentTime
 * - handleChunkLoad: when a new chunklist comes in, adjust video.currentTime
 *   according to current wallclock time*
 * - handleSeekEnd: handle when user clicks anywhere on history timeline
 */
const Player = forwardRef(
  (
    {
      className,
      id,
      accessToken,
      digitalSignage,
      play: playProp,
      time: timeProp,
      onClose,
      onPlay: _onPlay,
      onPause: _onPause,
      extraActions,
      notDraggable,
    },
    ref,
  ) => {
    const playerRootRef = useRef()
    const videoRef = useRef()

    const onPlayRef = useRef(_onPlay)
    const onPauseRef = useRef(_onPause)

    useEffect(() => { onPlayRef.current = _onPlay }, [_onPlay])
    useEffect(() => { onPauseRef.current = _onPause }, [_onPause])

    const [hasLoaded, setHasLoaded] = useState(false)

    const size = useResizeDetector({ targetRef: playerRootRef })

    const [{ countdownNotifications }, setPrefs] = usePrefs()

    const toggleNotifications = useCallback(async () => {
      if (!countdownNotifications) {
        let permission = await Notification.requestPermission()
        if (!permission) {
          // Safari uses the deprecated callback API
          permission = await new Promise((resolve) =>
            Notification.requestPermission(resolve),
          )
        }
        if (permission === 'granted') {
          setPrefs({ countdownNotifications: true })
        }
      } else {
        setPrefs({ countdownNotifications: undefined })
      }
    }, [setPrefs, countdownNotifications])

    const [
      playerState,
      dispatch,
    ] = useOrchestratedReducer({ id, ref: videoRef })

    const {
      loading,
      live,
      currentTimeUCT,
      position,
      paused,
      muted,
      seeking,
      chunkLoading,
      fullscreen,
      controls,
      poster,
      src,
      chunk,
      downloadTimeLimit,
      timeline,
      mode,
      width,
      height,
      audio,
    } = playerState

    /*
     * We want consumers to be able to access various properties via ref:
     *
     * 1. the video player element,
     * 2. container element,
     * 3. assorted player state functions, and
     * 4. the player state
     *
     * If there are name clashes, items further down in this list will overwrite
     * ones above it.
     *
     * This means that for most HTML properties, the root container element is
     * what is referenced, for HTMLMediaElement specific properties, the video
     * element is what is referenced, except when those properties are shadowed
     * by the explicitly exposed state functions or player state variables
     */
    useImperativeHandle(ref, () => {
      const handle = [videoRef, playerRootRef].reduce((acc, ref) => {
        for (const prop in ref.current) {
          if (typeof ref.current[prop] === 'function') {
            acc[prop] = (...args) => ref.current[prop](...args)
          } else {
            Object.defineProperty(acc, prop, {
              configurable: true,
              get() { return ref.current[prop] },
              set(value) { ref.current[prop] = value },
            })
          }
        }
        return acc
      }, {})

      handle.play = () => dispatch(play())
      handle.pause = () => dispatch(pause())
      handle.mute = (muted) => dispatch(mute(muted))
      handle.seek = (position) => dispatch(seek(position))
      handle.livePlayback = () => dispatch(livePlayback())
      handle.setFullscreen = (fullscreen) => dispatch(setFullscreen(fullscreen))

      for (const prop in playerState) {
        Object.defineProperty(handle, prop, { get() { return playerState[prop] } })
      }

      return handle
    })

    const previousChunkStart = usePrevious(chunk.start)
    const previousPosition = usePrevious(position)

    const theme = useTheme()
    const isMobile = size.width < theme.breakpoints.values.sm
    const isMobileOrTablet = size.width < theme.breakpoints.values.md
    const classes = useStyles({
      width,
      height,
      isMobile,
      isMobileOrTablet,
      digitalSignage,
    })

    const headers = useMemo(
      () => ({
        ...(accessToken != null
          ? { Authorization: `Basic ${accessToken}` }
          : undefined),
      }),
      [accessToken],
    )

    const streamQuery = useStreamQuery({ id, headers }, { skip: id == null, status: true })
    const streamPlaylistBaseUrlQuery = useStreamUrlQuery(
      { id: streamQuery.data?.id, headers },
      { skip: !streamQuery.data || streamQuery.data.is_vod },
    )
    const streamTokenQuery = useStreamTokenQuery(
      { id: streamQuery.data?.id, method: 'stream', headers },
      { skip: !streamQuery.data },
    )

    const siteName = useMemo(
      () =>
        streamQuery.data
          ? [
            streamQuery.data.division?.name,
            streamQuery.data.site?.name,
            streamQuery.data.section?.name,
          ]
            .filter(Boolean) // Filter out falsy values
            .join(' / ')
          : '',
      [streamQuery.data],
    )

    useEffect(() => {
      if (
        streamQuery.data &&
        (streamQuery.data.is_vod ||
          (streamQuery.data.status && streamPlaylistBaseUrlQuery.data))
      ) {
        dispatch(
          init({
            stream: streamQuery.data,
            timeline: streamQuery.data.status?.timeline,
            download_limit: streamQuery.data.status?.download_limit,
            resolution: streamQuery.data.status?.resolution,
            currently_streaming: streamQuery.data.status?.currently_streaming,
            playlistBaseUrl: streamQuery.data.is_vod
              ? streamQuery.data.uri
              : streamPlaylistBaseUrlQuery.data,
          }),
        )
      }
    }, [
      dispatch,
      streamQuery.data,
      streamQuery.fulfilledTimeStamp,
      streamQuery.startedTimeStamp,
      streamPlaylistBaseUrlQuery.data,
    ])

    useEffect(
      function attachSrcToVideo() {
        if (!streamTokenQuery.data?.token || !src) return

        // add auth for get
        const srcWithToken =
          src + `?token=${encodeURIComponent(streamTokenQuery.data.token)}`

        const hlsConfigParams = {
          maxBufferSize: Number(window.__ENV.REACT_APP_HLS_PLAYER_MAX_BUFF_SIZE ?? null) || 512 * 1024,
          maxBufferLength: Number(window.__ENV.REACT_APP_HLS_PLAYER_MAX_BUFF_LENGTH ?? null) || 30
        }
        const hls = !NATIVE_HLS ? new Hls(hlsConfigParams) : undefined

        if (NATIVE_HLS) {
          videoRef.current.src = srcWithToken
        } else {
          hls.attachMedia(videoRef.current)
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(srcWithToken)
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
              playVideoSafely(videoRef.current)
            })
          })

          // https://github.com/video-dev/hls.js/blob/master/docs/API.md#runtime-events
          // "fired when fragment matching with current video position is changing"
          // the most important logic in here is the updating of the wallclock start
          // and end time of the current complete chunklist. On Safari, this is
          // handled by the onLoadedData() handler.
          hls.on(Hls.Events.FRAG_CHANGED, (_, data) => {
            // in live mode, we're usually playing the last fragment of the
            // playlist, so we see that program time which is the real time at
            // which this last fragment in the list tarts

            const programDateTime =
              data.frag.programDateTime || data.frag.start * 1000

            // video.currentTime is the time from the start of the whole
            // playlist in seconds; when shifting with a depth of 3600 e.g., this
            // will hang around at close to 3600.

            // currentTime, frag.startPTS and endPTS are relative to the current chunklist
            // frag.startPTS is the seconds timestamp at the start of this fragment relative to the playlist start
            // (frag.startPTS - frag.endPTS) is about 5 seconds, which is the length fo the current fragment
            // currentTime has to live on [startPTS, endPTS] FOR THE CURRENT FRAGMENT

            // videoRef.current.seekable should give you the seekable range of the
            // current playlist, i.e. all segments remember that in live + timeshift
            // mode.  this gets updated automatically as we push past the edge in
            // live mode

            // data.frag.startPTS (seconds at start of this fragment, relative to
            // start of first fragment in the currently loaded list) matches
            // data.frag.programDateTime. this will enable us to determine the start
            // and end time of the currently loaded video / chunk list.
            const chunk = {
              // programDateTime is in millis since epoch. By walking back to the
              // start of the list, we can find that start also in millis since epoch.
              start:
                programDateTime -
                1000 *
                (data.frag.startPTS - videoRef.current.seekable.start(0)),
              end:
                programDateTime +
                1000 * (videoRef.current.seekable.end(0) - data.frag.startPTS),
            }

            dispatch(setChunk(chunk))
          })

          hls.on(Hls.Events.ERROR, function (_, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  hls.startLoad()
                  break
                case Hls.ErrorTypes.MEDIA_ERROR:
                  hls.recoverMediaError()
                  break
                default:
                  hls.destroy()
                  break
              }
            }
          })
        }

        return () => {
          hls?.destroy()
        }
      },
      [dispatch, src, streamTokenQuery.data?.token, accessToken],
    )

    useEffect(
      function handleChunkChange() {
        if (mode === MODE.VOD || previousChunkStart === chunk.start) return

        dispatch(setChunkLoading(false))

        if (live) return

        // If we're here because the src URL changed in history mode remember
        // that the chunk we load starts at 5 minutes _before_ where we want to
        // be.
        const newCurrentTime = chunkLoading
          ? 5 * MILLISECONDS_IN_MINUTE
          : currentTimeUCT - previousChunkStart

        if (
          0 < newCurrentTime &&
          newCurrentTime < chunk.end - chunk.start &&
          Math.abs(newCurrentTime / 1000 - videoRef.current.currentTime) > 10
        ) {
          videoRef.current.currentTime = newCurrentTime / 1000
          if (!paused) {
            playVideoSafely(videoRef.current)
          } else {
            videoRef.current?.pause()
          }
        }
      },
      [
        dispatch,
        previousChunkStart,
        chunk,
        currentTimeUCT,
        live,
        mode,
        paused,
        chunkLoading,
      ],
    )

    const newResolution = useCallback(
      (video) => {
        if (
          video.videoWidth > 0 &&
          video.videoHeight > 0 &&
          (width / height !== video.videoWidth / video.videoHeight || // aspect ratios are different, or
            (video.videoWidth > width && video.videoHeight > height)) // new size is bigger
        ) {
          return {
            width: video.videoWidth,
            height: video.videoHeight,
          }
        }
        return { width, height }
      },
      [width, height],
    )

    useEffect(
      function handleVODPositionChange() {
        if (
          mode === MODE.VOD &&
          previousPosition !== position &&
          position != null
        ) {
          videoRef.current.currentTime = position / 1000
          playVideoSafely(videoRef.current)
        }
      },
      [mode, previousPosition, position, paused],
    )

    const resetVideo = useCallback(() => {
      dispatch(pause())
      videoRef.current.currentTime = 0
    }, [dispatch])

    const handleTimeUpdate = useCallback(
      (event) => {
        const video = event.target
        dispatch(timeUpdate(video.currentTime))
        if (video.ended) {
          resetVideo()
        }
        dispatch(setVideoProperties(newResolution(video)))
      },
      [dispatch, resetVideo, newResolution],
    )

    const handleFullscreen = useCallback(() => {
      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        goFullScreen(playerRootRef.current)
      } else {
        exitFullScreen()
      }
    }, [])

    const onFullscreenChange = useCallback(
      (event) => {
        if (!document.fullscreenElement && !document.webkitFullscreenElement) {
          dispatch(setFullscreen(false))
        } else if (
          document.fullscreenElement === event.target ||
          document.webkitFullscreenElement === event.target
        ) {
          dispatch(setFullscreen(true))
        }
      },
      [dispatch],
    )

    useEffect(
      function attachFullscreenChangeListener() {
        const playerOverlay = playerRootRef.current
        playerOverlay.addEventListener('fullscreenchange', onFullscreenChange)
        return () => {
          playerOverlay.removeEventListener(
            'fullscreenchange',
            onFullscreenChange,
          )
        }
      },
      [onFullscreenChange],
    )

    useEffect(
      function handlePlayStateChange() {
        if (!paused && !loading && !chunkLoading && !seeking) {
          playVideoSafely(videoRef.current)
        } else {
          setTimeout(() => videoRef.current?.pause(), 0)
        }
      },
      [paused, loading, chunkLoading, seeking],
    )

    useUpdateEffect(
      function handlePauseChange() {
        if (paused) {
          onPauseRef.current?.()
        } else {
          onPlayRef.current?.()
        }
      },
      [paused],
    )

    useEffect(
      function handlePlayPropChange() {
        dispatch(playProp ? play() : pause())
      },
      [dispatch, playProp],
    )

    const handleSeekStart = useCallback(() => {
      dispatch(setSeeking())
      dispatch(setPoster(videoRef.current))
    }, [dispatch])

    const handleSeekEnd = useCallback(
      (time) => {
        if (!timeline) return
        dispatch(seek(time))
      },
      [dispatch, timeline],
    )

    const originalTimeline = streamQuery.data?.status?.timeline

    useEffect(() => {
      if (!originalTimeline || !mode || !timeProp || !hasLoaded) return
      if (mode === MODE.VOD) {
        dispatch(seek(timeProp))
      } else {
        if (
          originalTimeline[0].start * 1000 < timeProp &&
          timeProp <= originalTimeline[originalTimeline.length - 1].end * 1000
        ) {
          dispatch(seek(timeProp))
        }
      }
    }, [dispatch, mode, originalTimeline, timeProp, hasLoaded])

    const initialCountdown = useMemo(
      () =>
        Math.max(
          0,
          timeProp - originalTimeline?.[originalTimeline?.length - 1].end,
        ) || undefined,
      [originalTimeline, timeProp],
    )

    const countdown =
      Math.max(0, timeProp - timeline?.[timeline?.length - 1].end) || undefined

    const [
      advanceCountdownNotification,
      setAdvanceCountdownNotification,
    ] = useState(false)
    const [countdownNotification, setCountdownNotification] = useState(false)

    useEffect(() => {
      if (
        countdownNotifications &&
        !advanceCountdownNotification &&
        initialCountdown >= MIN_TIME_BEFORE_ADVANCE_NOTIFICATION &&
        countdown <= ADVANCE_NOTIFICATION
      ) {
        new Notification('Get ready!', {
          body: `Your live video event will start in ${moment.duration(
            ADVANCE_NOTIFICATION,
          )}`,
          timestamp: timeProp,
          silent: true,
        })
        setAdvanceCountdownNotification(true)
      }
    }, [
      countdownNotifications,
      initialCountdown,
      advanceCountdownNotification,
      countdown,
      timeProp,
    ])

    useEffect(() => {
      if (
        countdownNotifications &&
        !countdownNotification &&
        countdown <= 1000
      ) {
        new Notification("It's time!", {
          body: `Your live video event has started`,
          timestamp: timeProp,
          renotify: true,
          requireInteraction: true,
        })
        setCountdownNotification(true)
      }
    }, [countdownNotifications, countdown, timeProp, countdownNotification])

    const handleCanPlay = useCallback(
      (event) => {
        const video = event.target

        setHasLoaded(true)

        dispatch(setLoading(false))
        dispatch(
          setVideoProperties({
            ...newResolution(video),
            duration: video.duration,
            audio:
              video.mozHasAudio ||
              Boolean(video.webkitAudioDecodedByteCount) ||
              Boolean(video.audioTracks && video.audioTracks.length),
          }),
        )

        if (!paused) {
          playVideoSafely(videoRef.current)
        } else {
          video.pause()
        }
      },
      [dispatch, paused, newResolution],
    )

    /**
     * Only on Safari, use the loadedmetadata event to update the start and and
     * times of the current loaded chunklist.
     *
     * The Hls.js version of this is done in the FRAG_CHANGE event handler.
     *
     */
    const handleLoadedData = useCallback(
      (event) => {
        // only safari does native HLS and supports getStartDate()
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement#browser_compatibility
        if (NATIVE_HLS) {
          const video = event.target
          if (typeof video.getStartDate === 'function') {
            const startMS = video.getStartDate().getTime()
            const start = startMS
            const end = startMS + video.duration * 1000
            dispatch(setChunk({ start, end }))
          } else {
            console.error(
              new Error(
                'Native HLS but no getStartDate(). This should never happen.',
              ),
            )
          }
        }
      },
      [dispatch],
    )

    const titleBarRef = useRef()
    const bottomBarRef = useRef()

    useEffect(() => {
      if (controls) {
        titleBarRef.current.classList.remove(classes.hidden)
        bottomBarRef.current.classList.remove(classes.hidden)
      } else {
        titleBarRef.current.classList.add(classes.hidden)
        bottomBarRef.current.classList.add(classes.hidden)
      }
    }, [controls, classes.hidden])

    const [resetControlsTimeout, clearControlsTimeout] = useTimeout(
      () => dispatch(showControls(false)),
      HIDE_CONTROLS_DELAY,
    )

    const handleVideoMouseMove = useMemo(
      () =>
        throttle(() => {
          dispatch(showControls(true))
          resetControlsTimeout()
        }),
      [dispatch, resetControlsTimeout],
    )

    useEffect(() => {
      if (!loading) resetControlsTimeout()
    }, [loading, resetControlsTimeout])

    const clickTimeout = useRef(null)
    const clickStartPos = useRef({ x: 0, y: 0 })
    const handlePointerDown = useCallback((event) => {
      clickStartPos.current.x = event.clientX
      clickStartPos.current.y = event.clientY
    }, [])
    const handlePointerUp = useCallback(
      (event) => {
        const movement = {
          x: event.clientX - clickStartPos.current.x,
          y: event.clientY - clickStartPos.current.y,
        }
        if (Math.abs(movement.x) > 2 || Math.abs(movement.y) > 2) {
          // Result of drag or resize, do nothing
          return
        }
        if (!clickTimeout.current) {
          // Single click
          clickTimeout.current = setTimeout(() => {
            dispatch(paused ? play() : pause())
            clickTimeout.current = null
          }, DBLCLICK_INTERVAL)
        } else {
          // Double click
          clearTimeout(clickTimeout.current)
          clickTimeout.current = null
          handleFullscreen()
        }
      },
      [dispatch, handleFullscreen, paused],
    )

    // Make the buttons smaller on mobile
    const dvrTheme = useCallback(
      (theme) => {
        if (!isMobile) return theme

        const scaleFactor = 0.8

        return {
          ...theme,
          typography: {
            ...theme.typography,
            body1: {
              ...theme.typography.body1,
              fontSize: `${parseFloat(theme.typography.body1.fontSize) * scaleFactor
                }rem`,
            },
            body2: {
              ...theme.typography.body2,
              fontSize: `${parseFloat(theme.typography.body2.fontSize) * scaleFactor
                }rem`,
            },
          },
          props: {
            ...theme.props,
            MuiIconButton: {
              size: 'small',
            },
          },
          overrides: {
            ...theme.overrides,
            MuiSvgIcon: {
              root: {
                fontSize: `${1.5 * scaleFactor}rem`,
              },
            },
          },
        }
      },
      [isMobile],
    )

    const [contextMenuPos, setContextMenuPos] = useState(null)
    const [contextMenuOpen, setContextMenuOpen] = useState(false)
    const [timePickerOpen, setTimePickerOpen] = useState(false)

    const handleContextMenuOpen = useCallback((e) => {
      e.preventDefault()
      setTimePickerOpen(false)
      setContextMenuPos({
        left: e.clientX - 2,
        top: e.clientY - 4,
      })
      setContextMenuOpen(true)
    }, [])

    const copyVideoUrl = useCallback(
      async (e) => {
        if (streamQuery.data) {
          await navigator.clipboard.writeText(streamUrl(streamQuery.data))
          setContextMenuOpen(false)
          setContextMenuPos(null)
        }
      },
      [streamQuery.data],
    )

    const copyVideoUrlAtCurrentTime = async (e) => {
      if (streamQuery.data) {
        await navigator.clipboard.writeText(
          `${streamUrl(streamQuery.data)}?time=${formatUrlTime(
            mode,
            currentTimeUCT,
          )}`,
        )
        setContextMenuOpen(false)
        setContextMenuPos(null)
      }
    }

    const formattedTimeProp = useMemo(
      () =>
        timeProp != null
          ? moment(timeProp).format('D MMM YYYY, h:mm:ssa')
          : undefined,
      [timeProp],
    )

    return (
      <div
        ref={playerRootRef}
        tabIndex={-1}
        className={clsx(classes.playerRoot, className)}
        onContextMenu={handleContextMenuOpen}
      >
        <TimePicker
          open={timePickerOpen}
          setOpen={(open) => {
            setTimePickerOpen(open)
            if (!open) {
              setContextMenuPos(null)
            }
          }}
          mode={mode}
          timeline={timeline}
          currentTimeUCT={currentTimeUCT}
          stream={streamQuery.data}
          anchorPosition={contextMenuPos ?? undefined}
        />
        <Menu
          keepMounted
          open={contextMenuOpen}
          onClose={() => {
            setContextMenuOpen(false)
            setContextMenuPos(null)
          }}
          anchorReference="anchorPosition"
          anchorPosition={contextMenuPos ?? undefined}
        >
          <MenuItem onClick={copyVideoUrl}>Copy Video URL</MenuItem>
          <MenuItem onClick={copyVideoUrlAtCurrentTime}>
            Copy Video URL at current time
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              const { left, top } = e.currentTarget.getBoundingClientRect()
              setContextMenuOpen(false)
              setContextMenuPos({
                left,
                top,
              })
              setTimePickerOpen(true)
            }}
          >
            Copy Video URL at specific time
          </MenuItem>
        </Menu>
        <ThemeProvider theme={dvrTheme}>
          <video
            autoPlay={playProp}
            playsInline
            width={width}
            height={height}
            poster={poster}
            className={classes.videoElement}
            ref={videoRef}
            muted={muted}
            onTimeUpdate={handleTimeUpdate}
            onCanPlay={handleCanPlay}
            onSeeking={() => dispatch(setLoading(true))}
            onSeeked={() => dispatch(setLoading(false))}
            onWaiting={() => dispatch(setLoading(true))}
            // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/loadeddata_event
            onLoadedData={handleLoadedData}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onMouseMove={handleVideoMouseMove}
          />
          {paused ? (
            <PlayIcon className={classes.playIcon} />
          ) : (
            (loading || chunkLoading) && (
              <div
                className={classes.loadingContainer}
                onMouseMove={handleVideoMouseMove}
              >
                <div className={classes.loadingBlock}>
                  <CircularProgress
                    color="primary"
                    size={isMobile ? 60 : isMobileOrTablet ? 90 : 120}
                    className={classes.spinner}
                  />
                  <Typography className={classes.loadingText}>
                    Loading video...
                  </Typography>
                </div>
              </div>
            )
          )}

          <Box
            ref={titleBarRef}
            onMouseEnter={clearControlsTimeout}
            onMouseLeave={resetControlsTimeout}
            className={clsx(notDraggable, classes.titleBar)}
          >
            <div className={classes.title}>
              <Typography variant="body1">
                {`${streamQuery.data?.name} : ${streamQuery.data?.description}`}
              </Typography>
              {siteName && <Typography variant="body2">{siteName}</Typography>}
            </div>
            {extraActions}
            {onClose && (
              <Tooltip arrow placement="bottom" title="Close video">
                <IconButton aria-label="close" onClick={onClose}>
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <div
            ref={bottomBarRef}
            onMouseEnter={clearControlsTimeout}
            onMouseLeave={resetControlsTimeout}
            className={clsx(notDraggable, classes.bottomBar)}
          >
            {countdown && (
              <Box className={classes.countdown}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    countdownNotifications
                      ? 'Notifications on'
                      : 'Notifications off'
                  }
                >
                  {'Notification' in window && (
                    <IconButton size="small" onClick={toggleNotifications}>
                      {countdownNotifications ? (
                        <NotificationsActiveIcon
                          className={classes.notificationsIcon}
                          fontSize="inherit"
                        />
                      ) : (
                        <NotificationsOffIcon
                          className={classes.notificationsIcon}
                          fontSize="inherit"
                        />
                      )}
                    </IconButton>
                  )}
                </Tooltip>{' '}
                <Tooltip
                  arrow
                  placement="top"
                  title={`Time until ${formattedTimeProp}`}
                >
                  <Typography variant="body1">
                    {formatDuration(countdown)}
                  </Typography>
                </Tooltip>
              </Box>
            )}
            <Box className={classes.controlsBar}>
              {timeline && (
                <DVRTimeline
                  currentTime={position}
                  timeline={timeline}
                  onSeekStart={handleSeekStart}
                  onSeekEnd={handleSeekEnd}
                  mode={mode}
                />
              )}
              {mode === MODE.LIVE &&
                (live ? (
                  <Tooltip arrow placement="top" title="Playing live video">
                    <IconButton className={classes.disabledIconButton}>
                      <LiveTvIcon className={classes.liveButtonSelected} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip arrow placement="top" title="Return to live video">
                    <IconButton onClick={() => dispatch(livePlayback())}>
                      <LiveTvIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                ))}
              <Tooltip arrow placement="top" title={paused ? 'Play' : 'Pause'}>
                <IconButton
                  aria-label={paused ? 'Play' : 'Pause'}
                  onClick={() => dispatch(paused ? play() : pause())}
                >
                  {paused ? (
                    <PlayArrowIcon className={classes.icon} />
                  ) : (
                    <PauseIcon className={classes.icon} />
                  )}
                </IconButton>
              </Tooltip>
              {audio ? (
                <Tooltip
                  arrow
                  placement="top"
                  title={muted ? 'Unmute' : 'Mute'}
                >
                  <IconButton
                    aria-label={muted ? 'Unmute' : 'Mute'}
                    onClick={() => dispatch(mute(!muted))}
                  >
                    {muted ? (
                      <VolumeMuteIcon className={classes.icon} />
                    ) : (
                      <VolumeUpIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip arrow placement="top" title="This video has no audio">
                  <IconButton className={classes.disabledIconButton}>
                    <VolumeOffIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
              <Typography variant="body2">
                {timeline &&
                  (mode === MODE.VOD
                    ? `${msToTime(currentTimeUCT)} / ${msToTime(
                      timeline[timeline.length - 1].end,
                    )}`
                    : `${dateToStringShort(
                      new Date(timeline[0].start),
                    )} - ${dateToStringSecs(new Date(currentTimeUCT))}`)}
              </Typography>
              <div className={classes.rightIcons}>
                <Tooltip arrow placement="top" title="Copy video URL">
                  <IconButton
                    data-tour="player-copyurl"
                    onClick={handleContextMenuOpen}
                  >
                    <CopyIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                {mode !== MODE.VOD && (
                  <DownloadStreamModalAndIcon
                    stream={streamQuery.data}
                    className={classes.icon}
                    timeline={timeline}
                    maxDuration={downloadTimeLimit}
                    currentTime={currentTimeUCT}
                    container={playerRootRef.current}
                  />
                )}
                <Tooltip
                  arrow
                  placement="top"
                  title={fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
                >
                  <IconButton
                    aria-label="Full Screen"
                    data-tour="player-fullscreen"
                    onClick={handleFullscreen}
                  >
                    {fullscreen ? (
                      <FullscreenExitIcon className={classes.icon} />
                    ) : (
                      <FullscreenIcon className={classes.icon} />
                    )}
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
          </div>
        </ThemeProvider>
      </div>
    )
  },
)

Player.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  accessToken: PropTypes.string,
  play: PropTypes.bool,
  onClose: PropTypes.func,
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  extraActions: PropTypes.element,
  notDraggable: PropTypes.string,
}

export default Player
