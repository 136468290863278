import { useMemo, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import qs from 'qs'
import moment from 'moment'
import _get from 'lodash/fp/get'

const parse = (str) =>
  Object.fromEntries(
    Object.entries(qs.parse(str, { ignoreQueryPrefix: true, comma: true })).map(
      ([key, value]) => [
        key,
        typeof value === 'string'
          ? // If the value is a top-level string, then we need to check whether
          // it's a date. The dates may have a + in them to indicate a GMT+x
          // timezone, but those are parsed as spaces
          value.replace(
            /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}) (\d{2}:\d{2})/,
            '$1+$2',
          )
          : value,
      ],
    ),
  )

const makeNewQueryString = (urlKey, params, newParams) =>
  qs.stringify(urlKey
    ? {
      ...params,
      [urlKey]: {
        ...params[urlKey],
        ...newParams,
      },
    }
    : {
      ...params,
      ...newParams,
    }, {
    encode: false,
    addQueryPrefix: true,
    strictNullHandling: true,
    arrayFormat: 'comma',
  })

/*
 * `urlKey` may be provided. It is either a string or the return value of
 * a React `useState` hook call.
 *
 * If it is a string, the query params will all be prefixed with this string in
 * the URL. This is useful when you need to have multiple sets of the same query
 * params on a single page. The `urlKey` effectively namespaces them.
 *
 * If it's the return value of a React `useHook` call, the query params will not
 * be reflected in the URL at all. Instead, they will be stored in the provided
 * React state. Note that the values are still serialized before being stored,
 * and deserialized before being returned.
 */
const useURLParams = (urlKey) => {
  const location = useLocation()
  const history = useHistory()

  const params = useMemo(() => {
    // We've been given the result of a useState hook, which means that instead
    // of storing the state in the URL we must store it in there
    if (Array.isArray(urlKey)) {
      return parse(urlKey[0] || '')
    }
    return parse(location.search)
  }, [urlKey, location.search])

  const setParams = useCallback(
    (_newParams) => {
      // Serialize Dates and Moment dates
      const newParams = Object.fromEntries(
        Object.entries(_newParams).map(([key, value]) => [
          key,
          moment.isMoment(value)
            ? value.format()
            : value instanceof Date
              ? moment(value).format()
              : value
        ])
      )

      if (Array.isArray(urlKey)) {
        urlKey[1]((currentQueryString) => {
          const params = parse(currentQueryString || '')
          return makeNewQueryString(undefined, params, newParams)
        })
      } else {
        const params = parse(history.location.search)
        const queryString = makeNewQueryString(urlKey, params, newParams)
        if (queryString !== history.location.search) {
          // TODO: consider whether using `history.push` is better. Or perhaps we
          // make it configurable.
          history.replace({ ...history.location, search: queryString })
        }
      }
    },
    [history, urlKey],
  )

  return useMemo(() => [
    typeof urlKey === 'string'
      ? _get(urlKey, params) ?? {}
      : params,
    setParams
  ], [
    urlKey,
    params,
    setParams,
  ])
}

export default useURLParams
