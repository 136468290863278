export const MILLISECONDS_IN_MINUTE = 60 * 1000

/**
 * Format a javascript Date object as a human-readable text string of the form
 * "Tue, Mar 9, 2021, 15:30:51 GMT+2".
 */
export const dateToString = (date, extraOptions) =>
  // set locale to undefined to get user's frontend timezone
  date?.toLocaleString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    // second: '2-digit',
    timeZoneName: 'short',
    // dateStyle: 'full',
    // timeStyle: 'long',
    ...extraOptions,
  })

/**
 * Format input date as: day Month, HH:MM.
 *
 * It is safe to leave off timezone in timestamps displayed to user since we always display dates
 * in user locale/frontend timezone.  In theory this can replace the other date formatting function
 * unless we need to keep it for displaying more detailed date strings where it is required.
 *
 * @param date
 * @param extraOptions
 * @returns {string | undefined}
 */
export const dateToStringShort = (date, extraOptions) =>
  // set locale to undefined to get user's frontend timezone
  date?.toLocaleString(undefined, {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    ...extraOptions,
  })

export const msToTime = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

// generate marks for the dvr timeline
export const generateDvrTimelineMarks = (timeline) =>
  timeline
    ?.flatMap(({ start, end }) => [start, end])
    .sort()
    .filter((t, i, ts) => {
      if (i === 0) return true

      // If the current time is too close to the next one
      // remove it
      if (ts[i + 1] - t < 60) {
        return false
      }

      return true
    })
    .map((t) => ({
      value: t,
      label: dateToStringShort(new Date(t)),
    }))

/**
 * Setup brand new Hls instance for new stream SRCPARAM from scratch.
 *
 * This must not use the "src" state slice, instead using an explicitly
 * passed-in parameter.
 *
 * TODO: the below is out-of-date, this issue has been fixed and our version of
 * HLS.js has been updated. We still need to investigate whether we need to
 * change our implementation here.
 *
 * Unfortunately, Hls.js does not yet support keeping the Hls instance alive
 * and just calling loadSource() to load a new chunklist, see
 * https://github.com/video-dev/hls.js/issues/2473
 *
 * This means we have to recreate the whole thing with every new chunklist in
 * history mode, which causes a visible frame blank at every transition,
 * potentialy every 10 minutes or so if the user is passively viewing history
 * mode video.
 */
/**
 * Wrap shared function to add seconds.
 */
export const dateToStringSecs = (date) =>
  dateToStringShort(date, { second: '2-digit' })

export const goFullScreen = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen()
  }
}

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

export const timelineToMs = (timeline) =>
  timeline.map((timespan) =>
    Object.fromEntries(
      Object.entries(timespan).map(([key, value]) => [key, value * 1000]),
    ),
  )

export const newLiveTimeline = (timeline) => {
  const end = Date.now()
  const start = timeline[timeline.length - 1].start
  const duration = end - start
  return timeline.slice(0, -1).concat({ start, duration, end })
}

/*
 * The video play method returns a promise that may reject either becasue the
 * user hasn't allowed autoplay or because the source loading was interrupted.
 *
 * If either of those errors happen, just swallow them and keep going
 */
export const playVideoSafely = async (video) => {
  try {
    return await video?.play()
  } catch (error) {
    if (
      error instanceof DOMException &&
      ['NotAllowedError', 'AbortError'].includes(error.name)
    ) {
      // Do nothing
    } else {
      throw error
    }
  }
}
