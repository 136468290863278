import React, { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch, Link } from 'react-router-dom'
import clsx from 'clsx'
import { use100vh } from 'react-div-100vh'
import _get from 'lodash/fp/get'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AppBar from '@material-ui/core/AppBar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SettingsIcon from '@material-ui/icons/Settings'
import StarIcon from '@material-ui/icons/Star'
import HomeIcon from '@material-ui/icons/Home'
import MapIcon from '@material-ui/icons/Map'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import CodeIcon from '@material-ui/icons/Code'
import HelpIcon from '@material-ui/icons/HelpOutline'
import HelpIconSolid from '@material-ui/icons/Help'
import MenuBookIcon from '@material-ui/icons/MenuBookOutlined'
import MailIcon from '@material-ui/icons/MailOutline'
import DarkIcon from '@material-ui/icons/Brightness4'
import LightIcon from '@material-ui/icons/Brightness7'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'

import { useIsAdmin, msal } from '../../services/msal'
import { useIsDSDManager } from '../../services/stream-manager'
import useWidth from '../../hooks/useWidth'
import usePrefs from '../../hooks/usePrefs'
import stoneThreeLogo from '../../assets/stone_three_logo_transparent_white.png'

import Tour, { Beacon } from '../tour'

import DivisionList from './DivisionList'
import AccountInfoList from './AccountInfoList'
import ApplicationBarButton from './ApplicationBarButton'

import useSurfaceStyles from './styles'


const DRAWER_WIDTH = 280

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: _get('fullHeight'),
  },
  appBar: {
    flex: 1,
    display: 'flex',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(-1.5),
  },
  appBarLogo: ({ isMobileOrTablet }) => ({
    display: isMobileOrTablet ? 'none' : 'block',
    margin: 0,
    marginRight: theme.spacing(2),
    height: '100%',
    width: 'auto',
    maxHeight: 48,
  }),
  topbarBranding: {
    display: 'flex',
    padding: theme.spacing(1),
    flex: 1,
    '& > h2': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      transform: 'translateY(-300%)',
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  topBarButton: {
    color: theme.palette.type === 'dark'
      ? theme.palette.text.primary
      : theme.palette.primary.main,
  },
  title: {
    flexGrow: 1,
    lineHeight: 1,
  },
  logoSecondary: ({ isMobileOrTablet }) => ({
    display: isMobileOrTablet ? 'none' : 'flex',
    width: isMobileOrTablet ? undefined : 350,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    '& > img': {
      display: 'block',
      maxWidth: `calc(100% - ${theme.spacing(2)}px)`,
      maxHeight: 48,
    },
  }),
  desktop: {},
  mobileOrTablet: {},
  drawer: {
    position: 'sticky',
    top: 0,
    height: _get('fullHeight'),
    '&$desktop': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&$mobileOrTablet': {
      overflow: 'overlay !important',
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'overlay !important',
    fallbacks: {
      overflow: 'auto !important',
    },
    backgroundColor: theme.palette.type === 'dark'
      ? theme.palette.primary.dark
      : theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  drawerBackWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
  },
  drawerBackIcon: {
    color: 'inherit',
  },
  mainDrawer: {
    '&$desktop': {
      flex: 1,
      overflow: 'overlay !important',
      fallbacks: {
        overflow: 'auto !important',
      },
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: _get('fullHeight'),
    flex: 1,
    position: 'relative',
    width: ({ isMobileOrTablet }) => isMobileOrTablet ? '100%' : `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'overlay !important',
    flexDirection: 'column',
    fallbacks: {
      overflow: 'auto !important',
    },
  },
  fixedHeight: {
    height: 240,
  },
  developerLogoWrapper: {
    position: 'relative',
    width: '75%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  developerLogo: {
    width: '100%',
    height: 'auto',
    visibility: 'hidden',
    display: 'block',
  },
  developerLogoMask: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    mask: `url(${stoneThreeLogo}) 0 0 / 100% 100%`,
  },
  sidebarLogo: {
    width: '75%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const GlobalItems = ({ onSelect }) => {
  const surfaceClasses = useSurfaceStyles()
  const isAdmin = useIsAdmin()
  const isDSDManager = useIsDSDManager()
  const width = useWidth()
  const isMobileOrTablet = width.match(/(xs|sm)/)

  const homeMatch = Boolean(useRouteMatch({ path: '/', exact: true }))
  const mapMatch = Boolean(useRouteMatch('/map'))
  const dashboardMatch = Boolean(useRouteMatch('/dashboard'))
  const analyticsMatch = Boolean(useRouteMatch('/analytics'))
  const settingsMatch = Boolean(useRouteMatch('/settings'))
  const DSDMatch = Boolean(useRouteMatch('/digital-signage'))

  return (
    <List>
      <ListItem
        className={surfaceClasses.listItem}
        classes={{ selected: surfaceClasses.listItemSelected }}
        data-tour="sidebar-home"
        selected={homeMatch}
        button
        onClick={onSelect}
        component={Link}
        to="/"
      >
        <ListItemIcon>
          <HomeIcon className={surfaceClasses.drawerIcons} />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem
        className={surfaceClasses.listItem}
        classes={{ selected: surfaceClasses.listItemSelected }}
        data-tour="sidebar-map"
        selected={mapMatch}
        button
        onClick={onSelect}
        component={Link}
        to="/map"
      >
        <ListItemIcon>
          <MapIcon className={surfaceClasses.drawerIcons} />
        </ListItemIcon>
        <ListItemText primary="Map" />
      </ListItem>
      <ListItem
        className={surfaceClasses.listItem}
        classes={{ selected: surfaceClasses.listItemSelected }}
        data-tour="sidebar-dashboard"
        selected={dashboardMatch}
        button
        onClick={onSelect}
        component={Link}
        to="/dashboard"
      >
        <ListItemIcon>
          <StarIcon className={surfaceClasses.drawerIcons} />
        </ListItemIcon>
        <ListItemText primary="My Dashboard" />
      </ListItem>
      {!isMobileOrTablet && isDSDManager && (
        <ListItem
          className={surfaceClasses.listItem}
          classes={{ selected: surfaceClasses.listItemSelected }}
          selected={DSDMatch}
          button
          onClick={onSelect}
          component={Link}
          to="/digital-signage/manage"
        >
          <ListItemIcon>
            <OndemandVideoIcon className={surfaceClasses.drawerIcons} />
          </ListItemIcon>
          <ListItemText primary="Digital Signage" />
        </ListItem>
      )}
      {!isMobileOrTablet && isAdmin && (
        <>
          <ListItem
            className={surfaceClasses.listItem}
            classes={{ selected: surfaceClasses.listItemSelected }}
            selected={analyticsMatch}
            button
            onClick={onSelect}
            component={Link}
            to="/analytics"
          >
            <ListItemIcon>
              <AssessmentIcon className={surfaceClasses.drawerIcons} />
            </ListItemIcon>
            <ListItemText primary="Analytics" />
          </ListItem>
          <ListItem
            className={surfaceClasses.listItem}
            classes={{ selected: surfaceClasses.listItemSelected }}
            selected={settingsMatch}
            button
            onClick={onSelect}
            component={Link}
            to="/settings"
          >
            <ListItemIcon>
              <SettingsIcon className={surfaceClasses.drawerIcons} />
            </ListItemIcon>
            <ListItemText primary="Admin" />
          </ListItem>
        </>
      )}
    </List>
  )
}

const Surface = ({ children }) => {
  const theme = useTheme()
  const width = useWidth()
  const isMobileOrTablet = useMemo(() => width.match(/(xs|sm)/), [width])
  const fullHeight = use100vh()
  const classes = useStyles({ isMobileOrTablet, fullHeight })
  const isTabletOrMobileDevice = useMemo(() => width.match(/(xs|sm|md)/), [
    width,
  ])
  const [mobileOpen, setMobileOpen] = useState(false)
  // Force the drawer to be open on desktop
  const open = useMemo(() => (isTabletOrMobileDevice ? mobileOpen : true), [
    isTabletOrMobileDevice,
    mobileOpen,
  ])

  const toggleDrawer = useCallback(() => setMobileOpen((open) => !open), [])
  const handleDrawerOpen = useCallback(() => setMobileOpen(true), [])
  const handleDrawerClose = useCallback(() => setMobileOpen(false), [])
  const handleLogout = useCallback(() => msal.logoutRedirect(), [])

  useEffect(
    function closeWhenBecomingMobile() {
      if (isTabletOrMobileDevice) {
        setMobileOpen(false)
      }
    },
    [isTabletOrMobileDevice],
  )

  const [prefs, setPrefs] = usePrefs()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const colorScheme = useMemo(
    () => prefs.colorScheme ?? (prefersDarkMode ? 'dark' : 'light'),
    [prefs.colorScheme, prefersDarkMode],
  )

  const toggleColorScheme = useCallback(() => {
    if (prefs.colorScheme == null) {
      setPrefs({ colorScheme: prefersDarkMode ? 'light' : 'dark' })
    } else if (prefs.colorScheme === 'light') {
      setPrefs({ colorScheme: prefersDarkMode ? undefined : 'dark' })
    } else if (prefs.colorScheme === 'dark') {
      setPrefs({ colorScheme: prefersDarkMode ? 'light' : undefined })
    }
  }, [prefersDarkMode, prefs.colorScheme, setPrefs])

  const toggleTours = useCallback(() => {
    setPrefs((prefs) => ({
      ...prefs,
      tour: {
        ...prefs.tour,
        hasRun: undefined,
        enableTour: prefs.tour?.enableTour ? false : true,
      },
    }))
  }, [setPrefs])

  return (
    <>
      <Tour
        name="sidebar"
        steps={[
          {
            disableBeacon: true,
            target: 'body',
            title: 'Welcome Tour',
            content: (
              <>
                <p>This is a tooltip tour explaining how to use this system.</p>
                <p>
                  If you want to skip it and return to it later, click the help
                  icon{' '}
                  <HelpIcon
                    color="primary"
                    style={{ fontSize: '1.5em', verticalAlign: 'bottom' }}
                  />{' '}
                  in the top-right of the application.
                </p>
                <p>Let's get started!</p>
              </>
            ),
            placement: 'center',
          },
          {
            target: 'body',
            title: 'Help Indicators',
            content: (
              <>
                <p>
                  Various help tours are available in the application. Some
                  start automatically, others are indicated using this
                  indicator:
                </p>
                <Beacon />
                <p>
                  Click on it to start the tour. If you close a tooltip in the
                  middle of a tour, this indicator will be shown to indicate
                  where you can resume it.
                </p>
              </>
            ),
            placement: 'center',
          },
          {
            target: '[data-tour="sidebar-home"]',
            title: 'Home',
            content:
              'Click here to see which streams are currently popular and to see upcoming events.​',
            placement: 'right',
          },
          {
            target: '[data-tour="sidebar-map"]',
            title: 'See where we’re streaming from',
            content:
              'Click here to see the location of the streams overlaid on a map.​',
            placement: 'right',
          },
          {
            target: '[data-tour="sidebar-dashboard"]',
            title: 'Create your own streaming page',
            content: 'Click here to customize your own streams page layout.​',
            placement: 'right',
          },
          {
            target: '[data-tour="sidebar-streams"]',
            title: 'Browse streams',
            content:
              'Click here to browse through streams and uploaded videos.',
            placement: 'right',
          },
          {
            target: 'body',
            title: 'Thank you!',
            content: (
              <>
                <p>This is the end of the first tour.</p>
                <p>
                  Remember to look out for help indicators to trigger new tours:
                </p>
                <Beacon />
                <p>
                  And if you ever want to re-set the tours, you can click the
                  help icon{' '}
                  <HelpIcon
                    color="primary"
                    style={{ fontSize: '1.5em', verticalAlign: 'bottom' }}
                  />{' '}
                  in the top-right of the application.
                </p>
              </>
            ),
            placement: 'center',
          },
        ]}
      />
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar disableGutters>
            {isTabletOrMobileDevice ? (
              <IconButton
                color="inherit"
                aria-label={`${open ? 'close' : 'open'} drawer`}
                onClick={toggleDrawer}
                className={classes.menuButton}
              >
                {open ? (
                  <ArrowBackIcon fontSize="large" />
                ) : (
                  <MenuIcon fontSize="large" />
                )}
              </IconButton>
            ) : null}
            <div className={classes.topbarBranding}>
              {theme.assets?.logoTitle &&
                []
                  .concat(theme.assets.logoTitle)
                  .filter((_, i) => !isMobileOrTablet || i === 0)
                  .map((logo) => (
                    <img
                      key={logo}
                      className={classes.appBarLogo}
                      src={`/theme/${logo}`}
                      alt="Logo"
                    />
                  ))}
              {!isMobileOrTablet &&
                !Array.isArray(theme.assets?.logoTitle) &&
                theme.assets?.titleText && (
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className={classes.title}
                  >
                    {theme.assets?.titleText}
                  </Typography>
                )}
            </div>
            {window.__ENV.REACT_APP_DARKMODE === 'true' && (
              <ApplicationBarButton
                className={classes.topBarButton}
                title={`Switch to ${colorScheme === 'light' ? 'dark' : 'light'
                  } mode`}
                onClick={toggleColorScheme}
                icon={colorScheme === 'light' ? <DarkIcon /> : <LightIcon />}
              />
            )}
            {window.__ENV.REACT_APP_TRAINING_DOCS_URL != null && (window.__ENV.REACT_APP_TRAINING_DOCS_URL.length > 0) && (
              <ApplicationBarButton
                className={classes.topBarButton}
                title="Training docs"
                href={window.__ENV.REACT_APP_TRAINING_DOCS_URL}
                icon={<MenuBookIcon />}
              />
            )}
            <ApplicationBarButton
              className={classes.topBarButton}
              title="API docs"
              href="/api/docs"
              icon={<CodeIcon />}
            />
            {!isMobileOrTablet && (
              <ApplicationBarButton
                className={classes.topBarButton}
                title={prefs.tour?.enableTour ? "Hide tours" : "Show tours"}
                onClick={toggleTours}
                icon={prefs.tour?.enableTour ? <HelpIconSolid /> : <HelpIcon />}
              />
            )}
            <ApplicationBarButton
              className={classes.topBarButton}
              title="Support"
              href={[
                'mailto:',
                window.__ENV.REACT_APP_SUPPORT_EMAIL_ADDRESS,
                '?subject=',
                encodeURIComponent(
                  window.__ENV.REACT_APP_SUPPORT_EMAIL_SUBJECT,
                ),
                '&body=',
                encodeURIComponent(
                  `${window.__ENV.REACT_APP_SUPPORT_EMAIL_BODY}`.replace(
                    /\n/g,
                    '\r\n',
                  ),
                ),
              ].join('')}
              icon={<MailIcon />}
            />
            <ApplicationBarButton
              className={classes.topBarButton}
              title="Logout"
              onClick={handleLogout}
              icon={<ExitToAppIcon />}
            />
            {theme.assets?.logoSecondary && (
              <div className={classes.logoSecondary}>
                <img src={`/theme/${theme.assets.logoSecondary}`} alt="Logo" />
              </div>
            )}
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          className={clsx(classes.drawer, {
            [classes.mobileOrTablet]: isMobileOrTablet,
            [classes.desktop]: !isMobileOrTablet,
          })}
          variant={isTabletOrMobileDevice ? 'temporary' : 'permanent'}
          classes={{ paper: classes.drawerPaper }}
          open={open}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
        >
          <Toolbar disableGutters className={classes.drawerBackWrapper}>
            <IconButton
              className={classes.drawerBackIcon}
              onClick={handleDrawerClose}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <div className={clsx(classes.mainDrawer, {
            [classes.desktop]: !isMobileOrTablet,
          })}>
            <GlobalItems onSelect={handleDrawerClose} />
            <Divider />
            <AccountInfoList onSelect={handleDrawerClose} />
            <Divider />
            <DivisionList onSelect={handleDrawerClose} />
            {theme.assets?.logoSidebar && (
              <>
                <Divider />
                {[].concat(theme.assets?.logoSidebar).map((logo) => (
                  <img
                    key={logo}
                    className={classes.sidebarLogo}
                    src={`/theme/${logo}`}
                    alt="Logo"
                  />
                ))}
              </>
            )}
            {window.__ENV.REACT_APP_S3_LOGO === 'true' && (
              <>
                <Divider />
                <div className={classes.developerLogoWrapper}>
                  <img
                    className={clsx(classes.developerLogo)}
                    src={stoneThreeLogo}
                    alt="Developed by Stone Three"
                  />
                  <div className={classes.developerLogoMask} />
                </div>
              </>
            )}
          </div>
        </SwipeableDrawer>
        <div className={classes.contentWrapper}>
          <Toolbar />
          {children}
        </div>
      </div>
    </>
  )
}

Surface.propTypes = {
  children: PropTypes.element,
}

export default Surface
