import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from "@material-ui/core/IconButton"


const ApplicationBarButton = ({ title, href, icon, onClick, ...rest }) => {
    // These props are set for button with a click handler.
    const clickHandlerProps = { onClick }
    // These props are set for a button with href to URL/action outside of app, e.g. API docs or support mail compose.
    const hrefProps = {
        target: '_blank',
        rel: 'noopener noreferrer',
        href,
    }
    const iconButtonProps = onClick ? clickHandlerProps : hrefProps

    return (
        <>
            <Tooltip
                arrow
                title={title}
                placement="bottom"
            >
                <IconButton
                    color="primary"
                    {...iconButtonProps}
                    {...rest}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        </>
    )
}

export default ApplicationBarButton
