import React, {
  Children,
  cloneElement,
  useState,
  useMemo,
  useCallback,
} from 'react'
import { Formik, Form } from 'formik'
import _difference from 'lodash/fp/difference'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import DisableFormFields from '../../styles/DisableFormFields'
import {
  useAddEntityToGroupMutation,
  useRemoveEntityFromGroupMutation,
  mergeQueryResultsWith,
} from '../../services/stream-manager'

import AlertDialog from '../dialogs/AlertDialog'

const useStyles = makeStyles((theme) => ({
  content: {
    overflowY: 'auto !important',
    overflowX: 'unset !important',
  },
  progress: {
    marginBottom: -theme.spacing(0.5),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}))

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const dummyMutation = [undefined, {}]

const AddEditDialog = ({
  label,
  editing,
  open,
  setOpen,
  createValidationSchema,
  initialValues,
  createMutation: [create, createResult] = dummyMutation,
  updateMutation: [update, updateResult] = dummyMutation,
  children: child,
}) => {
  Children.only(child)

  const classes = useStyles()
  const [addMultiple, setAddMultiple] = useState(false)

  const [
    addEntityToGroup,
    addEntityToGroupResult,
  ] = useAddEntityToGroupMutation()
  const [
    removeEntityFromGroup,
    removeEntityFromGroupResult,
  ] = useRemoveEntityFromGroupMutation()

  const combinedMutationStatus = useCombinedStatus(
    createResult,
    updateResult,
    addEntityToGroupResult,
    removeEntityFromGroupResult,
  )

  const handleEdit = useCallback(
    async ({ __groups__, ...values }) => {
      // Since we use PATCH endpoints, so only send through changed fields
      const changedValues = Object.fromEntries(
        Object.entries(values).filter(
          ([prop, value]) => value !== initialValues[prop],
        ),
      )

      if (Object.keys(changedValues).length) {
        const updateResponse = await update(
          {
            id: initialValues.id,
            ...Object.fromEntries(
              Object.entries(values).filter(
                ([prop, value]) => value !== initialValues[prop],
              ),
            ),
          },
          initialValues,
        )
        if (updateResponse.error) return
      }

      const newGroups = _difference(__groups__, initialValues.__groups__)
      if (newGroups.length) {
        const addResponse = await addEntityToGroup(
          newGroups.map((group) => ({ group, entity_id: initialValues.id })),
        )
        if (addResponse.error) return
      }

      const oldGroups = _difference(initialValues.__groups__, __groups__)
      if (oldGroups.length) {
        const removeResponse = await removeEntityFromGroup(
          oldGroups.map((group) => ({ group, entity_id: initialValues.id })),
        )
        if (removeResponse.error) return
      }

      setOpen(false)
    },
    [setOpen, update, initialValues, addEntityToGroup, removeEntityFromGroup],
  )

  const handleAdd = useCallback(
    async (
      { __groups__: [group, ...otherGroups] = [], ...values },
      { handleReset },
    ) => {
      values = { groups: [group, ...otherGroups], ...values }
      const createResponse = await create({ group, ...values })
      if (createResponse?.error) return

      if (otherGroups.length) {
        const addResponse = await addEntityToGroup(
          otherGroups.map((group) => ({
            group,
            entity_id: createResponse.data.id,
          })),
        )
        if (addResponse.error) return
      }

      addMultiple && handleReset()
      setOpen(addMultiple)
    },
    [setOpen, addMultiple, create, addEntityToGroup],
  )

  const title = `${editing ? 'Edit' : 'Add'} ${label}`

  const validationSchema = useMemo(
    () => createValidationSchema?.({ editing }),
    [createValidationSchema, editing],
  )

  return (
    <>
      {[
        createResult,
        updateResult,
        addEntityToGroupResult,
        removeEntityFromGroupResult,
      ].map((result, i) => (
        <AlertDialog
          key={result.startedTimeStamp || i}
          open={result.isError}
          severity="error"
          title={`Error ${editing ? 'updating' : 'creating'} ${label}`}
          status={result.error?.status}
          alert={result.error?.data}
        />
      ))}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        {combinedMutationStatus.isLoading && (
          <LinearProgress className={classes.progress} />
        )}
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={editing ? handleEdit : handleAdd}
          >
            {() => (
              <Form>
                <DisableFormFields
                  disabled={
                    editing ? updateResult.isLoading : createResult.isLoading
                  }
                >
                  {cloneElement(child, { editing })}
                  <Box
                    mt={2}
                    mb={1}
                    display="flex"
                    justifyContent="space-between"
                  >
                    {!editing && (
                      <FormControlLabel
                        label="Add Multiple"
                        control={
                          <Switch
                            checked={addMultiple}
                            onChange={() =>
                              setAddMultiple((checked) => !checked)
                            }
                          />
                        }
                      />
                    )}
                    <Box>
                      <Button
                        type="button"
                        className={classes.cancelButton}
                        onClick={() => setOpen(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        {editing ? 'Update' : 'Add'} {label}
                      </Button>
                    </Box>
                  </Box>
                </DisableFormFields>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AddEditDialog

export const AddEditButton = ({
  label,
  editing,
  'data-tour': dataTour,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const title = `${editing ? 'Edit' : 'Add'} ${label}`

  return (
    <>
      <Tooltip title={title}>
        <IconButton
          data-tour={dataTour}
          aria-label={title}
          edge={!editing ? 'start' : undefined}
          onClick={() => setOpen(true)}
          size={editing ? 'small' : 'medium'}
        >
          {editing ? <EditIcon fontSize="small" /> : <AddIcon />}
        </IconButton>
      </Tooltip>
      <AddEditDialog
        open={open}
        setOpen={setOpen}
        label={label}
        editing={editing}
        {...props}
      />
    </>
  )
}
