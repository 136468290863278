import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import slug from 'slug'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import GPSIcon from 'mdi-material-ui/MapMarkerPath'

import { useStreamQuery } from '../../services/stream-manager'
import { base64ToUuid } from '../../utils/uuid'
import useURLParams from '../../hooks/useURLParams'

import PageTitle from '../../components/PageTitle'

import Container from '../layout/Container'
import AlertDialog from '../dialogs/AlertDialog'
import { TopStreamViewers } from '../analytics/TopStreamViewers'
import FavouriteButton from '../dashboard/FavouriteButton'

import Player from '../player/Player'

import StreamHealth from './StreamHealth'

const useStyles = makeStyles((theme) => ({
  playerContainer: {
    backgroundColor: theme.palette.grey[900],
  },
  player: {
    margin: '0 auto',
  },
  cardHeader: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(4),
    paddingTop: 0,
  },
  cardTitle: {
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '-7px',
  },
  vodInfoTable: {
    marginBottom: theme.spacing(1),
    fontSize: 'smaller',
    '& th': {
      textAlign: 'left',
      padding: 0,
    },
    '& td': {
      padding: 0,
    },
  },
  vodInfoFresh: {
    color: theme.palette.success.main,
  },
  vodInfoStale: {
    color: theme.palette.warning.main,
  },
  vodInfoExpiring: {
    color: theme.palette.error.main,
  },
  location: {
    fontSize: '0.7rem',
    marginBottom: theme.spacing(1),
  },
  streamHealth: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& svg': {
      fontSize: '1rem',
      marginRight: theme.spacing(1),
    },
  },
}))

const StreamPage = ({ match, location }) => {
  const history = useHistory()
  const classes = useStyles()

  const [time, setTime] = useState()
  const [{ time: _time }] = useURLParams()
  useEffect(() => {
    if (!_time) return
    if (_time.match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})\+(\d{2}:\d{2})/)) {
      setTime(moment(_time).valueOf())
    } else {
      setTime(moment.duration(_time).asMilliseconds())
    }
  }, [_time])

  const id = useMemo(() => base64ToUuid(match.params.id), [match.params.id])

  const streamQuery = useStreamQuery(id)

  // Add a slug to the path. It's optional, but it makes it easier for users to
  // recognize the URL
  useEffect(() => {
    if (streamQuery.data) {
      const streamSlug = slug(streamQuery.data.name ?? '')
      if ((match.params.slug ?? '') !== streamSlug) {
        history.replace(
          [
            location.pathname,
            '/' + streamSlug,
            location.search,
            location.hash,
          ].join(''),
        )
      }
    }
  }, [history, location, streamQuery.data, match.params.slug])

  return (
    <>
      <AlertDialog
        key={streamQuery.startedTimeStamp}
        open={streamQuery.isError}
        status={streamQuery.error?.status}
        alert={streamQuery.error?.data}
      />
      <PageTitle>{streamQuery.data?.name ?? ''}</PageTitle>
      <Container className={classes.container}>
        <Card>
          <div className={classes.playerContainer}>
            <Player play className={classes.player} id={id} time={time} />
          </div>

          <CardHeader
            classes={{ root: classes.cardHeader, title: classes.cardTitle }}
            title={streamQuery.data?.description ?? ''}
            subheader={[
              streamQuery.data?.division?.name,
              streamQuery.data?.site?.name,
              streamQuery.data?.section?.name,
            ]
              .filter(Boolean)
              .join(' / ')}
            action={
              streamQuery.data && (
                <>
                  <FavouriteButton streamId={id} />
                  <TopStreamViewers stream={streamQuery.data} />
                </>
              )
            }
          />
          <CardContent className={classes.cardContent}>
            {streamQuery.data?.is_vod && (() => {
              const now = moment.utc()
              const expires = streamQuery.data.uploaded && moment
                .utc(streamQuery.data.uploaded)
                .add(window.__ENV.REACT_APP_VOD_EXPIRY_WINDOW, 'seconds')

              return (
                <table
                  className={clsx(
                    classes.vodInfoTable,
                    expires?.diff(now, 'days') < 1
                      ? classes.vodInfoExpiring
                      : expires?.diff(now, 'weeks') < 1
                        ? classes.vodInfoStale
                        : classes.vodInfoFresh
                  )}
                >
                  <tbody>
                    {streamQuery.data.video_id && (
                      <tr>
                        <th scope="row">ID:</th>
                        <td>{streamQuery.data.video_id}</td>
                      </tr>
                    )}
                    {streamQuery.data.video_type && (
                      <tr>
                        <th scope="row">Type:</th>
                        <td>{streamQuery.data.video_type}</td>
                      </tr>
                    )}
                    {streamQuery.data.source && (
                      <tr>
                        <th scope="row">Source:</th>
                        <td>{streamQuery.data.source}</td>
                      </tr>
                    )}
                    {streamQuery.data.source && (
                      <>
                        <tr>
                          <th scope="row">Uploaded:</th>
                          <td>{moment.utc(streamQuery.data.uploaded).format('D/MM/YYYY')}</td>
                        </tr>
                        <tr>
                          <th scope="row">Expires:</th>
                          <td>{expires.format('D/MM/YYYY')}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              )
            })()}
            {streamQuery.data?.recorded && (
              <Typography
                className={classes.location}
                color="textSecondary"
                component="p"
              >
                {moment
                  .utc(streamQuery.data?.recorded)
                  .format('D MMM YYYY HH:mm')}
              </Typography>
            )}
            <div className={classes.streamHealth}>
              <StreamHealth id={id} />
              {streamQuery.data?.has_track && (
                <Tooltip title={`Has a GPS track`}>
                  <GPSIcon />
                </Tooltip>
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

export default StreamPage
