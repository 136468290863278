import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'

import boxToDropShadow from '../../styles/boxToDropShadow'

import {
  useMarkStreamFavouriteMutation,
  useUnmarkStreamFavouriteMutation,
  mergeQueryResultsWith,
  useIsFavouriteStreamQuery,
} from '../../services/stream-manager'

import AlertDialog from '../dialogs/AlertDialog'

const useStyles = makeStyles((theme) => ({
  favouriteIcon: {
    color: theme.palette.common.white,
    cursor: 'pointer',
    filter: boxToDropShadow(theme.shadows[1]),
  },
  loading: ({ isFavourite }) => ({
    color: isFavourite
      ? theme.palette.primary.light
      : theme.palette.common.white,
    filter: boxToDropShadow(theme.shadows[1]),
  }),
  isFavourite: {
    color: theme.palette.primary.light,
  },
}))

const useCombinedStatus = mergeQueryResultsWith(() => undefined)

const FavouriteButton = ({ className, streamId, inPlayer }) => {
  const isFavouriteStreamQuery = useIsFavouriteStreamQuery(streamId)

  const isFavourite = isFavouriteStreamQuery.isSuccess

  const classes = useStyles({ isFavourite })

  const [
    markStreamFavourite,
    markStreamFavouriteResult,
  ] = useMarkStreamFavouriteMutation()
  const [
    unmarkStreamFavourite,
    unmarkStreamFavouriteResult,
  ] = useUnmarkStreamFavouriteMutation()

  const combinedStatus = useCombinedStatus(
    isFavouriteStreamQuery,
    markStreamFavouriteResult,
    unmarkStreamFavouriteResult,
  )

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (isFavourite) {
        return unmarkStreamFavourite({ id: streamId })
      } else {
        return markStreamFavourite({ id: streamId })
      }
    },
    [isFavourite, streamId, markStreamFavourite, unmarkStreamFavourite],
  )

  const loading = combinedStatus.isLoading || combinedStatus.isFetching

  return (
    <>
      <AlertDialog
        open={markStreamFavouriteResult.isError}
        status={markStreamFavouriteResult.error?.status}
        alert={markStreamFavouriteResult.error?.data}
      />
      <Tooltip
        title={
          loading
            ? 'Loading'
            : isFavourite
            ? 'Remove from My Dashboard'
            : 'Add to My Dashboard'
        }
        arrow
        placement="top"
      >
        <IconButton
          className={clsx(className, classes.favouriteIcon)}
          onClick={!loading ? handleClick : undefined}
        >
          {combinedStatus.isLoading || combinedStatus.isFetching ? (
            <CircularProgress
              size={!inPlayer ? 35 : 19.2}
              className={classes.loading}
            />
          ) : isFavourite ? (
            <StarIcon
              fontSize={!inPlayer ? 'large' : undefined}
              className={classes.isFavourite}
            />
          ) : (
            <StarBorderIcon fontSize={!inPlayer ? 'large' : undefined} />
          )}
        </IconButton>
      </Tooltip>
    </>
  )
}

FavouriteButton.propTypes = {
  streamId: PropTypes.string.isRequired,
}

export default FavouriteButton
